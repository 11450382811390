/* Advanced Buttons */
/* Loading Buttons - Thanks to https://tympanus.net/Development/ProgressButtonStyles/ */
.progress-button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .85rem 2.13rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    position: relative;
    outline: none;
    background: #1d9650;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.progress-button[disabled],
.progress-button[disabled].state-loading {
    cursor: default;
}

.progress-button .content {
    position: relative;
    display: block;
}

.progress-button .content::before,
.progress-button .content::after {
    position: absolute;
    right: 20px;
    bottom: 1rem;
    color: #fff;
    font-family: "Roboto";
    opacity: 0;
    -webkit-transition: opacity 0.3s 0.3s;
    transition: opacity 0.3s 0.3s;
    line-height: 1.5rem;
    font-size: 3rem;
}

.progress-button .content::before {
    /* Checkmark for success */
    content: "";
    background-color: transparent;
    width: 12px;
    /* short arm */
    border-bottom: 5px solid #fff;
    height: 26px;
    /* long arm */
    border-right: 5px solid #fff;
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.progress-button .content::after {
    content: "\D7";
    /* Cross for error */
}

.progress-button.state-success .content::before,
.progress-button.state-error .content::after {
    opacity: 1;
}

.notransition {
    -webkit-transition: none !important;
    transition: none !important;
}

.progress-button .progressBar {
    background: #148544;
}

.progress-button .progress-inner {
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
}

.progress-button[data-horizontal] .progress-inner {
    top: 0;
    width: 0;
    height: 100%;
    -webkit-transition: width 0.3s, opacity 0.3s;
    transition: width 0.3s, opacity 0.3s;
}

.progress-button[data-vertical] .progress-inner {
    bottom: 0;
    width: 100%;
    height: 0;
    -webkit-transition: height 0.3s, opacity 0.3s;
    transition: height 0.3s, opacity 0.3s;
}

.progress-button[data-perspective] {
    position: relative;
    display: inline-block;
    padding: 0;
    background: transparent;
    -webkit-perspective: 900px;
    perspective: 900px;
}

.progress-button[data-perspective] .content {
    padding: 0 60px;
    background: #1d9650;
}

.progress-button[data-perspective] .progress-wrap {
    display: block;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.progress-button[data-perspective] .content,
.progress-button[data-perspective] .progressBar {
    outline: 1px solid rgba(0, 0, 0, 0);
    /* Smoothen jagged edges in FF */
}

.progress-button[data-style="shrink"] {
    overflow: hidden;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.progress-button[data-style="shrink"][data-horizontal] .content {
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
}

.progress-button[data-style="shrink"][data-horizontal] .content::before,
.progress-button[data-style="shrink"][data-horizontal] .content::after {
    bottom: -3rem;
    right: auto;
    left: 50%;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.progress-button[data-style="shrink"][data-horizontal] .content::before {
    transform: rotate(45deg) translateX(-50%);
    -o-transform: rotate(45deg) translateX(-50%);
    -ms-transform: rotate(45deg) translateX(-50%);
    -webkit-transform: rotate(45deg) translateX(-50%);
}

.progress-button[data-style="shrink"][data-horizontal] .content::after {
    transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.progress-button[data-style="shrink"][data-horizontal].state-loading {
    -webkit-transform: scaleY(0.3);
    transform: scaleY(0.3);
}

.progress-button[data-style="shrink"][data-horizontal].state-loading .content {
    opacity: 0;
}

.progress-button[data-style="shrink"][data-horizontal].state-success .content,
.progress-button[data-style="shrink"][data-horizontal].state-error .content {
    -webkit-transform: translateY(-3rem);
    transform: translateY(-3rem);
}