.cfo-assistant {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: background .2s ease-in-out;
    pointer-events: none;
    background: transparent;
}

.cfo-assistant.open {
    background: rgba(0, 0, 0, 0.4);
    pointer-events: all;
}

.cfo-assistant .assistant-sidebar {
    position: absolute;
    right: -4rem;
    top: 0;
    height: 100%;
    width: 4rem;
    background: #222;
    display: flex;
    flex-direction: column;

    transition: right .1s ease-in-out;
}

.cfo-assistant .assistant-sidebar .placeholder {
    display: block;
    position: sticky;
    top: 0;
    right: 0;
    height: 4rem;
    background: #222;
    z-index: 10;
}

.cfo-assistant .assistant-sidebar hr {
    margin: 0 .75rem;
    background: #fff;
    position: sticky;
    top: 4rem;
    z-index: 10;
}

.cfo-assistant.open .assistant-sidebar {
    right: 0;
}

.cfo-assistant button {
    flex: 0 0 auto;
}

.cfo-assistant > button {
    position: absolute;
    top: .7rem;
    right: .7rem;
    z-index: 1000;
    width: 2rem;
    padding: .2rem 0 0 0;
    height: 2rem;
    pointer-events: all;
}
@media (max-width:960px){
    .cfo-assistant > button {
        top: 0.5rem;
        right: 3.75rem;
    }
}

.cfo-assistant.open button {
    color: #fff;
}

.cfo-assistant.open .assistant-sidebar button {
    width: 100%;
    height: 4rem;
    margin: 0;
}

.cfo-assistant-blocker {
    height: 2rem;
    width: 2rem;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 500;
}

.cfo-assistant-note span {
    color: red;
}

.cfo-assistant .scroll-vertical {
    width: .1rem;
    height: 100%;
    right: 1px;
    top: 0;
}

.cfo-assistant .thumb-vertical {
    background: #aaa;
}

/* .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}

.wrapperOpen {
    display: block;
    opacity: 1;
}

.button {
    position: fixed;
    top: 1rem;
    right: 1rem;
    transition: opacity .2s ease-in-out;
    z-index: 1001;
}

.button > button {
    height: 2rem;
    width: 2rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
}

.wrapperOpen + .button > button {
    background: #fff;
}

.closeButton {
    background: transparent;
    border: none;
    color: white;
    z-index: 3000;
    position: fixed;
    top: 1.25rem;
    right: .9rem;
    cursor: pointer;
}

.icon {
    color: #fff;
}

.child {
    right: -.8rem;
}

.note span {
    color: red;
}

.actions {
    position: relative;
}

.blocker {
    height: 2rem;
    width: 2rem;
    margin: 0;
    padding: 0;
} */