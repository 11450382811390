:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #868e96;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #868e96;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
    width: device-width
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

[role=button],
a,
area,
button,
input:not([type=range]),
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #868e96;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3 {
    font-size: 1.75rem
}

.h4,
h4 {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #868e96
}

.blockquote-footer::before {
    content: "\2014 \00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #868e96
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12
}

.offset-1 {
    margin-left: 8.333333%
}

.offset-2 {
    margin-left: 16.666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.333333%
}

.offset-5 {
    margin-left: 41.666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.333333%
}

.offset-8 {
    margin-left: 66.666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.333333%
}

.offset-11 {
    margin-left: 91.666667%
}

@media (min-width:576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.333333%
    }

    .offset-sm-2 {
        margin-left: 16.666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.333333%
    }

    .offset-sm-5 {
        margin-left: 41.666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.333333%
    }

    .offset-sm-8 {
        margin-left: 66.666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.333333%
    }

    .offset-sm-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.333333%
    }

    .offset-md-2 {
        margin-left: 16.666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.333333%
    }

    .offset-md-5 {
        margin-left: 41.666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.333333%
    }

    .offset-md-8 {
        margin-left: 66.666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.333333%
    }

    .offset-md-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.333333%
    }

    .offset-lg-2 {
        margin-left: 16.666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.333333%
    }

    .offset-lg-5 {
        margin-left: 41.666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.333333%
    }

    .offset-lg-8 {
        margin-left: 66.666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.333333%
    }

    .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.333333%
    }

    .offset-xl-2 {
        margin-left: 16.666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.333333%
    }

    .offset-xl-5 {
        margin-left: 41.666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.333333%
    }

    .offset-xl-8 {
        margin-left: 66.666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.333333%
    }

    .offset-xl-11 {
        margin-left: 91.666667%
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6
}

.table .table {
    background-color: #fff
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #b8daff
}

.table-hover .table-primary:hover {
    background-color: #9fcdff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #dddfe2
}

.table-hover .table-secondary:hover {
    background-color: #cfd2d6
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #cfd2d6
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #c3e6cb
}

.table-hover .table-success:hover {
    background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #bee5eb
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #ffeeba
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f5c6cb
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fdfdfe
}

.table-hover .table-light:hover {
    background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c6c8ca
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.table-dark {
    color: #fff;
    background-color: #212529
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #32383e
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.99px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.99px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.99px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.99px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-control::-webkit-input-placeholder {
    color: #868e96;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #868e96;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #868e96;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #868e96;
    opacity: 1
}

.form-control::placeholder {
    color: #868e96;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px)
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-append>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px)
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px)
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
    color: #868e96
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    width: 250px;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, .8);
    border-radius: .2rem
}

.custom-select.valid,
.form-control.valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-color: #28a745
}

.custom-select.valid:focus,
.form-control.valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-select.valid~.valid-feedback,
.custom-select.valid~.valid-tooltip,
.form-control.valid~.valid-feedback,
.form-control.valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block
}

.form-check-input.valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28a745
}

.custom-control-input.valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28a745
}

.custom-control-input.valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    background-color: #71dd8a
}

.custom-control-input.valid~.valid-feedback,
.custom-control-input.valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    background-color: #34ce57
}

.custom-control-input.valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-file-input.valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #28a745
}

.custom-file-input.valid~.custom-file-label::before,
.was-validated .custom-file-input:valid~.custom-file-label::before {
    border-color: inherit
}

.custom-file-input.valid~.valid-feedback,
.custom-file-input.valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block
}

.custom-file-input.valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    width: 250px;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, .8);
    border-radius: .2rem
}

.custom-select.invalid,
.form-control.invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545
}

.custom-select.invalid:focus,
.form-control.invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-select.invalid~.invalid-feedback,
.custom-select.invalid~.invalid-tooltip,
.form-control.invalid~.invalid-feedback,
.form-control.invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block
}

.form-check-input.invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.custom-control-input.invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #dc3545
}

.custom-control-input.invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    background-color: #efa2a9
}

.custom-control-input.invalid~.invalid-feedback,
.custom-control-input.invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    background-color: #e4606d
}

.custom-control-input.invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-file-input.invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #dc3545
}

.custom-file-input.invalid~.custom-file-label::before,
.was-validated .custom-file-input:invalid~.custom-file-label::before {
    border-color: inherit
}

.custom-file-input.invalid~.invalid-feedback,
.custom-file-input.invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block
}

.custom-file-input.invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn:focus,
.btn:hover {
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

.btn:not([disabled]):not(.disabled) {
    cursor: pointer
}

.btn:not([disabled]):not(.disabled).active,
.btn:not([disabled]):not(.disabled):active {
    background-image: none
}

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-primary:not([disabled]):not(.disabled).active:focus,
.btn-primary:not([disabled]):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96
}

.btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(134, 142, 150, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #666e76
}

.btn-secondary:not([disabled]):not(.disabled).active:focus,
.btn-secondary:not([disabled]):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(134, 142, 150, .5)
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:not([disabled]):not(.disabled).active,
.btn-success:not([disabled]):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

.btn-success:not([disabled]):not(.disabled).active:focus,
.btn-success:not([disabled]):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not([disabled]):not(.disabled).active,
.btn-info:not([disabled]):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not([disabled]):not(.disabled).active:focus,
.btn-info:not([disabled]):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:not([disabled]):not(.disabled).active,
.btn-warning:not([disabled]):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not([disabled]):not(.disabled).active:focus,
.btn-warning:not([disabled]):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not([disabled]):not(.disabled).active,
.btn-danger:not([disabled]):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not([disabled]):not(.disabled).active:focus,
.btn-danger:not([disabled]):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not([disabled]):not(.disabled).active,
.btn-light:not([disabled]):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not([disabled]):not(.disabled).active,
.btn-dark:not([disabled]):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not([disabled]):not(.disabled).active:focus,
.btn-dark:not([disabled]):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent
}

.btn-outline-primary:not([disabled]):not(.disabled).active,
.btn-outline-primary:not([disabled]):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
    color: #868e96;
    background-color: transparent;
    background-image: none;
    border-color: #868e96
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(134, 142, 150, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent
}

.btn-outline-secondary:not([disabled]):not(.disabled).active,
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #868e96;
    border-color: #868e96;
    box-shadow: 0 0 0 .2rem rgba(134, 142, 150, .5)
}

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent
}

.btn-outline-success:not([disabled]):not(.disabled).active,
.btn-outline-success:not([disabled]):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline-info:not([disabled]):not(.disabled).active,
.btn-outline-info:not([disabled]):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-warning:not([disabled]):not(.disabled).active,
.btn-outline-warning:not([disabled]):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-danger:not([disabled]):not(.disabled).active,
.btn-outline-danger:not([disabled]):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not([disabled]):not(.disabled).active,
.btn-outline-light:not([disabled]):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent
}

.btn-outline-dark:not([disabled]):not(.disabled).active,
.btn-outline-dark:not([disabled]):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #212529;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #868e96
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    opacity: 0;
    transition: opacity .15s linear
}

.fade.show {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.show {
    display: block
}

tr.collapse.show {
    display: table-row
}

tbody.collapse.show {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

.dropdown,
.dropup {
    position: relative
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #868e96;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #868e96;
    white-space: nowrap
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 1
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after {
    margin-left: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group .custom-file,
.input-group .custom-select,
.input-group .form-control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group .custom-file:focus,
.input-group .custom-select:focus,
.input-group .form-control:focus {
    z-index: 3
}

.input-group .custom-file+.form-control,
.input-group .custom-select+.form-control,
.input-group .form-control+.form-control {
    margin-left: -1px
}

.input-group .custom-select:not(:last-child),
.input-group .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group .custom-select:not(:first-child),
.input-group .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.input-group .custom-file:not(:last-child) .custom-file-control,
.input-group .custom-file:not(:last-child) .custom-file-control::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group .custom-file:not(:first-child) .custom-file-control,
.input-group .custom-file:not(:first-child) .custom-file-control::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #007bff
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff
}

.custom-control-input:disabled~.custom-control-label {
    color: #868e96
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef
}

.custom-control-label {
    margin-bottom: 0
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none
}

.custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef
}

.custom-select::-ms-expand {
    opacity: 0
}

.custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 75%
}

.custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 125%
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-file-input:focus~.custom-file-control::before {
    border-color: #80bdff
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 .25rem .25rem 0
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #868e96
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
    color: #868e96;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff
}

.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:not([disabled]):not(.disabled) {
    cursor: pointer
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    z-index: 1500;
}

@media (max-width:575.99px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:767.99px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:991.99px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        position: absolute;
        top: 0;
        right: 1.5rem;
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:1199.99px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    margin-bottom: 1rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-deck {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.card-group>.card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-group {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:first-child .card-header,
    .card-group>.card:first-child .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:first-child .card-footer,
    .card-group>.card:first-child .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:last-child .card-header,
    .card-group>.card:last-child .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:last-child .card-footer,
    .card-group>.card:last-child .card-img-bottom {
        border-bottom-left-radius: 0
    }

    .card-group>.card:only-child {
        border-radius: .25rem
    }

    .card-group>.card:only-child .card-header,
    .card-group>.card:only-child .card-img-top {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem
    }

    .card-group>.card:only-child .card-footer,
    .card-group>.card:only-child .card-img-bottom {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
        border-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #868e96;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #868e96
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6
}

.page-link:focus,
.page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:not([disabled]):not(.disabled) {
    cursor: pointer
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.page-item.disabled .page-link {
    color: #868e96;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #007bff
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc
}

.badge-secondary {
    color: #fff;
    background-color: #868e96
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d
}

.badge-success {
    color: #fff;
    background-color: #28a745
}

.badge-success[href]:focus,
.badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34
}

.badge-info {
    color: #fff;
    background-color: #17a2b8
}

.badge-info[href]:focus,
.badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b
}

.badge-warning {
    color: #212529;
    background-color: #ffc107
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00
}

.badge-danger {
    color: #fff;
    background-color: #dc3545
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa
}

.badge-light[href]:focus,
.badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5
}

.badge-dark {
    color: #fff;
    background-color: #343a40
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff
}

.alert-primary hr {
    border-top-color: #9fcdff
}

.alert-primary .alert-link {
    color: #002752
}

.alert-secondary {
    color: #464a4e;
    background-color: #e7e8ea;
    border-color: #dddfe2
}

.alert-secondary hr {
    border-top-color: #cfd2d6
}

.alert-secondary .alert-link {
    color: #2e3133
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb
}

.alert-success hr {
    border-top-color: #b1dfbb
}

.alert-success .alert-link {
    color: #0b2e13
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb
}

.alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #062c33
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-warning .alert-link {
    color: #533f03
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca
}

.alert-dark hr {
    border-top-color: #b9bbbe
}

.alert-dark .alert-link {
    color: #040505
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width .6s ease
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item:focus,
.list-group-item:hover {
    z-index: 1;
    text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #868e96;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff
}

a.list-group-item-primary,
button.list-group-item-primary {
    color: #004085
}

a.list-group-item-primary:focus,
a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
    color: #004085;
    background-color: #9fcdff
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085
}

.list-group-item-secondary {
    color: #464a4e;
    background-color: #dddfe2
}

a.list-group-item-secondary,
button.list-group-item-secondary {
    color: #464a4e
}

a.list-group-item-secondary:focus,
a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
    color: #464a4e;
    background-color: #cfd2d6
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb
}

a.list-group-item-success,
button.list-group-item-success {
    color: #155724
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb
}

a.list-group-item-success.active,
button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb
}

a.list-group-item-info,
button.list-group-item-info {
    color: #0c5460
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5
}

a.list-group-item-info.active,
button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: #856404
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: #721c24
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe
}

a.list-group-item-light,
button.list-group-item-light {
    color: #818182
}

a.list-group-item-light:focus,
a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6
}

a.list-group-item-light.active,
button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca
}

a.list-group-item-dark,
button.list-group-item-dark {
    color: #1b1e21
}

a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.close:not([disabled]):not(.disabled) {
    cursor: pointer
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%)
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2))
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem
}

.modal-header.no-border {
    border-bottom: none;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-terms {
    overflow-y: scroll;
    height: 700px;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem 1rem 0 1rem;
}

.modal-footer:not(.hide-bar) {
    border-top: 1px solid #e9ecef
}

.modal-footer>:not(:first-child) {
    margin-left: .25rem
}

.modal-footer>:not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2))
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1008px
    }
}

.modal.show {
    display: block;
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
    bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
    border-width: .5rem .5rem 0
}

.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
    bottom: 1px;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
    border-width: .5rem .5rem .5rem 0
}

.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
    left: 1px;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
    top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
    border-width: 0 .5rem .5rem .5rem
}

.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
    right: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
    border-width: .5rem 0 .5rem .5rem
}

.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
    right: 1px;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-item {
    position: relative;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    transition: -webkit-transform .6s ease;
    transition: transform .6s ease;
    transition: transform .6s ease, -webkit-transform .6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.active.carousel-item-right,
.carousel-item-next {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {

    .active.carousel-item-right,
    .carousel-item-next {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.active.carousel-item-left,
.carousel-item-prev {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {

    .active.carousel-item-left,
    .carousel-item-prev {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, .5)
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators .active {
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #007bff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0062cc !important
}

.bg-secondary {
    background-color: #868e96 !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #6c757d !important
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #e9ecef !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #007bff !important
}

.border-secondary {
    border-color: #868e96 !important
}

.border-success {
    border-color: #28a745 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #343a40 !important
}

.border-white {
    border-color: #fff !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-right {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-left {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

.d-print-block {
    display: none !important
}

@media print {
    .d-print-block {
        display: block !important
    }
}

.d-print-inline {
    display: none !important
}

@media print {
    .d-print-inline {
        display: inline !important
    }
}

.d-print-inline-block {
    display: none !important
}

@media print {
    .d-print-inline-block {
        display: inline-block !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1,
.my-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3,
.my-3 {
    margin-top: 1rem !important
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5,
.my-5 {
    margin-top: 3rem !important
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pr-3,
.px-3 {
    padding-right: 1rem !important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
    padding-left: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5,
.py-5 {
    padding-top: 3rem !important
}

.pr-5,
.px-5 {
    padding-right: 3rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
    padding-left: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

.text-justify {
    text-align: justify !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media (max-width:990px) {
    td.text-right {
        text-align: left !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0062cc !important
}

.text-secondary {
    color: #868e96 !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #6c757d !important
}

.text-success {
    color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #1e7e34 !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
    color: #117a8b !important
}

.text-warning {
    color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #d39e00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #bd2130 !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
    color: #dae0e5 !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124 !important
}

.text-muted {
    color: #868e96 !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd !important
    }
}

.card .shades.card-review,
.shades.black,
.shades.picker__list-item:hover {
    background-color: #000 !important
}

.card .shades.card-reveal,
.card-wrapper .shades.face,
.carousel-thumbnails .carousel-indicators .shades.active,
.picker__box .picker__header .shades.picker__select--month.browser-default,
.picker__box .picker__header .shades.picker__select--year.browser-default,
.shades.picker__list-item,
.shades.white,
.testimonial-card .shades.avatar {
    background-color: #fff !important
}

.card .card-review,
.picker__list-item:hover {
    background-color: #000 !important
}

.btn-link,
.card .activator,
.card-wrapper .face .content .rotate-btn,
.toast-close-button:focus,
.toast-close-button:hover {
    color: #000 !important
}

.card .card-reveal,
.card-wrapper .face,
.carousel-thumbnails .carousel-indicators .active,
.picker__box .picker__header .picker__select--month.browser-default,
.picker__box .picker__header .picker__select--year.browser-default,
.picker__list-item,
.testimonial-card .avatar,
.white {
    background-color: #fff !important
}

#toast-container>div,
.btn,
.card.card-cascade .view.gradient-card-header,
.card.card-dark .activator,
.card.card-dark .card-body,
.card.card-dark .card-body a,
.clockpicker-display .clockpicker-display-column #click-am.text-primary,
.clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary,
.colorful-side-nav .collapsible a,
.colorful-side-nav .social a,
.colorful-side-nav p,
.dark-side-nav .collapsible a,
.dark-side-nav .social a,
.dark-side-nav p,
.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-tick,
.darktheme .picker__box .picker__date-display .clockpicker-display,
.darktheme .picker__box .picker__date-display .clockpicker-display .clockpicker-span-am-pm,
.darktheme .picker__box .picker__footer button,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:hover,
.picker--focused .picker__list-item--selected,
.picker--time .picker__button--clear:focus,
.picker--time .picker__button--clear:focus:before,
.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:hover:before,
.picker__box .picker__header .picker__date-display,
.picker__box .picker__table .picker--focused,
.picker__box .picker__table .picker__day--outfocus,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover,
.picker__date-display,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-am.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary,
.picker__list-item--selected,
.picker__list-item--selected:hover,
.side-nav,
.side-nav .search-form .form-control,
.stylish-side-nav .collapsible a,
.stylish-side-nav .social a,
.stylish-side-nav p,
.toast-close-button,
.toast-message a,
.toast-message label,
.white-text,
section .contact {
    color: #fff !important
}

.stepper li.warning a .circle,
.toast-error {
    background-color: #ff3547 !important
}

.toast-warning {
    background-color: #fb3 !important
}

.toast-success {
    background-color: #00c851 !important
}

.toast-info {
    background-color: #33b5e5 !important
}

.stepper li.active a .circle,
.stepper li.completed a .circle {
    background-color: #4285f4 !important
}

.card.card-cascade.wider,
.colorful-select .dropdown-content li.disabled a:hover,
.colorful-select .dropdown-content li.disabled span:hover,
.colorful-select .dropdown-content li.disabled.active span,
.colorful-select .dropdown-content li.optgroup a:hover,
.colorful-select .dropdown-content li.optgroup span:hover,
.colorful-select .dropdown-content li.optgroup.active span,
.colorful-select .dropdown-content li:disabled a:hover,
.colorful-select .dropdown-content li:disabled span:hover,
.colorful-select .dropdown-content li:disabled.active span,
.modal-dialog.cascading-modal.modal-avatar .modal-header,
.z-depth-0 {
    box-shadow: none !important
}

#toast-container>div,
.author-box,
.badge,
.btn,
.card-wrapper .back,
.card-wrapper .front,
.card.card-cascade.wider .card-body,
.card:not([class*=card-outline-]),
.chip,
.counter,
.dropdown-content,
.jumbotron,
.md-pills .nav-link.active,
.modal-dialog.cascading-modal .modal-c-tabs .nav-tabs,
.modal-dialog.modal-notify .modal-header,
.navbar,
.pagination .active .page-link,
.pricing-card .price .version,
.section-blog-fw .view,
.side-nav,
.social-list,
.z-depth-1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12)
}

.black-skin .btn-default.active:not([disabled]):not(.disabled),
.black-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.black-skin .btn-default.focus,
.black-skin .btn-default:focus,
.black-skin .btn-default:not([disabled]):not(.disabled):active,
.black-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.black-skin .btn-primary.active:not([disabled]):not(.disabled),
.black-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.black-skin .btn-primary.focus,
.black-skin .btn-primary:focus,
.black-skin .btn-primary:not([disabled]):not(.disabled):active,
.black-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.black-skin .btn-secondary.active:not([disabled]):not(.disabled),
.black-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.black-skin .btn-secondary.focus,
.black-skin .btn-secondary:focus,
.black-skin .btn-secondary:not([disabled]):not(.disabled):active,
.black-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.btn-amber.active:not([disabled]):not(.disabled),
.btn-amber.active:not([disabled]):not(.disabled):focus,
.btn-amber.focus,
.btn-amber:focus,
.btn-amber:not([disabled]):not(.disabled):active,
.btn-amber:not([disabled]):not(.disabled):active:focus,
.btn-black.active:not([disabled]):not(.disabled),
.btn-black.active:not([disabled]):not(.disabled):focus,
.btn-black.focus,
.btn-black:focus,
.btn-black:not([disabled]):not(.disabled):active,
.btn-black:not([disabled]):not(.disabled):active:focus,
.btn-blue-grey.active:not([disabled]):not(.disabled),
.btn-blue-grey.active:not([disabled]):not(.disabled):focus,
.btn-blue-grey.focus,
.btn-blue-grey:focus,
.btn-blue-grey:not([disabled]):not(.disabled):active,
.btn-blue-grey:not([disabled]):not(.disabled):active:focus,
.btn-blue.active:not([disabled]):not(.disabled),
.btn-blue.active:not([disabled]):not(.disabled):focus,
.btn-blue.focus,
.btn-blue:focus,
.btn-blue:not([disabled]):not(.disabled):active,
.btn-blue:not([disabled]):not(.disabled):active:focus,
.btn-brown.active:not([disabled]):not(.disabled),
.btn-brown.active:not([disabled]):not(.disabled):focus,
.btn-brown.focus,
.btn-brown:focus,
.btn-brown:not([disabled]):not(.disabled):active,
.btn-brown:not([disabled]):not(.disabled):active:focus,
.btn-comm.active:not([disabled]):not(.disabled),
.btn-comm.active:not([disabled]):not(.disabled):focus,
.btn-comm.focus,
.btn-comm:focus,
.btn-comm:not([disabled]):not(.disabled):active,
.btn-comm:not([disabled]):not(.disabled):active:focus,
.btn-cyan.active:not([disabled]):not(.disabled),
.btn-cyan.active:not([disabled]):not(.disabled):focus,
.btn-cyan.focus,
.btn-cyan:focus,
.btn-cyan:not([disabled]):not(.disabled):active,
.btn-cyan:not([disabled]):not(.disabled):active:focus,
.btn-danger.active:not([disabled]):not(.disabled),
.btn-danger.active:not([disabled]):not(.disabled):focus,
.btn-danger.focus,
.btn-danger:focus,
.btn-danger:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled):active:focus,
.btn-dark-green.active:not([disabled]):not(.disabled),
.btn-dark-green.active:not([disabled]):not(.disabled):focus,
.btn-dark-green.focus,
.btn-dark-green:focus,
.btn-dark-green:not([disabled]):not(.disabled):active,
.btn-dark-green:not([disabled]):not(.disabled):active:focus,
.btn-dark.active:not([disabled]):not(.disabled),
.btn-dark.active:not([disabled]):not(.disabled):focus,
.btn-dark.focus,
.btn-dark:focus,
.btn-dark:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled):active:focus,
.btn-deep-orange.active:not([disabled]):not(.disabled),
.btn-deep-orange.active:not([disabled]):not(.disabled):focus,
.btn-deep-orange.focus,
.btn-deep-orange:focus,
.btn-deep-orange:not([disabled]):not(.disabled):active,
.btn-deep-orange:not([disabled]):not(.disabled):active:focus,
.btn-deep-purple.active:not([disabled]):not(.disabled),
.btn-deep-purple.active:not([disabled]):not(.disabled):focus,
.btn-deep-purple.focus,
.btn-deep-purple:focus,
.btn-deep-purple:not([disabled]):not(.disabled):active,
.btn-deep-purple:not([disabled]):not(.disabled):active:focus,
.btn-default.active:not([disabled]):not(.disabled),
.btn-default.active:not([disabled]):not(.disabled):focus,
.btn-default.focus,
.btn-default:focus,
.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled):active:focus,
.btn-dribbble.active:not([disabled]):not(.disabled),
.btn-dribbble.active:not([disabled]):not(.disabled):focus,
.btn-dribbble.focus,
.btn-dribbble:focus,
.btn-dribbble:not([disabled]):not(.disabled):active,
.btn-dribbble:not([disabled]):not(.disabled):active:focus,
.btn-elegant.active:not([disabled]):not(.disabled),
.btn-elegant.active:not([disabled]):not(.disabled):focus,
.btn-elegant.focus,
.btn-elegant:focus,
.btn-elegant:not([disabled]):not(.disabled):active,
.btn-elegant:not([disabled]):not(.disabled):active:focus,
.btn-email.active:not([disabled]):not(.disabled),
.btn-email.active:not([disabled]):not(.disabled):focus,
.btn-email.focus,
.btn-email:focus,
.btn-email:not([disabled]):not(.disabled):active,
.btn-email:not([disabled]):not(.disabled):active:focus,
.btn-fb.active:not([disabled]):not(.disabled),
.btn-fb.active:not([disabled]):not(.disabled):focus,
.btn-fb.focus,
.btn-fb:focus,
.btn-fb:not([disabled]):not(.disabled):active,
.btn-fb:not([disabled]):not(.disabled):active:focus,
.btn-floating,
.btn-floating.btn-flat.active,
.btn-git.active:not([disabled]):not(.disabled),
.btn-git.active:not([disabled]):not(.disabled):focus,
.btn-git.focus,
.btn-git:focus,
.btn-git:not([disabled]):not(.disabled):active,
.btn-git:not([disabled]):not(.disabled):active:focus,
.btn-gplus.active:not([disabled]):not(.disabled),
.btn-gplus.active:not([disabled]):not(.disabled):focus,
.btn-gplus.focus,
.btn-gplus:focus,
.btn-gplus:not([disabled]):not(.disabled):active,
.btn-gplus:not([disabled]):not(.disabled):active:focus,
.btn-green.active:not([disabled]):not(.disabled),
.btn-green.active:not([disabled]):not(.disabled):focus,
.btn-green.focus,
.btn-green:focus,
.btn-green:not([disabled]):not(.disabled):active,
.btn-green:not([disabled]):not(.disabled):active:focus,
.btn-grey.active:not([disabled]):not(.disabled),
.btn-grey.active:not([disabled]):not(.disabled):focus,
.btn-grey.focus,
.btn-grey:focus,
.btn-grey:not([disabled]):not(.disabled):active,
.btn-grey:not([disabled]):not(.disabled):active:focus,
.btn-indigo.active:not([disabled]):not(.disabled),
.btn-indigo.active:not([disabled]):not(.disabled):focus,
.btn-indigo.focus,
.btn-indigo:focus,
.btn-indigo:not([disabled]):not(.disabled):active,
.btn-indigo:not([disabled]):not(.disabled):active:focus,
.btn-info.active:not([disabled]):not(.disabled),
.btn-info.active:not([disabled]):not(.disabled):focus,
.btn-info.focus,
.btn-info:focus,
.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled):active:focus,
.btn-ins.active:not([disabled]):not(.disabled),
.btn-ins.active:not([disabled]):not(.disabled):focus,
.btn-ins.focus,
.btn-ins:focus,
.btn-ins:not([disabled]):not(.disabled):active,
.btn-ins:not([disabled]):not(.disabled):active:focus,
.btn-li.active:not([disabled]):not(.disabled),
.btn-li.active:not([disabled]):not(.disabled):focus,
.btn-li.focus,
.btn-li:focus,
.btn-li:not([disabled]):not(.disabled):active,
.btn-li:not([disabled]):not(.disabled):active:focus,
.btn-light-blue.active:not([disabled]):not(.disabled),
.btn-light-blue.active:not([disabled]):not(.disabled):focus,
.btn-light-blue.focus,
.btn-light-blue:focus,
.btn-light-blue:not([disabled]):not(.disabled):active,
.btn-light-blue:not([disabled]):not(.disabled):active:focus,
.btn-light-green.active:not([disabled]):not(.disabled),
.btn-light-green.active:not([disabled]):not(.disabled):focus,
.btn-light-green.focus,
.btn-light-green:focus,
.btn-light-green:not([disabled]):not(.disabled):active,
.btn-light-green:not([disabled]):not(.disabled):active:focus,
.btn-light.active:not([disabled]):not(.disabled),
.btn-light.active:not([disabled]):not(.disabled):focus,
.btn-light.focus,
.btn-light:focus,
.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled):active:focus,
.btn-lime.active:not([disabled]):not(.disabled),
.btn-lime.active:not([disabled]):not(.disabled):focus,
.btn-lime.focus,
.btn-lime:focus,
.btn-lime:not([disabled]):not(.disabled):active,
.btn-lime:not([disabled]):not(.disabled):active:focus,
.btn-mdb-color.active:not([disabled]):not(.disabled),
.btn-mdb-color.active:not([disabled]):not(.disabled):focus,
.btn-mdb-color.focus,
.btn-mdb-color:focus,
.btn-mdb-color:not([disabled]):not(.disabled):active,
.btn-mdb-color:not([disabled]):not(.disabled):active:focus,
.btn-mdb.active:not([disabled]):not(.disabled),
.btn-mdb.active:not([disabled]):not(.disabled):focus,
.btn-mdb.focus,
.btn-mdb:focus,
.btn-mdb:not([disabled]):not(.disabled):active,
.btn-mdb:not([disabled]):not(.disabled):active:focus,
.btn-orange.active:not([disabled]):not(.disabled),
.btn-orange.active:not([disabled]):not(.disabled):focus,
.btn-orange.focus,
.btn-orange:focus,
.btn-orange:not([disabled]):not(.disabled):active,
.btn-orange:not([disabled]):not(.disabled):active:focus,
.btn-outline-amber.active:not([disabled]):not(.disabled),
.btn-outline-amber.active:not([disabled]):not(.disabled):focus,
.btn-outline-amber:not([disabled]):not(.disabled):active,
.btn-outline-amber:not([disabled]):not(.disabled):active:focus,
.btn-outline-black.active:not([disabled]):not(.disabled),
.btn-outline-black.active:not([disabled]):not(.disabled):focus,
.btn-outline-black:not([disabled]):not(.disabled):active,
.btn-outline-black:not([disabled]):not(.disabled):active:focus,
.btn-outline-blue-grey.active:not([disabled]):not(.disabled),
.btn-outline-blue-grey.active:not([disabled]):not(.disabled):focus,
.btn-outline-blue-grey:not([disabled]):not(.disabled):active,
.btn-outline-blue-grey:not([disabled]):not(.disabled):active:focus,
.btn-outline-blue.active:not([disabled]):not(.disabled),
.btn-outline-blue.active:not([disabled]):not(.disabled):focus,
.btn-outline-blue:not([disabled]):not(.disabled):active,
.btn-outline-blue:not([disabled]):not(.disabled):active:focus,
.btn-outline-brown.active:not([disabled]):not(.disabled),
.btn-outline-brown.active:not([disabled]):not(.disabled):focus,
.btn-outline-brown:not([disabled]):not(.disabled):active,
.btn-outline-brown:not([disabled]):not(.disabled):active:focus,
.btn-outline-cyan.active:not([disabled]):not(.disabled),
.btn-outline-cyan.active:not([disabled]):not(.disabled):focus,
.btn-outline-cyan:not([disabled]):not(.disabled):active,
.btn-outline-cyan:not([disabled]):not(.disabled):active:focus,
.btn-outline-danger.active:not([disabled]):not(.disabled),
.btn-outline-danger.active:not([disabled]):not(.disabled):focus,
.btn-outline-danger:not([disabled]):not(.disabled):active,
.btn-outline-danger:not([disabled]):not(.disabled):active:focus,
.btn-outline-dark-green.active:not([disabled]):not(.disabled),
.btn-outline-dark-green.active:not([disabled]):not(.disabled):focus,
.btn-outline-dark-green:not([disabled]):not(.disabled):active,
.btn-outline-dark-green:not([disabled]):not(.disabled):active:focus,
.btn-outline-dark.active:not([disabled]):not(.disabled),
.btn-outline-dark.active:not([disabled]):not(.disabled):focus,
.btn-outline-dark:not([disabled]):not(.disabled):active,
.btn-outline-dark:not([disabled]):not(.disabled):active:focus,
.btn-outline-deep-orange.active:not([disabled]):not(.disabled),
.btn-outline-deep-orange.active:not([disabled]):not(.disabled):focus,
.btn-outline-deep-orange:not([disabled]):not(.disabled):active,
.btn-outline-deep-orange:not([disabled]):not(.disabled):active:focus,
.btn-outline-deep-purple.active:not([disabled]):not(.disabled),
.btn-outline-deep-purple.active:not([disabled]):not(.disabled):focus,
.btn-outline-deep-purple:not([disabled]):not(.disabled):active,
.btn-outline-deep-purple:not([disabled]):not(.disabled):active:focus,
.btn-outline-default.active:not([disabled]):not(.disabled),
.btn-outline-default.active:not([disabled]):not(.disabled):focus,
.btn-outline-default:not([disabled]):not(.disabled):active,
.btn-outline-default:not([disabled]):not(.disabled):active:focus,
.btn-outline-elegant.active:not([disabled]):not(.disabled),
.btn-outline-elegant.active:not([disabled]):not(.disabled):focus,
.btn-outline-elegant:not([disabled]):not(.disabled):active,
.btn-outline-elegant:not([disabled]):not(.disabled):active:focus,
.btn-outline-green.active:not([disabled]):not(.disabled),
.btn-outline-green.active:not([disabled]):not(.disabled):focus,
.btn-outline-green:not([disabled]):not(.disabled):active,
.btn-outline-green:not([disabled]):not(.disabled):active:focus,
.btn-outline-grey.active:not([disabled]):not(.disabled),
.btn-outline-grey.active:not([disabled]):not(.disabled):focus,
.btn-outline-grey:not([disabled]):not(.disabled):active,
.btn-outline-grey:not([disabled]):not(.disabled):active:focus,
.btn-outline-indigo.active:not([disabled]):not(.disabled),
.btn-outline-indigo.active:not([disabled]):not(.disabled):focus,
.btn-outline-indigo:not([disabled]):not(.disabled):active,
.btn-outline-indigo:not([disabled]):not(.disabled):active:focus,
.btn-outline-info.active:not([disabled]):not(.disabled),
.btn-outline-info.active:not([disabled]):not(.disabled):focus,
.btn-outline-info:not([disabled]):not(.disabled):active,
.btn-outline-info:not([disabled]):not(.disabled):active:focus,
.btn-outline-light-blue.active:not([disabled]):not(.disabled),
.btn-outline-light-blue.active:not([disabled]):not(.disabled):focus,
.btn-outline-light-blue:not([disabled]):not(.disabled):active,
.btn-outline-light-blue:not([disabled]):not(.disabled):active:focus,
.btn-outline-light-green.active:not([disabled]):not(.disabled),
.btn-outline-light-green.active:not([disabled]):not(.disabled):focus,
.btn-outline-light-green:not([disabled]):not(.disabled):active,
.btn-outline-light-green:not([disabled]):not(.disabled):active:focus,
.btn-outline-light.active:not([disabled]):not(.disabled),
.btn-outline-light.active:not([disabled]):not(.disabled):focus,
.btn-outline-light:not([disabled]):not(.disabled):active,
.btn-outline-light:not([disabled]):not(.disabled):active:focus,
.btn-outline-lime.active:not([disabled]):not(.disabled),
.btn-outline-lime.active:not([disabled]):not(.disabled):focus,
.btn-outline-lime:not([disabled]):not(.disabled):active,
.btn-outline-lime:not([disabled]):not(.disabled):active:focus,
.btn-outline-mdb-color.active:not([disabled]):not(.disabled),
.btn-outline-mdb-color.active:not([disabled]):not(.disabled):focus,
.btn-outline-mdb-color:not([disabled]):not(.disabled):active,
.btn-outline-mdb-color:not([disabled]):not(.disabled):active:focus,
.btn-outline-orange.active:not([disabled]):not(.disabled),
.btn-outline-orange.active:not([disabled]):not(.disabled):focus,
.btn-outline-orange:not([disabled]):not(.disabled):active,
.btn-outline-orange:not([disabled]):not(.disabled):active:focus,
.btn-outline-pink.active:not([disabled]):not(.disabled),
.btn-outline-pink.active:not([disabled]):not(.disabled):focus,
.btn-outline-pink:not([disabled]):not(.disabled):active,
.btn-outline-pink:not([disabled]):not(.disabled):active:focus,
.btn-outline-primary.active:not([disabled]):not(.disabled),
.btn-outline-primary.active:not([disabled]):not(.disabled):focus,
.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled):active:focus,
.btn-outline-purple.active:not([disabled]):not(.disabled),
.btn-outline-purple.active:not([disabled]):not(.disabled):focus,
.btn-outline-purple:not([disabled]):not(.disabled):active,
.btn-outline-purple:not([disabled]):not(.disabled):active:focus,
.btn-outline-red.active:not([disabled]):not(.disabled),
.btn-outline-red.active:not([disabled]):not(.disabled):focus,
.btn-outline-red:not([disabled]):not(.disabled):active,
.btn-outline-red:not([disabled]):not(.disabled):active:focus,
.btn-outline-secondary.active:not([disabled]):not(.disabled),
.btn-outline-secondary.active:not([disabled]):not(.disabled):focus,
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):not(.disabled):active:focus,
.btn-outline-success.active:not([disabled]):not(.disabled),
.btn-outline-success.active:not([disabled]):not(.disabled):focus,
.btn-outline-success:not([disabled]):not(.disabled):active,
.btn-outline-success:not([disabled]):not(.disabled):active:focus,
.btn-outline-teal.active:not([disabled]):not(.disabled),
.btn-outline-teal.active:not([disabled]):not(.disabled):focus,
.btn-outline-teal:not([disabled]):not(.disabled):active,
.btn-outline-teal:not([disabled]):not(.disabled):active:focus,
.btn-outline-unique.active:not([disabled]):not(.disabled),
.btn-outline-unique.active:not([disabled]):not(.disabled):focus,
.btn-outline-unique:not([disabled]):not(.disabled):active,
.btn-outline-unique:not([disabled]):not(.disabled):active:focus,
.btn-outline-warning.active:not([disabled]):not(.disabled),
.btn-outline-warning.active:not([disabled]):not(.disabled):focus,
.btn-outline-warning:not([disabled]):not(.disabled):active,
.btn-outline-warning:not([disabled]):not(.disabled):active:focus,
.btn-outline-white.active:not([disabled]):not(.disabled),
.btn-outline-white.active:not([disabled]):not(.disabled):focus,
.btn-outline-white:not([disabled]):not(.disabled):active,
.btn-outline-white:not([disabled]):not(.disabled):active:focus,
.btn-outline-yellow.active:not([disabled]):not(.disabled),
.btn-outline-yellow.active:not([disabled]):not(.disabled):focus,
.btn-outline-yellow:not([disabled]):not(.disabled):active,
.btn-outline-yellow:not([disabled]):not(.disabled):active:focus,
.btn-pin.active:not([disabled]):not(.disabled),
.btn-pin.active:not([disabled]):not(.disabled):focus,
.btn-pin.focus,
.btn-pin:focus,
.btn-pin:not([disabled]):not(.disabled):active,
.btn-pin:not([disabled]):not(.disabled):active:focus,
.btn-pink.active:not([disabled]):not(.disabled),
.btn-pink.active:not([disabled]):not(.disabled):focus,
.btn-pink.focus,
.btn-pink:focus,
.btn-pink:not([disabled]):not(.disabled):active,
.btn-pink:not([disabled]):not(.disabled):active:focus,
.btn-primary.active:not([disabled]):not(.disabled),
.btn-primary.active:not([disabled]):not(.disabled):focus,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled):active:focus,
.btn-purple.active:not([disabled]):not(.disabled),
.btn-purple.active:not([disabled]):not(.disabled):focus,
.btn-purple.focus,
.btn-purple:focus,
.btn-purple:not([disabled]):not(.disabled):active,
.btn-purple:not([disabled]):not(.disabled):active:focus,
.btn-red.active:not([disabled]):not(.disabled),
.btn-red.active:not([disabled]):not(.disabled):focus,
.btn-red.focus,
.btn-red:focus,
.btn-red:not([disabled]):not(.disabled):active,
.btn-red:not([disabled]):not(.disabled):active:focus,
.btn-secondary.active:not([disabled]):not(.disabled),
.btn-secondary.active:not([disabled]):not(.disabled):focus,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled):active:focus,
.btn-slack.active:not([disabled]):not(.disabled),
.btn-slack.active:not([disabled]):not(.disabled):focus,
.btn-slack.focus,
.btn-slack:focus,
.btn-slack:not([disabled]):not(.disabled):active,
.btn-slack:not([disabled]):not(.disabled):active:focus,
.btn-so.active:not([disabled]):not(.disabled),
.btn-so.active:not([disabled]):not(.disabled):focus,
.btn-so.focus,
.btn-so:focus,
.btn-so:not([disabled]):not(.disabled):active,
.btn-so:not([disabled]):not(.disabled):active:focus,
.btn-success.active:not([disabled]):not(.disabled),
.btn-success.active:not([disabled]):not(.disabled):focus,
.btn-success.focus,
.btn-success:focus,
.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled):active:focus,
.btn-teal.active:not([disabled]):not(.disabled),
.btn-teal.active:not([disabled]):not(.disabled):focus,
.btn-teal.focus,
.btn-teal:focus,
.btn-teal:not([disabled]):not(.disabled):active,
.btn-teal:not([disabled]):not(.disabled):active:focus,
.btn-tw.active:not([disabled]):not(.disabled),
.btn-tw.active:not([disabled]):not(.disabled):focus,
.btn-tw.focus,
.btn-tw:focus,
.btn-tw:not([disabled]):not(.disabled):active,
.btn-tw:not([disabled]):not(.disabled):active:focus,
.btn-unique.active:not([disabled]):not(.disabled),
.btn-unique.active:not([disabled]):not(.disabled):focus,
.btn-unique.focus,
.btn-unique:focus,
.btn-unique:not([disabled]):not(.disabled):active,
.btn-unique:not([disabled]):not(.disabled):active:focus,
.btn-vk.active:not([disabled]):not(.disabled),
.btn-vk.active:not([disabled]):not(.disabled):focus,
.btn-vk.focus,
.btn-vk:focus,
.btn-vk:not([disabled]):not(.disabled):active,
.btn-vk:not([disabled]):not(.disabled):active:focus,
.btn-warning.active:not([disabled]):not(.disabled),
.btn-warning.active:not([disabled]):not(.disabled):focus,
.btn-warning.focus,
.btn-warning:focus,
.btn-warning:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled):active:focus,
.btn-white.active:not([disabled]):not(.disabled),
.btn-white.active:not([disabled]):not(.disabled):focus,
.btn-white.focus,
.btn-white:focus,
.btn-white:not([disabled]):not(.disabled):active,
.btn-white:not([disabled]):not(.disabled):active:focus,
.btn-yellow.active:not([disabled]):not(.disabled),
.btn-yellow.active:not([disabled]):not(.disabled):focus,
.btn-yellow.focus,
.btn-yellow:focus,
.btn-yellow:not([disabled]):not(.disabled):active,
.btn-yellow:not([disabled]):not(.disabled):active:focus,
.btn-yt.active:not([disabled]):not(.disabled),
.btn-yt.active:not([disabled]):not(.disabled):focus,
.btn-yt.focus,
.btn-yt:focus,
.btn-yt:not([disabled]):not(.disabled):active,
.btn-yt:not([disabled]):not(.disabled):active:focus,
.btn:active,
.btn:active:focus,
.btn:focus,
.btn:hover,
.card.card-cascade.card-avatar img,
.card.card-cascade.wider.reverse .card-body,
.colorful-select .dropdown-content li.active span,
.comments-list .badge,
.cyan-skin .btn-default.active:not([disabled]):not(.disabled),
.cyan-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.cyan-skin .btn-default.focus,
.cyan-skin .btn-default:focus,
.cyan-skin .btn-default:not([disabled]):not(.disabled):active,
.cyan-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.cyan-skin .btn-primary.active:not([disabled]):not(.disabled),
.cyan-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.cyan-skin .btn-primary.focus,
.cyan-skin .btn-primary:focus,
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active,
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.cyan-skin .btn-secondary.active:not([disabled]):not(.disabled),
.cyan-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.cyan-skin .btn-secondary.focus,
.cyan-skin .btn-secondary:focus,
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active,
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.deep-purple-skin .btn-default.active:not([disabled]):not(.disabled),
.deep-purple-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.deep-purple-skin .btn-default.focus,
.deep-purple-skin .btn-default:focus,
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active,
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.deep-purple-skin .btn-primary.active:not([disabled]):not(.disabled),
.deep-purple-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.deep-purple-skin .btn-primary.focus,
.deep-purple-skin .btn-primary:focus,
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active,
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.deep-purple-skin .btn-secondary.active:not([disabled]):not(.disabled),
.deep-purple-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.deep-purple-skin .btn-secondary.focus,
.deep-purple-skin .btn-secondary:focus,
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active,
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:hover,
.form-header,
.grey-skin .btn-default.active:not([disabled]):not(.disabled),
.grey-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.grey-skin .btn-default.focus,
.grey-skin .btn-default:focus,
.grey-skin .btn-default:not([disabled]):not(.disabled):active,
.grey-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.grey-skin .btn-primary.active:not([disabled]):not(.disabled),
.grey-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.grey-skin .btn-primary.focus,
.grey-skin .btn-primary:focus,
.grey-skin .btn-primary:not([disabled]):not(.disabled):active,
.grey-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.grey-skin .btn-secondary.active:not([disabled]):not(.disabled),
.grey-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.grey-skin .btn-secondary.focus,
.grey-skin .btn-secondary:focus,
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active,
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.indigo-skin .btn-default.active:not([disabled]):not(.disabled),
.indigo-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.indigo-skin .btn-default.focus,
.indigo-skin .btn-default:focus,
.indigo-skin .btn-default:not([disabled]):not(.disabled):active,
.indigo-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.indigo-skin .btn-primary.active:not([disabled]):not(.disabled),
.indigo-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.indigo-skin .btn-primary.focus,
.indigo-skin .btn-primary:focus,
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active,
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.indigo-skin .btn-secondary.active:not([disabled]):not(.disabled),
.indigo-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.indigo-skin .btn-secondary.focus,
.indigo-skin .btn-secondary:focus,
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active,
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.light-blue-skin .btn-default.active:not([disabled]):not(.disabled),
.light-blue-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.light-blue-skin .btn-default.focus,
.light-blue-skin .btn-default:focus,
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active,
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.light-blue-skin .btn-primary.active:not([disabled]):not(.disabled),
.light-blue-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.light-blue-skin .btn-primary.focus,
.light-blue-skin .btn-primary:focus,
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active,
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.light-blue-skin .btn-secondary.active:not([disabled]):not(.disabled),
.light-blue-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.light-blue-skin .btn-secondary.focus,
.light-blue-skin .btn-secondary:focus,
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active,
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.md-pills .nav-link.active:hover,
.mdb-skin .btn-default.active:not([disabled]):not(.disabled),
.mdb-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.mdb-skin .btn-default.focus,
.mdb-skin .btn-default:focus,
.mdb-skin .btn-default:not([disabled]):not(.disabled):active,
.mdb-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.mdb-skin .btn-primary.active:not([disabled]):not(.disabled),
.mdb-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.mdb-skin .btn-primary.focus,
.mdb-skin .btn-primary:focus,
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active,
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.mdb-skin .btn-secondary.active:not([disabled]):not(.disabled),
.mdb-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.mdb-skin .btn-secondary.focus,
.mdb-skin .btn-secondary:focus,
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active,
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.media .media-left img,
.modal-dialog .modal-content,
.modal-dialog.cascading-modal .modal-header,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-danger .btn-primary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal.focus,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal:focus,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-info .btn-primary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-info .btn-primary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-info .btn-primary-modal.focus,
.modal-dialog.modal-notify.modal-info .btn-primary-modal:focus,
.modal-dialog.modal-notify.modal-info .btn-primary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-info .btn-primary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-success .btn-primary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-success .btn-primary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-success .btn-primary-modal.focus,
.modal-dialog.modal-notify.modal-success .btn-primary-modal:focus,
.modal-dialog.modal-notify.modal-success .btn-primary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-success .btn-primary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:not([disabled]):not(.disabled):active:focus,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal.active:not([disabled]):not(.disabled),
.modal-dialog.modal-notify.modal-warning .btn-primary-modal.active:not([disabled]):not(.disabled):focus,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal.focus,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal:focus,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal:not([disabled]):not(.disabled):active,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal:not([disabled]):not(.disabled):active:focus,
.nav-tabs,
.navy-blue-skin .btn-default.active:not([disabled]):not(.disabled),
.navy-blue-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.navy-blue-skin .btn-default.focus,
.navy-blue-skin .btn-default:focus,
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active,
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.navy-blue-skin .btn-primary.active:not([disabled]):not(.disabled),
.navy-blue-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.navy-blue-skin .btn-primary.focus,
.navy-blue-skin .btn-primary:focus,
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active,
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.navy-blue-skin .btn-secondary.active:not([disabled]):not(.disabled),
.navy-blue-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.navy-blue-skin .btn-secondary.focus,
.navy-blue-skin .btn-secondary:focus,
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active,
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.picker__box .picker__table .picker--focused,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover,
.pink-skin .btn-default.active:not([disabled]):not(.disabled),
.pink-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.pink-skin .btn-default.focus,
.pink-skin .btn-default:focus,
.pink-skin .btn-default:not([disabled]):not(.disabled):active,
.pink-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.pink-skin .btn-primary.active:not([disabled]):not(.disabled),
.pink-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.pink-skin .btn-primary.focus,
.pink-skin .btn-primary:focus,
.pink-skin .btn-primary:not([disabled]):not(.disabled):active,
.pink-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.pink-skin .btn-secondary.active:not([disabled]):not(.disabled),
.pink-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.pink-skin .btn-secondary.focus,
.pink-skin .btn-secondary:focus,
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active,
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.pricing-card .header,
.reply-form .badge,
.show>.black-skin .btn-default.dropdown-toggle,
.show>.black-skin .btn-default.dropdown-toggle:focus,
.show>.black-skin .btn-primary.dropdown-toggle,
.show>.black-skin .btn-primary.dropdown-toggle:focus,
.show>.black-skin .btn-secondary.dropdown-toggle,
.show>.black-skin .btn-secondary.dropdown-toggle:focus,
.show>.btn-amber.dropdown-toggle,
.show>.btn-amber.dropdown-toggle:focus,
.show>.btn-black.dropdown-toggle,
.show>.btn-black.dropdown-toggle:focus,
.show>.btn-blue-grey.dropdown-toggle,
.show>.btn-blue-grey.dropdown-toggle:focus,
.show>.btn-blue.dropdown-toggle,
.show>.btn-blue.dropdown-toggle:focus,
.show>.btn-brown.dropdown-toggle,
.show>.btn-brown.dropdown-toggle:focus,
.show>.btn-comm.dropdown-toggle,
.show>.btn-comm.dropdown-toggle:focus,
.show>.btn-cyan.dropdown-toggle,
.show>.btn-cyan.dropdown-toggle:focus,
.show>.btn-danger.dropdown-toggle,
.show>.btn-danger.dropdown-toggle:focus,
.show>.btn-dark-green.dropdown-toggle,
.show>.btn-dark-green.dropdown-toggle:focus,
.show>.btn-dark.dropdown-toggle,
.show>.btn-dark.dropdown-toggle:focus,
.show>.btn-deep-orange.dropdown-toggle,
.show>.btn-deep-orange.dropdown-toggle:focus,
.show>.btn-deep-purple.dropdown-toggle,
.show>.btn-deep-purple.dropdown-toggle:focus,
.show>.btn-default.dropdown-toggle,
.show>.btn-default.dropdown-toggle:focus,
.show>.btn-dribbble.dropdown-toggle,
.show>.btn-dribbble.dropdown-toggle:focus,
.show>.btn-elegant.dropdown-toggle,
.show>.btn-elegant.dropdown-toggle:focus,
.show>.btn-email.dropdown-toggle,
.show>.btn-email.dropdown-toggle:focus,
.show>.btn-fb.dropdown-toggle,
.show>.btn-fb.dropdown-toggle:focus,
.show>.btn-git.dropdown-toggle,
.show>.btn-git.dropdown-toggle:focus,
.show>.btn-gplus.dropdown-toggle,
.show>.btn-gplus.dropdown-toggle:focus,
.show>.btn-green.dropdown-toggle,
.show>.btn-green.dropdown-toggle:focus,
.show>.btn-grey.dropdown-toggle,
.show>.btn-grey.dropdown-toggle:focus,
.show>.btn-indigo.dropdown-toggle,
.show>.btn-indigo.dropdown-toggle:focus,
.show>.btn-info.dropdown-toggle,
.show>.btn-info.dropdown-toggle:focus,
.show>.btn-ins.dropdown-toggle,
.show>.btn-ins.dropdown-toggle:focus,
.show>.btn-li.dropdown-toggle,
.show>.btn-li.dropdown-toggle:focus,
.show>.btn-light-blue.dropdown-toggle,
.show>.btn-light-blue.dropdown-toggle:focus,
.show>.btn-light-green.dropdown-toggle,
.show>.btn-light-green.dropdown-toggle:focus,
.show>.btn-light.dropdown-toggle,
.show>.btn-light.dropdown-toggle:focus,
.show>.btn-lime.dropdown-toggle,
.show>.btn-lime.dropdown-toggle:focus,
.show>.btn-mdb-color.dropdown-toggle,
.show>.btn-mdb-color.dropdown-toggle:focus,
.show>.btn-mdb.dropdown-toggle,
.show>.btn-mdb.dropdown-toggle:focus,
.show>.btn-orange.dropdown-toggle,
.show>.btn-orange.dropdown-toggle:focus,
.show>.btn-outline-amber.dropdown-toggle,
.show>.btn-outline-amber.dropdown-toggle:focus,
.show>.btn-outline-black.dropdown-toggle,
.show>.btn-outline-black.dropdown-toggle:focus,
.show>.btn-outline-blue-grey.dropdown-toggle,
.show>.btn-outline-blue-grey.dropdown-toggle:focus,
.show>.btn-outline-blue.dropdown-toggle,
.show>.btn-outline-blue.dropdown-toggle:focus,
.show>.btn-outline-brown.dropdown-toggle,
.show>.btn-outline-brown.dropdown-toggle:focus,
.show>.btn-outline-cyan.dropdown-toggle,
.show>.btn-outline-cyan.dropdown-toggle:focus,
.show>.btn-outline-danger.dropdown-toggle,
.show>.btn-outline-danger.dropdown-toggle:focus,
.show>.btn-outline-dark-green.dropdown-toggle,
.show>.btn-outline-dark-green.dropdown-toggle:focus,
.show>.btn-outline-dark.dropdown-toggle,
.show>.btn-outline-dark.dropdown-toggle:focus,
.show>.btn-outline-deep-orange.dropdown-toggle,
.show>.btn-outline-deep-orange.dropdown-toggle:focus,
.show>.btn-outline-deep-purple.dropdown-toggle,
.show>.btn-outline-deep-purple.dropdown-toggle:focus,
.show>.btn-outline-default.dropdown-toggle,
.show>.btn-outline-default.dropdown-toggle:focus,
.show>.btn-outline-elegant.dropdown-toggle,
.show>.btn-outline-elegant.dropdown-toggle:focus,
.show>.btn-outline-green.dropdown-toggle,
.show>.btn-outline-green.dropdown-toggle:focus,
.show>.btn-outline-grey.dropdown-toggle,
.show>.btn-outline-grey.dropdown-toggle:focus,
.show>.btn-outline-indigo.dropdown-toggle,
.show>.btn-outline-indigo.dropdown-toggle:focus,
.show>.btn-outline-info.dropdown-toggle,
.show>.btn-outline-info.dropdown-toggle:focus,
.show>.btn-outline-light-blue.dropdown-toggle,
.show>.btn-outline-light-blue.dropdown-toggle:focus,
.show>.btn-outline-light-green.dropdown-toggle,
.show>.btn-outline-light-green.dropdown-toggle:focus,
.show>.btn-outline-light.dropdown-toggle,
.show>.btn-outline-light.dropdown-toggle:focus,
.show>.btn-outline-lime.dropdown-toggle,
.show>.btn-outline-lime.dropdown-toggle:focus,
.show>.btn-outline-mdb-color.dropdown-toggle,
.show>.btn-outline-mdb-color.dropdown-toggle:focus,
.show>.btn-outline-orange.dropdown-toggle,
.show>.btn-outline-orange.dropdown-toggle:focus,
.show>.btn-outline-pink.dropdown-toggle,
.show>.btn-outline-pink.dropdown-toggle:focus,
.show>.btn-outline-primary.dropdown-toggle,
.show>.btn-outline-primary.dropdown-toggle:focus,
.show>.btn-outline-purple.dropdown-toggle,
.show>.btn-outline-purple.dropdown-toggle:focus,
.show>.btn-outline-red.dropdown-toggle,
.show>.btn-outline-red.dropdown-toggle:focus,
.show>.btn-outline-secondary.dropdown-toggle,
.show>.btn-outline-secondary.dropdown-toggle:focus,
.show>.btn-outline-success.dropdown-toggle,
.show>.btn-outline-success.dropdown-toggle:focus,
.show>.btn-outline-teal.dropdown-toggle,
.show>.btn-outline-teal.dropdown-toggle:focus,
.show>.btn-outline-unique.dropdown-toggle,
.show>.btn-outline-unique.dropdown-toggle:focus,
.show>.btn-outline-warning.dropdown-toggle,
.show>.btn-outline-warning.dropdown-toggle:focus,
.show>.btn-outline-white.dropdown-toggle,
.show>.btn-outline-white.dropdown-toggle:focus,
.show>.btn-outline-yellow.dropdown-toggle,
.show>.btn-outline-yellow.dropdown-toggle:focus,
.show>.btn-pin.dropdown-toggle,
.show>.btn-pin.dropdown-toggle:focus,
.show>.btn-pink.dropdown-toggle,
.show>.btn-pink.dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle,
.show>.btn-primary.dropdown-toggle:focus,
.show>.btn-purple.dropdown-toggle,
.show>.btn-purple.dropdown-toggle:focus,
.show>.btn-red.dropdown-toggle,
.show>.btn-red.dropdown-toggle:focus,
.show>.btn-secondary.dropdown-toggle,
.show>.btn-secondary.dropdown-toggle:focus,
.show>.btn-slack.dropdown-toggle,
.show>.btn-slack.dropdown-toggle:focus,
.show>.btn-so.dropdown-toggle,
.show>.btn-so.dropdown-toggle:focus,
.show>.btn-success.dropdown-toggle,
.show>.btn-success.dropdown-toggle:focus,
.show>.btn-teal.dropdown-toggle,
.show>.btn-teal.dropdown-toggle:focus,
.show>.btn-tw.dropdown-toggle,
.show>.btn-tw.dropdown-toggle:focus,
.show>.btn-unique.dropdown-toggle,
.show>.btn-unique.dropdown-toggle:focus,
.show>.btn-vk.dropdown-toggle,
.show>.btn-vk.dropdown-toggle:focus,
.show>.btn-warning.dropdown-toggle,
.show>.btn-warning.dropdown-toggle:focus,
.show>.btn-white.dropdown-toggle,
.show>.btn-white.dropdown-toggle:focus,
.show>.btn-yellow.dropdown-toggle,
.show>.btn-yellow.dropdown-toggle:focus,
.show>.btn-yt.dropdown-toggle,
.show>.btn-yt.dropdown-toggle:focus,
.show>.cyan-skin .btn-default.dropdown-toggle,
.show>.cyan-skin .btn-default.dropdown-toggle:focus,
.show>.cyan-skin .btn-primary.dropdown-toggle,
.show>.cyan-skin .btn-primary.dropdown-toggle:focus,
.show>.cyan-skin .btn-secondary.dropdown-toggle,
.show>.cyan-skin .btn-secondary.dropdown-toggle:focus,
.show>.deep-purple-skin .btn-default.dropdown-toggle,
.show>.deep-purple-skin .btn-default.dropdown-toggle:focus,
.show>.deep-purple-skin .btn-primary.dropdown-toggle,
.show>.deep-purple-skin .btn-primary.dropdown-toggle:focus,
.show>.deep-purple-skin .btn-secondary.dropdown-toggle,
.show>.deep-purple-skin .btn-secondary.dropdown-toggle:focus,
.show>.grey-skin .btn-default.dropdown-toggle,
.show>.grey-skin .btn-default.dropdown-toggle:focus,
.show>.grey-skin .btn-primary.dropdown-toggle,
.show>.grey-skin .btn-primary.dropdown-toggle:focus,
.show>.grey-skin .btn-secondary.dropdown-toggle,
.show>.grey-skin .btn-secondary.dropdown-toggle:focus,
.show>.indigo-skin .btn-default.dropdown-toggle,
.show>.indigo-skin .btn-default.dropdown-toggle:focus,
.show>.indigo-skin .btn-primary.dropdown-toggle,
.show>.indigo-skin .btn-primary.dropdown-toggle:focus,
.show>.indigo-skin .btn-secondary.dropdown-toggle,
.show>.indigo-skin .btn-secondary.dropdown-toggle:focus,
.show>.light-blue-skin .btn-default.dropdown-toggle,
.show>.light-blue-skin .btn-default.dropdown-toggle:focus,
.show>.light-blue-skin .btn-primary.dropdown-toggle,
.show>.light-blue-skin .btn-primary.dropdown-toggle:focus,
.show>.light-blue-skin .btn-secondary.dropdown-toggle,
.show>.light-blue-skin .btn-secondary.dropdown-toggle:focus,
.show>.mdb-skin .btn-default.dropdown-toggle,
.show>.mdb-skin .btn-default.dropdown-toggle:focus,
.show>.mdb-skin .btn-primary.dropdown-toggle,
.show>.mdb-skin .btn-primary.dropdown-toggle:focus,
.show>.mdb-skin .btn-secondary.dropdown-toggle,
.show>.mdb-skin .btn-secondary.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-danger .btn-primary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-danger .btn-primary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-info .btn-primary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-info .btn-primary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-success .btn-primary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-success .btn-primary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal.dropdown-toggle:focus,
.show>.modal-dialog.modal-notify.modal-warning .btn-primary-modal.dropdown-toggle,
.show>.modal-dialog.modal-notify.modal-warning .btn-primary-modal.dropdown-toggle:focus,
.show>.navy-blue-skin .btn-default.dropdown-toggle,
.show>.navy-blue-skin .btn-default.dropdown-toggle:focus,
.show>.navy-blue-skin .btn-primary.dropdown-toggle,
.show>.navy-blue-skin .btn-primary.dropdown-toggle:focus,
.show>.navy-blue-skin .btn-secondary.dropdown-toggle,
.show>.navy-blue-skin .btn-secondary.dropdown-toggle:focus,
.show>.pink-skin .btn-default.dropdown-toggle,
.show>.pink-skin .btn-default.dropdown-toggle:focus,
.show>.pink-skin .btn-primary.dropdown-toggle,
.show>.pink-skin .btn-primary.dropdown-toggle:focus,
.show>.pink-skin .btn-secondary.dropdown-toggle,
.show>.pink-skin .btn-secondary.dropdown-toggle:focus,
.show>.white-skin .btn-default.dropdown-toggle,
.show>.white-skin .btn-default.dropdown-toggle:focus,
.show>.white-skin .btn-primary.dropdown-toggle,
.show>.white-skin .btn-primary.dropdown-toggle:focus,
.show>.white-skin .btn-secondary.dropdown-toggle,
.show>.white-skin .btn-secondary.dropdown-toggle:focus,
.white-skin .btn-default.active:not([disabled]):not(.disabled),
.white-skin .btn-default.active:not([disabled]):not(.disabled):focus,
.white-skin .btn-default.focus,
.white-skin .btn-default:focus,
.white-skin .btn-default:not([disabled]):not(.disabled):active,
.white-skin .btn-default:not([disabled]):not(.disabled):active:focus,
.white-skin .btn-primary.active:not([disabled]):not(.disabled),
.white-skin .btn-primary.active:not([disabled]):not(.disabled):focus,
.white-skin .btn-primary.focus,
.white-skin .btn-primary:focus,
.white-skin .btn-primary:not([disabled]):not(.disabled):active,
.white-skin .btn-primary:not([disabled]):not(.disabled):active:focus,
.white-skin .btn-secondary.active:not([disabled]):not(.disabled),
.white-skin .btn-secondary.active:not([disabled]):not(.disabled):focus,
.white-skin .btn-secondary.focus,
.white-skin .btn-secondary:focus,
.white-skin .btn-secondary:not([disabled]):not(.disabled):active,
.white-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,
.z-depth-1-half,
ul.instagram-photos li img {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}

.btn-floating:hover,
.card-overlay,
.card.card-cascade .view,
.colorful-select .dropdown-content li a:hover,
.colorful-select .dropdown-content li span:hover,
.comments-list img,
.modal-dialog.cascading-modal.modal-avatar .modal-header img,
.reply-form img,
.testimonial-carousel .testimonial .avatar img,
.z-depth-2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)
}

.z-depth-3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19)
}

.z-depth-4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .22), 0 25px 55px 0 rgba(0, 0, 0, .21)
}

.z-depth-5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22)
}

.hoverable {
    -webkit-transition: box-shadow .55s;
    transition: box-shadow .55s;
    box-shadow: 0
}

.hoverable:hover {
    -webkit-transition: box-shadow .45s;
    transition: box-shadow .45s;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)
}

a {
    color: #0275d8;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent
}

a:focus,
a:hover {
    text-decoration: none
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

.disabled {
    cursor: not-allowed !important
}

.author-box,
.badge,
.jumbotron,
.list-group .list-group-item,
.navbar-toggler {
    border-radius: 2px
}

.card .card-circle ul,
.card-overlay ul,
.flex-center ul,
.section.team-section .avatar ul,
.testimonial-carousel .testimonial .avatar ul {
    list-style-type: none;
    padding: 0
}

.media .avatar-sm {
    width: 56px;
    border-radius: 50%
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.animated.bounceIn,
.animated.bounceOut,
.animated.flipOutX,
.animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

@-webkit-keyframes bounce {

    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@keyframes bounce {

    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom
}

@-webkit-keyframes flash {

    50%,
    from,
    to {
        opacity: 1
    }

    25%,
    75% {
        opacity: 0
    }
}

@keyframes flash {

    50%,
    from,
    to {
        opacity: 1
    }

    25%,
    75% {
        opacity: 0
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

@-webkit-keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand
}

@-webkit-keyframes shake {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

@keyframes shake {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake
}

@-webkit-keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg)
    }

    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg)
    }

    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg)
    }

    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg)
    }

    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg)
    }

    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg)
    }

    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg)
    }

    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg)
    }

    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.headShake {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: headShake;
    animation-name: headShake
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

.swing {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble
}

@-webkit-keyframes jello {

    11.1%,
    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    77.7% {
        -webkit-transform: skewX(.39062deg) skewY(.39062deg);
        transform: skewX(.39062deg) skewY(.39062deg)
    }

    88.8% {
        -webkit-transform: skewX(-.19531deg) skewY(-.19531deg);
        transform: skewX(-.19531deg) skewY(-.19531deg)
    }
}

@keyframes jello {

    11.1%,
    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    77.7% {
        -webkit-transform: skewX(.39062deg) skewY(.39062deg);
        transform: skewX(.39062deg) skewY(.39062deg)
    }

    88.8% {
        -webkit-transform: skewX(-.19531deg) skewY(-.19531deg);
        transform: skewX(-.19531deg) skewY(-.19531deg)
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes bounceIn {

    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes bounceIn {

    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

@-webkit-keyframes bounceInDown {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInDown {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown
}

@-webkit-keyframes bounceInLeft {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInLeft {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft
}

@-webkit-keyframes bounceInRight {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInRight {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight
}

@-webkit-keyframes bounceInUp {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes bounceInUp {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp
}

@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut
}

@-webkit-keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft
}

@-webkit-keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight
}

@-webkit-keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutDownBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes fadeOutDownBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutRightBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes fadeOutRightBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutUpBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes fadeOutUpBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig
}

@-webkit-keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY
}

@-webkit-keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

.flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important
}

@-webkit-keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

.flipOutY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY
}

@-webkit-keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedOut {
    from {
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

@keyframes lightSpeedOut {
    from {
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

@-webkit-keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn
}

@-webkit-keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft
}

@-webkit-keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight
}

@-webkit-keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft
}

@-webkit-keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight
}

@-webkit-keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

@keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut
}

@-webkit-keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

.rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft
}

@-webkit-keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight
}

@-webkit-keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft
}

@-webkit-keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

@keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

.rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge
}

@-webkit-keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn
}

@-webkit-keyframes rollOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

@keyframes rollOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

.rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn
}

@-webkit-keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown
}

@-webkit-keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft
}

@-webkit-keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight
}

@-webkit-keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp
}

@-webkit-keyframes zoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

@keyframes zoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut
}

@-webkit-keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown
}

@-webkit-keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft
}

@-webkit-keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight
}

@-webkit-keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown
}

@-webkit-keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp
}

.m-0 {
    margin: 0 !important
}

.mt-0 {
    margin-top: 0 !important
}

.mr-0 {
    margin-right: 0 !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.ml-0 {
    margin-left: 0 !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.m-1,
.section-blog-fw .author-box,
.section-blog-fw .jumbotron {
    margin: .25rem !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mr-1 {
    margin-right: .25rem !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.ml-1 {
    margin-left: .25rem !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mr-2 {
    margin-right: .5rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.ml-2 {
    margin-left: .5rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mr-3 {
    margin-right: 1rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.ml-3 {
    margin-left: 1rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mr-4 {
    margin-right: 1.5rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.ml-4 {
    margin-left: 1.5rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mr-5 {
    margin-right: 3rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.ml-5 {
    margin-left: 3rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0 {
    padding-top: 0 !important
}

.pr-0 {
    padding-right: 0 !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pl-0 {
    padding-left: 0 !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pr-1 {
    padding-right: .25rem !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pl-1 {
    padding-left: .25rem !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pr-2 {
    padding-right: .5rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pl-2 {
    padding-left: .5rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pr-3 {
    padding-right: 1rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pl-3 {
    padding-left: 1rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pr-4 {
    padding-right: 1.5rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pl-4 {
    padding-left: 1.5rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pr-5 {
    padding-right: 3rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.pl-5 {
    padding-left: 3rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto {
    margin-top: auto !important
}

.mr-auto {
    margin-right: auto !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ml-auto {
    margin-left: auto !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0 {
        margin-left: 0 !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1 {
        margin-left: .25rem !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2 {
        margin-left: .5rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3 {
        margin-left: 1rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4 {
        margin-left: 1.5rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5 {
        margin-left: 3rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0 {
        padding-left: 0 !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1 {
        padding-left: .25rem !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2 {
        padding-left: .5rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3 {
        padding-left: 1rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4 {
        padding-left: 1.5rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5 {
        padding-left: 3rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto {
        margin-left: auto !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0 {
        margin-left: 0 !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1 {
        margin-left: .25rem !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2 {
        margin-left: .5rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3 {
        margin-left: 1rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4 {
        margin-left: 1.5rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5 {
        margin-left: 3rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0 {
        padding-left: 0 !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1 {
        padding-left: .25rem !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2 {
        padding-left: .5rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3 {
        padding-left: 1rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4 {
        padding-left: 1.5rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5 {
        padding-left: 3rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto {
        margin-left: auto !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0 {
        margin-left: 0 !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1 {
        margin-left: .25rem !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2 {
        margin-left: .5rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3 {
        margin-left: 1rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4 {
        margin-left: 1.5rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5 {
        margin-left: 3rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0 {
        padding-left: 0 !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1 {
        padding-left: .25rem !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2 {
        padding-left: .5rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3 {
        padding-left: 1rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4 {
        padding-left: 1.5rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5 {
        padding-left: 3rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto {
        margin-left: auto !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0 {
        margin-left: 0 !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1 {
        margin-left: .25rem !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2 {
        margin-left: .5rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3 {
        margin-left: 1rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4 {
        margin-left: 1.5rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5 {
        margin-left: 3rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0 {
        padding-left: 0 !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1 {
        padding-left: .25rem !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2 {
        padding-left: .5rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3 {
        padding-left: 1rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4 {
        padding-left: 1.5rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5 {
        padding-left: 3rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto {
        margin-left: auto !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

.e-px {
    padding-left: 0;
    padding-right: 0
}

@media (min-width:576px) {
    .e-px {
        padding-left: 5%;
        padding-right: 5%
    }
}

@media (min-width:768px) {
    .e-px {
        padding-left: 10%;
        padding-right: 10%
    }
}

@media (min-width:992px) {
    .e-px {
        padding-left: 15%;
        padding-right: 15%
    }
}

@media (min-width:1200px) {
    .e-px {
        padding-left: 20%;
        padding-right: 20%
    }
}

.comments-list img,
.img-fluid,
.modal-dialog.cascading-modal.modal-avatar .modal-header,
.reply-form img,
.section-blog-fw .view img {
    max-width: 100%;
    height: auto
}

.inline-ul>li {
    display: inline
}

.list-inline-div>div {
    display: inline-block
}

@media only screen and (max-width:992px) {
    .center-on-small-only {
        text-align: center
    }

    .center-on-small-only .comments-list img,
    .center-on-small-only .img-fluid,
    .center-on-small-only .modal-dialog.cascading-modal.modal-avatar .modal-header,
    .center-on-small-only .reply-form img,
    .center-on-small-only .section-blog-fw .view img,
    .comments-list .center-on-small-only img,
    .modal-dialog.cascading-modal.modal-avatar .center-on-small-only .modal-header,
    .reply-form .center-on-small-only img,
    .section-blog-fw .view .center-on-small-only img {
        display: inline
    }
}

.card .card-circle,
.card-overlay,
.flex-center,
.section.team-section .avatar,
.testimonial-carousel .testimonial .avatar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.card .card-circle p,
.card-overlay p,
.flex-center p,
.section.team-section .avatar p,
.testimonial-carousel .testimonial .avatar p {
    margin: 0
}

.card .card-circle ul,
.card-overlay ul,
.flex-center ul,
.section.team-section .avatar ul,
.testimonial-carousel .testimonial .avatar ul {
    text-align: center
}

.card .card-circle ul li,
.card-overlay ul li,
.flex-center ul li,
.section.team-section .avatar ul li,
.testimonial-carousel .testimonial .avatar ul li {
    margin-bottom: 1rem
}

@media (max-width:992px) {
    .hidden-md-down {
        display: none !important
    }
}

@media (min-width:992px) {
    .mb-r {
        margin-bottom: 3rem !important
    }
}

@media (max-width:992px) {
    .mb-r {
        margin-bottom: 2rem !important
    }
}

.font-bold {
    font-weight: 500
}

.font-up {
    text-transform: uppercase
}

.hr-light {
    border-top: 1px solid #fff
}

.hr-dark {
    border-top: 1px solid #666
}

.blockquote .bq-title {
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 0
}

.blockquote p {
    font-size: 1.1rem
}

.bq-primary {
    border-left: 3px solid #4285f4
}

.bq-primary .bq-title {
    color: #4285f4
}

.bq-warning {
    border-left: 3px solid #fb3
}

.bq-warning .bq-title {
    color: #fb3
}

.bq-danger {
    border-left: 3px solid #ff3547
}

.bq-danger .bq-title {
    color: #ff3547
}

.bq-success {
    border-left: 3px solid #00c851
}

.bq-success .bq-title {
    color: #00c851
}

body {
    font-family: Roboto, sans-serif;
    font-weight: 300
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300
}

.h1-responsive,
.section-heading h1 {
    font-size: 150%
}

.h2-responsive,
.section-blog-fw h2 {
    font-size: 145%
}

.h3-responsive {
    font-size: 135%
}

.h4-responsive {
    font-size: 135%
}

.h5-responsive {
    font-size: 135%
}

@media (min-width:576px) {

    .h1-responsive,
    .section-heading h1 {
        font-size: 170%
    }

    .h2-responsive,
    .section-blog-fw h2 {
        font-size: 140%
    }

    .h3-responsive {
        font-size: 125%
    }

    .h4-responsive {
        font-size: 125%
    }

    .h5-responsive {
        font-size: 125%
    }
}

@media (min-width:768px) {

    .h1-responsive,
    .section-heading h1 {
        font-size: 200%
    }

    .h2-responsive,
    .section-blog-fw h2 {
        font-size: 170%
    }

    .h3-responsive {
        font-size: 140%
    }

    .h4-responsive {
        font-size: 125%
    }

    .h5-responsive {
        font-size: 125%
    }
}

@media (min-width:992px) {

    .h1-responsive,
    .section-heading h1 {
        font-size: 200%
    }

    .h2-responsive,
    .section-blog-fw h2 {
        font-size: 170%
    }

    .h3-responsive {
        font-size: 140%
    }

    .h4-responsive {
        font-size: 125%
    }

    .h5-responsive {
        font-size: 125%
    }
}

@media (min-width:1200px) {

    .h1-responsive,
    .section-heading h1 {
        font-size: 250%
    }

    .h2-responsive,
    .section-blog-fw h2 {
        font-size: 200%
    }

    .h3-responsive {
        font-size: 170%
    }

    .h4-responsive {
        font-size: 140%
    }

    .h5-responsive {
        font-size: 125%
    }
}

.fs-0 {
    font-size: 0 !important
}

.fs-1 {
    font-size: .25rem !important
}

.fs-2 {
    font-size: .5rem !important
}

.fs-3 {
    font-size: 1rem !important
}

.fs-4 {
    font-size: 1.5rem !important
}

.fs-5 {
    font-size: 3rem !important
}

.divider-new {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 300;
    margin-top: 45px;
    margin-bottom: 45px
}

.divider-new h2 {
    margin-top: 5px
}

.divider-new:before {
    content: '';
    height: 1.5px;
    background: #c6c6c6;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 .45em 0 0
}

.divider-new:after {
    content: '';
    height: 1.5px;
    background: #c6c6c6;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 .45em
}

blockquote {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-left: .25rem solid #eceeef
}

blockquote p {
    font-size: .9rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem
}

button:focus {
    outline: 0 !important
}

.btn {
    font-size: .8rem;
    padding: .85rem 2.13rem;
    margin: 6px;
    border-radius: 2px;
    border: 0;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    text-transform: uppercase;
    white-space: normal !important;
    word-wrap: break-word;
    cursor: pointer
}

.btn:active,
.btn:active:focus,
.btn:focus,
.btn:hover {
    outline: 0
}

.btn .fa {
    font-size: 1rem;
    position: relative;
    vertical-align: middle;
    margin-top: -2px
}

.btn .fa.right {
    margin-left: 3px
}

.btn .fa.left {
    margin-right: 3px
}

.btn.btn-lg {
    font-size: .9rem;
    padding: 1rem 2.4rem
}

.btn.btn-lg .fa {
    font-size: 1.2rem
}

.btn.btn-md {
    font-size: .7rem;
    padding: .7rem 1.6rem
}

.btn.btn-md .fa {
    font-size: .9rem
}

.btn.btn-sm,
.btn.wishlist {
    font-size: .6rem;
    padding: .5rem 1.6rem
}

.btn.btn-sm.btn-table,
.btn.btn-table.wishlist {
    padding: .5rem .9rem
}

.btn.btn-sm .fa,
.btn.wishlist .fa {
    font-size: .7rem
}

.btn.btn-tb {
    padding: .3rem 1rem
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
    pointer-events: none
}

.btn[class*=btn-outline-] {
    padding-top: .76rem;
    padding-bottom: .76rem
}

.btn[class*=btn-outline-].btn-sm,
.btn[class*=btn-outline-].wishlist {
    padding-top: .42rem;
    padding-bottom: .42rem
}

.btn[class*=btn-outline-].btn-md {
    padding-top: .58rem;
    padding-bottom: .58rem
}

.btn[class*=btn-outline-].btn-lg {
    padding-top: .9rem;
    padding-bottom: .9rem
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #b579d2 !important;
    border-color: #b579d2 !important
}

.btn-group .btn {
    margin: 0
}

.btn-block {
    margin: inherit
}

.btn-split {
    padding-left: .85rem;
    padding-right: 1.25rem
}

.btn-link {
    background-color: transparent;
    box-shadow: none !important
}

.btn-link:focus,
.btn-link:hover {
    background-color: transparent;
    box-shadow: none !important
}

.btn-flat {
    box-shadow: none
}

.btn-flat:active,
.btn-flat:focus,
.btn-flat:hover {
    box-shadow: none !important
}

.btn-primary {
    background-color: #4285f4 !important;
    color: #fff !important
}

.btn-primary:hover {
    background-color: #5a95f5
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus {
    background-color: #1266f1
}

.btn-primary.dropdown-toggle {
    background-color: #4285f4 !important
}

.btn-primary.dropdown-toggle:focus,
.btn-primary.dropdown-toggle:hover {
    background-color: #5a95f5 !important
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #4285f4 !important
}

.btn-outline-primary {
    border: 2px solid #4285f4;
    color: #4285f4 !important;
    background-color: transparent
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:active:focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
    background-color: transparent;
    color: #4285f4;
    border-color: #4285f4
}

.btn-outline-primary:not([disabled]):not(.disabled).active,
.btn-outline-primary:not([disabled]):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: transparent !important;
    border-color: #4285f4 !important
}

.btn-danger {
    background-color: #ff3547 !important;
    color: #fff !important
}

.btn-danger:hover {
    background-color: #ff4f5e
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:focus {
    background-color: #ff0219
}

.btn-danger.dropdown-toggle {
    background-color: #ff3547 !important
}

.btn-danger.dropdown-toggle:focus,
.btn-danger.dropdown-toggle:hover {
    background-color: #ff4f5e !important
}

.btn-danger:not([disabled]):not(.disabled).active,
.btn-danger:not([disabled]):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    background-color: #ff3547 !important
}

.btn-outline-danger {
    border: 2px solid #ff3547;
    color: #ff3547 !important;
    background-color: transparent
}

.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:active:focus,
.btn-outline-danger:focus,
.btn-outline-danger:hover {
    background-color: transparent;
    color: #ff3547;
    border-color: #ff3547
}

.btn-outline-danger:not([disabled]):not(.disabled).active,
.btn-outline-danger:not([disabled]):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    background-color: transparent !important;
    border-color: #ff3547 !important
}

.btn-warning {
    background-color: #f80 !important;
    color: #fff !important
}

.btn-warning:hover {
    background-color: #ff941a
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:focus {
    background-color: #cc6d00
}

.btn-warning.dropdown-toggle {
    background-color: #f80 !important
}

.btn-warning.dropdown-toggle:focus,
.btn-warning.dropdown-toggle:hover {
    background-color: #ff941a !important
}

.btn-warning:not([disabled]):not(.disabled).active,
.btn-warning:not([disabled]):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    background-color: #f80 !important
}

.btn-outline-warning {
    border: 2px solid #f80;
    color: #f80 !important;
    background-color: transparent
}

.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:active:focus,
.btn-outline-warning:focus,
.btn-outline-warning:hover {
    background-color: transparent;
    color: #f80;
    border-color: #f80
}

.btn-outline-warning:not([disabled]):not(.disabled).active,
.btn-outline-warning:not([disabled]):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    background-color: transparent !important;
    border-color: #f80 !important
}

.btn-success {
    background-color: #00c851 !important;
    color: #fff !important
}

.btn-success:hover {
    background-color: #00e25b
}

.btn-success.active,
.btn-success:active,
.btn-success:focus {
    background-color: #00953c
}

.btn-success.dropdown-toggle {
    background-color: #00c851 !important
}

.btn-success.dropdown-toggle:focus,
.btn-success.dropdown-toggle:hover {
    background-color: #00e25b !important
}

.btn-success:not([disabled]):not(.disabled).active,
.btn-success:not([disabled]):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    background-color: #00c851 !important
}

.btn-outline-success {
    border: 2px solid #00c851;
    color: #00c851 !important;
    background-color: transparent
}

.btn-outline-success.active,
.btn-outline-success:active,
.btn-outline-success:active:focus,
.btn-outline-success:focus,
.btn-outline-success:hover {
    background-color: transparent;
    color: #00c851;
    border-color: #00c851
}

.btn-outline-success:not([disabled]):not(.disabled).active,
.btn-outline-success:not([disabled]):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    background-color: transparent !important;
    border-color: #00c851 !important
}

.btn-info {
    background-color: #33b5e5 !important;
    color: #fff !important
}

.btn-info:hover {
    background-color: #4abde8
}

.btn-info.active,
.btn-info:active,
.btn-info:focus {
    background-color: #1a9bcb
}

.btn-info.dropdown-toggle {
    background-color: #33b5e5 !important
}

.btn-info.dropdown-toggle:focus,
.btn-info.dropdown-toggle:hover {
    background-color: #4abde8 !important
}

.btn-info:not([disabled]):not(.disabled).active,
.btn-info:not([disabled]):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    background-color: #33b5e5 !important
}

.btn-outline-info {
    border: 2px solid #33b5e5;
    color: #33b5e5 !important;
    background-color: transparent
}

.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:active:focus,
.btn-outline-info:focus,
.btn-outline-info:hover {
    background-color: transparent;
    color: #33b5e5;
    border-color: #33b5e5
}

.btn-outline-info:not([disabled]):not(.disabled).active,
.btn-outline-info:not([disabled]):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    background-color: transparent !important;
    border-color: #33b5e5 !important
}

.btn-default {
    background-color: #2bbbad !important;
    color: #fff !important
}

.btn-default:hover {
    background-color: #30cfc0
}

.btn-default.active,
.btn-default:active,
.btn-default:focus {
    background-color: #219287
}

.btn-default.dropdown-toggle {
    background-color: #2bbbad !important
}

.btn-default.dropdown-toggle:focus,
.btn-default.dropdown-toggle:hover {
    background-color: #30cfc0 !important
}

.btn-default:not([disabled]):not(.disabled).active,
.btn-default:not([disabled]):not(.disabled):active,
.show>.btn-default.dropdown-toggle {
    background-color: #2bbbad !important
}

.btn-outline-default {
    border: 2px solid #2bbbad;
    color: #2bbbad !important;
    background-color: transparent
}

.btn-outline-default.active,
.btn-outline-default:active,
.btn-outline-default:active:focus,
.btn-outline-default:focus,
.btn-outline-default:hover {
    background-color: transparent;
    color: #2bbbad;
    border-color: #2bbbad
}

.btn-outline-default:not([disabled]):not(.disabled).active,
.btn-outline-default:not([disabled]):not(.disabled):active,
.show>.btn-outline-default.dropdown-toggle {
    background-color: transparent !important;
    border-color: #2bbbad !important
}

.btn-secondary {
    background-color: #a6c !important;
    color: #fff !important
}

.btn-secondary:hover {
    background-color: #b579d2
}

.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus {
    background-color: #9540bf
}

.btn-secondary.dropdown-toggle {
    background-color: #a6c !important
}

.btn-secondary.dropdown-toggle:focus,
.btn-secondary.dropdown-toggle:hover {
    background-color: #b579d2 !important
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #a6c !important
}

.btn-outline-secondary {
    border: 2px solid #a6c;
    color: #a6c !important;
    background-color: transparent
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:active:focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
    background-color: transparent;
    color: #a6c;
    border-color: #a6c
}

.btn-outline-secondary:not([disabled]):not(.disabled).active,
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    background-color: transparent !important;
    border-color: #a6c !important
}

.btn-elegant {
    background-color: #2e2e2e !important;
    color: #fff !important
}

.btn-elegant:hover {
    background-color: #3b3b3b
}

.btn-elegant.active,
.btn-elegant:active,
.btn-elegant:focus {
    background-color: #151515
}

.btn-elegant.dropdown-toggle {
    background-color: #2e2e2e !important
}

.btn-elegant.dropdown-toggle:focus,
.btn-elegant.dropdown-toggle:hover {
    background-color: #3b3b3b !important
}

.btn-elegant:not([disabled]):not(.disabled).active,
.btn-elegant:not([disabled]):not(.disabled):active,
.show>.btn-elegant.dropdown-toggle {
    background-color: #2e2e2e !important
}

.btn-outline-elegant {
    border: 2px solid #2e2e2e;
    color: #2e2e2e !important;
    background-color: transparent
}

.btn-outline-elegant.active,
.btn-outline-elegant:active,
.btn-outline-elegant:active:focus,
.btn-outline-elegant:focus,
.btn-outline-elegant:hover {
    background-color: transparent;
    color: #2e2e2e;
    border-color: #2e2e2e
}

.btn-outline-elegant:not([disabled]):not(.disabled).active,
.btn-outline-elegant:not([disabled]):not(.disabled):active,
.show>.btn-outline-elegant.dropdown-toggle {
    background-color: transparent !important;
    border-color: #2e2e2e !important
}

.btn-unique {
    background-color: #880e4f !important;
    color: #fff !important
}

.btn-unique:hover {
    background-color: #9f105c
}

.btn-unique.active,
.btn-unique:active,
.btn-unique:focus {
    background-color: #5a0934
}

.btn-unique.dropdown-toggle {
    background-color: #880e4f !important
}

.btn-unique.dropdown-toggle:focus,
.btn-unique.dropdown-toggle:hover {
    background-color: #9f105c !important
}

.btn-unique:not([disabled]):not(.disabled).active,
.btn-unique:not([disabled]):not(.disabled):active,
.show>.btn-unique.dropdown-toggle {
    background-color: #880e4f !important
}

.btn-outline-unique {
    border: 2px solid #880e4f;
    color: #880e4f !important;
    background-color: transparent
}

.btn-outline-unique.active,
.btn-outline-unique:active,
.btn-outline-unique:active:focus,
.btn-outline-unique:focus,
.btn-outline-unique:hover {
    background-color: transparent;
    color: #880e4f;
    border-color: #880e4f
}

.btn-outline-unique:not([disabled]):not(.disabled).active,
.btn-outline-unique:not([disabled]):not(.disabled):active,
.show>.btn-outline-unique.dropdown-toggle {
    background-color: transparent !important;
    border-color: #880e4f !important
}

.btn-dark-green {
    background-color: #388e3c !important;
    color: #fff !important
}

.btn-dark-green:hover {
    background-color: #3fa044
}

.btn-dark-green.active,
.btn-dark-green:active,
.btn-dark-green:focus {
    background-color: #2a692d
}

.btn-dark-green.dropdown-toggle {
    background-color: #388e3c !important
}

.btn-dark-green.dropdown-toggle:focus,
.btn-dark-green.dropdown-toggle:hover {
    background-color: #3fa044 !important
}

.btn-dark-green:not([disabled]):not(.disabled).active,
.btn-dark-green:not([disabled]):not(.disabled):active,
.show>.btn-dark-green.dropdown-toggle {
    background-color: #388e3c !important
}

.btn-outline-dark-green {
    border: 2px solid #388e3c;
    color: #388e3c !important;
    background-color: transparent
}

.btn-outline-dark-green.active,
.btn-outline-dark-green:active,
.btn-outline-dark-green:active:focus,
.btn-outline-dark-green:focus,
.btn-outline-dark-green:hover {
    background-color: transparent;
    color: #388e3c;
    border-color: #388e3c
}

.btn-outline-dark-green:not([disabled]):not(.disabled).active,
.btn-outline-dark-green:not([disabled]):not(.disabled):active,
.show>.btn-outline-dark-green.dropdown-toggle {
    background-color: transparent !important;
    border-color: #388e3c !important
}

.btn-mdb-color {
    background-color: #59698d !important;
    color: #fff !important
}

.btn-mdb-color:hover {
    background-color: #63759d
}

.btn-mdb-color.active,
.btn-mdb-color:active,
.btn-mdb-color:focus {
    background-color: #45526e
}

.btn-mdb-color.dropdown-toggle {
    background-color: #59698d !important
}

.btn-mdb-color.dropdown-toggle:focus,
.btn-mdb-color.dropdown-toggle:hover {
    background-color: #63759d !important
}

.btn-mdb-color:not([disabled]):not(.disabled).active,
.btn-mdb-color:not([disabled]):not(.disabled):active,
.show>.btn-mdb-color.dropdown-toggle {
    background-color: #59698d !important
}

.btn-outline-mdb-color {
    border: 2px solid #59698d;
    color: #59698d !important;
    background-color: transparent
}

.btn-outline-mdb-color.active,
.btn-outline-mdb-color:active,
.btn-outline-mdb-color:active:focus,
.btn-outline-mdb-color:focus,
.btn-outline-mdb-color:hover {
    background-color: transparent;
    color: #59698d;
    border-color: #59698d
}

.btn-outline-mdb-color:not([disabled]):not(.disabled).active,
.btn-outline-mdb-color:not([disabled]):not(.disabled):active,
.show>.btn-outline-mdb-color.dropdown-toggle {
    background-color: transparent !important;
    border-color: #59698d !important
}

.btn-red {
    background-color: #d32f2f !important;
    color: #fff !important
}

.btn-red:hover {
    background-color: #d74444
}

.btn-red.active,
.btn-red:active,
.btn-red:focus {
    background-color: #ab2424
}

.btn-red.dropdown-toggle {
    background-color: #d32f2f !important
}

.btn-red.dropdown-toggle:focus,
.btn-red.dropdown-toggle:hover {
    background-color: #d74444 !important
}

.btn-red:not([disabled]):not(.disabled).active,
.btn-red:not([disabled]):not(.disabled):active,
.show>.btn-red.dropdown-toggle {
    background-color: #d32f2f !important
}

.btn-outline-red {
    border: 2px solid #d32f2f;
    color: #d32f2f !important;
    background-color: transparent
}

.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:active:focus,
.btn-outline-red:focus,
.btn-outline-red:hover {
    background-color: transparent;
    color: #d32f2f;
    border-color: #d32f2f
}

.btn-outline-red:not([disabled]):not(.disabled).active,
.btn-outline-red:not([disabled]):not(.disabled):active,
.show>.btn-outline-red.dropdown-toggle {
    background-color: transparent !important;
    border-color: #d32f2f !important
}

.btn-pink {
    background-color: #ec407a !important;
    color: #fff !important
}

.btn-pink:hover {
    background-color: #ee578a
}

.btn-pink.active,
.btn-pink:active,
.btn-pink:focus {
    background-color: #e2175b
}

.btn-pink.dropdown-toggle {
    background-color: #ec407a !important
}

.btn-pink.dropdown-toggle:focus,
.btn-pink.dropdown-toggle:hover {
    background-color: #ee578a !important
}

.btn-pink:not([disabled]):not(.disabled).active,
.btn-pink:not([disabled]):not(.disabled):active,
.show>.btn-pink.dropdown-toggle {
    background-color: #ec407a !important
}

.btn-outline-pink {
    border: 2px solid #ec407a;
    color: #ec407a !important;
    background-color: transparent
}

.btn-outline-pink.active,
.btn-outline-pink:active,
.btn-outline-pink:active:focus,
.btn-outline-pink:focus,
.btn-outline-pink:hover {
    background-color: transparent;
    color: #ec407a;
    border-color: #ec407a
}

.btn-outline-pink:not([disabled]):not(.disabled).active,
.btn-outline-pink:not([disabled]):not(.disabled):active,
.show>.btn-outline-pink.dropdown-toggle {
    background-color: transparent !important;
    border-color: #ec407a !important
}

.btn-purple {
    background-color: #8e24aa !important;
    color: #fff !important
}

.btn-purple:hover {
    background-color: #a028bf
}

.btn-purple.active,
.btn-purple:active,
.btn-purple:focus {
    background-color: #6b1b80
}

.btn-purple.dropdown-toggle {
    background-color: #8e24aa !important
}

.btn-purple.dropdown-toggle:focus,
.btn-purple.dropdown-toggle:hover {
    background-color: #a028bf !important
}

.btn-purple:not([disabled]):not(.disabled).active,
.btn-purple:not([disabled]):not(.disabled):active,
.show>.btn-purple.dropdown-toggle {
    background-color: #8e24aa !important
}

.btn-outline-purple {
    border: 2px solid #8e24aa;
    color: #8e24aa !important;
    background-color: transparent
}

.btn-outline-purple.active,
.btn-outline-purple:active,
.btn-outline-purple:active:focus,
.btn-outline-purple:focus,
.btn-outline-purple:hover {
    background-color: transparent;
    color: #8e24aa;
    border-color: #8e24aa
}

.btn-outline-purple:not([disabled]):not(.disabled).active,
.btn-outline-purple:not([disabled]):not(.disabled):active,
.show>.btn-outline-purple.dropdown-toggle {
    background-color: transparent !important;
    border-color: #8e24aa !important
}

.btn-deep-purple {
    background-color: #512da8 !important;
    color: #fff !important
}

.btn-deep-purple:hover {
    background-color: #5b32bc
}

.btn-deep-purple.active,
.btn-deep-purple:active,
.btn-deep-purple:focus {
    background-color: #3e2280
}

.btn-deep-purple.dropdown-toggle {
    background-color: #512da8 !important
}

.btn-deep-purple.dropdown-toggle:focus,
.btn-deep-purple.dropdown-toggle:hover {
    background-color: #5b32bc !important
}

.btn-deep-purple:not([disabled]):not(.disabled).active,
.btn-deep-purple:not([disabled]):not(.disabled):active,
.show>.btn-deep-purple.dropdown-toggle {
    background-color: #512da8 !important
}

.btn-outline-deep-purple {
    border: 2px solid #512da8;
    color: #512da8 !important;
    background-color: transparent
}

.btn-outline-deep-purple.active,
.btn-outline-deep-purple:active,
.btn-outline-deep-purple:active:focus,
.btn-outline-deep-purple:focus,
.btn-outline-deep-purple:hover {
    background-color: transparent;
    color: #512da8;
    border-color: #512da8
}

.btn-outline-deep-purple:not([disabled]):not(.disabled).active,
.btn-outline-deep-purple:not([disabled]):not(.disabled):active,
.show>.btn-outline-deep-purple.dropdown-toggle {
    background-color: transparent !important;
    border-color: #512da8 !important
}

.btn-indigo {
    background-color: #3f51b5 !important;
    color: #fff !important
}

.btn-indigo:hover {
    background-color: #4d5ec1
}

.btn-indigo.active,
.btn-indigo:active,
.btn-indigo:focus {
    background-color: #32408f
}

.btn-indigo.dropdown-toggle {
    background-color: #3f51b5 !important
}

.btn-indigo.dropdown-toggle:focus,
.btn-indigo.dropdown-toggle:hover {
    background-color: #4d5ec1 !important
}

.btn-indigo:not([disabled]):not(.disabled).active,
.btn-indigo:not([disabled]):not(.disabled):active,
.show>.btn-indigo.dropdown-toggle {
    background-color: #3f51b5 !important
}

.btn-outline-indigo {
    border: 2px solid #3f51b5;
    color: #3f51b5 !important;
    background-color: transparent
}

.btn-outline-indigo.active,
.btn-outline-indigo:active,
.btn-outline-indigo:active:focus,
.btn-outline-indigo:focus,
.btn-outline-indigo:hover {
    background-color: transparent;
    color: #3f51b5;
    border-color: #3f51b5
}

.btn-outline-indigo:not([disabled]):not(.disabled).active,
.btn-outline-indigo:not([disabled]):not(.disabled):active,
.show>.btn-outline-indigo.dropdown-toggle {
    background-color: transparent !important;
    border-color: #3f51b5 !important
}

.btn-blue {
    background-color: #1976d2 !important;
    color: #fff !important
}

.btn-blue:hover {
    background-color: #2083e4
}

.btn-blue.active,
.btn-blue:active,
.btn-blue:focus {
    background-color: #145ca4
}

.btn-blue.dropdown-toggle {
    background-color: #1976d2 !important
}

.btn-blue.dropdown-toggle:focus,
.btn-blue.dropdown-toggle:hover {
    background-color: #2083e4 !important
}

.btn-blue:not([disabled]):not(.disabled).active,
.btn-blue:not([disabled]):not(.disabled):active,
.show>.btn-blue.dropdown-toggle {
    background-color: #1976d2 !important
}

.btn-outline-blue {
    border: 2px solid #1976d2;
    color: #1976d2 !important;
    background-color: transparent
}

.btn-outline-blue.active,
.btn-outline-blue:active,
.btn-outline-blue:active:focus,
.btn-outline-blue:focus,
.btn-outline-blue:hover {
    background-color: transparent;
    color: #1976d2;
    border-color: #1976d2
}

.btn-outline-blue:not([disabled]):not(.disabled).active,
.btn-outline-blue:not([disabled]):not(.disabled):active,
.show>.btn-outline-blue.dropdown-toggle {
    background-color: transparent !important;
    border-color: #1976d2 !important
}

.btn-light-blue {
    background-color: #82b1ff !important;
    color: #fff !important
}

.btn-light-blue:hover {
    background-color: #9cc1ff
}

.btn-light-blue.active,
.btn-light-blue:active,
.btn-light-blue:focus {
    background-color: #4f91ff
}

.btn-light-blue.dropdown-toggle {
    background-color: #82b1ff !important
}

.btn-light-blue.dropdown-toggle:focus,
.btn-light-blue.dropdown-toggle:hover {
    background-color: #9cc1ff !important
}

.btn-light-blue:not([disabled]):not(.disabled).active,
.btn-light-blue:not([disabled]):not(.disabled):active,
.show>.btn-light-blue.dropdown-toggle {
    background-color: #82b1ff !important
}

.btn-outline-light-blue {
    border: 2px solid #82b1ff;
    color: #82b1ff !important;
    background-color: transparent
}

.btn-outline-light-blue.active,
.btn-outline-light-blue:active,
.btn-outline-light-blue:active:focus,
.btn-outline-light-blue:focus,
.btn-outline-light-blue:hover {
    background-color: transparent;
    color: #82b1ff;
    border-color: #82b1ff
}

.btn-outline-light-blue:not([disabled]):not(.disabled).active,
.btn-outline-light-blue:not([disabled]):not(.disabled):active,
.show>.btn-outline-light-blue.dropdown-toggle {
    background-color: transparent !important;
    border-color: #82b1ff !important
}

.btn-cyan {
    background-color: #00bcd4 !important;
    color: #fff !important
}

.btn-cyan:hover {
    background-color: #00d3ee
}

.btn-cyan.active,
.btn-cyan:active,
.btn-cyan:focus {
    background-color: #008fa1
}

.btn-cyan.dropdown-toggle {
    background-color: #00bcd4 !important
}

.btn-cyan.dropdown-toggle:focus,
.btn-cyan.dropdown-toggle:hover {
    background-color: #00d3ee !important
}

.btn-cyan:not([disabled]):not(.disabled).active,
.btn-cyan:not([disabled]):not(.disabled):active,
.show>.btn-cyan.dropdown-toggle {
    background-color: #00bcd4 !important
}

.btn-outline-cyan {
    border: 2px solid #00bcd4;
    color: #00bcd4 !important;
    background-color: transparent
}

.btn-outline-cyan.active,
.btn-outline-cyan:active,
.btn-outline-cyan:active:focus,
.btn-outline-cyan:focus,
.btn-outline-cyan:hover {
    background-color: transparent;
    color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-cyan:not([disabled]):not(.disabled).active,
.btn-outline-cyan:not([disabled]):not(.disabled):active,
.show>.btn-outline-cyan.dropdown-toggle {
    background-color: transparent !important;
    border-color: #00bcd4 !important
}

.btn-teal {
    background-color: #00796b !important;
    color: #fff !important
}

.btn-teal:hover {
    background-color: #009382
}

.btn-teal.active,
.btn-teal:active,
.btn-teal:focus {
    background-color: #00463e
}

.btn-teal.dropdown-toggle {
    background-color: #00796b !important
}

.btn-teal.dropdown-toggle:focus,
.btn-teal.dropdown-toggle:hover {
    background-color: #009382 !important
}

.btn-teal:not([disabled]):not(.disabled).active,
.btn-teal:not([disabled]):not(.disabled):active,
.show>.btn-teal.dropdown-toggle {
    background-color: #00796b !important
}

.btn-outline-teal {
    border: 2px solid #00796b;
    color: #00796b !important;
    background-color: transparent
}

.btn-outline-teal.active,
.btn-outline-teal:active,
.btn-outline-teal:active:focus,
.btn-outline-teal:focus,
.btn-outline-teal:hover {
    background-color: transparent;
    color: #00796b;
    border-color: #00796b
}

.btn-outline-teal:not([disabled]):not(.disabled).active,
.btn-outline-teal:not([disabled]):not(.disabled):active,
.show>.btn-outline-teal.dropdown-toggle {
    background-color: transparent !important;
    border-color: #00796b !important
}

.btn-green {
    background-color: #388e3c !important;
    color: #fff !important
}

.btn-green:hover {
    background-color: #3fa044
}

.btn-green.active,
.btn-green:active,
.btn-green:focus {
    background-color: #2a692d
}

.btn-green.dropdown-toggle {
    background-color: #388e3c !important
}

.btn-green.dropdown-toggle:focus,
.btn-green.dropdown-toggle:hover {
    background-color: #3fa044 !important
}

.btn-green:not([disabled]):not(.disabled).active,
.btn-green:not([disabled]):not(.disabled):active,
.show>.btn-green.dropdown-toggle {
    background-color: #388e3c !important
}

.btn-outline-green {
    border: 2px solid #388e3c;
    color: #388e3c !important;
    background-color: transparent
}

.btn-outline-green.active,
.btn-outline-green:active,
.btn-outline-green:active:focus,
.btn-outline-green:focus,
.btn-outline-green:hover {
    background-color: transparent;
    color: #388e3c;
    border-color: #388e3c
}

.btn-outline-green:not([disabled]):not(.disabled).active,
.btn-outline-green:not([disabled]):not(.disabled):active,
.show>.btn-outline-green.dropdown-toggle {
    background-color: transparent !important;
    border-color: #388e3c !important
}

.btn-light-green {
    background-color: #8bc34a !important;
    color: #fff !important
}

.btn-light-green:hover {
    background-color: #97c95d
}

.btn-light-green.active,
.btn-light-green:active,
.btn-light-green:focus {
    background-color: #71a436
}

.btn-light-green.dropdown-toggle {
    background-color: #8bc34a !important
}

.btn-light-green.dropdown-toggle:focus,
.btn-light-green.dropdown-toggle:hover {
    background-color: #97c95d !important
}

.btn-light-green:not([disabled]):not(.disabled).active,
.btn-light-green:not([disabled]):not(.disabled):active,
.show>.btn-light-green.dropdown-toggle {
    background-color: #8bc34a !important
}

.btn-outline-light-green {
    border: 2px solid #8bc34a;
    color: #8bc34a !important;
    background-color: transparent
}

.btn-outline-light-green.active,
.btn-outline-light-green:active,
.btn-outline-light-green:active:focus,
.btn-outline-light-green:focus,
.btn-outline-light-green:hover {
    background-color: transparent;
    color: #8bc34a;
    border-color: #8bc34a
}

.btn-outline-light-green:not([disabled]):not(.disabled).active,
.btn-outline-light-green:not([disabled]):not(.disabled):active,
.show>.btn-outline-light-green.dropdown-toggle {
    background-color: transparent !important;
    border-color: #8bc34a !important
}

.btn-lime {
    background-color: #afb42b !important;
    color: #fff !important
}

.btn-lime:hover {
    background-color: #c3c930
}

.btn-lime.active,
.btn-lime:active,
.btn-lime:focus {
    background-color: #878b21
}

.btn-lime.dropdown-toggle {
    background-color: #afb42b !important
}

.btn-lime.dropdown-toggle:focus,
.btn-lime.dropdown-toggle:hover {
    background-color: #c3c930 !important
}

.btn-lime:not([disabled]):not(.disabled).active,
.btn-lime:not([disabled]):not(.disabled):active,
.show>.btn-lime.dropdown-toggle {
    background-color: #afb42b !important
}

.btn-outline-lime {
    border: 2px solid #afb42b;
    color: #afb42b !important;
    background-color: transparent
}

.btn-outline-lime.active,
.btn-outline-lime:active,
.btn-outline-lime:active:focus,
.btn-outline-lime:focus,
.btn-outline-lime:hover {
    background-color: transparent;
    color: #afb42b;
    border-color: #afb42b
}

.btn-outline-lime:not([disabled]):not(.disabled).active,
.btn-outline-lime:not([disabled]):not(.disabled):active,
.show>.btn-outline-lime.dropdown-toggle {
    background-color: transparent !important;
    border-color: #afb42b !important
}

.btn-yellow {
    background-color: #fbc02d !important;
    color: #fff !important
}

.btn-yellow:hover {
    background-color: #fbc846
}

.btn-yellow.active,
.btn-yellow:active,
.btn-yellow:focus {
    background-color: #f0ad05
}

.btn-yellow.dropdown-toggle {
    background-color: #fbc02d !important
}

.btn-yellow.dropdown-toggle:focus,
.btn-yellow.dropdown-toggle:hover {
    background-color: #fbc846 !important
}

.btn-yellow:not([disabled]):not(.disabled).active,
.btn-yellow:not([disabled]):not(.disabled):active,
.show>.btn-yellow.dropdown-toggle {
    background-color: #fbc02d !important
}

.btn-outline-yellow {
    border: 2px solid #fbc02d;
    color: #fbc02d !important;
    background-color: transparent
}

.btn-outline-yellow.active,
.btn-outline-yellow:active,
.btn-outline-yellow:active:focus,
.btn-outline-yellow:focus,
.btn-outline-yellow:hover {
    background-color: transparent;
    color: #fbc02d;
    border-color: #fbc02d
}

.btn-outline-yellow:not([disabled]):not(.disabled).active,
.btn-outline-yellow:not([disabled]):not(.disabled):active,
.show>.btn-outline-yellow.dropdown-toggle {
    background-color: transparent !important;
    border-color: #fbc02d !important
}

.btn-amber {
    background-color: #ffa000 !important;
    color: #fff !important
}

.btn-amber:hover {
    background-color: #ffaa1a
}

.btn-amber.active,
.btn-amber:active,
.btn-amber:focus {
    background-color: #cc8000
}

.btn-amber.dropdown-toggle {
    background-color: #ffa000 !important
}

.btn-amber.dropdown-toggle:focus,
.btn-amber.dropdown-toggle:hover {
    background-color: #ffaa1a !important
}

.btn-amber:not([disabled]):not(.disabled).active,
.btn-amber:not([disabled]):not(.disabled):active,
.show>.btn-amber.dropdown-toggle {
    background-color: #ffa000 !important
}

.btn-outline-amber {
    border: 2px solid #ffa000;
    color: #ffa000 !important;
    background-color: transparent
}

.btn-outline-amber.active,
.btn-outline-amber:active,
.btn-outline-amber:active:focus,
.btn-outline-amber:focus,
.btn-outline-amber:hover {
    background-color: transparent;
    color: #ffa000;
    border-color: #ffa000
}

.btn-outline-amber:not([disabled]):not(.disabled).active,
.btn-outline-amber:not([disabled]):not(.disabled):active,
.show>.btn-outline-amber.dropdown-toggle {
    background-color: transparent !important;
    border-color: #ffa000 !important
}

.btn-orange {
    background-color: #f57c00 !important;
    color: #fff !important
}

.btn-orange:hover {
    background-color: #ff8910
}

.btn-orange.active,
.btn-orange:active,
.btn-orange:focus {
    background-color: #c26200
}

.btn-orange.dropdown-toggle {
    background-color: #f57c00 !important
}

.btn-orange.dropdown-toggle:focus,
.btn-orange.dropdown-toggle:hover {
    background-color: #ff8910 !important
}

.btn-orange:not([disabled]):not(.disabled).active,
.btn-orange:not([disabled]):not(.disabled):active,
.show>.btn-orange.dropdown-toggle {
    background-color: #f57c00 !important
}

.btn-outline-orange {
    border: 2px solid #f57c00;
    color: #f57c00 !important;
    background-color: transparent
}

.btn-outline-orange.active,
.btn-outline-orange:active,
.btn-outline-orange:active:focus,
.btn-outline-orange:focus,
.btn-outline-orange:hover {
    background-color: transparent;
    color: #f57c00;
    border-color: #f57c00
}

.btn-outline-orange:not([disabled]):not(.disabled).active,
.btn-outline-orange:not([disabled]):not(.disabled):active,
.show>.btn-outline-orange.dropdown-toggle {
    background-color: transparent !important;
    border-color: #f57c00 !important
}

.btn-deep-orange {
    background-color: #ff7043 !important;
    color: #fff !important
}

.btn-deep-orange:hover {
    background-color: #ff835d
}

.btn-deep-orange.active,
.btn-deep-orange:active,
.btn-deep-orange:focus {
    background-color: #ff4910
}

.btn-deep-orange.dropdown-toggle {
    background-color: #ff7043 !important
}

.btn-deep-orange.dropdown-toggle:focus,
.btn-deep-orange.dropdown-toggle:hover {
    background-color: #ff835d !important
}

.btn-deep-orange:not([disabled]):not(.disabled).active,
.btn-deep-orange:not([disabled]):not(.disabled):active,
.show>.btn-deep-orange.dropdown-toggle {
    background-color: #ff7043 !important
}

.btn-outline-deep-orange {
    border: 2px solid #ff7043;
    color: #ff7043 !important;
    background-color: transparent
}

.btn-outline-deep-orange.active,
.btn-outline-deep-orange:active,
.btn-outline-deep-orange:active:focus,
.btn-outline-deep-orange:focus,
.btn-outline-deep-orange:hover {
    background-color: transparent;
    color: #ff7043;
    border-color: #ff7043
}

.btn-outline-deep-orange:not([disabled]):not(.disabled).active,
.btn-outline-deep-orange:not([disabled]):not(.disabled):active,
.show>.btn-outline-deep-orange.dropdown-toggle {
    background-color: transparent !important;
    border-color: #ff7043 !important
}

.btn-brown {
    background-color: #795548 !important;
    color: #fff !important
}

.btn-brown:hover {
    background-color: #896052
}

.btn-brown.active,
.btn-brown:active,
.btn-brown:focus {
    background-color: #593f35
}

.btn-brown.dropdown-toggle {
    background-color: #795548 !important
}

.btn-brown.dropdown-toggle:focus,
.btn-brown.dropdown-toggle:hover {
    background-color: #896052 !important
}

.btn-brown:not([disabled]):not(.disabled).active,
.btn-brown:not([disabled]):not(.disabled):active,
.show>.btn-brown.dropdown-toggle {
    background-color: #795548 !important
}

.btn-outline-brown {
    border: 2px solid #795548;
    color: #795548 !important;
    background-color: transparent
}

.btn-outline-brown.active,
.btn-outline-brown:active,
.btn-outline-brown:active:focus,
.btn-outline-brown:focus,
.btn-outline-brown:hover {
    background-color: transparent;
    color: #795548;
    border-color: #795548
}

.btn-outline-brown:not([disabled]):not(.disabled).active,
.btn-outline-brown:not([disabled]):not(.disabled):active,
.show>.btn-outline-brown.dropdown-toggle {
    background-color: transparent !important;
    border-color: #795548 !important
}

.btn-grey {
    background-color: #616161 !important;
    color: #fff !important
}

.btn-grey:hover {
    background-color: #6e6e6e
}

.btn-grey.active,
.btn-grey:active,
.btn-grey:focus {
    background-color: #484848
}

.btn-grey.dropdown-toggle {
    background-color: #616161 !important
}

.btn-grey.dropdown-toggle:focus,
.btn-grey.dropdown-toggle:hover {
    background-color: #6e6e6e !important
}

.btn-grey:not([disabled]):not(.disabled).active,
.btn-grey:not([disabled]):not(.disabled):active,
.show>.btn-grey.dropdown-toggle {
    background-color: #616161 !important
}

.btn-outline-grey {
    border: 2px solid #616161;
    color: #616161 !important;
    background-color: transparent
}

.btn-outline-grey.active,
.btn-outline-grey:active,
.btn-outline-grey:active:focus,
.btn-outline-grey:focus,
.btn-outline-grey:hover {
    background-color: transparent;
    color: #616161;
    border-color: #616161
}

.btn-outline-grey:not([disabled]):not(.disabled).active,
.btn-outline-grey:not([disabled]):not(.disabled):active,
.show>.btn-outline-grey.dropdown-toggle {
    background-color: transparent !important;
    border-color: #616161 !important
}

.btn-blue-grey {
    background-color: #78909c !important;
    color: #fff !important
}

.btn-blue-grey:hover {
    background-color: #879ca7
}

.btn-blue-grey.active,
.btn-blue-grey:active,
.btn-blue-grey:focus {
    background-color: #5f7682
}

.btn-blue-grey.dropdown-toggle {
    background-color: #78909c !important
}

.btn-blue-grey.dropdown-toggle:focus,
.btn-blue-grey.dropdown-toggle:hover {
    background-color: #879ca7 !important
}

.btn-blue-grey:not([disabled]):not(.disabled).active,
.btn-blue-grey:not([disabled]):not(.disabled):active,
.show>.btn-blue-grey.dropdown-toggle {
    background-color: #78909c !important
}

.btn-outline-blue-grey {
    border: 2px solid #78909c;
    color: #78909c !important;
    background-color: transparent
}

.btn-outline-blue-grey.active,
.btn-outline-blue-grey:active,
.btn-outline-blue-grey:active:focus,
.btn-outline-blue-grey:focus,
.btn-outline-blue-grey:hover {
    background-color: transparent;
    color: #78909c;
    border-color: #78909c
}

.btn-outline-blue-grey:not([disabled]):not(.disabled).active,
.btn-outline-blue-grey:not([disabled]):not(.disabled):active,
.show>.btn-outline-blue-grey.dropdown-toggle {
    background-color: transparent !important;
    border-color: #78909c !important
}

.btn-dark {
    background-color: #212121 !important;
    color: #fff !important
}

.btn-dark:hover {
    background-color: #2e2e2e
}

.btn-dark.active,
.btn-dark:active,
.btn-dark:focus {
    background-color: #080808
}

.btn-dark.dropdown-toggle {
    background-color: #212121 !important
}

.btn-dark.dropdown-toggle:focus,
.btn-dark.dropdown-toggle:hover {
    background-color: #2e2e2e !important
}

.btn-dark:not([disabled]):not(.disabled).active,
.btn-dark:not([disabled]):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    background-color: #212121 !important
}

.btn-outline-dark {
    border: 2px solid #212121;
    color: #212121 !important;
    background-color: transparent
}

.btn-outline-dark.active,
.btn-outline-dark:active,
.btn-outline-dark:active:focus,
.btn-outline-dark:focus,
.btn-outline-dark:hover {
    background-color: transparent;
    color: #212121;
    border-color: #212121
}

.btn-outline-dark:not([disabled]):not(.disabled).active,
.btn-outline-dark:not([disabled]):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    background-color: transparent !important;
    border-color: #212121 !important
}

.btn-light {
    background-color: #e0e0e0 !important;
    color: #000 !important
}

.btn-light:hover {
    background-color: #ededed
}

.btn-light.active,
.btn-light:active,
.btn-light:focus {
    background-color: #c7c7c7
}

.btn-light.dropdown-toggle {
    background-color: #e0e0e0 !important
}

.btn-light.dropdown-toggle:focus,
.btn-light.dropdown-toggle:hover {
    background-color: #ededed !important
}

.btn-light:not([disabled]):not(.disabled).active,
.btn-light:not([disabled]):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    background-color: #e0e0e0 !important
}

.btn-outline-light {
    border: 2px solid #e0e0e0;
    color: #e0e0e0 !important;
    background-color: transparent
}

.btn-outline-light.active,
.btn-outline-light:active,
.btn-outline-light:active:focus,
.btn-outline-light:focus,
.btn-outline-light:hover {
    background-color: transparent;
    color: #e0e0e0;
    border-color: #e0e0e0
}

.btn-outline-light:not([disabled]):not(.disabled).active,
.btn-outline-light:not([disabled]):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    background-color: transparent !important;
    border-color: #e0e0e0 !important
}

.btn-white {
    background-color: #fff !important;
    color: #000 !important
}

.btn-white:hover {
    background-color: #fff
}

.btn-white.active,
.btn-white:active,
.btn-white:focus {
    background-color: #e6e6e6
}

.btn-white.dropdown-toggle {
    background-color: #fff !important
}

.btn-white.dropdown-toggle:focus,
.btn-white.dropdown-toggle:hover {
    background-color: #fff !important
}

.btn-white:not([disabled]):not(.disabled).active,
.btn-white:not([disabled]):not(.disabled):active,
.show>.btn-white.dropdown-toggle {
    background-color: #fff !important
}

.btn-outline-white {
    border: 2px solid #fff;
    color: #fff !important;
    background-color: transparent
}

.btn-outline-white.active,
.btn-outline-white:active,
.btn-outline-white:active:focus,
.btn-outline-white:focus,
.btn-outline-white:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff
}

.btn-outline-white:not([disabled]):not(.disabled).active,
.btn-outline-white:not([disabled]):not(.disabled):active,
.show>.btn-outline-white.dropdown-toggle {
    background-color: transparent !important;
    border-color: #fff !important
}

.btn-black {
    background-color: #000 !important;
    color: #fff !important
}

.btn-black:hover {
    background-color: #0d0d0d
}

.btn-black.active,
.btn-black:active,
.btn-black:focus {
    background-color: #000
}

.btn-black.dropdown-toggle {
    background-color: #000 !important
}

.btn-black.dropdown-toggle:focus,
.btn-black.dropdown-toggle:hover {
    background-color: #0d0d0d !important
}

.btn-black:not([disabled]):not(.disabled).active,
.btn-black:not([disabled]):not(.disabled):active,
.show>.btn-black.dropdown-toggle {
    background-color: #000 !important
}

.btn-outline-black {
    border: 2px solid #000;
    color: #000 !important;
    background-color: transparent
}

.btn-outline-black.active,
.btn-outline-black:active,
.btn-outline-black:active:focus,
.btn-outline-black:focus,
.btn-outline-black:hover {
    background-color: transparent;
    color: #000;
    border-color: #000
}

.btn-outline-black:not([disabled]):not(.disabled).active,
.btn-outline-black:not([disabled]):not(.disabled):active,
.show>.btn-outline-black.dropdown-toggle {
    background-color: transparent !important;
    border-color: #000 !important
}

.card {
    font-weight: 400;
    border-radius: .3rem
}

.card:not([class*=card-outline-]) {
    border: 0
}

.card img {
    border-radius: 2px 2px 0 0
}

.card .card-body {
    position: relative
}

.card .card-body h5 {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400
}

.card .card-body h3 {
    margin-bottom: 1rem;
    font-weight: 400
}

.card .card-body h4 {
    font-weight: 400
}

.card .card-body p {
    margin-bottom: 1rem
}

.card .card-title a {
    -webkit-transition: .3s;
    transition: .3s
}

.card .card-title a:hover {
    -webkit-transition: .3s;
    transition: .3s
}

.card .card-text {
    font-size: .9rem;
    color: #747373;
    font-weight: 400
}

.card .card-footer.links-light a {
    font-size: 15px;
    color: #757575
}

.card .card-footer.links-light a:hover {
    color: #d50000;
    -webkit-transition: .4s;
    transition: .4s
}

.card .card-footer.links-light a .fa {
    font-size: 17px
}

.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover {
    color: #1d1e1f;
    background-color: #f7f7f9
}

.navbar {
    font-weight: 300;
    padding-right: 1rem !important
}

.navbar form input {
    margin: 0 5px 1px 8px;
    height: 1rem
}

.navbar .navbar-brand {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    overflow: visible
}

.navbar .breadcrumb {
    margin: 0;
    background-color: inherit;
    font-weight: 300;
    font-size: 15px;
    padding: .3em 0 0 1em
}

.navbar .navbar-toggler {
    border-width: 0
}

.navbar .nav-flex-icons,
.navbar.double-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
}

@media (max-width:992px) {
    .navbar .container {
        width: 100%
    }

    .navbar .container .navbar-toggler-right {
        right: 0
    }
}

.navbar.navbar-dark .navbar-nav .nav-item .nav-link.disabled,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link.disabled:hover {
    color: rgba(255, 255, 255, .5)
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link.disabled,
.navbar.navbar-light .navbar-nav .nav-item .nav-link.disabled:hover {
    color: rgba(0, 0, 0, .5)
}

.navbar .nav-item .nav-link {
    display: block
}

.navbar .nav-item .nav-link.disabled:active {
    pointer-events: none
}

.navbar .nav-item .nav-link .fa {
    padding-left: 3px;
    padding-right: 3px
}

@media (max-width:992px) {
    .navbar .nav-item .nav-link {
        padding-left: 6px;
        padding-right: 6px
    }
}

.navbar .dropdown-menu {
    position: absolute !important;
    margin-top: 0
}

.navbar .dropdown-menu a {
    font-size: .9375rem;
    font-weight: 300;
    padding: 10px;
    color: #000 !important
}

.navbar .dropdown-menu a:hover {
    color: #fff !important
}

@media (max-width:600px) {
    .navbar .dropdown-menu.dropdown-menu-right {
        right: auto !important
    }
}

.navbar.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    cursor: pointer
}

.navbar.navbar-light .breadcrumb .nav-item .nav-link,
.navbar.navbar-light .navbar-nav .nav-item .nav-link {
    color: #000;
    -webkit-transition: .35s;
    transition: .35s
}

.navbar.navbar-light .breadcrumb .nav-item .nav-link:hover,
.navbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
    color: rgba(0, 0, 0, .75)
}

.navbar.navbar-light .breadcrumb .nav-item.active>.nav-link,
.navbar.navbar-light .navbar-nav .nav-item.active>.nav-link {
    background-color: rgba(0, 0, 0, .1)
}

.navbar.navbar-light .breadcrumb .nav-item.active>.nav-link:hover,
.navbar.navbar-light .navbar-nav .nav-item.active>.nav-link:hover {
    color: #000
}

.navbar.navbar-light .navbar-toggler {
    color: #000
}

.navbar.navbar-light form input[type=text] {
    border-bottom: 1px solid #000
}

.navbar.navbar-light form input[type=text]:focus:not([readonly]) {
    border-color: #4285f4
}

.navbar.navbar-light form .form-control {
    color: #000
}

.navbar.navbar-light form .form-control::-webkit-input-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control:-moz-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control::-moz-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control::-ms-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control::-webkit-input-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control::-moz-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control:-ms-input-placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-light form .form-control::placeholder {
    color: #000;
    font-weight: 300
}

.navbar.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    cursor: pointer
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: #fff;
    -webkit-transition: .35s;
    transition: .35s
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link:hover,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar.navbar-dark .breadcrumb .nav-item.active>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item.active>.nav-link {
    background-color: rgba(255, 255, 255, .1)
}

.navbar.navbar-dark .breadcrumb .nav-item.active>.nav-link:hover,
.navbar.navbar-dark .navbar-nav .nav-item.active>.nav-link:hover {
    color: #fff
}

.navbar.navbar-dark .navbar-toggler {
    color: #fff
}

.navbar.navbar-dark form input[type=text] {
    border-bottom: 1px solid #fff
}

.navbar.navbar-dark form input[type=text]:focus:not([readonly]) {
    border-color: #4285f4
}

.navbar.navbar-dark form .form-control {
    color: #fff
}

.navbar.navbar-dark form .form-control::-webkit-input-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control:-moz-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control::-moz-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control::-ms-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control::-webkit-input-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control::-moz-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control:-ms-input-placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.navbar-dark form .form-control::placeholder {
    color: #fff;
    font-weight: 300
}

.navbar.scrolling-navbar {
    z-index: 100
}

@media (min-width:600px) {
    .navbar.scrolling-navbar {
        -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
        transition: background .5s ease-in-out, padding .5s ease-in-out;
        padding-top: 12px;
        padding-bottom: 12px
    }

    .navbar.scrolling-navbar .navbar-nav>li {
        -webkit-transition-duration: 1s;
        transition-duration: 1s
    }

    .navbar.scrolling-navbar.top-nav-collapse {
        padding-top: 5px;
        padding-bottom: 5px
    }
}

@media (min-width:600px) {
    .intro-margin.view {
        overflow: visible;
        margin-top: -56px
    }
}

.pagination .page-link {
    background-color: transparent;
    font-size: .8rem
}

.pagination .active .page-link {
    border-radius: 2px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}

.pagination .active .page-link:hover {
    color: #fff
}

.pagination.pagination-circle .page-link {
    border-radius: 50%;
    margin-left: 2px;
    margin-right: 2px
}

.pagination.pagination-circle .page-link:hover {
    border-radius: 50%
}

.pagination.pagination-circle .active .page-link {
    border-radius: 50%
}

.pagination .page-link {
    border: 0;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.pagination .page-link:hover {
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    background-color: #eee
}

.pagination .page-link:focus {
    background-color: transparent
}

.pagination.pg-blue .active .page-link {
    background-color: #4285f4
}

.pagination.pg-red .active .page-link {
    background-color: #ff3547
}

.pagination.pg-teal .active .page-link {
    background-color: #2bbbad
}

.pagination.pg-darkgrey .active .page-link {
    background-color: #37474f
}

.pagination.pg-dark .active .page-link {
    background-color: #2e2e2e
}

.pagination.pg-bluegrey .active .page-link {
    background-color: #3f729b
}

.pagination.pg-amber .active .page-link {
    background-color: #ff6f00
}

.pagination.pg-purple .active .page-link {
    background-color: #5e35b1
}

.badge {
    color: #fff
}

.badge-primary {
    background-color: #4285f4
}

.badge-danger {
    background-color: #ff3547
}

.badge-warning {
    background-color: #f80
}

.badge-success {
    background-color: #00c851
}

.badge-info {
    background-color: #33b5e5
}

.badge-default {
    background-color: #2bbbad
}

.badge-secondary {
    background-color: #a6c
}

.badge-elegant {
    background-color: #2e2e2e
}

.badge-unique {
    background-color: #880e4f
}

.badge-dark-green {
    background-color: #388e3c
}

.badge-mdb-color {
    background-color: #59698d
}

.badge-red {
    background-color: #d32f2f
}

.badge-pink {
    background-color: #ec407a
}

.badge-purple {
    background-color: #8e24aa
}

.badge-deep-purple {
    background-color: #512da8
}

.badge-indigo {
    background-color: #3f51b5
}

.badge-blue {
    background-color: #1976d2
}

.badge-light-blue {
    background-color: #82b1ff
}

.badge-cyan {
    background-color: #00bcd4
}

.badge-teal {
    background-color: #00796b
}

.badge-green {
    background-color: #388e3c
}

.badge-light-green {
    background-color: #8bc34a
}

.badge-lime {
    background-color: #afb42b
}

.badge-yellow {
    background-color: #fbc02d
}

.badge-amber {
    background-color: #ffa000
}

.badge-orange {
    background-color: #f57c00
}

.badge-deep-orange {
    background-color: #ff7043
}

.badge-brown {
    background-color: #795548
}

.badge-grey {
    background-color: #616161
}

.badge-blue-grey {
    background-color: #78909c
}

.badge-dark {
    background-color: #212121
}

.badge-light {
    background-color: #e0e0e0
}

.badge-white {
    background-color: #fff
}

.badge-black {
    background-color: #000
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-warning {
    color: #fff !important
}

body.modal-open {
    padding-right: 0 !important;
    overflow: auto
}

body.scrollable {
    overflow-y: auto
}

.modal-dialog .modal-content {
    border-radius: 2px;
    border: 0
}

.modal {
    padding-right: 0 !important
}

@media (min-width:768px) {
    .modal .modal-dialog.modal-top {
        top: 0
    }

    .modal .modal-dialog.modal-left {
        left: 0
    }

    .modal .modal-dialog.modal-right {
        right: 0
    }

    .modal .modal-dialog.modal-bottom {
        bottom: 0
    }

    .modal .modal-dialog.modal-top-left {
        top: 10px;
        left: 10px
    }

    .modal .modal-dialog.modal-top-right {
        top: 10px;
        right: 10px
    }

    .modal .modal-dialog.modal-bottom-left {
        left: 10px;
        bottom: 10px
    }

    .modal .modal-dialog.modal-bottom-right {
        right: 10px;
        bottom: 10px
    }
}

.modal.fade.top:not(.show) .modal-dialog {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0)
}

.modal.fade.left:not(.show) .modal-dialog {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0)
}

.modal.fade.right:not(.show) .modal-dialog {
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0)
}

.modal.fade.bottom:not(.show) .modal-dialog {
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0)
}

@media (min-width:992px) {
    .modal.modal-scrolling {
        position: relative
    }

    .modal.modal-scrolling .modal-dialog {
        position: fixed;
        z-index: 1050
    }

    .modal.modal-content-clickable {
        top: auto;
        bottom: auto
    }

    .modal.modal-content-clickable .modal-dialog {
        position: fixed
    }

    .modal .modal-fluid {
        width: 100%;
        max-width: 100%
    }

    .modal .modal-fluid .modal-content {
        width: 100%
    }

    .modal .modal-frame {
        position: absolute;
        width: 100%;
        max-width: 100%;
        margin: 0
    }

    .modal .modal-frame.modal-bottom {
        bottom: 0
    }

    .modal .modal-full-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        width: 400px;
        height: 100%;
        margin: 0;
        top: 0;
        right: 0
    }

    .modal .modal-full-height.modal-bottom,
    .modal .modal-full-height.modal-top {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto
    }

    .modal .modal-full-height.modal-top {
        bottom: auto
    }

    .modal .modal-full-height.modal-bottom {
        top: auto
    }

    .modal .modal-full-height .modal-content {
        width: 100%
    }

    .modal .modal-full-height.modal-lg {
        max-width: 90%;
        width: 90%
    }
}

@media (min-width:992px) and (min-width:992px) {
    .modal .modal-full-height.modal-lg {
        max-width: 800px;
        width: 800px
    }
}

@media (min-width:992px) and (min-width:1200px) {
    .modal .modal-full-height.modal-lg {
        max-width: 1000px;
        width: 1000px
    }
}

@media (min-width:992px) {
    .modal .modal-side {
        position: absolute;
        right: 10px;
        bottom: 10px;
        margin: 0;
        width: 400px
    }
}

.modal-dialog .btn .fa {
    color: #fff !important
}

.modal-dialog [class*=btn-outline-] .fa {
    color: inherit !important
}

.modal-dialog.cascading-modal {
    margin-top: 10%
}

.modal-dialog.cascading-modal .close {
    color: #fff;
    opacity: 1;
    text-shadow: none
}

.modal-dialog.cascading-modal .modal-header {
    text-align: center;
    margin: -2rem 1rem 1rem 1rem;
    padding: 1.5rem;
    border: none;
    border-radius: 3px
}

.modal-dialog.cascading-modal .modal-header .close {
    margin-right: 1rem
}

.modal-dialog.cascading-modal .modal-header .title {
    width: 100%;
    margin-bottom: 0;
    font-size: 1.25rem
}

.modal-dialog.cascading-modal .modal-header .title .fa {
    margin-right: 9px
}

.modal-dialog.cascading-modal .modal-header .social-buttons {
    margin-top: 1.5rem
}

.modal-dialog.cascading-modal .modal-header .social-buttons a {
    font-size: 1rem
}

.modal-dialog.cascading-modal .modal-c-tabs .nav-tabs {
    margin: -1.5rem 1rem 0 1rem
}

.modal-dialog.cascading-modal .modal-c-tabs .tab-content {
    padding: 1.7rem 0 0 0
}

.modal-dialog.cascading-modal .nav-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.modal-dialog.cascading-modal .nav-tabs li {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.modal-dialog.cascading-modal .nav-tabs li a {
    text-align: center;
    font-weight: 400
}

.modal-dialog.cascading-modal .modal-body,
.modal-dialog.cascading-modal .modal-footer {
    color: #616161;
    padding-right: 2rem;
    padding-left: 2rem
}

.modal-dialog.cascading-modal .modal-body .additional-option,
.modal-dialog.cascading-modal .modal-footer .additional-option {
    text-align: center;
    margin-top: 1rem
}

.modal-dialog.cascading-modal.modal-avatar {
    margin-top: 6rem
}

.modal-dialog.cascading-modal.modal-avatar .modal-header {
    margin: -6rem 2rem -1rem 2rem
}

.modal-dialog.cascading-modal.modal-avatar .modal-header img {
    width: 130px;
    margin-left: auto;
    margin-right: auto
}

.modal-dialog.modal-notify .heading {
    margin: 0;
    padding: .3rem;
    color: #fff;
    font-size: 1.15rem
}

.modal-dialog.modal-notify .modal-header {
    border: 0
}

.modal-dialog.modal-notify .close {
    opacity: 1
}

.modal-dialog.modal-notify .modal-body {
    padding: 1.5rem;
    color: #616161
}

.modal-dialog.modal-notify .btn-outline-secondary-modal {
    background-color: transparent
}

.modal-dialog.modal-notify.modal-info .modal-header {
    background-color: #5394ff
}

.modal-dialog.modal-notify.modal-info .fa {
    color: #5394ff
}

.modal-dialog.modal-notify.modal-info .badge {
    background-color: #5394ff
}

.modal-dialog.modal-notify.modal-info .btn-primary-modal {
    background-color: #5394ff !important;
    color: #fff !important
}

.modal-dialog.modal-notify.modal-info .btn-primary-modal:hover {
    background-color: #6da4ff
}

.modal-dialog.modal-notify.modal-info .btn-primary-modal.active,
.modal-dialog.modal-notify.modal-info .btn-primary-modal:active,
.modal-dialog.modal-notify.modal-info .btn-primary-modal:focus {
    background-color: #2074ff
}

.modal-dialog.modal-notify.modal-info .btn-primary-modal.dropdown-toggle {
    background-color: #5394ff !important
}

.modal-dialog.modal-notify.modal-info .btn-primary-modal.dropdown-toggle:focus,
.modal-dialog.modal-notify.modal-info .btn-primary-modal.dropdown-toggle:hover {
    background-color: #6da4ff !important
}

.modal-dialog.modal-notify.modal-info .btn-primary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-info .btn-primary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-info .btn-primary-modal.dropdown-toggle {
    background-color: #5394ff !important
}

.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal {
    border: 2px solid #5394ff;
    color: #5394ff !important;
    background-color: transparent
}

.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal.active,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:active,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:active:focus,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:focus,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:hover {
    background-color: transparent;
    color: #5394ff;
    border-color: #5394ff
}

.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-info .btn-outline-secondary-modal.dropdown-toggle {
    background-color: transparent !important;
    border-color: #5394ff !important
}

.modal-dialog.modal-notify.modal-warning .modal-header {
    background-color: #ff8e38
}

.modal-dialog.modal-notify.modal-warning .fa {
    color: #ff8e38
}

.modal-dialog.modal-notify.modal-warning .badge {
    background-color: #ff8e38
}

.modal-dialog.modal-notify.modal-warning .btn-primary-modal {
    background-color: #ff8e38 !important;
    color: #fff !important
}

.modal-dialog.modal-notify.modal-warning .btn-primary-modal:hover {
    background-color: #ff9c52
}

.modal-dialog.modal-notify.modal-warning .btn-primary-modal.active,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal:active,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal:focus {
    background-color: #ff7105
}

.modal-dialog.modal-notify.modal-warning .btn-primary-modal.dropdown-toggle {
    background-color: #ff8e38 !important
}

.modal-dialog.modal-notify.modal-warning .btn-primary-modal.dropdown-toggle:focus,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal.dropdown-toggle:hover {
    background-color: #ff9c52 !important
}

.modal-dialog.modal-notify.modal-warning .btn-primary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-warning .btn-primary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-warning .btn-primary-modal.dropdown-toggle {
    background-color: #ff8e38 !important
}

.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal {
    border: 2px solid #ff8e38;
    color: #ff8e38 !important;
    background-color: transparent
}

.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal.active,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:active,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:active:focus,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:focus,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:hover {
    background-color: transparent;
    color: #ff8e38;
    border-color: #ff8e38
}

.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-warning .btn-outline-secondary-modal.dropdown-toggle {
    background-color: transparent !important;
    border-color: #ff8e38 !important
}

.modal-dialog.modal-notify.modal-success .modal-header {
    background-color: #01d36b
}

.modal-dialog.modal-notify.modal-success .fa {
    color: #01d36b
}

.modal-dialog.modal-notify.modal-success .badge {
    background-color: #01d36b
}

.modal-dialog.modal-notify.modal-success .btn-primary-modal {
    background-color: #01d36b !important;
    color: #fff !important
}

.modal-dialog.modal-notify.modal-success .btn-primary-modal:hover {
    background-color: #01ec78
}

.modal-dialog.modal-notify.modal-success .btn-primary-modal.active,
.modal-dialog.modal-notify.modal-success .btn-primary-modal:active,
.modal-dialog.modal-notify.modal-success .btn-primary-modal:focus {
    background-color: #01a051
}

.modal-dialog.modal-notify.modal-success .btn-primary-modal.dropdown-toggle {
    background-color: #01d36b !important
}

.modal-dialog.modal-notify.modal-success .btn-primary-modal.dropdown-toggle:focus,
.modal-dialog.modal-notify.modal-success .btn-primary-modal.dropdown-toggle:hover {
    background-color: #01ec78 !important
}

.modal-dialog.modal-notify.modal-success .btn-primary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-success .btn-primary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-success .btn-primary-modal.dropdown-toggle {
    background-color: #01d36b !important
}

.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal {
    border: 2px solid #01d36b;
    color: #01d36b !important;
    background-color: transparent
}

.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal.active,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:active,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:active:focus,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:focus,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:hover {
    background-color: transparent;
    color: #01d36b;
    border-color: #01d36b
}

.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-success .btn-outline-secondary-modal.dropdown-toggle {
    background-color: transparent !important;
    border-color: #01d36b !important
}

.modal-dialog.modal-notify.modal-danger .modal-header {
    background-color: #ff4b4b
}

.modal-dialog.modal-notify.modal-danger .fa {
    color: #ff4b4b
}

.modal-dialog.modal-notify.modal-danger .badge {
    background-color: #ff4b4b
}

.modal-dialog.modal-notify.modal-danger .btn-primary-modal {
    background-color: #ff4b4b !important;
    color: #fff !important
}

.modal-dialog.modal-notify.modal-danger .btn-primary-modal:hover {
    background-color: #ff6565
}

.modal-dialog.modal-notify.modal-danger .btn-primary-modal.active,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal:active,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal:focus {
    background-color: #ff1818
}

.modal-dialog.modal-notify.modal-danger .btn-primary-modal.dropdown-toggle {
    background-color: #ff4b4b !important
}

.modal-dialog.modal-notify.modal-danger .btn-primary-modal.dropdown-toggle:focus,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal.dropdown-toggle:hover {
    background-color: #ff6565 !important
}

.modal-dialog.modal-notify.modal-danger .btn-primary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-danger .btn-primary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-danger .btn-primary-modal.dropdown-toggle {
    background-color: #ff4b4b !important
}

.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal {
    border: 2px solid #ff4b4b;
    color: #ff4b4b !important;
    background-color: transparent
}

.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal.active,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:active,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:active:focus,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:focus,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:hover {
    background-color: transparent;
    color: #ff4b4b;
    border-color: #ff4b4b
}

.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:not([disabled]):not(.disabled).active,
.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal:not([disabled]):not(.disabled):active,
.show>.modal-dialog.modal-notify.modal-danger .btn-outline-secondary-modal.dropdown-toggle {
    background-color: transparent !important;
    border-color: #ff4b4b !important
}

.progress {
    box-shadow: none;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    background-color: #eee
}

.progress .progress-bar {
    border-radius: 0;
    height: 4px;
    background-color: #0d47a1
}

.progress.progress-default {
    height: 1rem
}

.progress.progress-default .progress-bar {
    height: 1rem
}

.progress.progress-wider {
    height: 20px
}

.progress.progress-wider .progress-bar {
    height: 20px
}

.progress.progress-narrower {
    height: 1px
}

.progress.progress-narrower .progress-bar {
    height: 1px
}

.carousel .carousel-control-next-icon,
.carousel .carousel-control-prev-icon {
    width: 36px;
    height: 36px
}

.carousel .carousel-control-prev-icon {
    /* background-image:url(../img/svg/arrow_left.svg) */
}

.carousel .carousel-control-next-icon {
    /* background-image:url(../img/svg/arrow_right.svg) */
}

.carousel .carousel-indicators li {
    max-width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer
}

.carousel .video-fluid {
    height: 100%;
    width: 100%
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transition-property: opacity;
    transition-property: opacity
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

@supports (transform-style:preserve-3d) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev,
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.accordion .card {
    border-bottom: 1px solid #eee;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
}

.accordion .card .card-header {
    padding: 1rem 1.5rem;
    background: 0 0;
    border-bottom: 0
}

.accordion .card .card-header a:not(.collapsed) .rotate-icon {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.accordion .card .fa-angle-down {
    float: right
}

.accordion .card .card-block {
    padding-top: .25rem
}

.accordion .card .card-body {
    line-height: 1.7;
    color: #626262;
    font-size: .9rem;
    font-weight: 300
}

.accordion .card h5 {
    font-size: 1.1rem;
    font-weight: 400
}

.collapsible-body {
    display: none
}

.author-box,
.jumbotron {
    background-color: #fff;
    padding: 2rem
}

.jumbotron.m-1,
.jumbotron.m-2,
.jumbotron.m-3,
.m-1.author-box,
.m-2.author-box,
.m-3.author-box,
.section-blog-fw .author-box,
.section-blog-fw .jumbotron {
    z-index: 50;
    position: relative;
    margin-left: 3%;
    margin-right: 3%
}

.jumbotron.m-1,
.m-1.author-box,
.section-blog-fw .author-box,
.section-blog-fw .jumbotron {
    margin-top: -20px
}

.jumbotron.m-2,
.m-2.author-box {
    margin-top: -30px
}

.jumbotron.m-3,
.m-3.author-box {
    margin-top: -40px
}

.view {
    overflow: hidden;
    position: relative;
    cursor: default
}

.view .mask {
    background-attachment: fixed
}

.view .full-bg-img,
.view .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0
}

.view img,
.view video {
    display: block;
    position: relative
}

.hm-zoom img,
.hm-zoom video,
.overlay img,
.overlay video {
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}

.hm-zoom:hover .mask,
.overlay:hover .mask {
    opacity: 1
}

.hm-zoom:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.overlay .mask {
    opacity: 0;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.pattern-1 {
    background: url(/res/overlays/01.png)
}

.pattern-2 {
    background: url(/res/overlays/02.png)
}

.pattern-3 {
    background: url(/res/overlays/03.png)
}

.pattern-4 {
    background: url(/res/overlays/04.png)
}

.pattern-5 {
    background: url(/res/overlays/05.png)
}

.pattern-6 {
    background: url(/res/overlays/06.png)
}

.pattern-7 {
    background: url(/res/overlays/07.png)
}

.pattern-8 {
    background: url(/res/overlays/08.png)
}

.pattern-9 {
    background: url(/res/overlays/09.png)
}

.hm-blue-slight .full-bg-img,
.hm-blue-slight .mask {
    background-color: rgba(3, 169, 244, .1)
}

.hm-red-slight .full-bg-img,
.hm-red-slight .mask {
    background-color: rgba(244, 67, 54, .1)
}

.hm-pink-slight .full-bg-img,
.hm-pink-slight .mask {
    background-color: rgba(233, 30, 99, .1)
}

.hm-purple-slight .full-bg-img,
.hm-purple-slight .mask {
    background-color: rgba(156, 39, 176, .1)
}

.hm-indigo-slight .full-bg-img,
.hm-indigo-slight .mask {
    background-color: rgba(63, 81, 181, .1)
}

.hm-cyan-slight .full-bg-img,
.hm-cyan-slight .mask {
    background-color: rgba(0, 188, 212, .1)
}

.hm-teal-slight .full-bg-img,
.hm-teal-slight .mask {
    background-color: rgba(0, 150, 136, .1)
}

.hm-green-slight .full-bg-img,
.hm-green-slight .mask {
    background-color: rgba(76, 175, 80, .1)
}

.hm-lime-slight .full-bg-img,
.hm-lime-slight .mask {
    background-color: rgba(205, 220, 57, .1)
}

.hm-yellow-slight .full-bg-img,
.hm-yellow-slight .mask {
    background-color: rgba(255, 235, 59, .1)
}

.hm-orange-slight .full-bg-img,
.hm-orange-slight .mask {
    background-color: rgba(255, 152, 0, .1)
}

.hm-brown-slight .full-bg-img,
.hm-brown-slight .mask {
    background-color: rgba(121, 85, 72, .1)
}

.hm-grey-slight .full-bg-img,
.hm-grey-slight .mask {
    background-color: rgba(158, 158, 158, .1)
}

.hm-bluegrey-slight .full-bg-img,
.hm-bluegrey-slight .mask {
    background-color: rgba(96, 125, 139, .1)
}

.hm-black-slight .full-bg-img,
.hm-black-slight .mask {
    background-color: rgba(0, 0, 0, .1)
}

.hm-stylish-slight .full-bg-img,
.hm-stylish-slight .mask {
    background-color: rgba(62, 69, 81, .1)
}

.hm-white-slight .full-bg-img,
.hm-white-slight .mask {
    background-color: rgba(255, 255, 255, .1)
}

.hm-blue-light .full-bg-img,
.hm-blue-light .mask {
    background-color: rgba(3, 169, 244, .3)
}

.hm-red-light .full-bg-img,
.hm-red-light .mask {
    background-color: rgba(244, 67, 54, .3)
}

.hm-pink-light .full-bg-img,
.hm-pink-light .mask {
    background-color: rgba(233, 30, 99, .3)
}

.hm-purple-light .full-bg-img,
.hm-purple-light .mask {
    background-color: rgba(156, 39, 176, .3)
}

.hm-indigo-light .full-bg-img,
.hm-indigo-light .mask {
    background-color: rgba(63, 81, 181, .3)
}

.hm-cyan-light .full-bg-img,
.hm-cyan-light .mask {
    background-color: rgba(0, 188, 212, .3)
}

.hm-teal-light .full-bg-img,
.hm-teal-light .mask {
    background-color: rgba(0, 150, 136, .3)
}

.hm-green-light .full-bg-img,
.hm-green-light .mask {
    background-color: rgba(76, 175, 80, .3)
}

.hm-lime-light .full-bg-img,
.hm-lime-light .mask {
    background-color: rgba(205, 220, 57, .3)
}

.hm-yellow-light .full-bg-img,
.hm-yellow-light .mask {
    background-color: rgba(255, 235, 59, .3)
}

.hm-orange-light .full-bg-img,
.hm-orange-light .mask {
    background-color: rgba(255, 152, 0, .3)
}

.hm-brown-light .full-bg-img,
.hm-brown-light .mask {
    background-color: rgba(121, 85, 72, .3)
}

.hm-grey-light .full-bg-img,
.hm-grey-light .mask {
    background-color: rgba(158, 158, 158, .3)
}

.hm-bluegrey-light .full-bg-img,
.hm-bluegrey-light .mask {
    background-color: rgba(96, 125, 139, .3)
}

.hm-black-light .full-bg-img,
.hm-black-light .mask {
    background-color: rgba(0, 0, 0, .3)
}

.hm-stylish-light .full-bg-img,
.hm-stylish-light .mask {
    background-color: rgba(62, 69, 81, .3)
}

.hm-white-light .full-bg-img,
.hm-white-light .mask {
    background-color: rgba(255, 255, 255, .3)
}

.hm-blue-strong .full-bg-img,
.hm-blue-strong .mask {
    background-color: rgba(3, 169, 244, .7)
}

.hm-red-strong .full-bg-img,
.hm-red-strong .mask {
    background-color: rgba(244, 67, 54, .7)
}

.hm-pink-strong .full-bg-img,
.hm-pink-strong .mask {
    background-color: rgba(233, 30, 99, .7)
}

.hm-purple-strong .full-bg-img,
.hm-purple-strong .mask {
    background-color: rgba(156, 39, 176, .7)
}

.hm-indigo-strong .full-bg-img,
.hm-indigo-strong .mask {
    background-color: rgba(63, 81, 181, .7)
}

.hm-cyan-strong .full-bg-img,
.hm-cyan-strong .mask {
    background-color: rgba(0, 188, 212, .7)
}

.hm-teal-strong .full-bg-img,
.hm-teal-strong .mask {
    background-color: rgba(0, 150, 136, .7)
}

.hm-green-strong .full-bg-img,
.hm-green-strong .mask {
    background-color: rgba(76, 175, 80, .7)
}

.hm-lime-strong .full-bg-img,
.hm-lime-strong .mask {
    background-color: rgba(205, 220, 57, .7)
}

.hm-yellow-strong .full-bg-img,
.hm-yellow-strong .mask {
    background-color: rgba(255, 235, 59, .7)
}

.hm-orange-strong .full-bg-img,
.hm-orange-strong .mask {
    background-color: rgba(255, 152, 0, .7)
}

.hm-brown-strong .full-bg-img,
.hm-brown-strong .mask {
    background-color: rgba(121, 85, 72, .7)
}

.hm-grey-strong .full-bg-img,
.hm-grey-strong .mask {
    background-color: rgba(158, 158, 158, .7)
}

.hm-bluegrey-strong .full-bg-img,
.hm-bluegrey-strong .mask {
    background-color: rgba(96, 125, 139, .7)
}

.hm-black-strong .full-bg-img,
.hm-black-strong .mask {
    background-color: rgba(0, 0, 0, .7)
}

.hm-stylish-strong .full-bg-img,
.hm-stylish-strong .mask {
    background-color: rgba(62, 69, 81, .7)
}

.hm-white-strong .full-bg-img,
.hm-white-strong .mask {
    background-color: rgba(255, 255, 255, .7)
}

.full-height,
.full-height body,
.full-height header,
.full-height header .view {
    height: 100%
}

.intro {
    min-height: 1000px;
    position: relative
}

@media (max-width:768px) {
    .intro {
        min-height: 1000px
    }
}

.intro-video video {
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    -webkit-transition: 1s opacity;
    transition: 1s opacity
}

.waves-effect {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1
}

.waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    background: rgba(0, 0, 0, .2);
    background: -webkit-radial-gradient(rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%);
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: scale(0) translate(0, 0);
    -ms-transform: scale(0) translate(0, 0);
    transform: scale(0) translate(0, 0);
    pointer-events: none
}

.waves-effect.waves-light .waves-ripple {
    background: rgba(255, 255, 255, .4);
    background: -webkit-radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%)
}

.waves-effect.waves-classic .waves-ripple {
    background: rgba(0, 0, 0, .2)
}

.waves-effect.waves-classic.waves-light .waves-ripple {
    background: rgba(255, 255, 255, .4)
}

.waves-notransition {
    -webkit-transition: none !important;
    transition: none !important
}

.waves-button,
.waves-circle {
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%)
}

.waves-button,
.waves-button-input,
.waves-button:hover,
.waves-button:visited {
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    outline: 0;
    color: inherit;
    background-color: transparent;
    font-size: 1em;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    z-index: 1
}

.waves-button {
    padding: .85em 1.1em;
    border-radius: .2em
}

.waves-button-input {
    margin: 0;
    padding: .85em 1.1em
}

.waves-input-wrapper {
    border-radius: .2em;
    vertical-align: middle;
    display: inline-block
}

.waves-input-wrapper.waves-button {
    padding: 0
}

.waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1
}

.waves-circle {
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%
}

.waves-float {
    -webkit-mask-image: none;
    box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, .12);
    -webkit-transition: all .3s;
    transition: all .3s
}

.waves-float:active {
    box-shadow: 0 8px 20px 1px rgba(0, 0, 0, .3)
}

.waves-block {
    display: block
}

a.waves-effect,
a.waves-light {
    display: inline-block
}

input[type=date],
input[type=datetime-local],
input[type=email],
input[type=number],
input[type=password],
input[type=search-md],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea.md-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #bdbdbd;
    border-radius: 0;
    outline: 0;
    height: 2.1rem;
    width: 100%;
    font-size: 1rem;
    box-shadow: none;
    box-sizing: content-box;
    -webkit-transition: all .3s;
    transition: all .3s
}

input[type=date]:disabled,
input[type=date][readonly=readonly],
input[type=datetime-local]:disabled,
input[type=datetime-local][readonly=readonly],
input[type=email]:disabled,
input[type=email][readonly=readonly],
input[type=number]:disabled,
input[type=number][readonly=readonly],
input[type=password]:disabled,
input[type=password][readonly=readonly],
input[type=search-md]:disabled,
input[type=search-md][readonly=readonly],
input[type=search]:disabled,
input[type=search][readonly=readonly],
input[type=tel]:disabled,
input[type=tel][readonly=readonly],
input[type=text]:disabled,
input[type=text][readonly=readonly],
input[type=time]:disabled,
input[type=time][readonly=readonly],
input[type=url]:disabled,
input[type=url][readonly=readonly],
textarea.md-textarea:disabled,
textarea.md-textarea[readonly=readonly] {
    color: rgba(0, 0, 0, .46);
    border-bottom: 1px dotted rgba(0, 0, 0, .46);
    background-color: transparent
}

input[type=date]:disabled+label,
input[type=date][readonly=readonly]+label,
input[type=datetime-local]:disabled+label,
input[type=datetime-local][readonly=readonly]+label,
input[type=email]:disabled+label,
input[type=email][readonly=readonly]+label,
input[type=number]:disabled+label,
input[type=number][readonly=readonly]+label,
input[type=password]:disabled+label,
input[type=password][readonly=readonly]+label,
input[type=search-md]:disabled+label,
input[type=search-md][readonly=readonly]+label,
input[type=search]:disabled+label,
input[type=search][readonly=readonly]+label,
input[type=tel]:disabled+label,
input[type=tel][readonly=readonly]+label,
input[type=text]:disabled+label,
input[type=text][readonly=readonly]+label,
input[type=time]:disabled+label,
input[type=time][readonly=readonly]+label,
input[type=url]:disabled+label,
input[type=url][readonly=readonly]+label,
textarea.md-textarea:disabled+label,
textarea.md-textarea[readonly=readonly]+label {
    color: rgba(0, 0, 0, .46);
    background-color: transparent
}

input[type=date]:focus:not([readonly])+label,
input[type=datetime-local]:focus:not([readonly])+label,
input[type=email]:focus:not([readonly])+label,
input[type=number]:focus:not([readonly])+label,
input[type=password]:focus:not([readonly])+label,
input[type=search-md]:focus:not([readonly])+label,
input[type=search]:focus:not([readonly])+label,
input[type=tel]:focus:not([readonly])+label,
input[type=text]:focus:not([readonly])+label,
input[type=time]:focus:not([readonly])+label,
input[type=url]:focus:not([readonly])+label,
textarea.md-textarea:focus:not([readonly])+label {
    color: #4285f4
}

input[type=date].valid,
input[type=date]:focus.valid,
input[type=datetime-local].valid,
input[type=datetime-local]:focus.valid,
input[type=email].valid,
input[type=email]:focus.valid,
input[type=number].valid,
input[type=number]:focus.valid,
input[type=password].valid,
input[type=password]:focus.valid,
input[type=search-md].valid,
input[type=search-md]:focus.valid,
input[type=search].valid,
input[type=search]:focus.valid,
input[type=tel].valid,
input[type=tel]:focus.valid,
input[type=text].valid,
input[type=text]:focus.valid,
input[type=time].valid,
input[type=time]:focus.valid,
input[type=url].valid,
input[type=url]:focus.valid,
textarea.md-textarea.valid,
textarea.md-textarea:focus.valid {
    border-bottom: 1px solid #00c851;
    box-shadow: 0 1px 0 0 #00c851
}

input[type=date].valid+label:after,
input[type=date]:focus.valid+label:after,
input[type=datetime-local].valid+label:after,
input[type=datetime-local]:focus.valid+label:after,
input[type=email].valid+label:after,
input[type=email]:focus.valid+label:after,
input[type=number].valid+label:after,
input[type=number]:focus.valid+label:after,
input[type=password].valid+label:after,
input[type=password]:focus.valid+label:after,
input[type=search-md].valid+label:after,
input[type=search-md]:focus.valid+label:after,
input[type=search].valid+label:after,
input[type=search]:focus.valid+label:after,
input[type=tel].valid+label:after,
input[type=tel]:focus.valid+label:after,
input[type=text].valid+label:after,
input[type=text]:focus.valid+label:after,
input[type=time].valid+label:after,
input[type=time]:focus.valid+label:after,
input[type=url].valid+label:after,
input[type=url]:focus.valid+label:after,
textarea.md-textarea.valid+label:after,
textarea.md-textarea:focus.valid+label:after {
    content: attr(data-success);
    color: #00c851;
    opacity: 1
}

input[type=date].invalid,
input[type=date]:focus.invalid,
input[type=datetime-local].invalid,
input[type=datetime-local]:focus.invalid,
input[type=email].invalid,
input[type=email]:focus.invalid,
input[type=number].invalid,
input[type=number]:focus.invalid,
input[type=password].invalid,
input[type=password]:focus.invalid,
input[type=search-md].invalid,
input[type=search-md]:focus.invalid,
input[type=search].invalid,
input[type=search]:focus.invalid,
input[type=tel].invalid,
input[type=tel]:focus.invalid,
input[type=text].invalid,
input[type=text]:focus.invalid,
input[type=time].invalid,
input[type=time]:focus.invalid,
input[type=url].invalid,
input[type=url]:focus.invalid,
textarea.md-textarea.invalid,
textarea.md-textarea:focus.invalid {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336
}

input[type=date].invalid+label:after,
input[type=date]:focus.invalid+label:after,
input[type=datetime-local].invalid+label:after,
input[type=datetime-local]:focus.invalid+label:after,
input[type=email].invalid+label:after,
input[type=email]:focus.invalid+label:after,
input[type=number].invalid+label:after,
input[type=number]:focus.invalid+label:after,
input[type=password].invalid+label:after,
input[type=password]:focus.invalid+label:after,
input[type=search-md].invalid+label:after,
input[type=search-md]:focus.invalid+label:after,
input[type=search].invalid+label:after,
input[type=search]:focus.invalid+label:after,
input[type=tel].invalid+label:after,
input[type=tel]:focus.invalid+label:after,
input[type=text].invalid+label:after,
input[type=text]:focus.invalid+label:after,
input[type=time].invalid+label:after,
input[type=time]:focus.invalid+label:after,
input[type=url].invalid+label:after,
input[type=url]:focus.invalid+label:after,
textarea.md-textarea.invalid+label:after,
textarea.md-textarea:focus.invalid+label:after {
    content: attr(data-error);
    color: #f44336;
    opacity: 1
}

input[type=date]+label:after,
input[type=datetime-local]+label:after,
input[type=email]+label:after,
input[type=number]+label:after,
input[type=password]+label:after,
input[type=search-md]+label:after,
input[type=search]+label:after,
input[type=tel]+label:after,
input[type=text]+label:after,
input[type=time]+label:after,
input[type=url]+label:after,
textarea.md-textarea+label:after {
    display: block;
    content: "";
    position: absolute;
    top: 65px;
    opacity: 0;
    -webkit-transition: .2s opacity ease-out, .2s color ease-out;
    transition: .2s opacity ease-out, .2s color ease-out
}

input[type=date].input-alternate,
input[type=datetime-local].input-alternate,
input[type=email].input-alternate,
input[type=number].input-alternate,
input[type=password].input-alternate,
input[type=search-md].input-alternate,
input[type=search].input-alternate,
input[type=tel].input-alternate,
input[type=text].input-alternate,
input[type=time].input-alternate,
input[type=url].input-alternate,
textarea.md-textarea.input-alternate {
    padding: 0 15px;
    height: 2.1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
    font-size: .875rem;
    border-bottom: 0;
    -webkit-transition: none !important;
    transition: none !important
}

input[type=date].input-alternate:focus,
input[type=date].input-alternate:hover,
input[type=datetime-local].input-alternate:focus,
input[type=datetime-local].input-alternate:hover,
input[type=email].input-alternate:focus,
input[type=email].input-alternate:hover,
input[type=number].input-alternate:focus,
input[type=number].input-alternate:hover,
input[type=password].input-alternate:focus,
input[type=password].input-alternate:hover,
input[type=search-md].input-alternate:focus,
input[type=search-md].input-alternate:hover,
input[type=search].input-alternate:focus,
input[type=search].input-alternate:hover,
input[type=tel].input-alternate:focus,
input[type=tel].input-alternate:hover,
input[type=text].input-alternate:focus,
input[type=text].input-alternate:hover,
input[type=time].input-alternate:focus,
input[type=time].input-alternate:hover,
input[type=url].input-alternate:focus,
input[type=url].input-alternate:hover,
textarea.md-textarea.input-alternate:focus,
textarea.md-textarea.input-alternate:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2), 0 0 0 1px rgba(0, 0, 0, .08) !important;
    border-bottom: 0
}

.form-control {
    padding: 0;
    padding-bottom: .6rem;
    padding-top: .5rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    margin-top: .2rem;
    margin-bottom: 1rem
}

.form-control:focus {
    background: 0 0;
    box-shadow: none
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
    border-bottom: 1px solid #bdbdbd
}

.md-form {
    position: relative;
    margin-bottom: 1.5rem
}

.md-form label {
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    color: #757575;
    position: absolute;
    top: .8rem;
    left: 0;
    font-size: 1rem;
    cursor: text
}

.md-form label.active,
.md-form input:required:valid~label {
    -webkit-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    transform: translateY(-140%);
    font-size: .8rem
}

.md-form .prefix {
    -webkit-transition: color .2s;
    transition: color .2s;
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    padding-top: .5rem;
    color: #9e9e9e
}

.md-form .prefix~input,
.md-form .prefix~textarea {
    margin-left: 3rem;
    width: 92%;
    width: -webkit-calc(100% - 3rem);
    width: calc(100% - 3rem)
}

.md-form .prefix~label {
    margin-left: 3rem
}

.md-form .prefix.active {
    color: #4285f4
}

@media only screen and (max-width:992px) {
    .md-form .prefix~input {
        width: 86%;
        width: -webkit-calc(100% - 3rem);
        width: calc(100% - 3rem)
    }
}

@media only screen and (max-width:600px) {
    .md-form .prefix~input {
        width: 80%;
        width: -webkit-calc(100% - 3rem);
        width: calc(100% - 3rem)
    }
}

.md-form .btn {
    margin-bottom: 1.5rem
}

.md-form.form-sm input {
    padding-bottom: .2rem;
    padding-top: .2rem;
    font-size: .8rem;
    line-height: .5
}

.md-form.form-sm label {
    font-size: .9rem
}

.md-form.form-sm .prefix {
    font-size: 1.5rem;
    top: .4rem
}

.md-form.form-sm .prefix~input,
.md-form.form-sm .prefix~label,
.md-form.form-sm .prefix~textarea {
    margin-left: 2.2rem
}

.md-form.input-group {
    padding-left: 1rem
}

.md-form.input-group::-webkit-input-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group:-moz-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group::-moz-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group::-ms-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group::-webkit-input-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group::-moz-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group:-ms-input-placeholder {
    color: #999;
    padding-top: 2px
}

.md-form.input-group::placeholder {
    color: #999;
    padding-top: 2px
}

.form-group {
    display: block
}

.form-inline fieldset {
    margin-right: 1.5rem
}

.form-inline .form-group {
    margin-right: 2rem
}

textarea {
    width: 100%;
    height: 3rem;
    background-color: transparent
}

textarea.md-textarea {
    overflow-y: hidden;
    padding: 1.6rem 0;
    resize: none;
    min-height: 3rem
}

.hiddendiv {
    display: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-top: 1.2rem
}

.orange-gradient {
    background: -webkit-linear-gradient(50deg, #ffd86f, #fc6262);
    background: linear-gradient(40deg, #ffd86f, #fc6262)
}

.table-inverse td,
.table-inverse th,
.table-inverse thead th {
    border-color: #fff
}

.edge-header {
    display: block;
    height: 278px;
    background-color: #ccc
}

.free-bird {
    margin-top: -100px
}

footer.page-footer {
    margin-top: 20px;
    padding-top: 20px;
    color: #fff
}

footer.page-footer .container-fluid {
    width: auto
}

footer.page-footer .footer-copyright {
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    color: rgba(255, 255, 255, .6);
    background-color: rgba(0, 0, 0, .2);
    text-align: center;
    font-size: .9rem
}

footer.page-footer a {
    color: #fff
}

footer.page-footer .title {
    text-transform: uppercase
}

footer.page-footer .call-to-action {
    text-align: center;
    padding-top: 1.3rem;
    padding-bottom: .5rem
}

footer.page-footer .call-to-action ul li {
    display: inline-block;
    padding-right: 10px
}

footer.page-footer .social-section ul li {
    display: inline-block
}

footer.page-footer .social-section ul a {
    margin-left: 0;
    padding-left: 0
}

footer.page-footer ul {
    list-style-type: none;
    padding: 0
}

ul.instagram-photos {
    list-style-type: none;
    padding: 0
}

ul.instagram-photos li {
    display: inline-block;
    max-width: 100px;
    margin: 4px
}

ul.instagram-photos li img {
    margin: 0
}

.media .media-left {
    padding: 0 10px 10px 0
}

.list-group a:hover {
    -webkit-transition: .5s;
    transition: .5s
}

table th {
    font-size: .9rem;
    font-weight: 400
}

table td {
    font-size: .9rem;
    font-weight: 300
}

table.table thead th {
    border-top: none
}

table.table td,
table.table th {
    padding-top: 1.1rem;
    padding-bottom: 1rem
}

table.table a,
table.table p {
    margin: 0;
}

table.table .label-table {
    margin: 0;
    padding: 0;
    line-height: 15px;
    height: 15px
}

table.table .btn-table {
    margin: 0 1px;
    padding: 3px 7px
}

table.table .btn-table .fa {
    font-size: 11px
}

table.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075);
    -webkit-transition: .5s;
    transition: .5s
}

table .th-lg {
    min-width: 9rem
}

table.table-sm td,
table.table-sm th {
    padding-top: .6rem;
    padding-bottom: .6rem
}

.btn-mdb {
    background-color: #45526e !important;
    color: #fff !important
}

.btn-mdb:hover {
    background-color: #4f5e7e
}

.btn-mdb.active,
.btn-mdb:active,
.btn-mdb:focus {
    background-color: #313b4f
}

.btn-mdb.dropdown-toggle {
    background-color: #45526e !important
}

.btn-mdb.dropdown-toggle:focus,
.btn-mdb.dropdown-toggle:hover {
    background-color: #4f5e7e !important
}

.btn-mdb:not([disabled]):not(.disabled).active,
.btn-mdb:not([disabled]):not(.disabled):active,
.show>.btn-mdb.dropdown-toggle {
    background-color: #45526e !important
}

.colorful-side-nav {
    background-color: #00695c
}

.colorful-side-nav li {
    background-color: #00695c
}

.colorful-side-nav li.active,
.colorful-side-nav li:hover {
    background-color: #008372
}

.colorful-side-nav .collapsible a {
    margin: 0
}

.colorful-side-nav .collapsible a:active {
    background-color: #008372
}

.colorful-side-nav .collapsible a.active {
    background-color: #008372
}

.colorful-side-nav .collapsible>li {
    padding: 0
}

.colorful-side-nav .social {
    border-top: 1px solid #26a69a;
    border-bottom: 1px solid #26a69a
}

.stylish-side-nav {
    background-color: #3e4551
}

.stylish-side-nav li {
    background-color: #3e4551
}

.stylish-side-nav li.active,
.stylish-side-nav li:hover {
    background-color: #4b515d
}

.stylish-side-nav .collapsible a {
    margin: 0
}

.stylish-side-nav .collapsible a:active {
    background-color: #4b515d
}

.stylish-side-nav .collapsible a.active {
    background-color: #4b515d
}

.stylish-side-nav .collapsible>li {
    padding: 0
}

.stylish-side-nav .social {
    border-top: 1px solid #4b515d;
    border-bottom: 1px solid #4b515d
}

.light-side-nav .logo-wrapper {
    height: 80px
}

.light-side-nav .logo-wrapper a {
    height: 80px
}

.light-side-nav .logo-wrapper img {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 7%;
    padding-bottom: 7%
}

.light-side-nav p {
    color: #1c2331
}

.light-side-nav .collapsible a {
    color: #1c2331
}

.light-side-nav .social {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee
}

.light-side-nav .navbar-form {
    margin: 10px
}

.dark-side-nav {
    background-color: #212121
}

.dark-side-nav li {
    background-color: #212121
}

.dark-side-nav li.active,
.dark-side-nav li:hover {
    background-color: #2e2e2e
}

.dark-side-nav .collapsible a {
    margin: 0
}

.dark-side-nav .collapsible a .active,
.dark-side-nav .collapsible a:active {
    background-color: #2e2e2e
}

.dark-side-nav .collapsible>li {
    padding: 0
}

.dark-side-nav .social {
    border-top: 1px solid #4b515d;
    border-bottom: 1px solid #4b515d
}

.social-list {
    font-weight: 400;
    width: auto;
    padding: 1rem;
    list-style-type: none
}

.social-list li {
    margin-top: 15px
}

.social-list .fa {
    margin-right: 20px;
    font-size: 20px
}

.social-list .fa-facebook {
    margin-right: 26px
}

.social-list a {
    font-size: 20px
}

.btn-flat {
    position: relative;
    padding: 8px 30px;
    border: none;
    margin: 10px;
    text-decoration: none;
    outline: 0 !important
}

.btn.btn-flat {
    color: inherit !important;
    background-color: transparent;
    box-shadow: none
}

.btn-floating {
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    width: 47px;
    height: 47px;
    padding: 0;
    border-radius: 50%;
    margin: 10px;
    background-color: #a6c;
    color: #fff;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s
}

.btn-floating i {
    display: inline-block;
    width: inherit;
    color: #fff;
    font-size: 1.25rem;
    line-height: 47px;
    text-align: center
}

.btn-floating:before {
    border-radius: 0
}

.btn-floating.btn-sm,
.btn-floating.btn-small,
.btn-floating.wishlist {
    width: 36.15385px;
    height: 36.15385px
}

.btn-floating.btn-sm i,
.btn-floating.btn-small i,
.btn-floating.wishlist i {
    font-size: .96154rem;
    line-height: 36.15385px
}

.btn-floating.btn-large,
.btn-floating.btn-lg {
    width: 61.1px;
    height: 61.1px
}

.btn-floating.btn-large i,
.btn-floating.btn-lg i {
    font-size: 1.625rem;
    line-height: 61.1px
}

.fixed-action-btn {
    position: fixed;
    z-index: 998;
    right: 35px;
    bottom: 35px;
    padding-top: 15px;
    margin-bottom: 0
}

.fixed-action-btn ul {
    position: absolute;
    right: 0;
    bottom: 64px;
    left: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center
}

.fixed-action-btn ul li {
    margin-bottom: 15px
}

.fixed-action-btn ul a.btn-floating {
    opacity: 0
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: none
}

.btn-rounded {
    border-radius: 10em
}

.btn.purple-gradient {
    background: -webkit-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
    background: linear-gradient(40deg, #ff6ec4, #7873f5) !important;
    -webkit-transition: .5s ease;
    transition: .5s ease
}

.btn.purple-gradient:active,
.btn.purple-gradient:active:focus .btn.purple-gradient.active,
.btn.purple-gradient:focus,
.btn.purple-gradient:hover {
    background: -webkit-linear-gradient(50deg, #ff88ce, #8f8bf7);
    background: linear-gradient(40deg, #ff88ce, #8f8bf7)
}

.btn.peach-gradient {
    background: -webkit-linear-gradient(50deg, #ffd86f, #fc6262) !important;
    background: linear-gradient(40deg, #ffd86f, #fc6262) !important;
    -webkit-transition: .5s ease;
    transition: .5s ease
}

.btn.peach-gradient:active,
.btn.peach-gradient:active:focus .btn.peach-gradient.active,
.btn.peach-gradient:focus,
.btn.peach-gradient:hover {
    background: -webkit-linear-gradient(50deg, #ffdf89, #fc7b7b);
    background: linear-gradient(40deg, #ffdf89, #fc7b7b)
}

.btn.aqua-gradient {
    background: -webkit-linear-gradient(50deg, #2096ff, #05ffa3) !important;
    background: linear-gradient(40deg, #2096ff, #05ffa3) !important;
    -webkit-transition: .5s ease;
    transition: .5s ease
}

.btn.aqua-gradient:active,
.btn.aqua-gradient:active:focus .btn.aqua-gradient.active,
.btn.aqua-gradient:focus,
.btn.aqua-gradient:hover {
    background: -webkit-linear-gradient(50deg, #3aa2ff, #1fffac);
    background: linear-gradient(40deg, #3aa2ff, #1fffac)
}

.btn.blue-gradient {
    background: -webkit-linear-gradient(50deg, #45cafc, #303f9f) !important;
    background: linear-gradient(40deg, #45cafc, #303f9f) !important;
    -webkit-transition: .5s ease;
    transition: .5s ease
}

.btn.blue-gradient:active,
.btn.blue-gradient:active:focus .btn.blue-gradient.active,
.btn.blue-gradient:focus,
.btn.blue-gradient:hover {
    background: -webkit-linear-gradient(50deg, #5ed1fc, #3647b3);
    background: linear-gradient(40deg, #5ed1fc, #3647b3)
}

.btn-fb {
    background-color: #3b5998
}

.btn-fb:focus,
.btn-fb:hover {
    background-color: #4264aa
}

.fb-ic {
    color: #3b5998 !important
}

.fb-ic:focus,
.fb-ic:hover {
    color: #3b5998
}

.btn-tw {
    background-color: #55acee
}

.btn-tw:focus,
.btn-tw:hover {
    background-color: #6cb7f0
}

.tw-ic {
    color: #55acee !important
}

.tw-ic:focus,
.tw-ic:hover {
    color: #55acee
}

.btn-gplus {
    background-color: #dd4b39
}

.btn-gplus:focus,
.btn-gplus:hover {
    background-color: #e15f4f
}

.gplus-ic {
    color: #dd4b39 !important
}

.gplus-ic:focus,
.gplus-ic:hover {
    color: #dd4b39
}

.btn-yt {
    background-color: #ed302f
}

.btn-yt:focus,
.btn-yt:hover {
    background-color: #ef4746
}

.yt-ic {
    color: #ed302f !important
}

.yt-ic:focus,
.yt-ic:hover {
    color: #ed302f
}

.btn-li {
    background-color: #0082ca
}

.btn-li:focus,
.btn-li:hover {
    background-color: #0092e4
}

.li-ic {
    color: #0082ca !important
}

.li-ic:focus,
.li-ic:hover {
    color: #0082ca
}

.btn-pin {
    background-color: #c61118
}

.btn-pin:focus,
.btn-pin:hover {
    background-color: #dd131b
}

.pin-ic {
    color: #c61118 !important
}

.pin-ic:focus,
.pin-ic:hover {
    color: #c61118
}

.btn-ins {
    background-color: #2e5e86
}

.btn-ins:focus,
.btn-ins:hover {
    background-color: #356b99
}

.ins-ic {
    color: #2e5e86 !important
}

.ins-ic:focus,
.ins-ic:hover {
    color: #2e5e86
}

.btn-git {
    background-color: #333
}

.btn-git:focus,
.btn-git:hover {
    background-color: #404040
}

.git-ic {
    color: #333 !important
}

.git-ic:focus,
.git-ic:hover {
    color: #333
}

.btn-comm {
    background-color: #30cfc0
}

.btn-comm:focus,
.btn-comm:hover {
    background-color: #45d4c6
}

.comm-ic {
    color: #30cfc0 !important
}

.comm-ic:focus,
.comm-ic:hover {
    color: #30cfc0
}

.btn-vk {
    background-color: #4c75a3
}

.btn-vk:focus,
.btn-vk:hover {
    background-color: #5882b1
}

.vk-ic {
    color: #4c75a3 !important
}

.vk-ic:focus,
.vk-ic:hover {
    color: #4c75a3
}

.btn-dribbble {
    background-color: #ec4a89
}

.btn-dribbble:focus,
.btn-dribbble:hover {
    background-color: #ee6198
}

.dribbble-ic {
    color: #ec4a89 !important
}

.dribbble-ic:focus,
.dribbble-ic:hover {
    color: #ec4a89
}

.btn-so {
    background-color: #ffac44
}

.btn-so:focus,
.btn-so:hover {
    background-color: #ffb75e
}

.so-ic {
    color: #ffac44 !important
}

.so-ic:focus,
.so-ic:hover {
    color: #ffac44
}

.btn-slack {
    background-color: #56b68b
}

.btn-slack:focus,
.btn-slack:hover {
    background-color: #68be97
}

.slack-ic {
    color: #56b68b !important
}

.slack-ic:focus,
.slack-ic:hover {
    color: #56b68b
}

.btn-email {
    background-color: #4b515d
}

.btn-email:focus,
.btn-email:hover {
    background-color: #565d6b
}

.email-ic {
    color: #4b515d !important
}

.email-ic:focus,
.email-ic:hover {
    color: #4b515d
}

.btn-social-all {
    background-color: #436281
}

.btn-social-all:focus,
.btn-social-all:hover {
    background-color: #5c7fa3
}

.btn-social-all .fa {
    margin-top: 0
}

.counter {
    position: absolute;
    margin-left: -23px;
    margin-top: -1px;
    background-color: #fe1212;
    color: #fff;
    padding: 1px 7px;
    font-size: 11px;
    z-index: 2;
    border-radius: 10rem
}

.social-counters .btn {
    margin-left: 9px
}

.icons-sm {
    padding: .5rem;
    margin: .5rem;
    font-size: 1.2rem
}

.btn-fb {
    background-color: #3b5998 !important;
    color: #fff !important
}

.btn-fb:hover {
    background-color: #4264aa
}

.btn-fb.active,
.btn-fb:active,
.btn-fb:focus {
    background-color: #2d4373
}

.btn-fb.dropdown-toggle {
    background-color: #3b5998 !important
}

.btn-fb.dropdown-toggle:focus,
.btn-fb.dropdown-toggle:hover {
    background-color: #4264aa !important
}

.btn-fb:not([disabled]):not(.disabled).active,
.btn-fb:not([disabled]):not(.disabled):active,
.show>.btn-fb.dropdown-toggle {
    background-color: #3b5998 !important
}

.btn-tw {
    background-color: #55acee !important;
    color: #fff !important
}

.btn-tw:hover {
    background-color: #6cb7f0
}

.btn-tw.active,
.btn-tw:active,
.btn-tw:focus {
    background-color: #2795e9
}

.btn-tw.dropdown-toggle {
    background-color: #55acee !important
}

.btn-tw.dropdown-toggle:focus,
.btn-tw.dropdown-toggle:hover {
    background-color: #6cb7f0 !important
}

.btn-tw:not([disabled]):not(.disabled).active,
.btn-tw:not([disabled]):not(.disabled):active,
.show>.btn-tw.dropdown-toggle {
    background-color: #55acee !important
}

.btn-gplus {
    background-color: #dd4b39 !important;
    color: #fff !important
}

.btn-gplus:hover {
    background-color: #e15f4f
}

.btn-gplus.active,
.btn-gplus:active,
.btn-gplus:focus {
    background-color: #c23321
}

.btn-gplus.dropdown-toggle {
    background-color: #dd4b39 !important
}

.btn-gplus.dropdown-toggle:focus,
.btn-gplus.dropdown-toggle:hover {
    background-color: #e15f4f !important
}

.btn-gplus:not([disabled]):not(.disabled).active,
.btn-gplus:not([disabled]):not(.disabled):active,
.show>.btn-gplus.dropdown-toggle {
    background-color: #dd4b39 !important
}

.btn-yt {
    background-color: #ed302f !important;
    color: #fff !important
}

.btn-yt:hover {
    background-color: #ef4746
}

.btn-yt.active,
.btn-yt:active,
.btn-yt:focus {
    background-color: #d61413
}

.btn-yt.dropdown-toggle {
    background-color: #ed302f !important
}

.btn-yt.dropdown-toggle:focus,
.btn-yt.dropdown-toggle:hover {
    background-color: #ef4746 !important
}

.btn-yt:not([disabled]):not(.disabled).active,
.btn-yt:not([disabled]):not(.disabled):active,
.show>.btn-yt.dropdown-toggle {
    background-color: #ed302f !important
}

.btn-li {
    background-color: #0082ca !important;
    color: #fff !important
}

.btn-li:hover {
    background-color: #0092e4
}

.btn-li.active,
.btn-li:active,
.btn-li:focus {
    background-color: #006197
}

.btn-li.dropdown-toggle {
    background-color: #0082ca !important
}

.btn-li.dropdown-toggle:focus,
.btn-li.dropdown-toggle:hover {
    background-color: #0092e4 !important
}

.btn-li:not([disabled]):not(.disabled).active,
.btn-li:not([disabled]):not(.disabled):active,
.show>.btn-li.dropdown-toggle {
    background-color: #0082ca !important
}

.btn-pin {
    background-color: #c61118 !important;
    color: #fff !important
}

.btn-pin:hover {
    background-color: #dd131b
}

.btn-pin.active,
.btn-pin:active,
.btn-pin:focus {
    background-color: #970d12
}

.btn-pin.dropdown-toggle {
    background-color: #c61118 !important
}

.btn-pin.dropdown-toggle:focus,
.btn-pin.dropdown-toggle:hover {
    background-color: #dd131b !important
}

.btn-pin:not([disabled]):not(.disabled).active,
.btn-pin:not([disabled]):not(.disabled):active,
.show>.btn-pin.dropdown-toggle {
    background-color: #c61118 !important
}

.btn-ins {
    background-color: #2e5e86 !important;
    color: #fff !important
}

.btn-ins:hover {
    background-color: #356b99
}

.btn-ins.active,
.btn-ins:active,
.btn-ins:focus {
    background-color: #214360
}

.btn-ins.dropdown-toggle {
    background-color: #2e5e86 !important
}

.btn-ins.dropdown-toggle:focus,
.btn-ins.dropdown-toggle:hover {
    background-color: #356b99 !important
}

.btn-ins:not([disabled]):not(.disabled).active,
.btn-ins:not([disabled]):not(.disabled):active,
.show>.btn-ins.dropdown-toggle {
    background-color: #2e5e86 !important
}

.btn-git {
    background-color: #333 !important;
    color: #fff !important
}

.btn-git:hover {
    background-color: #404040
}

.btn-git.active,
.btn-git:active,
.btn-git:focus {
    background-color: #1a1a1a
}

.btn-git.dropdown-toggle {
    background-color: #333 !important
}

.btn-git.dropdown-toggle:focus,
.btn-git.dropdown-toggle:hover {
    background-color: #404040 !important
}

.btn-git:not([disabled]):not(.disabled).active,
.btn-git:not([disabled]):not(.disabled):active,
.show>.btn-git.dropdown-toggle {
    background-color: #333 !important
}

.btn-comm {
    background-color: #30cfc0 !important;
    color: #fff !important
}

.btn-comm:hover {
    background-color: #45d4c6
}

.btn-comm.active,
.btn-comm:active,
.btn-comm:focus {
    background-color: #26a69a
}

.btn-comm.dropdown-toggle {
    background-color: #30cfc0 !important
}

.btn-comm.dropdown-toggle:focus,
.btn-comm.dropdown-toggle:hover {
    background-color: #45d4c6 !important
}

.btn-comm:not([disabled]):not(.disabled).active,
.btn-comm:not([disabled]):not(.disabled):active,
.show>.btn-comm.dropdown-toggle {
    background-color: #30cfc0 !important
}

.btn-vk {
    background-color: #4c75a3 !important;
    color: #fff !important
}

.btn-vk:hover {
    background-color: #5882b1
}

.btn-vk.active,
.btn-vk:active,
.btn-vk:focus {
    background-color: #3c5c80
}

.btn-vk.dropdown-toggle {
    background-color: #4c75a3 !important
}

.btn-vk.dropdown-toggle:focus,
.btn-vk.dropdown-toggle:hover {
    background-color: #5882b1 !important
}

.btn-vk:not([disabled]):not(.disabled).active,
.btn-vk:not([disabled]):not(.disabled):active,
.show>.btn-vk.dropdown-toggle {
    background-color: #4c75a3 !important
}

.btn-dribbble {
    background-color: #ec4a89 !important;
    color: #fff !important
}

.btn-dribbble:hover {
    background-color: #ee6198
}

.btn-dribbble.active,
.btn-dribbble:active,
.btn-dribbble:focus {
    background-color: #e71c6b
}

.btn-dribbble.dropdown-toggle {
    background-color: #ec4a89 !important
}

.btn-dribbble.dropdown-toggle:focus,
.btn-dribbble.dropdown-toggle:hover {
    background-color: #ee6198 !important
}

.btn-dribbble:not([disabled]):not(.disabled).active,
.btn-dribbble:not([disabled]):not(.disabled):active,
.show>.btn-dribbble.dropdown-toggle {
    background-color: #ec4a89 !important
}

.btn-so {
    background-color: #ffac44 !important;
    color: #fff !important
}

.btn-so:hover {
    background-color: #ffb75e
}

.btn-so.active,
.btn-so:active,
.btn-so:focus {
    background-color: #ff9511
}

.btn-so.dropdown-toggle {
    background-color: #ffac44 !important
}

.btn-so.dropdown-toggle:focus,
.btn-so.dropdown-toggle:hover {
    background-color: #ffb75e !important
}

.btn-so:not([disabled]):not(.disabled).active,
.btn-so:not([disabled]):not(.disabled):active,
.show>.btn-so.dropdown-toggle {
    background-color: #ffac44 !important
}

.btn-slack {
    background-color: #56b68b !important;
    color: #fff !important
}

.btn-slack:hover {
    background-color: #68be97
}

.btn-slack.active,
.btn-slack:active,
.btn-slack:focus {
    background-color: #419871
}

.btn-slack.dropdown-toggle {
    background-color: #56b68b !important
}

.btn-slack.dropdown-toggle:focus,
.btn-slack.dropdown-toggle:hover {
    background-color: #68be97 !important
}

.btn-slack:not([disabled]):not(.disabled).active,
.btn-slack:not([disabled]):not(.disabled):active,
.show>.btn-slack.dropdown-toggle {
    background-color: #56b68b !important
}

.btn-email {
    background-color: #4b515d !important;
    color: #fff !important
}

.btn-email:hover {
    background-color: #565d6b
}

.btn-email.active,
.btn-email:active,
.btn-email:focus {
    background-color: #343841
}

.btn-email.dropdown-toggle {
    background-color: #4b515d !important
}

.btn-email.dropdown-toggle:focus,
.btn-email.dropdown-toggle:hover {
    background-color: #565d6b !important
}

.btn-email:not([disabled]):not(.disabled).active,
.btn-email:not([disabled]):not(.disabled):active,
.show>.btn-email.dropdown-toggle {
    background-color: #4b515d !important
}

.nav-tabs {
    border: 0;
    padding: .7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: -20px;
    background-color: #2bbbad;
    z-index: 2;
    position: relative;
    border-radius: 2px
}

.nav-tabs .nav-item+.nav-item {
    margin-left: 0
}

.nav-tabs .nav-link {
    border: 0;
    color: #fff
}

.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-link.active {
    background-color: rgba(255, 255, 255, .2);
    color: #fff;
    -webkit-transition: all 1s;
    transition: all 1s;
    border-radius: 2px
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.dropdown-toggle {
    background-color: #2bbbad;
    color: #fff;
    -webkit-transition: all 1s;
    transition: all 1s;
    border-radius: 2px
}

.md-pills {
    border: 0
}

.md-pills li {
    padding: .6rem
}

.md-pills .nav-link {
    -webkit-transition: all .4s;
    transition: all .4s;
    border-radius: 2px;
    color: #666;
    text-align: center
}

.md-pills .nav-link:hover {
    background-color: rgba(158, 158, 158, .3)
}

.md-pills .nav-link.active {
    color: #fff;
    background-color: #2bbbad
}

.pills-primary .nav-item .nav-link.active {
    background-color: #4285f4
}

.tabs-primary {
    background-color: #4285f4
}

.pills-danger .nav-item .nav-link.active {
    background-color: #ff3547
}

.tabs-danger {
    background-color: #ff3547
}

.pills-warning .nav-item .nav-link.active {
    background-color: #f80
}

.tabs-warning {
    background-color: #f80
}

.pills-success .nav-item .nav-link.active {
    background-color: #00c851
}

.tabs-success {
    background-color: #00c851
}

.pills-info .nav-item .nav-link.active {
    background-color: #33b5e5
}

.tabs-info {
    background-color: #33b5e5
}

.pills-default .nav-item .nav-link.active {
    background-color: #2bbbad
}

.tabs-default {
    background-color: #2bbbad
}

.pills-secondary .nav-item .nav-link.active {
    background-color: #a6c
}

.tabs-secondary {
    background-color: #a6c
}

.pills-elegant .nav-item .nav-link.active {
    background-color: #2e2e2e
}

.tabs-elegant {
    background-color: #2e2e2e
}

.pills-unique .nav-item .nav-link.active {
    background-color: #880e4f
}

.tabs-unique {
    background-color: #880e4f
}

.pills-dark-green .nav-item .nav-link.active {
    background-color: #388e3c
}

.tabs-dark-green {
    background-color: #388e3c
}

.pills-mdb-color .nav-item .nav-link.active {
    background-color: #59698d
}

.tabs-mdb-color {
    background-color: #59698d
}

.pills-red .nav-item .nav-link.active {
    background-color: #d32f2f
}

.tabs-red {
    background-color: #d32f2f
}

.pills-pink .nav-item .nav-link.active {
    background-color: #ec407a
}

.tabs-pink {
    background-color: #ec407a
}

.pills-purple .nav-item .nav-link.active {
    background-color: #8e24aa
}

.tabs-purple {
    background-color: #8e24aa
}

.pills-deep-purple .nav-item .nav-link.active {
    background-color: #512da8
}

.tabs-deep-purple {
    background-color: #512da8
}

.pills-indigo .nav-item .nav-link.active {
    background-color: #3f51b5
}

.tabs-indigo {
    background-color: #3f51b5
}

.pills-blue .nav-item .nav-link.active {
    background-color: #1976d2
}

.tabs-blue {
    background-color: #1976d2
}

.pills-light-blue .nav-item .nav-link.active {
    background-color: #82b1ff
}

.tabs-light-blue {
    background-color: #82b1ff
}

.pills-cyan .nav-item .nav-link.active {
    background-color: #00bcd4
}

.tabs-cyan {
    background-color: #00bcd4
}

.pills-teal .nav-item .nav-link.active {
    background-color: #00796b
}

.tabs-teal {
    background-color: #00796b
}

.pills-green .nav-item .nav-link.active {
    background-color: #388e3c
}

.tabs-green {
    background-color: #388e3c
}

.pills-light-green .nav-item .nav-link.active {
    background-color: #8bc34a
}

.tabs-light-green {
    background-color: #8bc34a
}

.pills-lime .nav-item .nav-link.active {
    background-color: #afb42b
}

.tabs-lime {
    background-color: #afb42b
}

.pills-yellow .nav-item .nav-link.active {
    background-color: #fbc02d
}

.tabs-yellow {
    background-color: #fbc02d
}

.pills-amber .nav-item .nav-link.active {
    background-color: #ffa000
}

.tabs-amber {
    background-color: #ffa000
}

.pills-orange .nav-item .nav-link.active {
    background-color: #f57c00
}

.tabs-orange {
    background-color: #f57c00
}

.pills-deep-orange .nav-item .nav-link.active {
    background-color: #ff7043
}

.tabs-deep-orange {
    background-color: #ff7043
}

.pills-brown .nav-item .nav-link.active {
    background-color: #795548
}

.tabs-brown {
    background-color: #795548
}

.pills-grey .nav-item .nav-link.active {
    background-color: #616161
}

.tabs-grey {
    background-color: #616161
}

.pills-blue-grey .nav-item .nav-link.active {
    background-color: #78909c
}

.tabs-blue-grey {
    background-color: #78909c
}

.pills-dark .nav-item .nav-link.active {
    background-color: #212121
}

.tabs-dark {
    background-color: #212121
}

.pills-light .nav-item .nav-link.active {
    background-color: #e0e0e0
}

.tabs-light {
    background-color: #e0e0e0
}

.pills-white .nav-item .nav-link.active {
    background-color: #fff
}

.tabs-white {
    background-color: #fff
}

.pills-black .nav-item .nav-link.active {
    background-color: #000
}

.tabs-black {
    background-color: #000
}

.tab-content {
    padding: 1rem;
    padding-top: 2rem;
    z-index: 1;
    border-radius: 0 0 .3rem .3rem
}

.tab-content.vertical {
    padding-top: 0
}

.classic-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    border-radius: .3rem .3rem 0 0
}

@media (min-width:62rem) {
    .classic-tabs {
        overflow-x: hidden
    }
}

.classic-tabs li {
    -webkit-box-flex: 1 0 30%;
    -webkit-flex: 1 0 30%;
    -ms-flex: 1 0 30%;
    flex: 1 0 30%
}

@media (min-width:62em) {
    .classic-tabs li {
        -webkit-box-flex: inherit;
        -webkit-flex: inherit;
        -ms-flex: inherit;
        flex: inherit
    }
}

.classic-tabs li a {
    display: block;
    padding: 20px 24px;
    font-size: 13px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .7);
    text-align: center;
    border-radius: 0
}

.classic-tabs li a.active {
    border-bottom: 3px solid;
    color: #fff
}

@media (min-width:62em) {
    .classic-tabs li:first-child {
        margin-left: 56px
    }
}

.classic-tabs.tabs-cyan li a.active {
    border-color: #ffeb3b
}

.classic-tabs.tabs-orange li a.active {
    border-color: #e53935
}

.classic-tabs.tabs-grey li a.active {
    border-color: #fff
}

.classic-tabs.tabs-pink li a.active {
    border-color: #673ab7
}

.classic-tabs.tabs-green li a.active {
    border-color: #1565c0
}

.classic-tabs.tabs-primary li a.active {
    border-color: #fff
}

.card.card-image {
    background-size: cover;
    background-position: center;
    width: 100%
}

.card .card-data {
    background-color: #929fba;
    color: #fff;
    text-align: center;
    height: auto;
    padding: 10px
}

.card .card-data ul {
    margin-bottom: 0;
    font-size: .9rem
}

.card .card-data ul li {
    display: inline
}

.card .card-data ul li .fa-clock-o {
    margin-left: 10px
}

.card .card-data ul a {
    color: #fff
}

.card .card-data ul a .fa {
    margin-left: 5px;
    margin-right: 3px
}

.card.card-dark .card-body {
    background-color: #2e2e2e
}

.card.card-dark .card-body hr {
    background-color: #666
}

.card.card-cascade {
    width: 100%
}

.card.card-cascade .view {
    border-radius: 4px
}

.card.card-cascade .view.gradient-card-header {
    padding: 1.6rem 1rem;
    text-align: center
}

.card.card-cascade .view.gradient-card-header h2 {
    font-weight: 400
}

.card.card-cascade .view.gradient-card-header .btn-floating {
    background-color: rgba(255, 255, 255, .2)
}

.card.card-cascade.narrower {
    margin-top: 20px
}

.card.card-cascade.narrower .view {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px
}

.card.card-cascade.wider {
    background-color: transparent
}

.card.card-cascade.wider .view {
    z-index: 2
}

.card.card-cascade.wider .card-body {
    margin-left: 4%;
    margin-right: 4%;
    background: #fff;
    z-index: 1;
    border-radius: 0 0 .3rem .3rem
}

.card.card-cascade.wider.reverse .card-body {
    z-index: 3;
    margin-top: -1rem;
    border-radius: 4px
}

.card.card-cascade.card-avatar {
    margin-top: 6rem;
    text-align: center
}

.card.card-cascade.card-avatar img {
    margin-top: -5rem;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    border-radius: 50%
}

.card.card-cascade .card-body {
    padding-top: 1.8rem
}

.card.card-cascade.panel-cascade .view {
    text-align: center;
    color: #fff
}

.card.card-cascade.panel-cascade .list-group .list-group-item {
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid #eee;
    color: #495057
}

.card.card-cascade.panel-cascade .list-group .list-group-item:hover {
    background-color: #eee
}

.card .activator {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    margin-right: 10px;
    font-size: 1.2rem
}

.card .rating {
    color: #ffa000
}

.card .btn-action {
    margin: -23px 20px;
    margin-left: auto;
    background-color: #929fba;
    height: 47px;
    width: 47px;
    line-height: 0
}

.card .btn-action .fa {
    font-size: 18px
}

.card .btn-action .fa.fa-chevron-right {
    margin-top: 2px;
    margin-left: 2px
}

.card .card-review {
    margin-top: -1px;
    color: #fff;
    padding: 50px 20px 20px 20px
}

.card .card-review ul {
    margin-bottom: 0;
    font-size: .9rem
}

.card .card-review ul li {
    display: inline
}

.card .card-review ul a {
    color: #fff
}

.card .card-review ul a .fa {
    margin-left: 5px;
    margin-right: 3px
}

.card .card-reveal {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    top: 100%;
    height: 100%;
    z-index: 1;
    display: none;
    padding: 1rem
}

.card .card-reveal .card-title {
    cursor: pointer
}

.card .card-reveal .card-title i {
    color: #9e9e9e;
    position: absolute;
    right: 10px
}

.card .card-reveal .content {
    position: relative
}

.card .card-reveal .content h4 {
    margin-top: 20px;
    margin-bottom: 15px
}

.card .card-reveal .content h5 {
    margin-top: 30px
}

.card .card-share {
    position: relative
}

.card .card-share .social-reveal {
    position: absolute;
    top: -28px;
    right: 30px;
    visibility: hidden;
    width: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform .35s ease;
    transition: -webkit-transform .35s ease;
    transition: transform .35s ease;
    transition: transform .35s ease, -webkit-transform .35s ease
}

.card .card-share .social-reveal .btn-floating {
    margin: .3rem
}

.card .card-share .social-reveal .btn-floating i {
    font-size: 18px
}

.card .card-share .social-reveal-active {
    z-index: 11;
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(-48px);
    -ms-transform: translateX(-48px);
    transform: translateX(-48px);
    -webkit-transition: -webkit-transform .35s ease;
    transition: -webkit-transform .35s ease;
    transition: transform .35s ease;
    transition: transform .35s ease, -webkit-transform .35s ease
}

.card.ovf-hidden {
    overflow: hidden
}

.card .card-footer {
    background-color: transparent
}

.card .card-footer .left {
    float: left
}

.card .card-footer .left .discount {
    text-decoration: line-through;
    color: #9e9e9e;
    font-size: 1.1rem
}

.card .card-footer .right {
    float: right;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.card .card-footer .right a {
    color: #757575;
    margin-left: 1rem;
    -webkit-transition: .4s;
    transition: .4s
}

.card .card-footer .right a:hover {
    color: #d50000;
    -webkit-transition: .4s;
    transition: .4s
}

.card .card-footer .right a.active {
    color: #d50000
}

.card-wrapper {
    margin: 0;
    min-height: 500px;
    position: relative;
    -webkit-perspective: 800px;
    perspective: 800px
}

.card-wrapper .face {
    position: absolute;
    width: 100%;
    height: 100%
}

.card-wrapper .face h4 {
    margin-bottom: 15px
}

.card-wrapper .face h5 {
    margin-top: 30px
}

.card-wrapper .face .content {
    text-align: left;
    padding: 15px
}

.card-wrapper .face .content p {
    margin-bottom: 30px
}

.card-wrapper .face .content .rotate-btn {
    padding: 1rem;
    margin-right: -8px;
    float: right;
    font-size: 1.2rem
}

.card-wrapper .card-up {
    overflow: hidden;
    height: 50%
}

.card-wrapper .card-up img {
    min-width: 400px
}

.card-wrapper .avatar {
    border-radius: 50%;
    display: block;
    height: 120px;
    margin: -65px auto 0;
    overflow: hidden;
    width: 120px
}

.card-wrapper .avatar img {
    border: 5px solid #fff;
    background: none repeat scroll 0 0 #fff;
    width: 100%
}

.card-wrapper .card-rotating {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%
}

.card-wrapper .card-rotating .content {
    position: relative
}

.card-wrapper .card-rotating .content .rotate-btn {
    position: absolute;
    right: 8px;
    top: 0
}

.card-wrapper .fa-repeat,
.card-wrapper .fa-undo {
    font-size: 20px;
    margin-top: 30px
}

.card-wrapper .fa-undo {
    margin-top: 30px
}

.card-wrapper .card-rotating {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.card-wrapper .back,
.card-wrapper .front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s
}

.card-wrapper .back {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    padding: 1rem
}

.card-wrapper .back .card-title {
    cursor: pointer
}

.card-wrapper .back .card-title i {
    color: #9e9e9e;
    position: absolute;
    right: 20px
}

.card-wrapper .card-rotating.effect__click.flipped .front {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.card-wrapper .card-rotating.effect__click.flipped .back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0)
}

.testimonial-card .card-up {
    overflow: hidden;
    height: 120px;
    border-radius: .3rem .3rem 0 0
}

.testimonial-card .avatar {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    margin: -65px auto 0;
    overflow: hidden;
    border: 5px solid #fff
}

.testimonial-card .avatar img {
    width: 100%
}

.testimonial-card .card-body {
    text-align: center
}

.card-inverse h5 {
    font-size: 1rem
}

.card-inverse h3 {
    font-weight: 400
}

.card-overlay {
    background-size: cover;
    padding: 1.5rem;
    border-radius: 2px
}

.card-overlay p {
    margin-bottom: 1rem
}

.dropdown .dropdown-menu {
    padding: .5rem
}

.dropdown .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover {
    background-color: #4285f4
}

.dropdown .dropdown-menu.dropdown-danger .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-danger .dropdown-item:hover {
    background-color: #c00
}

.dropdown .dropdown-menu.dropdown-default .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-default .dropdown-item:hover {
    background-color: #2bbbad
}

.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:hover {
    background-color: #a6c
}

.dropdown .dropdown-menu.dropdown-success .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-success .dropdown-item:hover {
    background-color: #00c851
}

.dropdown .dropdown-menu.dropdown-info .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-info .dropdown-item:hover {
    background-color: #33b5e5
}

.dropdown .dropdown-menu.dropdown-warning .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-warning .dropdown-item:hover {
    background-color: #fb3
}

.dropdown .dropdown-menu.dropdown-dark .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-dark .dropdown-item:hover {
    background-color: #2e2e2e
}

.dropdown .dropdown-menu.dropdown-ins .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-ins .dropdown-item:hover {
    background-color: #2e5e86
}

.dropdown .dropdown-menu .dropdown-item {
    padding: .5rem;
    margin-left: 0;
    font-size: .9rem
}

.dropdown .dropdown-menu .dropdown-item.disabled {
    color: #868e96
}

.dropdown .dropdown-menu .dropdown-item.disabled:active,
.dropdown .dropdown-menu .dropdown-item.disabled:focus,
.dropdown .dropdown-menu .dropdown-item.disabled:hover {
    box-shadow: none;
    color: #868e96 !important;
    background-color: transparent !important
}

.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:hover {
    border-radius: 2px;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    background-color: #4285f4
}

.dropdown-content {
    background-color: #fff;
    margin: 0;
    display: none;
    min-width: 100px;
    max-height: 650px;
    overflow-y: auto;
    opacity: 0;
    position: absolute;
    z-index: 999;
    will-change: width, height
}

.dropdown-content li {
    clear: both;
    color: #000;
    cursor: pointer;
    line-height: 1.3rem;
    width: 100%;
    text-align: left;
    text-transform: none
}

.dropdown-content li.active,
.dropdown-content li:hover {
    background-color: #eee
}

.dropdown-content li>a,
.dropdown-content li>span {
    font-size: .9rem;
    color: #4285f4;
    display: block;
    padding: .5rem
}

.dropdown-content li>a>i {
    height: inherit;
    line-height: inherit
}

.colorful-select .dropdown-content {
    padding: .5rem
}

.colorful-select .dropdown-content li.active span {
    color: #fff !important
}

.colorful-select .dropdown-content li.active span [type=checkbox]:checked+label:before {
    border-color: transparent #fff #fff transparent
}

.colorful-select .dropdown-content li a:hover,
.colorful-select .dropdown-content li span:hover {
    color: #fff !important;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    border-radius: 2px
}

.colorful-select .dropdown-content li a:hover [type=checkbox]+label:before,
.colorful-select .dropdown-content li span:hover [type=checkbox]+label:before {
    border-color: #fff
}

.colorful-select .dropdown-content li a:hover [type=checkbox]:checked+label:before,
.colorful-select .dropdown-content li span:hover [type=checkbox]:checked+label:before {
    border-color: transparent #fff #fff transparent
}

.colorful-select .dropdown-content li.disabled.active span,
.colorful-select .dropdown-content li.optgroup.active span,
.colorful-select .dropdown-content li:disabled.active span {
    color: rgba(0, 0, 0, .3) !important;
    border-bottom-color: rgba(0, 0, 0, .3);
    cursor: default
}

.colorful-select .dropdown-content li.disabled a:hover,
.colorful-select .dropdown-content li.disabled span:hover,
.colorful-select .dropdown-content li.optgroup a:hover,
.colorful-select .dropdown-content li.optgroup span:hover,
.colorful-select .dropdown-content li:disabled a:hover,
.colorful-select .dropdown-content li:disabled span:hover {
    color: rgba(0, 0, 0, .3) !important;
    border-bottom-color: rgba(0, 0, 0, .3);
    cursor: default;
    background-color: #fff !important
}

.colorful-select .dropdown-content li.disabled label,
.colorful-select .dropdown-content li.optgroup label,
.colorful-select .dropdown-content li:disabled label {
    cursor: default
}

.dropdown-primary .dropdown-content li a,
.dropdown-primary .dropdown-content li span:hover,
.dropdown-primary .dropdown-content li.active {
    background-color: #4285f4
}

.dropdown-primary .search-wrap input:focus {
    border-bottom: 1px solid #4285f4;
    box-shadow: 0 1px 0 0 #4285f4
}

.dropdown-danger .dropdown-content li a,
.dropdown-danger .dropdown-content li span:hover,
.dropdown-danger .dropdown-content li.active {
    background-color: #c00
}

.dropdown-danger .search-wrap input:focus {
    border-bottom: 1px solid #c00;
    box-shadow: 0 1px 0 0 #c00
}

.dropdown-default .dropdown-content li a,
.dropdown-default .dropdown-content li span:hover,
.dropdown-default .dropdown-content li.active {
    background-color: #2bbbad
}

.dropdown-default .search-wrap input:focus {
    border-bottom: 1px solid #2bbbad;
    box-shadow: 0 1px 0 0 #2bbbad
}

.dropdown-secondary .dropdown-content li a,
.dropdown-secondary .dropdown-content li span:hover,
.dropdown-secondary .dropdown-content li.active {
    background-color: #a6c
}

.dropdown-secondary .search-wrap input:focus {
    border-bottom: 1px solid #a6c;
    box-shadow: 0 1px 0 0 #a6c
}

.dropdown-success .dropdown-content li a,
.dropdown-success .dropdown-content li span:hover,
.dropdown-success .dropdown-content li.active {
    background-color: #00c851
}

.dropdown-success .search-wrap input:focus {
    border-bottom: 1px solid #00c851;
    box-shadow: 0 1px 0 0 #00c851
}

.dropdown-info .dropdown-content li a,
.dropdown-info .dropdown-content li span:hover,
.dropdown-info .dropdown-content li.active {
    background-color: #33b5e5
}

.dropdown-info .search-wrap input:focus {
    border-bottom: 1px solid #33b5e5;
    box-shadow: 0 1px 0 0 #33b5e5
}

.dropdown-warning .dropdown-content li a,
.dropdown-warning .dropdown-content li span:hover,
.dropdown-warning .dropdown-content li.active {
    background-color: #fb3
}

.dropdown-warning .search-wrap input:focus {
    border-bottom: 1px solid #fb3;
    box-shadow: 0 1px 0 0 #fb3
}

.dropdown-dark .dropdown-content li a,
.dropdown-dark .dropdown-content li span:hover,
.dropdown-dark .dropdown-content li.active {
    background-color: #2e2e2e
}

.dropdown-dark .search-wrap input:focus {
    border-bottom: 1px solid #2e2e2e;
    box-shadow: 0 1px 0 0 #2e2e2e
}

.dropdown-ins .dropdown-content li a,
.dropdown-ins .dropdown-content li span:hover,
.dropdown-ins .dropdown-content li.active {
    background-color: #2e5e86
}

.dropdown-ins .search-wrap input:focus {
    border-bottom: 1px solid #2e5e86;
    box-shadow: 0 1px 0 0 #2e5e86
}

.dropdown-menu.animated {
    -webkit-animation-duration: .55s;
    animation-duration: .55s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}

.navbar .nav-item.avatar {
    padding: 0
}

.navbar .nav-item.avatar.active {
    background-color: transparent !important
}

.navbar .nav-item.avatar .dropdown-toggle {
    padding: 0
}

.navbar .nav-item.avatar .dropdown-toggle img {
    height: 35px
}

.navbar .nav-item.avatar .dropdown-toggle:after {
    display: none
}

.double-nav {
    z-index: 100;
    color: #fff
}

@media (max-width:992px) {
    .double-nav {
        padding-top: 4px;
        padding-bottom: 4px
    }
}

@media (max-width:768px) {
    .double-nav .container {
        padding-left: 0;
        padding-right: 0
    }
}

.double-nav a {
    color: #fff;
    font-size: 15px
}

.double-nav .breadcrumb-dn p {
    margin: 0;
    padding-top: 0;
    padding-left: 1rem
}

@media (max-width:993px) {
    .double-nav .breadcrumb-dn {
        display: none
    }
}

.double-nav .button-collapse {
    position: absolute;
    left: 10px;
    font-size: 1.5rem
}

@media (min-width:1440px) {
    .double-nav .button-collapse {
        display: none
    }
}

@media (max-width:1440px) {
    .double-nav .button-collapse {
        display: block;
        position: relative;
        font-size: 1.4rem;
        margin-right: 10px;
        margin-left: 10px
    }
}

.nav-pills .nav-item a {
    text-align: left;
    color: #666;
    border-radius: 0
}

.nav-pills .nav-item a:hover {
    background-color: transparent;
    font-weight: 500;
    border-left: 1px solid
}

.nav-pills .nav-item a.active {
    background-color: transparent;
    color: #45526e;
    border-left: 2px solid;
    font-weight: 500;
    box-shadow: none
}

.nav-pills .nav-item a.active:active,
.nav-pills .nav-item a.active:focus,
.nav-pills .nav-item a.active:hover {
    background-color: transparent;
    font-weight: 500
}

.nav-pills .nav-item+.nav-item {
    margin-left: 0
}

@media only screen and (max-width:991px) {

    .sticky,
    .sticky-placeholder {
        display: none
    }
}

#scrollspy {
    width: 100%
}

@media only screen and (max-width:992px) {

    .col-lg-4 .sticky,
    .col-md-4 .sticky,
    .col-xl-4 .sticky {
        width: 200px
    }
}

@media only screen and (min-width:992px) {

    .col-md-4 .sticky .col-lg-4 .sticky,
    .col-xl-4 .sticky {
        width: 400px
    }
}

@media only screen and (min-width:1200px) {
    .col-md-4 .sticky {
        width: 300px
    }
}

@media only screen and (min-width:1440px) {
    .col-md-4 .sticky {
        width: 350px
    }
}

.dotted-scrollspy {
    display: block;
    position: fixed;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: rgba(0, 0, 0, .55);
    border-radius: 2px 0 0 2px
}

.dotted-scrollspy li {
    display: block;
    padding: 0 16px
}

.dotted-scrollspy li:first-child {
    padding-top: 10px
}

.dotted-scrollspy li:last-child {
    padding-bottom: 10px
}

.dotted-scrollspy li a {
    padding: .5rem
}

.dotted-scrollspy li a span {
    display: block;
    background-color: rgba(255, 255, 255, .54);
    width: 8px;
    height: 8px;
    border-radius: 50%
}

.dotted-scrollspy li a.active span {
    background: #fff
}

.scrollspy-example {
    overflow-y: scroll;
    position: relative;
    height: 200px;
    padding: 1rem
}

.pswp {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
    z-index: 1500;
    -webkit-text-size-adjust: 100%;
    -webkit-backface-visibility: hidden;
    outline: 0
}

.pswp * {
    box-sizing: border-box
}

.pswp img {
    max-width: none
}

.pswp--animate_opacity {
    opacity: .001;
    will-change: opacity;
    -webkit-transition: opacity 333ms cubic-bezier(.4, 0, .22, 1);
    transition: opacity 333ms cubic-bezier(.4, 0, .22, 1)
}

.pswp--open {
    display: block
}

.pswp--zoom-allowed .pswp__img {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.pswp--zoomed-in .pswp__img {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab
}

.pswp--dragging .pswp__img {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing
}

.pswp__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    will-change: opacity
}

.pswp__scroll-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.pswp__container,
.pswp__zoom-wrap {
    -ms-touch-action: none;
    touch-action: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.pswp__container,
.pswp__img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none
}

.pswp__zoom-wrap {
    position: absolute;
    width: 100%;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transition: -webkit-transform 333ms cubic-bezier(.4, 0, .22, 1);
    transition: -webkit-transform 333ms cubic-bezier(.4, 0, .22, 1);
    transition: transform 333ms cubic-bezier(.4, 0, .22, 1);
    transition: transform 333ms cubic-bezier(.4, 0, .22, 1), -webkit-transform 333ms cubic-bezier(.4, 0, .22, 1)
}

.pswp__bg {
    will-change: opacity;
    -webkit-transition: opacity 333ms cubic-bezier(.4, 0, .22, 1);
    transition: opacity 333ms cubic-bezier(.4, 0, .22, 1)
}

.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap {
    -webkit-transition: none;
    transition: none
}

.pswp__container,
.pswp__zoom-wrap {
    -webkit-backface-visibility: hidden
}

.pswp__item {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden
}

.pswp__img {
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    left: 0
}

.pswp__img--placeholder {
    -webkit-backface-visibility: hidden
}

.pswp__img--placeholder--blank {
    background: #222
}

.pswp--ie .pswp__img {
    width: 100% !important;
    height: auto !important;
    left: 0;
    top: 0
}

.pswp__error-msg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-top: -8px;
    color: #ccc
}

.pswp__error-msg a {
    color: #ccc;
    text-decoration: underline
}

.pswp__button {
    width: 44px;
    height: 44px;
    position: relative;
    background: 0 0;
    cursor: pointer;
    overflow: visible;
    -webkit-appearance: none;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    float: right;
    opacity: .75;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    box-shadow: none
}

.pswp__button:focus,
.pswp__button:hover {
    opacity: 1
}

.pswp__button:active {
    outline: 0;
    opacity: .9
}

.pswp__button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.pswp__ui--over-close .pswp__button--close {
    opacity: 1
}

.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
    /* background:url(../img/lightbox/default-skin.png) 0 0 no-repeat; */
    background-size: 264px 88px;
    width: 44px;
    height: 44px
}

@media (-webkit-min-device-pixel-ratio:1.1),
(-webkit-min-device-pixel-ratio:1.09375),
(min-resolution:105dpi),
(min-resolution:1.1dppx) {

    .pswp--svg .pswp__button,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
        /* background-image:url(../img/lightbox/default-skin.svg) */
    }

    .pswp--svg .pswp__button--arrow--left,
    .pswp--svg .pswp__button--arrow--right {
        background: 0 0
    }
}

.pswp__button--close {
    background-position: 0 -44px
}

.pswp__button--share {
    background-position: -44px -44px
}

.pswp__button--fs {
    display: none
}

.pswp--supports-fs .pswp__button--fs {
    display: block
}

.pswp--fs .pswp__button--fs {
    background-position: -44px 0
}

.pswp__button--zoom {
    display: none;
    background-position: -88px 0
}

.pswp--zoom-allowed .pswp__button--zoom {
    display: block
}

.pswp--zoomed-in .pswp__button--zoom {
    background-position: -132px 0
}

.pswp--touch .pswp__button--arrow--left,
.pswp--touch .pswp__button--arrow--right {
    visibility: hidden
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
    background: 0 0;
    top: 50%;
    margin-top: -50px;
    width: 70px;
    height: 100px;
    position: absolute
}

.pswp__button--arrow--left {
    left: 0
}

.pswp__button--arrow--right {
    right: 0
}

.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
    content: '';
    top: 35px;
    background-color: rgba(0, 0, 0, .3);
    height: 30px;
    width: 32px;
    position: absolute
}

.pswp__button--arrow--left:before {
    left: 6px;
    background-position: -138px -44px
}

.pswp__button--arrow--right:before {
    right: 6px;
    background-position: -94px -44px
}

.pswp__counter,
.pswp__share-modal {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.pswp__share-modal {
    display: block;
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    z-index: 1600;
    opacity: 0;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
    -webkit-backface-visibility: hidden;
    will-change: opacity
}

.pswp__share-modal--hidden {
    display: none
}

.pswp__share-tooltip {
    z-index: 1620;
    position: absolute;
    background: #fff;
    top: 56px;
    border-radius: 2px;
    display: block;
    width: auto;
    right: 44px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
    -webkit-transition: -webkit-transform .25s;
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
    -webkit-backface-visibility: hidden;
    will-change: transform
}

.pswp__share-tooltip a {
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px
}

.pswp__share-tooltip a:hover {
    text-decoration: none;
    color: #000
}

.pswp__share-tooltip a:first-child {
    border-radius: 2px 2px 0 0
}

.pswp__share-tooltip a:last-child {
    border-radius: 0 0 2px 2px
}

.pswp__share-modal--fade-in {
    opacity: 1
}

.pswp__share-modal--fade-in .pswp__share-tooltip {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.pswp--touch .pswp__share-tooltip a {
    padding: 16px 12px
}

a.pswp__share--facebook:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -12px;
    right: 15px;
    border: 6px solid transparent;
    border-bottom-color: #fff;
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    pointer-events: none
}

a.pswp__share--facebook:hover {
    background: #3e5c9a;
    color: #fff
}

a.pswp__share--facebook:hover:before {
    border-bottom-color: #3e5c9a
}

a.pswp__share--twitter:hover {
    background: #55acee;
    color: #fff
}

a.pswp__share--pinterest:hover {
    background: #ccc;
    color: #ce272d
}

a.pswp__share--download:hover {
    background: #ddd
}

.pswp__counter {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    font-size: 13px;
    line-height: 44px;
    color: #fff;
    opacity: .75;
    padding: 0 10px
}

.pswp__caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 44px
}

.pswp__caption small {
    font-size: 11px;
    color: #bbb
}

.pswp__caption__center {
    text-align: left;
    max-width: 420px;
    margin: 0 auto;
    font-size: 13px;
    padding: 10px;
    line-height: 20px;
    color: #ccc
}

.pswp__caption--empty {
    display: none
}

.pswp__caption--fake {
    visibility: hidden
}

.pswp__preloader {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -22px;
    opacity: 0;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
    will-change: opacity;
    direction: ltr
}

.pswp__preloader__icn {
    width: 20px;
    height: 20px;
    margin: 12px
}

.pswp__preloader--active {
    opacity: 1
}

.pswp__preloader--active .pswp__preloader__icn {
    /* background:url(../img/lightbox/preloader.gif) 0 0 no-repeat */
}

.pswp--css_animation .pswp__preloader--active {
    opacity: 1
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
    -webkit-animation: clockwise .5s linear infinite;
    animation: clockwise .5s linear infinite
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
    -webkit-animation: donut-rotate 1s cubic-bezier(.4, 0, .22, 1) infinite;
    animation: donut-rotate 1s cubic-bezier(.4, 0, .22, 1) infinite
}

.pswp--css_animation .pswp__preloader__icn {
    background: 0 0;
    opacity: .75;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 15px;
    top: 15px;
    margin: 0
}

.pswp--css_animation .pswp__preloader__cut {
    position: relative;
    width: 7px;
    height: 14px;
    overflow: hidden
}

.pswp--css_animation .pswp__preloader__donut {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: 0 0;
    margin: 0
}

@media screen and (max-width:1024px) {
    .pswp__preloader {
        position: relative;
        left: auto;
        top: auto;
        margin: 0;
        float: right
    }
}

@-webkit-keyframes clockwise {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes clockwise {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes donut-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(-140deg);
        transform: rotate(-140deg)
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes donut-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(-140deg);
        transform: rotate(-140deg)
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

.pswp__ui {
    -webkit-font-smoothing: auto;
    visibility: visible;
    opacity: 1;
    z-index: 1550
}

.pswp__top-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    width: 100%
}

.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right,
.pswp__caption,
.pswp__top-bar {
    -webkit-backface-visibility: hidden;
    will-change: opacity;
    -webkit-transition: opacity 333ms cubic-bezier(.4, 0, .22, 1);
    transition: opacity 333ms cubic-bezier(.4, 0, .22, 1)
}

.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
    visibility: visible
}

.pswp__caption,
.pswp__top-bar {
    background-color: rgba(0, 0, 0, .5)
}

.pswp__ui--fit .pswp__caption,
.pswp__ui--fit .pswp__top-bar {
    background-color: rgba(0, 0, 0, .3)
}

.pswp__ui--idle .pswp__top-bar {
    opacity: 0
}

.pswp__ui--idle .pswp__button--arrow--left,
.pswp__ui--idle .pswp__button--arrow--right {
    opacity: 0
}

.pswp__ui--hidden .pswp__button--arrow--left,
.pswp__ui--hidden .pswp__button--arrow--right,
.pswp__ui--hidden .pswp__caption,
.pswp__ui--hidden .pswp__top-bar {
    opacity: .001
}

.pswp__ui--one-slide .pswp__button--arrow--left,
.pswp__ui--one-slide .pswp__button--arrow--right,
.pswp__ui--one-slide .pswp__counter {
    display: none
}

.pswp__element--disabled {
    display: none !important
}

.pswp--minimal--dark .pswp__top-bar {
    background: 0 0
}

.mdb-lightbox figure {
    margin: 0;
    float: left
}

.comments-list .mdb-lightbox figure img,
.mdb-lightbox figure .comments-list img,
.mdb-lightbox figure .img-fluid,
.mdb-lightbox figure .modal-dialog.cascading-modal.modal-avatar .modal-header,
.mdb-lightbox figure .reply-form img,
.mdb-lightbox figure .section-blog-fw .view img,
.modal-dialog.cascading-modal.modal-avatar .mdb-lightbox figure .modal-header,
.reply-form .mdb-lightbox figure img,
.section-blog-fw .view .mdb-lightbox figure img {
    display: inline
}

.mdb-lightbox figure img {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    -webkit-transition: opacity .4s;
    transition: opacity .4s;
    border-radius: 0
}

.mdb-lightbox figure img:hover {
    opacity: .8
}

.mdb-lightbox.no-margin [class*=col-] {
    padding: 0
}

.mdb-lightbox [class*=col-] {
    padding: .5rem
}

.chip {
    display: inline-block;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, .6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: #eceff1;
    margin-bottom: 1rem;
    margin-right: 1rem
}

.chip img {
    float: left;
    margin: 0 8px 0 -12px;
    height: 32px;
    width: 32px;
    border-radius: 50%
}

.chip .close {
    cursor: pointer;
    float: right;
    font-size: 16px;
    line-height: 32px;
    padding-left: 8px;
    -webkit-transition: all .1s linear;
    transition: all .1s linear
}

.chips {
    outline: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #bdbdbd;
    margin-bottom: 30px;
    min-height: 45px;
    padding-bottom: 1rem;
    -webkit-transition: all .3s;
    transition: all .3s
}

.chips.focus {
    border-bottom: 1px solid #4285f4;
    box-shadow: 0 1px 0 0 #4285f4
}

.chips:hover {
    cursor: text
}

.chips .tag.selected {
    border-bottom: 1px solid #4285f4;
    color: #fff
}

.chips .input {
    display: inline-block;
    background: 0 0;
    border: 0;
    outline: 0;
    padding: 0 !important;
    width: 120px !important;
    color: rgba(0, 0, 0, .6);
    font-size: 13px;
    font-weight: 500;
    height: 32px;
    margin-right: 20px;
    line-height: 32px
}

.chips .input:focus {
    border: 0 !important;
    box-shadow: none !important
}

.table a {
    margin-left: 1rem
}

.table fieldset {
    margin-bottom: 0
}

.table fieldset label {
    height: 8px
}

.product-table img {
    max-height: 150px;
    min-width: 50px
}

.product-table td {
    vertical-align: middle
}

[type=checkbox],
[type=radio] {
    position: absolute;
    left: -9999px;
    visibility: hidden
}

[type=checkbox]+label,
[type=radio]+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none
}

.streak {
    display: block;
    position: relative;
    overflow: hidden;
    height: 250px
}

.streak.streak-md {
    height: 400px
}

@media (max-width:736px) {
    .streak.streak-md {
        height: 300px
    }
}

.streak.streak-lg {
    height: 650px
}

@media (max-width:450px) {
    .streak.streak-lg {
        height: 900px
    }
}

.streak.streak-long {
    height: 200px
}

@media (max-width:450px) {
    .streak.streak-long {
        height: 620px
    }
}

.streak.streak-long-2 {
    height: 400px
}

@media (max-width:450px) {
    .streak.streak-long-2 {
        height: 700px
    }
}

.streak.streak-photo {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

@media (min-width:1366px) {
    .streak.streak-photo {
        background-attachment: fixed
    }
}

.streak.no-flex {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    height: auto
}

.purple-gradient {
    background: -webkit-linear-gradient(50deg, #ff6ec4, #7873f5);
    background: linear-gradient(40deg, #ff6ec4, #7873f5)
}

.peach-gradient {
    background: -webkit-linear-gradient(50deg, #ffd86f, #fc6262);
    background: linear-gradient(40deg, #ffd86f, #fc6262)
}

.aqua-gradient {
    background: -webkit-linear-gradient(50deg, #2096ff, #05ffa3);
    background: linear-gradient(40deg, #2096ff, #05ffa3)
}

.blue-gradient {
    background: -webkit-linear-gradient(50deg, #45cafc, #303f9f);
    background: linear-gradient(40deg, #45cafc, #303f9f)
}

[type=radio]+label {
    -webkit-transition: .28s ease;
    transition: .28s ease
}

[type=radio]+label:after,
[type=radio]+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    -webkit-transition: .28s ease;
    transition: .28s ease
}

[type=radio]:not(:checked)+label:after,
[type=radio]:not(:checked)+label:before {
    border-color: #5a5a5a
}

[type=radio]:not(:checked)+label:after {
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0)
}

[type=radio]:checked+label:before {
    border-color: transparent
}

[type=radio]:checked+label:after {
    border-color: #4285f4;
    background-color: #4285f4;
    z-index: 0;
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02)
}

[type=radio]:disabled+label {
    color: rgba(0, 0, 0, .46)
}

[type=radio]:disabled+label:before {
    background-color: transparent;
    border-color: rgba(0, 0, 0, .46)
}

[type=radio]:disabled:not(:checked)+label:before {
    border-color: rgba(0, 0, 0, .46)
}

[type=radio]:disabled:checked+label:after {
    background-color: rgba(0, 0, 0, .46);
    border-color: #bdbdbd
}

[type=radio].with-gap:checked+label:before {
    border-color: #4285f4
}

[type=radio].with-gap:checked+label:after {
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5)
}

[type=radio].with-gap:checked:disabled+label:before {
    border-color: rgba(0, 0, 0, .46)
}

[type=checkbox]+label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    z-index: 0;
    border: 1.5px solid #8a8a8a;
    border-radius: 1px;
    margin-top: 2px;
    -webkit-transition: .2s;
    transition: .2s
}

[type=checkbox]:checked+label:before {
    top: -4px;
    left: -3px;
    width: 12px;
    height: 22px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent #4285f4 #4285f4 transparent;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox]:indeterminate+label:before {
    left: -10px;
    top: -11px;
    width: 10px;
    height: 22px;
    border: none;
    border-right: 2px solid #4285f4;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox]:disabled:checked+label:before {
    border-color: transparent rgba(0, 0, 0, .46) rgba(0, 0, 0, .46) transparent
}

[type=checkbox]:disabled:not(:checked)+label:before {
    border: none;
    margin-left: 2px;
    margin-top: 2px;
    background-color: rgba(0, 0, 0, .46)
}

[type=checkbox]:disabled:indeterminate+label:before {
    border-right: 2px solid rgba(0, 0, 0, .46);
    margin-left: 0;
    margin-top: 0;
    background-color: transparent
}

[type=checkbox][class*=filled-in]+label:after,
[type=checkbox][class*=filled-in]+label:before {
    content: '';
    left: 0;
    position: absolute;
    -webkit-transition: border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
    transition: border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
    z-index: 1;
    border-style: solid;
    border-width: 2px
}

[type=checkbox][class*=filled-in]+label:after {
    border-radius: 2px;
    height: 20px;
    width: 20px
}

[type=checkbox][class*=filled-in]+label:before {
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox][class*=filled-in]:not(:checked)+label:before {
    width: 0;
    height: 0;
    border-style: 3px;
    border-color: transparent;
    left: 6px;
    top: 10px
}

[type=checkbox][class*=filled-in]:not(:checked)+label:after {
    background-color: transparent;
    border-color: #5a5a5a;
    top: 0;
    z-index: 0
}

[type=checkbox][class*=filled-in]:checked+label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-color: transparent #fff #fff transparent
}

[type=checkbox][class*=filled-in]:checked+label:after {
    border-color: #a6c;
    background-color: #a6c;
    z-index: 0
}

[type=checkbox][class*=filled-in]:disabled:not(:checked)+label:before {
    background-color: transparent;
    border-color: #bdbdbd
}

[type=checkbox][class*=filled-in]:disabled:not(:checked)+label:after {
    border-color: transparent;
    background-color: #bdbdbd
}

[type=checkbox][class*=filled-in]:disabled:checked+label:before {
    background-color: transparent
}

[type=checkbox][class*=filled-in]:disabled:checked+label:after {
    background-color: #bdbdbd;
    border-color: #bdbdbd
}

[type=checkbox].filled-in-danger:checked+label:after {
    background-color: red;
    border-color: red
}

.select-wrapper .select-dropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none
}

.select-label {
    position: absolute
}

.select-wrapper {
    position: relative
}

.select-wrapper input.select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #bdbdbd;
    outline: 0;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 15px 0;
    padding: 0;
    display: block
}

.select-wrapper input.select-dropdown:disabled {
    color: rgba(0, 0, 0, .3);
    border-bottom-color: rgba(0, 0, 0, .3);
    cursor: default
}

.select-wrapper .search-wrap {
    padding: 1rem 0;
    width: 93%;
    display: block;
    margin: 0 auto
}

.select-wrapper .search-wrap input {
    padding-bottom: .4rem
}

.select-wrapper span.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 16px;
    font-size: 10px
}

.select-wrapper span.caret.disabled {
    color: rgba(0, 0, 0, .46)
}

.select-wrapper+label {
    position: absolute;
    top: -14px;
    font-size: .8rem
}

.select-wrapper i {
    color: rgba(0, 0, 0, .3)
}

.select-wrapper ul {
    list-style-type: none;
    padding-left: 0
}

select {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: none !important
}

select.browser-default {
    display: block !important
}

select:disabled {
    color: rgba(0, 0, 0, .3)
}

.select-dropdown ul {
    list-style-type: none;
    padding: 0
}

.select-dropdown li img {
    height: 30px;
    width: 30px;
    margin: 3px 12px;
    float: right
}

.select-dropdown li.disabled,
.select-dropdown li.disabled>span,
.select-dropdown li.optgroup {
    color: rgba(0, 0, 0, .3);
    background-color: transparent !important;
    cursor: context-menu
}

.select-dropdown li.optgroup {
    border-top: 1px solid #eee
}

.select-dropdown li.optgroup.selected>span {
    color: rgba(0, 0, 0, .7)
}

.select-dropdown li.optgroup>span {
    color: rgba(0, 0, 0, .4)
}

.multiple-select-dropdown li [type=checkbox]+label {
    height: 10px
}

.switch,
.switch * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none
}

.switch label {
    cursor: pointer
}

.switch label input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0
}

.switch label input[type=checkbox]:checked+.lever {
    background-color: #dccfe2
}

.switch label input[type=checkbox]:checked+.lever:after {
    background-color: #a6c;
    left: 24px
}

.switch label input[type=checkbox]:checked:not(:disabled)~.lever:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(170, 102, 204, .1)
}

.switch label input[type=checkbox]:not(:disabled)~.lever:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 0, 0, .08)
}

.switch label input[type=checkbox]:disabled+.lever {
    cursor: default
}

.switch label input[type=checkbox]:disabled+.lever:after,
.switch label input[type=checkbox]:disabled:checked+.lever:after {
    background-color: #bdbdbd
}

.switch label .lever {
    content: "";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    background-color: #818181;
    border-radius: 15px;
    margin-right: 10px;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
    vertical-align: middle;
    margin: 0 16px
}

.switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #f1f1f1;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4);
    left: -5px;
    top: -3px;
    -webkit-transition: left .3s ease, background .3s ease, box-shadow .1s ease;
    transition: left .3s ease, background .3s ease, box-shadow .1s ease
}

.file-field {
    position: relative
}

.file-field .file-path-wrapper {
    overflow: hidden;
    padding-left: 10px;
    height: 2.5rem
}

.file-field input.file-path {
    width: 100%;
    height: 2.1rem
}

.file-field .btn {
    float: left
}

.file-field span {
    cursor: pointer
}

.file-field input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1px;
    cursor: pointer;
    opacity: 0
}

.btn-file {
    padding-top: 1px
}

.range-field {
    position: relative
}

input[type=range] {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
    outline: 0;
    width: 100%;
    margin: 15px 0;
    padding: 0;
    -webkit-appearance: none;
    border: 1px solid #fff
}

input[type=range]:focus {
    outline: 0
}

input[type=range]+.thumb {
    position: absolute;
    border: none;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: #4285f4;
    top: 10px;
    margin-left: -6px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

input[type=range]+.thumb .value {
    display: block;
    width: 30px;
    text-align: center;
    color: #4285f4;
    font-size: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

input[type=range]+.thumb.active {
    border-radius: 50% 50% 50% 0
}

input[type=range]+.thumb.active .value {
    color: #fff;
    margin-left: -1px;
    margin-top: 8px;
    font-size: 10px
}

input[type=range]::-webkit-slider-runnable-track {
    height: 3px;
    background: #c2c0c2;
    border: none
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #4285f4;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    margin: -5px 0 0 0;
    -webkit-transition: .3s;
    transition: .3s
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc
}

input[type=range]::-moz-range-track {
    height: 3px;
    background: #ddd;
    border: none
}

input[type=range]::-moz-range-thumb {
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #4285f4;
    margin-top: -5px
}

input[type=range]:-moz-focusring {
    outline: 1px solid #fff;
    outline-offset: -1px
}

input[type=range]:focus::-moz-range-track {
    background: #ccc
}

input[type=range]::-ms-track {
    height: 3px;
    background: 0 0;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent
}

input[type=range]::-ms-fill-lower {
    background: #777
}

input[type=range]::-ms-fill-upper {
    background: #ddd
}

input[type=range]::-ms-thumb {
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #4285f4
}

input[type=range]:focus::-ms-fill-lower {
    background: #888
}

input[type=range]:focus::-ms-fill-upper {
    background: #ccc
}

.input-group .input-group-addon {
    background-color: #e0e0e0
}

.input-group .input-group-addon [type=checkbox]+label {
    padding-left: 18px;
    height: 13px
}

.input-group .input-group-addon [type=radio]+label {
    padding-left: 24px;
    height: 13px
}

.input-group .input-group-addon::-webkit-input-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon:-moz-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon::-moz-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon::-ms-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon::-webkit-input-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon::-moz-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon:-ms-input-placeholder {
    padding-left: 1rem
}

.input-group .input-group-addon::placeholder {
    padding-left: 1rem
}

.input-group span {
    z-index: 10
}

.input-group .form-control {
    margin-top: 3px;
    padding-top: 14px;
    padding-bottom: 7px;
    padding-left: 1.2rem
}

.mdb-autocomplete {
    margin-bottom: 1px
}

.mdb-autocomplete-clear {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    right: 0;
    top: .85rem;
    background: 0 0;
    border: none
}

.mdb-autocomplete-clear svg {
    fill: #a6a6a6
}

.mdb-autocomplete-wrap {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
    z-index: 100;
    max-height: 210px;
    overflow-y: auto;
    padding-left: 0;
    list-style-type: none
}

.mdb-autocomplete-wrap li {
    cursor: pointer;
    padding: 12px 15px;
    font-size: .875rem
}

.mdb-autocomplete-wrap li:hover {
    background: #eee
}

.jarallax {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 700px
}

.side-nav {
    position: fixed;
    width: 240px;
    left: 0;
    top: 0;
    margin: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    height: 100%;
    height: -webkit-calc(100%+ 60px);
    height: calc(100%+ 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    color: #d8d8d8;
    background-color: #2c2f34;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 999;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-transform: translateX(-105%);
    -ms-transform: translateX(-105%);
    transform: translateX(-105%);
    list-style-type: none;
    padding: 0
}

.side-nav>ul {
    max-height: 100vh
}

.side-nav ul {
    list-style-type: none
}

.side-nav ul li {
    padding: 0
}

.side-nav.right-aligned {
    right: 0;
    left: auto;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%)
}

.side-nav .collapsible {
    margin: 0;
    padding: 0
}

.side-nav .collapsible li a:hover {
    background-color: rgba(0, 0, 0, .15)
}

.side-nav .collapsible>li {
    border-radius: 2px
}

.side-nav .collapsible>li a.collapsible-header:hover {
    background-color: rgba(255, 255, 255, .15)
}

.side-nav .collapsible>li a.collapsible-header.active {
    background-color: rgba(255, 255, 255, .15)
}

.side-nav .collapsible ul {
    padding: 0;
    list-style-type: none
}

.side-nav .collapsible a {
    color: #fff;
    font-weight: 300;
    font-size: .8rem;
    height: 36px;
    line-height: 36px
}

.side-nav .collapsible a.active,
.side-nav .collapsible a:hover {
    border-radius: 2px
}

.side-nav .collapsible .fa {
    font-size: .8rem;
    margin-right: 13px
}

.side-nav .collapsible-body a {
    padding-left: 47px;
    height: 36px;
    line-height: 36px;
    background-color: rgba(0, 0, 0, .15)
}

.side-nav a {
    display: block;
    font-size: 1rem;
    height: 56px;
    line-height: 56px;
    padding-left: 20px
}

.side-nav .logo-wrapper {
    height: 140px
}

.side-nav .logo-wrapper a {
    height: 140px;
    width: 240px;
    padding: 0
}

.side-nav .logo-wrapper img {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20%;
    padding-bottom: 20%;
    height: auto
}

@media (max-height:992px) {
    .side-nav .logo-wrapper {
        height: 80px
    }

    .side-nav .logo-wrapper a {
        height: 80px
    }

    .side-nav .logo-wrapper img {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 7%;
        padding-bottom: 7%
    }
}

.side-nav .about {
    padding: 1rem;
    border-bottom: 1px solid rgba(153, 153, 153, .3)
}

.side-nav .about p {
    margin-bottom: 0;
    text-align: center
}

.side-nav .social {
    padding: 0;
    text-align: center;
    border-bottom: 1px solid rgba(153, 153, 153, .3)
}

.side-nav .social li {
    display: inline-block;
    padding: 0;
    margin: 0
}

.side-nav .social a {
    padding: 0;
    margin: 0;
    padding-right: .6rem;
    padding-left: .6rem
}

.side-nav .social .fa {
    color: #dbe4e7
}

.side-nav .search-form {
    padding: 0
}

.side-nav .search-form input[type=text] {
    border-bottom: 1px solid #fff;
    font-weight: 300;
    padding-left: 30px
}

.side-nav .search-form input[type=text]::-webkit-input-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]:-moz-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]::-moz-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]::-ms-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]::-webkit-input-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]::-moz-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]:-ms-input-placeholder {
    color: #fff !important
}

.side-nav .search-form input[type=text]::placeholder {
    color: #fff !important
}

.side-nav .search-form .form-control {
    margin-bottom: 0
}

.side-nav .sn-avatar-wrapper {
    padding-left: 33%;
    padding-right: 33%;
    padding-top: 10%
}

.side-nav .sn-avatar-wrapper img {
    margin: 0;
    padding: 0;
    max-width: 90px;
    border-radius: 5rem
}

@media only screen and (max-height:992px) {
    .side-nav .sn-avatar-wrapper {
        padding-left: 40%;
        padding-right: 40%;
        padding-top: 3%
    }

    .side-nav .sn-avatar-wrapper img {
        max-width: 50px
    }
}

.side-nav .sn-ad-avatar-wrapper {
    height: auto;
    margin-bottom: 0
}

.side-nav .sn-ad-avatar-wrapper img {
    max-width: 60px;
    padding: 20px 10px;
    float: left
}

.side-nav .sn-ad-avatar-wrapper p {
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0
}

.side-nav .user-box {
    padding: 20px 10px 0 10px
}

.side-nav .user-box img {
    margin: 0 auto 10px auto;
    max-width: 80px
}

.side-nav .fa-angle-down.rotate-icon {
    position: absolute;
    right: 0;
    top: 13px;
    margin-right: 20px
}

.side-nav .sidenav-bg {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 240px;
    z-index: -1;
    background-attachment: fixed
}

.side-nav .sidenav-bg:after {
    width: 100%;
    display: block;
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    margin-bottom: -99999px;
    padding-bottom: 99999px
}

.side-nav.fixed {
    left: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    position: fixed
}

.side-nav.fixed.right-aligned {
    right: 0;
    left: auto
}

@media only screen and (max-width:1440px) {
    .side-nav.fixed {
        -webkit-transform: translateX(-105%);
        -ms-transform: translateX(-105%);
        transform: translateX(-105%)
    }

    .side-nav.fixed.right-aligned {
        -webkit-transform: translateX(105%);
        -ms-transform: translateX(105%);
        transform: translateX(105%)
    }
}

.side-nav.side-nav-light {
    background-color: #e5e5e5
}

.side-nav.side-nav-light a {
    color: #555;
    font-weight: 400
}

.side-nav.side-nav-light .social .fa {
    color: #555
}

.side-nav.side-nav-light .search-form input[type=text] {
    border-bottom-color: rgba(153, 153, 153, .3);
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]::-webkit-input-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]:-moz-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]::-moz-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]::-ms-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]::-webkit-input-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]::-moz-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]:-ms-input-placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .search-form input[type=text]::placeholder {
    color: #555 !important
}

.side-nav.side-nav-light .collapsible-body a {
    background-color: rgba(0, 0, 0, .1)
}

.side-nav.side-nav-light .collapsible li .collapsible-header:hover {
    background-color: rgba(0, 0, 0, .05)
}

.side-nav.side-nav-light .collapsible li .collapsible-header.active {
    color: #4285f4;
    background-color: transparent
}

.drag-target {
    height: 100%;
    width: 10px;
    position: fixed;
    top: 0;
    z-index: 998
}

#sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 120vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 997;
    will-change: opacity
}

.side-nav .double-navbar .bc-min {
    padding: 5px 10px
}

.side-nav .double-navbar .bc-min .button-collapse {
    padding-left: 0
}

.collection-card .stripe {
    position: absolute;
    bottom: 3rem;
    width: 100%;
    text-align: center;
    padding: 1.2rem
}

.collection-card .stripe.dark {
    background-color: rgba(0, 0, 0, .7)
}

.collection-card .stripe.dark a p {
    color: #eee
}

.collection-card .stripe.light {
    background-color: rgba(255, 255, 255, .7)
}

.collection-card .stripe.light a p {
    color: #424242
}

.collection-card .stripe a p {
    padding: 0;
    margin: 0;
    letter-spacing: 4px;
    font-size: 1rem
}

.rating {
    list-style-type: none;
    padding: 0
}

.rating li {
    display: inline-block
}

.no-padding .fa {
    padding: 0
}

.product-panel .modal-dialog,
.ql-modal .modal-dialog {
    max-width: none;
    width: auto;
    margin: 2rem
}

.product-panel .modal-header,
.ql-modal .modal-header {
    text-align: center
}

.product-panel .price .badge,
.ql-modal .price .badge {
    background-color: rgba(244, 67, 54, .7);
    font-size: 1.4rem;
    display: inline-block;
    margin-left: 1rem
}

.product-panel .price p,
.ql-modal .price p {
    display: inline-block;
    font-size: 1.3rem
}

.product-panel .price p .price-before,
.ql-modal .price p .price-before {
    color: #000;
    text-decoration: line-through;
    margin-left: .5rem
}

.product-panel .price p .price-after,
.ql-modal .price p .price-after {
    font-size: 1.7rem;
    color: green
}

.product-panel .media img,
.ql-modal .media img {
    max-width: 60px
}

@media (max-width:1200px) {
    .ql-modal {
        padding-left: 0
    }

    .ql-modal .modal-dialog {
        margin: 0
    }
}

.wishlist {
    background-color: #ff3547
}

.collection-card .stripe {
    position: absolute;
    bottom: 3rem;
    width: 100%;
    text-align: center;
    padding: 1.2rem
}

.collection-card .stripe.dark {
    background-color: rgba(0, 0, 0, .7)
}

.collection-card .stripe.dark a p {
    color: #eee
}

.collection-card .stripe.light {
    background-color: rgba(255, 255, 255, .7)
}

.collection-card .stripe.light a p {
    color: #424242
}

.collection-card .stripe a p {
    padding: 0;
    margin: 0;
    letter-spacing: 4px;
    font-size: 1rem
}

.cart-modal .table,
.cart-modal th {
    text-align: center
}

.cart-modal .table .total {
    text-transform: uppercase;
    font-weight: 600
}

.cart-modal .table .fa-remove {
    color: #4285f4
}

.carousel-multi-item {
    margin-bottom: 100px
}

@media (min-width:768px) {
    .carousel-multi-item .col-md-4 {
        float: left;
        width: 33.333333%;
        max-width: 100%
    }
}

.carousel-multi-item .carousel-item-next,
.carousel-multi-item .carousel-item-prev,
.carousel-multi-item .carousel-item.active {
    display: block
}

.carousel-multi-item .carousel-indicators li {
    height: 20px;
    max-width: 20px;
    background-color: #4285f4;
    margin-bottom: -60px
}

.carousel-multi-item .carousel-indicators .active {
    height: 25px;
    max-width: 25px;
    background-color: #4285f4;
    border-radius: 50%
}

.carousel-multi-item .controls-top {
    text-align: center;
    margin-bottom: 30px
}

.carousel-multi-item .controls-top .btn-floating {
    background: #4285f4
}

.carousel-multi-item .carousel-indicators {
    margin-bottom: -2em
}

.carousel-multi-item .card {
    margin: 1px
}

.carousel-multi-item .card-cascade.narrower {
    margin-top: 20px;
    margin-bottom: 5px
}

@media only screen and (max-width:992px) {
    .carousel-multi-item .carousel-indicators li {
        margin-bottom: -30px
    }
}

.carousel-thumbnails {
    margin-bottom: 80px
}

.carousel-thumbnails .carousel-indicators {
    margin-bottom: -75px;
    position: absolute
}

.carousel-thumbnails .carousel-indicators li {
    height: auto;
    max-width: 100px;
    width: 100px;
    border: none
}

.carousel-thumbnails .carousel-indicators .active {
    height: auto;
    width: auto;
    opacity: .5
}

.carousel-thumbnails .carousel-indicators img {
    max-width: 100px;
    height: 50px;
    overflow: hidden;
    display: block
}

@media only screen and (max-width:600px) {
    .carousel-thumbnails {
        margin-bottom: 60px
    }

    .carousel-thumbnails .carousel-indicators {
        margin-bottom: -60px
    }

    .carousel-thumbnails .carousel-indicators img {
        max-width: 50px;
        height: 25px
    }
}

.stepper li a {
    padding: 24px;
    font-size: 14px;
    text-align: center
}

.stepper li a .circle {
    display: inline-block;
    color: #fff;
    border-radius: 50%;
    background: rgba(0, 0, 0, .38);
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    margin-right: 8px
}

.stepper li a .label {
    display: inline-block;
    color: rgba(0, 0, 0, .38)
}

.stepper li.active a .label,
.stepper li.completed a .label {
    font-weight: 600;
    color: rgba(0, 0, 0, .87)
}

.stepper-horizontal {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.stepper-horizontal li {
    -webkit-transition: .5s;
    transition: .5s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.stepper-horizontal li a .label {
    margin-top: 10px
}

.stepper-horizontal li:not(:last-child):after {
    content: '';
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 8px 0 0 0;
    height: 1px;
    background-color: rgba(0, 0, 0, .1)
}

.stepper-horizontal li:not(:first-child):before {
    content: '';
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 8px 0 0 0;
    height: 1px;
    background-color: rgba(0, 0, 0, .1)
}

.stepper-horizontal li:hover {
    background: #ddd
}

.stepper-vertical {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.stepper-vertical li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
}

.stepper-vertical li a {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.stepper-vertical li a .circle {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1
}

.stepper-vertical li a .label {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2
}

.stepper-vertical li.completed a .label {
    font-weight: 500
}

.stepper-vertical li .step-content {
    display: block;
    margin-top: 0;
    margin-left: 50px;
    padding: 15px
}

.stepper-vertical li .step-content p {
    font-size: .875rem
}

.stepper-vertical li:not(:last-child):after {
    content: " ";
    position: absolute;
    width: 1px;
    height: -webkit-calc(100% - 40px);
    height: calc(100% - 40px);
    left: 35px;
    top: 55px;
    background-color: rgba(0, 0, 0, .1)
}

.author-box {
    padding: 3rem
}

@media (max-width:601px) {
    .author-box {
        text-align: center
    }

    .author-box img {
        margin-bottom: 2rem;
        display: inline
    }
}

.author-box strong {
    font-weight: 600
}

.comments-list,
.reply-form {
    margin-bottom: 3rem
}

.comments-list ul,
.reply-form ul {
    list-style-type: none
}

.comments-list img,
.reply-form img {
    max-width: 100px;
    border-radius: 50%
}

.comments-list .row,
.reply-form .row {
    margin-bottom: 2rem
}

.comments-list .user-name,
.reply-form .user-name {
    color: #000
}

.comments-list .comment-date,
.reply-form .comment-date {
    color: #666
}

.comments-list .comment-text,
.reply-form .comment-text {
    color: #9e9e9e
}

.comments-list h4,
.reply-form h4 {
    text-align: center
}

@media (max-width:601px) {

    .comments-list,
    .reply-form {
        text-align: center
    }

    .comments-list img,
    .reply-form img {
        display: inline;
        margin-bottom: 1rem
    }

    .comments-list .md-form,
    .reply-form .md-form {
        text-align: left
    }
}

.comments-list .text-center,
.reply-form .text-center {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.section-blog-fw .author-box,
.section-blog-fw .jumbotron {
    text-align: center
}

.section-blog-fw h2 {
    margin-bottom: 1rem
}

.section-blog-fw h2 a {
    color: #424242;
    -webkit-transition: .2s;
    transition: .2s
}

.section-blog-fw h2 a:hover {
    color: #616161;
    -webkit-transition: .2s;
    transition: .2s
}

.personal-sm {
    margin-bottom: 10px
}

.personal-sm a {
    padding: 5px
}

.personal-sm a:first-of-type {
    padding-left: 0
}

.personal-sm a .fa {
    -webkit-transition-duration: .2s;
    transition-duration: .2s
}

.personal-sm a:hover .fa {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.toast-title {
    font-weight: 700
}

.toast-message {
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

.toast-message a:hover {
    color: #ccc;
    text-decoration: none
}

.toast-close-button {
    position: relative;
    right: -.3em;
    top: -.3em;
    float: right;
    font-size: 20px;
    font-weight: 700;
    -webkit-text-shadow: 0 1px 0 #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .8
}

.toast-close-button:focus,
.toast-close-button:hover {
    text-decoration: none;
    cursor: pointer;
    opacity: .4
}

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%
}

.toast-top-left {
    top: 12px;
    left: 12px
}

.toast-top-right {
    top: 12px;
    right: 12px
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px
}

#toast-container {
    position: fixed;
    z-index: 999999
}

#toast-container * {
    box-sizing: border-box
}

#toast-container>div {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    background-position: 15px center;
    background-repeat: no-repeat;
    opacity: .95
}

#toast-container>:hover {
    -webkit-transition: .45s;
    transition: .45s;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    opacity: 1;
    cursor: pointer
}

#toast-container.toast-bottom-center>div,
#toast-container.toast-top-center>div {
    width: 300px;
    margin: auto
}

#toast-container.toast-bottom-full-width>div,
#toast-container.toast-top-full-width>div {
    width: 96%;
    margin: auto
}

.toast {
    background-color: #030303
}

.toast-success {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important
}

.toast-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important
}

.toast-info {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important
}

.toast-warning {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000;
    opacity: .4
}

@media all and (max-width:240px) {
    #toast-container>div {
        padding: 8px 8px 8px 50px;
        width: 11em
    }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }
}

@media all and (min-width:241px) and (max-width:480px) {
    #toast-container>div {
        padding: 8px 8px 8px 50px;
        width: 18em
    }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }
}

@media all and (min-width:481px) and (max-width:768px) {
    #toast-container>div {
        padding: 15px 15px 15px 50px;
        width: 25em
    }
}

.collapsible .active .rotate-icon {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 150ms ease-in 0s;
    transition: all 150ms ease-in 0s
}

.min-chart {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 110px;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center
}

.min-chart canvas {
    position: absolute;
    top: 0;
    left: 0
}

.min-chart .percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2
}

.min-chart .percent:after {
    content: '%';
    margin-left: .1em;
    font-size: .8em
}

.progress {
    box-shadow: none;
    position: relative;
    display: block;
    width: 100%;
    height: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    background-color: #eee
}

.progress .progress-bar {
    box-shadow: none;
    height: 4px;
    border-radius: 0;
    background-color: #0d47a1
}

.progress .progress-bar-animated {
    -webkit-transition: width 2s ease-in-out;
    transition: width 2s ease-in-out
}

.progress .indeterminate {
    background-color: #90caf9
}

.progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(.65, .815, .735, .395) infinite;
    animation: indeterminate 2.1s cubic-bezier(.65, .815, .735, .395) infinite
}

.progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(.165, .84, .44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(.165, .84, .44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s
}

@-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}

.preloader-wrapper {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px
}

.preloader-wrapper.small {
    width: 36px;
    height: 36px
}

.preloader-wrapper.big {
    width: 64px;
    height: 64px
}

.preloader-wrapper.active {
    -webkit-animation: container-rotate 1568ms linear infinite;
    animation: container-rotate 1568ms linear infinite
}

.preloader-wrapper.crazy {
    -webkit-animation-duration: 333ms;
    animation-duration: 333ms
}

.preloader-wrapper .spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0
}

.preloader-wrapper .spinner-layer .gap-patch {
    position: absolute;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit
}

.preloader-wrapper .spinner-layer .gap-patch .circle {
    width: 1000%;
    left: -450%
}

.preloader-wrapper .spinner-layer .circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit
}

.preloader-wrapper .spinner-layer .circle-clipper .circle {
    width: 200%;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0
}

.preloader-wrapper .spinner-layer .circle-clipper.left .circle {
    left: 0;
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    -ms-transform: rotate(129deg);
    transform: rotate(129deg)
}

.preloader-wrapper .spinner-layer .circle-clipper.right .circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    -ms-transform: rotate(-129deg);
    transform: rotate(-129deg)
}

.preloader-wrapper .spinner-blue,
.preloader-wrapper .spinner-blue-only {
    border-color: #4285f4
}

.preloader-wrapper .spinner-red,
.preloader-wrapper .spinner-red-only {
    border-color: #db4437
}

.preloader-wrapper .spinner-yellow,
.preloader-wrapper .spinner-yellow-only {
    border-color: #f4b400
}

.preloader-wrapper .spinner-green,
.preloader-wrapper .spinner-green-only {
    border-color: #0f9d58
}

@-webkit-keyframes container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.active .spinner-layer {
    opacity: 1;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both
}

.active .spinner-layer.spinner-blue {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both
}

.active .spinner-layer.spinner-red {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both
}

.active .spinner-layer.spinner-yellow {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both
}

.active .spinner-layer.spinner-green {
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(.4, 0, .2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(.4, 0, .2, 1) infinite both
}

.active .circle-clipper.left .circle {
    -webkit-animation: left-spin 1333ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: left-spin 1333ms cubic-bezier(.4, 0, .2, 1) infinite both
}

.active .circle-clipper.right .circle {
    -webkit-animation: right-spin 1333ms cubic-bezier(.4, 0, .2, 1) infinite both;
    animation: right-spin 1333ms cubic-bezier(.4, 0, .2, 1) infinite both
}

@-webkit-keyframes fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg)
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg)
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg)
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg)
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg)
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg)
    }

    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg)
    }
}

@keyframes fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg)
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg)
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg)
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg)
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg)
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg)
    }

    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg)
    }
}

@-webkit-keyframes blue-fade-in-out {
    from {
        opacity: 1
    }

    25% {
        opacity: 1
    }

    26% {
        opacity: 0
    }

    89% {
        opacity: 0
    }

    90% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

@keyframes blue-fade-in-out {
    from {
        opacity: 1
    }

    25% {
        opacity: 1
    }

    26% {
        opacity: 0
    }

    89% {
        opacity: 0
    }

    90% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes red-fade-in-out {
    from {
        opacity: 0
    }

    15% {
        opacity: 0
    }

    25% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    51% {
        opacity: 0
    }
}

@keyframes red-fade-in-out {
    from {
        opacity: 0
    }

    15% {
        opacity: 0
    }

    25% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    51% {
        opacity: 0
    }
}

@-webkit-keyframes yellow-fade-in-out {
    from {
        opacity: 0
    }

    40% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: 1
    }

    76% {
        opacity: 0
    }
}

@keyframes yellow-fade-in-out {
    from {
        opacity: 0
    }

    40% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: 1
    }

    76% {
        opacity: 0
    }
}

@-webkit-keyframes green-fade-in-out {
    from {
        opacity: 0
    }

    65% {
        opacity: 0
    }

    75% {
        opacity: 1
    }

    90% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes green-fade-in-out {
    from {
        opacity: 0
    }

    65% {
        opacity: 0
    }

    75% {
        opacity: 1
    }

    90% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg)
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }

    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg)
    }
}

@keyframes left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg)
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }

    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg)
    }
}

@-webkit-keyframes right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg)
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg)
    }

    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg)
    }
}

@keyframes right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg)
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg)
    }

    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg)
    }
}

#spinnerContainer.cooldown {
    -webkit-animation: container-rotate 1568ms linear infinite, fade-out .4s cubic-bezier(.4, 0, .2, 1);
    animation: container-rotate 1568ms linear infinite, fade-out .4s cubic-bezier(.4, 0, .2, 1)
}

@-webkit-keyframes fade-out {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fade-out {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

#mdb-preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 9998;
    height: 100%;
    width: 100%
}

.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important
}

.ps.ps--active-x>.ps__scrollbar-x-rail,
.ps.ps--active-y>.ps__scrollbar-y-rail {
    display: block;
    background-color: transparent
}

.ps.ps--active-y>.ps__scrollbar-y-rail {
    top: 0 !important
}

.ps.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail {
    background-color: #eee;
    opacity: .9
}

.ps.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail>.ps__scrollbar-x {
    background-color: #999;
    height: 11px
}

.ps>.ps__scrollbar-x-rail {
    display: none;
    position: absolute;
    opacity: 0;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 0;
    height: 15px
}

.ps>.ps__scrollbar-x-rail>.ps__scrollbar-x {
    position: absolute;
    background-color: #aaa;
    border-radius: 6px;
    -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    bottom: 2px;
    height: 6px
}

.ps>.ps__scrollbar-x-rail:hover>.ps__scrollbar-x,
.ps>.ps__scrollbar-x-rail>.ps__scrollbar-x-rail:active>.ps__scrollbar-x {
    height: 11px
}

.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail {
    background-color: #eee;
    opacity: .9
}

.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    background-color: #999;
    width: 11px
}

.ps>.ps__scrollbar-y-rail {
    display: none;
    position: absolute;
    opacity: 0;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    width: 15px;
    z-index: 999
}

.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    position: absolute;
    background-color: #aaa;
    border-radius: 6px;
    -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    right: 2px;
    width: 6px
}

.ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y-rail:active>.ps__scrollbar-y {
    width: 11px
}

.ps:hover.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail {
    background-color: #eee;
    opacity: .9
}

.ps:hover.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail>.ps__scrollbar-x {
    background-color: #999;
    height: 11px
}

.ps:hover.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail {
    background-color: #eee;
    opacity: .9
}

.ps:hover.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    background-color: #999;
    width: 11px
}

.ps:hover>.ps__scrollbar-x-rail,
.ps:hover>.ps__scrollbar-y-rail {
    opacity: .6
}

.ps:hover>.ps__scrollbar-x-rail:hover {
    background-color: #eee;
    opacity: .9
}

.ps:hover>.ps__scrollbar-x-rail:hover>.ps__scrollbar-x {
    background-color: #999
}

.ps:hover>.ps__scrollbar-y-rail:hover {
    background-color: #eee;
    opacity: .9
}

.ps:hover>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
    background-color: #999
}

/* .sn-bg-1{
    background-image:url(https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg)
}
.sn-bg-2{
    background-image:url(https://mdbootstrap.com/img/Photos/Others/sidenav2.jpg)
}
.sn-bg-3{
    background-image:url(https://mdbootstrap.com/img/Photos/Others/sidenav3.jpg)
}
.sn-bg-4{
    background-image:url(https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg)
} */
.white-skin .gradient {
    background: #ababab;
    background: -webkit-linear-gradient(-45deg, #ababab 0, #fff 100%);
    background: -webkit-linear-gradient(315deg, #ababab 0, #fff 100%);
    background: linear-gradient(135deg, #ababab 0, #fff 100%)
}

.stepper li.active a .white-skin .circle,
.stepper li.completed a .white-skin .circle,
.white-skin .primary-color,
.white-skin .stepper li.active a .circle,
.white-skin .stepper li.completed a .circle {
    background-color: #4285f4 !important
}

.white-skin .navbar {
    background-color: #fff;
    color: #424242
}

.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #2a75f3
}

.white-skin .navbar .navbar-nav .nav-item a {
    color: #424242
}

.white-skin .navbar.double-nav a {
    color: #424242
}

.white-skin .page-footer {
    background-color: #6f7178
}

.white-skin .side-nav {
    background-color: #f9f9f9
}

.white-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.white-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #0d5bdd
}

.white-skin .side-nav .social a .fa {
    color: #424242
}

.white-skin .side-nav .social a:hover .fa {
    color: #4285f4 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.white-skin .side-nav .search-form input[type=text] {
    color: #424242 !important;
    border-bottom: 1px solid rgba(153, 153, 153, .3)
}

.white-skin .side-nav .search-form input[type=text]::-webkit-input-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]:-moz-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]::-moz-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]::-ms-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]::-webkit-input-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]::-moz-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]:-ms-input-placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .search-form input[type=text]::placeholder {
    color: rgba(66, 66, 66, .5) !important
}

.white-skin .side-nav .collapsible li {
    background-color: transparent
}

.white-skin .side-nav .collapsible li a {
    font-weight: 400
}

.white-skin .side-nav .collapsible li .collapsible-header {
    color: #424242;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.white-skin .side-nav .collapsible li .collapsible-header.active {
    color: #4285f4;
    background-color: transparent
}

.white-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(0, 0, 0, .05)
}

.white-skin .side-nav .collapsible li .collapsible-body a {
    color: #424242
}

.white-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #4285f4
}

.white-skin .side-nav .collapsible a {
    color: #424242;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.white-skin .side-nav .collapsible a:hover {
    background-color: rgba(0, 0, 0, .05)
}

.white-skin .side-nav .fa {
    color: #424242
}

.white-skin .side-nav .sidenav-bg.mask-strong:after,
.white-skin .side-nav .sidenav-bg:after {
    background: rgba(255, 255, 255, .8)
}

.white-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(255, 255, 255, .65)
}

.white-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(255, 255, 255, .5)
}

.white-skin .btn-primary {
    background-color: #4285f4 !important;
    color: #fff !important
}

.white-skin .btn-primary:hover {
    background-color: #5a95f5
}

.white-skin .btn-primary.active,
.white-skin .btn-primary:active,
.white-skin .btn-primary:focus {
    background-color: #1266f1
}

.white-skin .btn-primary.dropdown-toggle {
    background-color: #4285f4 !important
}

.white-skin .btn-primary.dropdown-toggle:focus,
.white-skin .btn-primary.dropdown-toggle:hover {
    background-color: #5a95f5 !important
}

.show>.white-skin .btn-primary.dropdown-toggle,
.white-skin .btn-primary:not([disabled]):not(.disabled).active,
.white-skin .btn-primary:not([disabled]):not(.disabled):active {
    background-color: #4285f4 !important
}

.white-skin .btn-secondary {
    background-color: #fc685f !important;
    color: #fff !important
}

.white-skin .btn-secondary:hover {
    background-color: #fc8078
}

.white-skin .btn-secondary.active,
.white-skin .btn-secondary:active,
.white-skin .btn-secondary:focus {
    background-color: #fb392d
}

.white-skin .btn-secondary.dropdown-toggle {
    background-color: #fc685f !important
}

.white-skin .btn-secondary.dropdown-toggle:focus,
.white-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #fc8078 !important
}

.show>.white-skin .btn-secondary.dropdown-toggle,
.white-skin .btn-secondary:not([disabled]):not(.disabled).active,
.white-skin .btn-secondary:not([disabled]):not(.disabled):active {
    background-color: #fc685f !important
}

.white-skin .btn-default {
    background-color: #454545 !important;
    color: #fff !important
}

.white-skin .btn-default:hover {
    background-color: #525252
}

.white-skin .btn-default.active,
.white-skin .btn-default:active,
.white-skin .btn-default:focus {
    background-color: #2c2c2c
}

.white-skin .btn-default.dropdown-toggle {
    background-color: #454545 !important
}

.white-skin .btn-default.dropdown-toggle:focus,
.white-skin .btn-default.dropdown-toggle:hover {
    background-color: #525252 !important
}

.show>.white-skin .btn-default.dropdown-toggle,
.white-skin .btn-default:not([disabled]):not(.disabled).active,
.white-skin .btn-default:not([disabled]):not(.disabled):active {
    background-color: #454545 !important
}

.white-skin .card .btn-action {
    background: #454545
}

.white-skin .card .btn-action:focus,
.white-skin .card .btn-action:hover {
    background-color: #525252 !important
}

.white-skin .card .btn-action.active {
    background-color: #121212 !important
}

.white-skin input[type=email]:focus:not([readonly]),
.white-skin input[type=password]:focus:not([readonly]),
.white-skin input[type=text]:focus:not([readonly]),
.white-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #4285f4;
    box-shadow: 0 1px 0 0 #4285f4
}

.white-skin input[type=email]:focus:not([readonly])+label,
.white-skin input[type=password]:focus:not([readonly])+label,
.white-skin input[type=text]:focus:not([readonly])+label,
.white-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #4285f4
}

.white-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #4285f4;
    border-bottom: 2px solid #4285f4
}

.white-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.white-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #4285f4;
    border-color: #4285f4
}

.white-skin .md-form .prefix.active {
    color: #4285f4
}

.white-skin .dropdown-content li:not(.disabled) span {
    color: #4285f4
}

.white-skin .top-nav-collapse {
    background-color: #fff
}

.white-skin .carousel-multi-item .carousel-indicators li,
.white-skin .carousel-multi-item .carousel-indicators li.active,
.white-skin .carousel-multi-item .controls-top>a {
    background-color: #4285f4
}

.white-skin .card-header,
.white-skin .form-header {
    background-color: #4c8bf5
}

.white-skin .spinner-primary-color,
.white-skin .spinner-primary-color-only {
    border-color: #4285f4
}

.white-skin .pagination-primary-color .page-item.active .page-link,
.white-skin .pagination-primary-color .page-item.active .page-link:focus,
.white-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #4285f4;
    border-color: #4285f4
}

.white-skin .pagination-primary-color .page-link {
    color: #4285f4
}

.black-skin .gradient {
    background: #000;
    background: -webkit-linear-gradient(-45deg, #000 0, #616161 100%);
    background: -webkit-linear-gradient(315deg, #000 0, #616161 100%);
    background: linear-gradient(135deg, #000 0, #616161 100%)
}

.black-skin .primary-color,
.black-skin .stepper li.active a .circle,
.black-skin .stepper li.completed a .circle,
.stepper li.active a .black-skin .circle,
.stepper li.completed a .black-skin .circle {
    background-color: #2ad1a3 !important
}

.black-skin .navbar {
    background-color: #222;
    color: #fff
}

.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #26bc92
}

.black-skin .page-footer {
    background-color: #222
}

.black-skin .side-nav {
    background-color: #222
}

.black-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.black-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #1d9171
}

.black-skin .side-nav .social a:hover .fa {
    color: #2ad1a3 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.black-skin .side-nav .collapsible li {
    background-color: transparent
}

.black-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.black-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: #2ad1a3
}

.black-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: #2ad1a3
}

.black-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.black-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #2ad1a3
}

.black-skin .side-nav .fa {
    color: #fff
}

.black-skin .side-nav .sidenav-bg.mask-strong:after,
.black-skin .side-nav .sidenav-bg:after {
    background: rgba(0, 0, 0, .8)
}

.black-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(0, 0, 0, .65)
}

.black-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(0, 0, 0, .5)
}

.black-skin .btn-primary {
    background-color: #2ad1a3 !important;
    color: #fff !important
}

.black-skin .btn-primary:hover {
    background-color: #3dd8ad
}

.black-skin .btn-primary.active,
.black-skin .btn-primary:active,
.black-skin .btn-primary:focus {
    background-color: #21a782
}

.black-skin .btn-primary.dropdown-toggle {
    background-color: #2ad1a3 !important
}

.black-skin .btn-primary.dropdown-toggle:focus,
.black-skin .btn-primary.dropdown-toggle:hover {
    background-color: #3dd8ad !important
}

.black-skin .btn-primary:not([disabled]):not(.disabled).active,
.black-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.black-skin .btn-primary.dropdown-toggle {
    background-color: #2ad1a3 !important
}

.black-skin .btn-secondary {
    background-color: #0f478a !important;
    color: #fff !important
}

.black-skin .btn-secondary:hover {
    background-color: #1253a1
}

.black-skin .btn-secondary.active,
.black-skin .btn-secondary:active,
.black-skin .btn-secondary:focus {
    background-color: #0a2f5c
}

.black-skin .btn-secondary.dropdown-toggle {
    background-color: #0f478a !important
}

.black-skin .btn-secondary.dropdown-toggle:focus,
.black-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #1253a1 !important
}

.black-skin .btn-secondary:not([disabled]):not(.disabled).active,
.black-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.black-skin .btn-secondary.dropdown-toggle {
    background-color: #0f478a !important
}

.black-skin .btn-default {
    background-color: #222 !important;
    color: #fff !important
}

.black-skin .btn-default:hover {
    background-color: #2f2f2f
}

.black-skin .btn-default.active,
.black-skin .btn-default:active,
.black-skin .btn-default:focus {
    background-color: #090909
}

.black-skin .btn-default.dropdown-toggle {
    background-color: #222 !important
}

.black-skin .btn-default.dropdown-toggle:focus,
.black-skin .btn-default.dropdown-toggle:hover {
    background-color: #2f2f2f !important
}

.black-skin .btn-default:not([disabled]):not(.disabled).active,
.black-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.black-skin .btn-default.dropdown-toggle {
    background-color: #222 !important
}

.black-skin .card .btn-action {
    background: #222
}

.black-skin .card .btn-action:focus,
.black-skin .card .btn-action:hover {
    background-color: #2f2f2f !important
}

.black-skin .card .btn-action.active {
    background-color: #000 !important
}

.black-skin input[type=email]:focus:not([readonly]),
.black-skin input[type=password]:focus:not([readonly]),
.black-skin input[type=text]:focus:not([readonly]),
.black-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #2ad1a3;
    box-shadow: 0 1px 0 0 #2ad1a3
}

.black-skin input[type=email]:focus:not([readonly])+label,
.black-skin input[type=password]:focus:not([readonly])+label,
.black-skin input[type=text]:focus:not([readonly])+label,
.black-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #2ad1a3
}

.black-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #2ad1a3;
    border-bottom: 2px solid #2ad1a3
}

.black-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.black-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #2ad1a3;
    border-color: #2ad1a3
}

.black-skin .md-form .prefix.active {
    color: #2ad1a3
}

.black-skin .dropdown-content li:not(.disabled) span {
    color: #2ad1a3
}

.black-skin .top-nav-collapse {
    background-color: #222
}

.black-skin .carousel-multi-item .carousel-indicators li,
.black-skin .carousel-multi-item .carousel-indicators li.active,
.black-skin .carousel-multi-item .controls-top>a {
    background-color: #2ad1a3
}

.black-skin .card-header,
.black-skin .form-header {
    background-color: #30d5a8
}

.black-skin .spinner-primary-color,
.black-skin .spinner-primary-color-only {
    border-color: #2ad1a3
}

.black-skin .pagination-primary-color .page-item.active .page-link,
.black-skin .pagination-primary-color .page-item.active .page-link:focus,
.black-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #2ad1a3;
    border-color: #2ad1a3
}

.black-skin .pagination-primary-color .page-link {
    color: #2ad1a3
}

.cyan-skin .gradient {
    background: #114861;
    background: -webkit-linear-gradient(-45deg, #114861 0, #45b0c0 100%);
    background: -webkit-linear-gradient(315deg, #114861 0, #45b0c0 100%);
    background: linear-gradient(135deg, #114861 0, #45b0c0 100%)
}

.cyan-skin .primary-color,
.cyan-skin .stepper li.active a .circle,
.cyan-skin .stepper li.completed a .circle,
.stepper li.active a .cyan-skin .circle,
.stepper li.completed a .cyan-skin .circle {
    background-color: #4fada7 !important
}

.cyan-skin .navbar {
    background-color: #114861;
    color: #fff
}

.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #eea82f
}

.cyan-skin .page-footer {
    background-color: #114861
}

.cyan-skin .side-nav {
    background-color: #24879d
}

.cyan-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.cyan-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #d99012
}

.cyan-skin .side-nav .social a:hover .fa {
    color: #f0b247 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.cyan-skin .side-nav .collapsible li {
    background-color: transparent
}

.cyan-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.cyan-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(29, 65, 82, .8)
}

.cyan-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(29, 65, 82, .8)
}

.cyan-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.cyan-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #ffa742
}

.cyan-skin .side-nav .fa {
    color: #fff
}

.cyan-skin .side-nav .sidenav-bg.mask-strong:after,
.cyan-skin .side-nav .sidenav-bg:after {
    background: rgba(21, 78, 96, .8)
}

.cyan-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(21, 78, 96, .65)
}

.cyan-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(21, 78, 96, .5)
}

.cyan-skin .btn-primary {
    background-color: #ffa742 !important;
    color: #fff !important
}

.cyan-skin .btn-primary:hover {
    background-color: #ffb35c
}

.cyan-skin .btn-primary.active,
.cyan-skin .btn-primary:active,
.cyan-skin .btn-primary:focus {
    background-color: #ff8f0f
}

.cyan-skin .btn-primary.dropdown-toggle {
    background-color: #ffa742 !important
}

.cyan-skin .btn-primary.dropdown-toggle:focus,
.cyan-skin .btn-primary.dropdown-toggle:hover {
    background-color: #ffb35c !important
}

.cyan-skin .btn-primary:not([disabled]):not(.disabled).active,
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.cyan-skin .btn-primary.dropdown-toggle {
    background-color: #ffa742 !important
}

.cyan-skin .btn-secondary {
    background-color: #45b0c0 !important;
    color: #fff !important
}

.cyan-skin .btn-secondary:hover {
    background-color: #58b8c6
}

.cyan-skin .btn-secondary.active,
.cyan-skin .btn-secondary:active,
.cyan-skin .btn-secondary:focus {
    background-color: #358f9d
}

.cyan-skin .btn-secondary.dropdown-toggle {
    background-color: #45b0c0 !important
}

.cyan-skin .btn-secondary.dropdown-toggle:focus,
.cyan-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #58b8c6 !important
}

.cyan-skin .btn-secondary:not([disabled]):not(.disabled).active,
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.cyan-skin .btn-secondary.dropdown-toggle {
    background-color: #45b0c0 !important
}

.cyan-skin .btn-default {
    background-color: #114861 !important;
    color: #fff !important
}

.cyan-skin .btn-default:hover {
    background-color: #155877
}

.cyan-skin .btn-default.active,
.cyan-skin .btn-default:active,
.cyan-skin .btn-default:focus {
    background-color: #092836
}

.cyan-skin .btn-default.dropdown-toggle {
    background-color: #114861 !important
}

.cyan-skin .btn-default.dropdown-toggle:focus,
.cyan-skin .btn-default.dropdown-toggle:hover {
    background-color: #155877 !important
}

.cyan-skin .btn-default:not([disabled]):not(.disabled).active,
.cyan-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.cyan-skin .btn-default.dropdown-toggle {
    background-color: #114861 !important
}

.cyan-skin .card .btn-action {
    background: #114861
}

.cyan-skin .card .btn-action:focus,
.cyan-skin .card .btn-action:hover {
    background-color: #155877 !important
}

.cyan-skin .card .btn-action.active {
    background-color: #02080a !important
}

.cyan-skin input[type=email]:focus:not([readonly]),
.cyan-skin input[type=password]:focus:not([readonly]),
.cyan-skin input[type=text]:focus:not([readonly]),
.cyan-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #f0b247;
    box-shadow: 0 1px 0 0 #f0b247
}

.cyan-skin input[type=email]:focus:not([readonly])+label,
.cyan-skin input[type=password]:focus:not([readonly])+label,
.cyan-skin input[type=text]:focus:not([readonly])+label,
.cyan-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #f0b247
}

.cyan-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #f0b247;
    border-bottom: 2px solid #f0b247
}

.cyan-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.cyan-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #f0b247;
    border-color: #f0b247
}

.cyan-skin .md-form .prefix.active {
    color: #f0b247
}

.cyan-skin .dropdown-content li:not(.disabled) span {
    color: #f0b247
}

.cyan-skin .top-nav-collapse {
    background-color: #114861
}

.cyan-skin .carousel-multi-item .carousel-indicators li,
.cyan-skin .carousel-multi-item .carousel-indicators li.active,
.cyan-skin .carousel-multi-item .controls-top>a {
    background-color: #f0b247
}

.cyan-skin .card-header,
.cyan-skin .form-header {
    background-color: #f1b650
}

.cyan-skin .spinner-primary-color,
.cyan-skin .spinner-primary-color-only {
    border-color: #4fada7
}

.cyan-skin .pagination-primary-color .page-item.active .page-link,
.cyan-skin .pagination-primary-color .page-item.active .page-link:focus,
.cyan-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #4fada7;
    border-color: #4fada7
}

.cyan-skin .pagination-primary-color .page-link {
    color: #4fada7
}

.mdb-skin .gradient {
    background: #0e2135;
    background: -webkit-linear-gradient(-45deg, #0e2135 0, #2c76b8 100%);
    background: -webkit-linear-gradient(315deg, #0e2135 0, #2c76b8 100%);
    background: linear-gradient(135deg, #0e2135 0, #2c76b8 100%)
}

.mdb-skin .primary-color,
.mdb-skin .stepper li.active a .circle,
.mdb-skin .stepper li.completed a .circle,
.stepper li.active a .mdb-skin .circle,
.stepper li.completed a .mdb-skin .circle {
    background-color: #33b5e5 !important
}

.mdb-skin .navbar {
    background-color: #243a51;
    color: #fff
}

.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #1dade2
}

.mdb-skin .page-footer {
    background-color: #243a51
}

.mdb-skin .side-nav {
    background-color: #224562
}

.mdb-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.mdb-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #178ab4
}

.mdb-skin .side-nav .social a:hover .fa {
    color: #33b5e5 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.mdb-skin .side-nav .collapsible li {
    background-color: transparent
}

.mdb-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.mdb-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(72, 198, 255, .8)
}

.mdb-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(72, 198, 255, .8)
}

.mdb-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.mdb-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #67d5ff
}

.mdb-skin .side-nav .fa {
    color: #fff
}

.mdb-skin .side-nav .sidenav-bg.mask-strong:after,
.mdb-skin .side-nav .sidenav-bg:after {
    background: rgba(13, 36, 60, .8)
}

.mdb-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(13, 36, 60, .65)
}

.mdb-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(13, 36, 60, .5)
}

.mdb-skin .btn-primary {
    background-color: #33b5e5 !important;
    color: #fff !important
}

.mdb-skin .btn-primary:hover {
    background-color: #4abde8
}

.mdb-skin .btn-primary.active,
.mdb-skin .btn-primary:active,
.mdb-skin .btn-primary:focus {
    background-color: #1a9bcb
}

.mdb-skin .btn-primary.dropdown-toggle {
    background-color: #33b5e5 !important
}

.mdb-skin .btn-primary.dropdown-toggle:focus,
.mdb-skin .btn-primary.dropdown-toggle:hover {
    background-color: #4abde8 !important
}

.mdb-skin .btn-primary:not([disabled]):not(.disabled).active,
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.mdb-skin .btn-primary.dropdown-toggle {
    background-color: #33b5e5 !important
}

.mdb-skin .btn-secondary {
    background-color: #0e3d67 !important;
    color: #fff !important
}

.mdb-skin .btn-secondary:hover {
    background-color: #114a7d
}

.mdb-skin .btn-secondary.active,
.mdb-skin .btn-secondary:active,
.mdb-skin .btn-secondary:focus {
    background-color: #08223a
}

.mdb-skin .btn-secondary.dropdown-toggle {
    background-color: #0e3d67 !important
}

.mdb-skin .btn-secondary.dropdown-toggle:focus,
.mdb-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #114a7d !important
}

.mdb-skin .btn-secondary:not([disabled]):not(.disabled).active,
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.mdb-skin .btn-secondary.dropdown-toggle {
    background-color: #0e3d67 !important
}

.mdb-skin .btn-default {
    background-color: #1a6398 !important;
    color: #fff !important
}

.mdb-skin .btn-default:hover {
    background-color: #1e71ae
}

.mdb-skin .btn-default.active,
.mdb-skin .btn-default:active,
.mdb-skin .btn-default:focus {
    background-color: #13476c
}

.mdb-skin .btn-default.dropdown-toggle {
    background-color: #1a6398 !important
}

.mdb-skin .btn-default.dropdown-toggle:focus,
.mdb-skin .btn-default.dropdown-toggle:hover {
    background-color: #1e71ae !important
}

.mdb-skin .btn-default:not([disabled]):not(.disabled).active,
.mdb-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.mdb-skin .btn-default.dropdown-toggle {
    background-color: #1a6398 !important
}

.mdb-skin .card .btn-action {
    background: #1a6398
}

.mdb-skin .card .btn-action:focus,
.mdb-skin .card .btn-action:hover {
    background-color: #1e71ae !important
}

.mdb-skin .card .btn-action.active {
    background-color: #0b2a41 !important
}

.mdb-skin input[type=email]:focus:not([readonly]),
.mdb-skin input[type=password]:focus:not([readonly]),
.mdb-skin input[type=text]:focus:not([readonly]),
.mdb-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #33b5e5;
    box-shadow: 0 1px 0 0 #33b5e5
}

.mdb-skin input[type=email]:focus:not([readonly])+label,
.mdb-skin input[type=password]:focus:not([readonly])+label,
.mdb-skin input[type=text]:focus:not([readonly])+label,
.mdb-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #33b5e5
}

.mdb-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #33b5e5;
    border-bottom: 2px solid #33b5e5
}

.mdb-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.mdb-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #33b5e5;
    border-color: #33b5e5
}

.mdb-skin .md-form .prefix.active {
    color: #33b5e5
}

.mdb-skin .dropdown-content li:not(.disabled) span {
    color: #33b5e5
}

.mdb-skin .top-nav-collapse {
    background-color: #243a51
}

.mdb-skin .carousel-multi-item .carousel-indicators li,
.mdb-skin .carousel-multi-item .carousel-indicators li.active,
.mdb-skin .carousel-multi-item .controls-top>a {
    background-color: #33b5e5
}

.mdb-skin .card-header,
.mdb-skin .form-header {
    background-color: #3cb8e6
}

.mdb-skin .spinner-primary-color,
.mdb-skin .spinner-primary-color-only {
    border-color: #33b5e5
}

.mdb-skin .pagination-primary-color .page-item.active .page-link,
.mdb-skin .pagination-primary-color .page-item.active .page-link:focus,
.mdb-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #33b5e5;
    border-color: #33b5e5
}

.mdb-skin .pagination-primary-color .page-link {
    color: #33b5e5
}

.deep-purple-skin .gradient {
    background: #2e1f49;
    background: -webkit-linear-gradient(-45deg, #2e1f49 0, #a47fe3 100%);
    background: -webkit-linear-gradient(315deg, #2e1f49 0, #a47fe3 100%);
    background: linear-gradient(135deg, #2e1f49 0, #a47fe3 100%)
}

.deep-purple-skin .primary-color,
.deep-purple-skin .stepper li.active a .circle,
.deep-purple-skin .stepper li.completed a .circle,
.stepper li.active a .deep-purple-skin .circle,
.stepper li.completed a .deep-purple-skin .circle {
    background-color: #d0637c !important
}

.deep-purple-skin .navbar {
    background-color: #7f7e91;
    color: #fff
}

.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #9d6eed
}

.deep-purple-skin .page-footer {
    background-color: #7f7e91
}

.deep-purple-skin .side-nav {
    background-color: #372e5f
}

.deep-purple-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.deep-purple-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #7e41e8
}

.deep-purple-skin .side-nav .social a:hover .fa {
    color: #ac85f0 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.deep-purple-skin .side-nav .collapsible li {
    background-color: transparent
}

.deep-purple-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.deep-purple-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(176, 139, 240, .5)
}

.deep-purple-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(176, 139, 240, .5)
}

.deep-purple-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.deep-purple-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #c9abfc
}

.deep-purple-skin .side-nav .fa {
    color: #fff
}

.deep-purple-skin .side-nav .sidenav-bg.mask-strong:after,
.deep-purple-skin .side-nav .sidenav-bg:after {
    background: rgba(36, 9, 56, .88)
}

.deep-purple-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(36, 9, 56, .65)
}

.deep-purple-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(36, 9, 56, .5)
}

.deep-purple-skin .btn-primary {
    background-color: #ac85f0 !important;
    color: #fff !important
}

.deep-purple-skin .btn-primary:hover {
    background-color: #bb9cf3
}

.deep-purple-skin .btn-primary.active,
.deep-purple-skin .btn-primary:active,
.deep-purple-skin .btn-primary:focus {
    background-color: #8d58ea
}

.deep-purple-skin .btn-primary.dropdown-toggle {
    background-color: #ac85f0 !important
}

.deep-purple-skin .btn-primary.dropdown-toggle:focus,
.deep-purple-skin .btn-primary.dropdown-toggle:hover {
    background-color: #bb9cf3 !important
}

.deep-purple-skin .btn-primary:not([disabled]):not(.disabled).active,
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.deep-purple-skin .btn-primary.dropdown-toggle {
    background-color: #ac85f0 !important
}

.deep-purple-skin .btn-secondary {
    background-color: #6e4ca3 !important;
    color: #fff !important
}

.deep-purple-skin .btn-secondary:hover {
    background-color: #7a58b1
}

.deep-purple-skin .btn-secondary.active,
.deep-purple-skin .btn-secondary:active,
.deep-purple-skin .btn-secondary:focus {
    background-color: #573c80
}

.deep-purple-skin .btn-secondary.dropdown-toggle {
    background-color: #6e4ca3 !important
}

.deep-purple-skin .btn-secondary.dropdown-toggle:focus,
.deep-purple-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #7a58b1 !important
}

.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled).active,
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.deep-purple-skin .btn-secondary.dropdown-toggle {
    background-color: #6e4ca3 !important
}

.deep-purple-skin .btn-default {
    background-color: #372e5f !important;
    color: #fff !important
}

.deep-purple-skin .btn-default:hover {
    background-color: #413670
}

.deep-purple-skin .btn-default.active,
.deep-purple-skin .btn-default:active,
.deep-purple-skin .btn-default:focus {
    background-color: #231d3d
}

.deep-purple-skin .btn-default.dropdown-toggle {
    background-color: #372e5f !important
}

.deep-purple-skin .btn-default.dropdown-toggle:focus,
.deep-purple-skin .btn-default.dropdown-toggle:hover {
    background-color: #413670 !important
}

.deep-purple-skin .btn-default:not([disabled]):not(.disabled).active,
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.deep-purple-skin .btn-default.dropdown-toggle {
    background-color: #372e5f !important
}

.deep-purple-skin .card .btn-action {
    background: #372e5f
}

.deep-purple-skin .card .btn-action:focus,
.deep-purple-skin .card .btn-action:hover {
    background-color: #413670 !important
}

.deep-purple-skin .card .btn-action.active {
    background-color: #0f0d1a !important
}

.deep-purple-skin input[type=email]:focus:not([readonly]),
.deep-purple-skin input[type=password]:focus:not([readonly]),
.deep-purple-skin input[type=text]:focus:not([readonly]),
.deep-purple-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #ac85f0;
    box-shadow: 0 1px 0 0 #ac85f0
}

.deep-purple-skin input[type=email]:focus:not([readonly])+label,
.deep-purple-skin input[type=password]:focus:not([readonly])+label,
.deep-purple-skin input[type=text]:focus:not([readonly])+label,
.deep-purple-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #ac85f0
}

.deep-purple-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #ac85f0;
    border-bottom: 2px solid #ac85f0
}

.deep-purple-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.deep-purple-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #ac85f0;
    border-color: #ac85f0
}

.deep-purple-skin .md-form .prefix.active {
    color: #ac85f0
}

.deep-purple-skin .dropdown-content li:not(.disabled) span {
    color: #ac85f0
}

.deep-purple-skin .top-nav-collapse {
    background-color: #7f7e91
}

.deep-purple-skin .carousel-multi-item .carousel-indicators li,
.deep-purple-skin .carousel-multi-item .carousel-indicators li.active,
.deep-purple-skin .carousel-multi-item .controls-top>a {
    background-color: #ac85f0
}

.deep-purple-skin .card-header,
.deep-purple-skin .form-header {
    background-color: #b28ef1
}

.deep-purple-skin .spinner-primary-color,
.deep-purple-skin .spinner-primary-color-only {
    border-color: #d0637c
}

.deep-purple-skin .pagination-primary-color .page-item.active .page-link,
.deep-purple-skin .pagination-primary-color .page-item.active .page-link:focus,
.deep-purple-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #d0637c;
    border-color: #d0637c
}

.deep-purple-skin .pagination-primary-color .page-link {
    color: #d0637c
}

.navy-blue-skin .gradient {
    background: #222735;
    background: -webkit-linear-gradient(-45deg, #222735 0, #4f68a6 100%);
    background: -webkit-linear-gradient(315deg, #222735 0, #4f68a6 100%);
    background: linear-gradient(135deg, #222735 0, #4f68a6 100%)
}

.navy-blue-skin .primary-color,
.navy-blue-skin .stepper li.active a .circle,
.navy-blue-skin .stepper li.completed a .circle,
.stepper li.active a .navy-blue-skin .circle,
.stepper li.completed a .navy-blue-skin .circle {
    background-color: #309e9e !important
}

.navy-blue-skin .navbar {
    background-color: #353b50;
    color: #fff
}

.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #36b6b6
}

.navy-blue-skin .page-footer {
    background-color: #353b50
}

.navy-blue-skin .side-nav {
    background-color: #4c5678
}

.navy-blue-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.navy-blue-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #2b8f8f
}

.navy-blue-skin .side-nav .social a:hover .fa {
    color: #40c6c6 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.navy-blue-skin .side-nav .collapsible li {
    background-color: transparent
}

.navy-blue-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.navy-blue-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(37, 207, 207, .8)
}

.navy-blue-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(37, 207, 207, .8)
}

.navy-blue-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.navy-blue-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #40c6c6
}

.navy-blue-skin .side-nav .fa {
    color: #fff
}

.navy-blue-skin .side-nav .sidenav-bg.mask-strong:after,
.navy-blue-skin .side-nav .sidenav-bg:after {
    background: rgba(14, 15, 32, .8)
}

.navy-blue-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(14, 15, 32, .65)
}

.navy-blue-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(14, 15, 32, .5)
}

.navy-blue-skin .btn-primary {
    background-color: #40c6c6 !important;
    color: #fff !important
}

.navy-blue-skin .btn-primary:hover {
    background-color: #54cccc
}

.navy-blue-skin .btn-primary.active,
.navy-blue-skin .btn-primary:active,
.navy-blue-skin .btn-primary:focus {
    background-color: #30a3a3
}

.navy-blue-skin .btn-primary.dropdown-toggle {
    background-color: #40c6c6 !important
}

.navy-blue-skin .btn-primary.dropdown-toggle:focus,
.navy-blue-skin .btn-primary.dropdown-toggle:hover {
    background-color: #54cccc !important
}

.navy-blue-skin .btn-primary:not([disabled]):not(.disabled).active,
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.navy-blue-skin .btn-primary.dropdown-toggle {
    background-color: #40c6c6 !important
}

.navy-blue-skin .btn-secondary {
    background-color: #7e51b4 !important;
    color: #fff !important
}

.navy-blue-skin .btn-secondary:hover {
    background-color: #8b63bc
}

.navy-blue-skin .btn-secondary.active,
.navy-blue-skin .btn-secondary:active,
.navy-blue-skin .btn-secondary:focus {
    background-color: #653f93
}

.navy-blue-skin .btn-secondary.dropdown-toggle {
    background-color: #7e51b4 !important
}

.navy-blue-skin .btn-secondary.dropdown-toggle:focus,
.navy-blue-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #8b63bc !important
}

.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled).active,
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.navy-blue-skin .btn-secondary.dropdown-toggle {
    background-color: #7e51b4 !important
}

.navy-blue-skin .btn-default {
    background-color: #293756 !important;
    color: #fff !important
}

.navy-blue-skin .btn-default:hover {
    background-color: #314267
}

.navy-blue-skin .btn-default.active,
.navy-blue-skin .btn-default:active,
.navy-blue-skin .btn-default:focus {
    background-color: #192133
}

.navy-blue-skin .btn-default.dropdown-toggle {
    background-color: #293756 !important
}

.navy-blue-skin .btn-default.dropdown-toggle:focus,
.navy-blue-skin .btn-default.dropdown-toggle:hover {
    background-color: #314267 !important
}

.navy-blue-skin .btn-default:not([disabled]):not(.disabled).active,
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.navy-blue-skin .btn-default.dropdown-toggle {
    background-color: #293756 !important
}

.navy-blue-skin .card .btn-action {
    background: #293756
}

.navy-blue-skin .card .btn-action:focus,
.navy-blue-skin .card .btn-action:hover {
    background-color: #314267 !important
}

.navy-blue-skin .card .btn-action.active {
    background-color: #080b11 !important
}

.navy-blue-skin input[type=email]:focus:not([readonly]),
.navy-blue-skin input[type=password]:focus:not([readonly]),
.navy-blue-skin input[type=text]:focus:not([readonly]),
.navy-blue-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #40c6c6;
    box-shadow: 0 1px 0 0 #40c6c6
}

.navy-blue-skin input[type=email]:focus:not([readonly])+label,
.navy-blue-skin input[type=password]:focus:not([readonly])+label,
.navy-blue-skin input[type=text]:focus:not([readonly])+label,
.navy-blue-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #40c6c6
}

.navy-blue-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #40c6c6;
    border-bottom: 2px solid #40c6c6
}

.navy-blue-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.navy-blue-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #40c6c6;
    border-color: #40c6c6
}

.navy-blue-skin .md-form .prefix.active {
    color: #40c6c6
}

.navy-blue-skin .dropdown-content li:not(.disabled) span {
    color: #40c6c6
}

.navy-blue-skin .top-nav-collapse {
    background-color: #353b50
}

.navy-blue-skin .carousel-multi-item .carousel-indicators li,
.navy-blue-skin .carousel-multi-item .carousel-indicators li.active,
.navy-blue-skin .carousel-multi-item .controls-top>a {
    background-color: #40c6c6
}

.navy-blue-skin .card-header,
.navy-blue-skin .form-header {
    background-color: #48c8c8
}

.navy-blue-skin .spinner-primary-color,
.navy-blue-skin .spinner-primary-color-only {
    border-color: #309e9e
}

.navy-blue-skin .pagination-primary-color .page-item.active .page-link,
.navy-blue-skin .pagination-primary-color .page-item.active .page-link:focus,
.navy-blue-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #309e9e;
    border-color: #309e9e
}

.navy-blue-skin .pagination-primary-color .page-link {
    color: #309e9e
}

.pink-skin .gradient {
    background: #812b5a;
    background: -webkit-linear-gradient(-45deg, #812b5a 0, #f28cc0 100%);
    background: -webkit-linear-gradient(315deg, #812b5a 0, #f28cc0 100%);
    background: linear-gradient(135deg, #812b5a 0, #f28cc0 100%)
}

.pink-skin .primary-color,
.pink-skin .stepper li.active a .circle,
.pink-skin .stepper li.completed a .circle,
.stepper li.active a .pink-skin .circle,
.stepper li.completed a .pink-skin .circle {
    background-color: #bd5d70 !important
}

.pink-skin .navbar {
    background-color: #535466;
    color: #fff
}

.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #9192a2
}

.pink-skin .page-footer {
    background-color: #535466
}

.pink-skin .side-nav {
    background-color: #aa5077
}

.pink-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.pink-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #76778b
}

.pink-skin .side-nav .social a:hover .fa {
    color: #9fa0ae !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.pink-skin .side-nav .collapsible li {
    background-color: transparent
}

.pink-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.pink-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(0, 0, 0, .4)
}

.pink-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(0, 0, 0, .4)
}

.pink-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.pink-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #ffb0e6
}

.pink-skin .side-nav .fa {
    color: #fff
}

.pink-skin .side-nav .sidenav-bg.mask-strong:after,
.pink-skin .side-nav .sidenav-bg:after {
    background: rgba(152, 47, 88, .8)
}

.pink-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(152, 47, 88, .65)
}

.pink-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(152, 47, 88, .5)
}

.pink-skin .btn-primary {
    background-color: #601f39 !important;
    color: #fff !important
}

.pink-skin .btn-primary:hover {
    background-color: #732544
}

.pink-skin .btn-primary.active,
.pink-skin .btn-primary:active,
.pink-skin .btn-primary:focus {
    background-color: #391322
}

.pink-skin .btn-primary.dropdown-toggle {
    background-color: #601f39 !important
}

.pink-skin .btn-primary.dropdown-toggle:focus,
.pink-skin .btn-primary.dropdown-toggle:hover {
    background-color: #732544 !important
}

.pink-skin .btn-primary:not([disabled]):not(.disabled).active,
.pink-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.pink-skin .btn-primary.dropdown-toggle {
    background-color: #601f39 !important
}

.pink-skin .btn-secondary {
    background-color: #e7649d !important;
    color: #fff !important
}

.pink-skin .btn-secondary:hover {
    background-color: #ea7aab
}

.pink-skin .btn-secondary.active,
.pink-skin .btn-secondary:active,
.pink-skin .btn-secondary:focus {
    background-color: #e03881
}

.pink-skin .btn-secondary.dropdown-toggle {
    background-color: #e7649d !important
}

.pink-skin .btn-secondary.dropdown-toggle:focus,
.pink-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #ea7aab !important
}

.pink-skin .btn-secondary:not([disabled]):not(.disabled).active,
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.pink-skin .btn-secondary.dropdown-toggle {
    background-color: #e7649d !important
}

.pink-skin .btn-default {
    background-color: #535466 !important;
    color: #fff !important
}

.pink-skin .btn-default:hover {
    background-color: #5e6074
}

.pink-skin .btn-default.active,
.pink-skin .btn-default:active,
.pink-skin .btn-default:focus {
    background-color: #3c3d4a
}

.pink-skin .btn-default.dropdown-toggle {
    background-color: #535466 !important
}

.pink-skin .btn-default.dropdown-toggle:focus,
.pink-skin .btn-default.dropdown-toggle:hover {
    background-color: #5e6074 !important
}

.pink-skin .btn-default:not([disabled]):not(.disabled).active,
.pink-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.pink-skin .btn-default.dropdown-toggle {
    background-color: #535466 !important
}

.pink-skin .card .btn-action {
    background: #535466
}

.pink-skin .card .btn-action:focus,
.pink-skin .card .btn-action:hover {
    background-color: #5e6074 !important
}

.pink-skin .card .btn-action.active {
    background-color: #25262e !important
}

.pink-skin input[type=email]:focus:not([readonly]),
.pink-skin input[type=password]:focus:not([readonly]),
.pink-skin input[type=text]:focus:not([readonly]),
.pink-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #9fa0ae;
    box-shadow: 0 1px 0 0 #9fa0ae
}

.pink-skin input[type=email]:focus:not([readonly])+label,
.pink-skin input[type=password]:focus:not([readonly])+label,
.pink-skin input[type=text]:focus:not([readonly])+label,
.pink-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #9fa0ae
}

.pink-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #9fa0ae;
    border-bottom: 2px solid #9fa0ae
}

.pink-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.pink-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #9fa0ae;
    border-color: #9fa0ae
}

.pink-skin .md-form .prefix.active {
    color: #9fa0ae
}

.pink-skin .dropdown-content li:not(.disabled) span {
    color: #9fa0ae
}

.pink-skin .top-nav-collapse {
    background-color: #535466
}

.pink-skin .carousel-multi-item .carousel-indicators li,
.pink-skin .carousel-multi-item .carousel-indicators li.active,
.pink-skin .carousel-multi-item .controls-top>a {
    background-color: #9fa0ae
}

.pink-skin .card-header,
.pink-skin .form-header {
    background-color: #a5a5b3
}

.pink-skin .spinner-primary-color,
.pink-skin .spinner-primary-color-only {
    border-color: #bd5d70
}

.pink-skin .pagination-primary-color .page-item.active .page-link,
.pink-skin .pagination-primary-color .page-item.active .page-link:focus,
.pink-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #bd5d70;
    border-color: #bd5d70
}

.pink-skin .pagination-primary-color .page-link {
    color: #bd5d70
}

.indigo-skin .gradient {
    background: #272d6e;
    background: -webkit-linear-gradient(-45deg, #272d6e 0, #90a8ff 100%);
    background: -webkit-linear-gradient(315deg, #272d6e 0, #90a8ff 100%);
    background: linear-gradient(135deg, #272d6e 0, #90a8ff 100%)
}

.indigo-skin .primary-color,
.indigo-skin .stepper li.active a .circle,
.indigo-skin .stepper li.completed a .circle,
.stepper li.active a .indigo-skin .circle,
.stepper li.completed a .indigo-skin .circle {
    background-color: #7f82c5 !important
}

.indigo-skin .navbar {
    background-color: #9095aa;
    color: #fff
}

.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #fd4d79
}

.indigo-skin .page-footer {
    background-color: #9095aa
}

.indigo-skin .side-nav {
    background-color: #404f9f
}

.indigo-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.indigo-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #fc1a53
}

.indigo-skin .side-nav .social a:hover .fa {
    color: #fd668c !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.indigo-skin .side-nav .collapsible li {
    background-color: transparent
}

.indigo-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.indigo-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(250, 80, 121, .8)
}

.indigo-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(250, 80, 121, .8)
}

.indigo-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.indigo-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #ff89ac
}

.indigo-skin .side-nav .fa {
    color: #fff
}

.indigo-skin .side-nav .sidenav-bg.mask-strong:after,
.indigo-skin .side-nav .sidenav-bg:after {
    background: rgba(35, 65, 134, .8)
}

.indigo-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(35, 65, 134, .65)
}

.indigo-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(35, 65, 134, .5)
}

.indigo-skin .btn-primary {
    background-color: #fd668c !important;
    color: #fff !important
}

.indigo-skin .btn-primary:hover {
    background-color: #fd7f9f
}

.indigo-skin .btn-primary.active,
.indigo-skin .btn-primary:active,
.indigo-skin .btn-primary:focus {
    background-color: #fc3466
}

.indigo-skin .btn-primary.dropdown-toggle {
    background-color: #fd668c !important
}

.indigo-skin .btn-primary.dropdown-toggle:focus,
.indigo-skin .btn-primary.dropdown-toggle:hover {
    background-color: #fd7f9f !important
}

.indigo-skin .btn-primary:not([disabled]):not(.disabled).active,
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.indigo-skin .btn-primary.dropdown-toggle {
    background-color: #fd668c !important
}

.indigo-skin .btn-secondary {
    background-color: #3c61ae !important;
    color: #fff !important
}

.indigo-skin .btn-secondary:hover {
    background-color: #456cbf
}

.indigo-skin .btn-secondary.active,
.indigo-skin .btn-secondary:active,
.indigo-skin .btn-secondary:focus {
    background-color: #2f4c88
}

.indigo-skin .btn-secondary.dropdown-toggle {
    background-color: #3c61ae !important
}

.indigo-skin .btn-secondary.dropdown-toggle:focus,
.indigo-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #456cbf !important
}

.indigo-skin .btn-secondary:not([disabled]):not(.disabled).active,
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.indigo-skin .btn-secondary.dropdown-toggle {
    background-color: #3c61ae !important
}

.indigo-skin .btn-default {
    background-color: #9095aa !important;
    color: #fff !important
}

.indigo-skin .btn-default:hover {
    background-color: #9ea3b5
}

.indigo-skin .btn-default.active,
.indigo-skin .btn-default:active,
.indigo-skin .btn-default:focus {
    background-color: #737994
}

.indigo-skin .btn-default.dropdown-toggle {
    background-color: #9095aa !important
}

.indigo-skin .btn-default.dropdown-toggle:focus,
.indigo-skin .btn-default.dropdown-toggle:hover {
    background-color: #9ea3b5 !important
}

.indigo-skin .btn-default:not([disabled]):not(.disabled).active,
.indigo-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.indigo-skin .btn-default.dropdown-toggle {
    background-color: #9095aa !important
}

.indigo-skin .card .btn-action {
    background: #9095aa
}

.indigo-skin .card .btn-action:focus,
.indigo-skin .card .btn-action:hover {
    background-color: #9ea3b5 !important
}

.indigo-skin .card .btn-action.active {
    background-color: #5c6178 !important
}

.indigo-skin input[type=email]:focus:not([readonly]),
.indigo-skin input[type=password]:focus:not([readonly]),
.indigo-skin input[type=text]:focus:not([readonly]),
.indigo-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #fd668c;
    box-shadow: 0 1px 0 0 #fd668c
}

.indigo-skin input[type=email]:focus:not([readonly])+label,
.indigo-skin input[type=password]:focus:not([readonly])+label,
.indigo-skin input[type=text]:focus:not([readonly])+label,
.indigo-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #fd668c
}

.indigo-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #fd668c;
    border-bottom: 2px solid #fd668c
}

.indigo-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.indigo-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #fd668c;
    border-color: #fd668c
}

.indigo-skin .md-form .prefix.active {
    color: #fd668c
}

.indigo-skin .dropdown-content li:not(.disabled) span {
    color: #fd668c
}

.indigo-skin .top-nav-collapse {
    background-color: #9095aa
}

.indigo-skin .carousel-multi-item .carousel-indicators li,
.indigo-skin .carousel-multi-item .carousel-indicators li.active,
.indigo-skin .carousel-multi-item .controls-top>a {
    background-color: #fd668c
}

.indigo-skin .card-header,
.indigo-skin .form-header {
    background-color: #fd7094
}

.indigo-skin .spinner-primary-color,
.indigo-skin .spinner-primary-color-only {
    border-color: #7f82c5
}

.indigo-skin .pagination-primary-color .page-item.active .page-link,
.indigo-skin .pagination-primary-color .page-item.active .page-link:focus,
.indigo-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #7f82c5;
    border-color: #7f82c5
}

.indigo-skin .pagination-primary-color .page-link {
    color: #7f82c5
}

.light-blue-skin .gradient {
    background: #69adf4;
    background: -webkit-linear-gradient(-45deg, #69adf4 0, #69adf4 100%);
    background: -webkit-linear-gradient(315deg, #69adf4 0, #69adf4 100%);
    background: linear-gradient(135deg, #69adf4 0, #69adf4 100%)
}

.light-blue-skin .primary-color,
.light-blue-skin .stepper li.active a .circle,
.light-blue-skin .stepper li.completed a .circle,
.stepper li.active a .light-blue-skin .circle,
.stepper li.completed a .light-blue-skin .circle {
    background-color: #3d799c !important
}

.light-blue-skin .navbar {
    background-color: #3f5c80;
    color: #fff
}

.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #a5b6be
}

.light-blue-skin .page-footer {
    background-color: #3f5c80
}

.light-blue-skin .side-nav {
    background-color: #6a9ed3
}

.light-blue-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.light-blue-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #889ea9
}

.light-blue-skin .side-nav .social a:hover .fa {
    color: #b4c2c9 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.light-blue-skin .side-nav .collapsible li {
    background-color: transparent
}

.light-blue-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.light-blue-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(29, 54, 86, .6)
}

.light-blue-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(29, 54, 86, .6)
}

.light-blue-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.light-blue-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #aadeff
}

.light-blue-skin .side-nav .fa {
    color: #fff
}

.light-blue-skin .side-nav .sidenav-bg.mask-strong:after,
.light-blue-skin .side-nav .sidenav-bg:after {
    background: rgba(87, 134, 180, .8)
}

.light-blue-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(87, 134, 180, .65)
}

.light-blue-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(87, 134, 180, .5)
}

.light-blue-skin .btn-primary {
    background-color: #ff4a67 !important;
    color: #fff !important
}

.light-blue-skin .btn-primary:hover {
    background-color: #ff647c
}

.light-blue-skin .btn-primary.active,
.light-blue-skin .btn-primary:active,
.light-blue-skin .btn-primary:focus {
    background-color: #ff173c
}

.light-blue-skin .btn-primary.dropdown-toggle {
    background-color: #ff4a67 !important
}

.light-blue-skin .btn-primary.dropdown-toggle:focus,
.light-blue-skin .btn-primary.dropdown-toggle:hover {
    background-color: #ff647c !important
}

.light-blue-skin .btn-primary:not([disabled]):not(.disabled).active,
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.light-blue-skin .btn-primary.dropdown-toggle {
    background-color: #ff4a67 !important
}

.light-blue-skin .btn-secondary {
    background-color: #64c3f0 !important;
    color: #fff !important
}

.light-blue-skin .btn-secondary:hover {
    background-color: #7bccf2
}

.light-blue-skin .btn-secondary.active,
.light-blue-skin .btn-secondary:active,
.light-blue-skin .btn-secondary:focus {
    background-color: #36b1ec
}

.light-blue-skin .btn-secondary.dropdown-toggle {
    background-color: #64c3f0 !important
}

.light-blue-skin .btn-secondary.dropdown-toggle:focus,
.light-blue-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #7bccf2 !important
}

.light-blue-skin .btn-secondary:not([disabled]):not(.disabled).active,
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.light-blue-skin .btn-secondary.dropdown-toggle {
    background-color: #64c3f0 !important
}

.light-blue-skin .btn-default {
    background-color: #2d486a !important;
    color: #fff !important
}

.light-blue-skin .btn-default:hover {
    background-color: #35547c
}

.light-blue-skin .btn-default.active,
.light-blue-skin .btn-default:active,
.light-blue-skin .btn-default:focus {
    background-color: #1e3046
}

.light-blue-skin .btn-default.dropdown-toggle {
    background-color: #2d486a !important
}

.light-blue-skin .btn-default.dropdown-toggle:focus,
.light-blue-skin .btn-default.dropdown-toggle:hover {
    background-color: #35547c !important
}

.light-blue-skin .btn-default:not([disabled]):not(.disabled).active,
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.light-blue-skin .btn-default.dropdown-toggle {
    background-color: #2d486a !important
}

.light-blue-skin .card .btn-action {
    background: #2d486a
}

.light-blue-skin .card .btn-action:focus,
.light-blue-skin .card .btn-action:hover {
    background-color: #35547c !important
}

.light-blue-skin .card .btn-action.active {
    background-color: #0f1722 !important
}

.light-blue-skin input[type=email]:focus:not([readonly]),
.light-blue-skin input[type=password]:focus:not([readonly]),
.light-blue-skin input[type=text]:focus:not([readonly]),
.light-blue-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #b4c2c9;
    box-shadow: 0 1px 0 0 #b4c2c9
}

.light-blue-skin input[type=email]:focus:not([readonly])+label,
.light-blue-skin input[type=password]:focus:not([readonly])+label,
.light-blue-skin input[type=text]:focus:not([readonly])+label,
.light-blue-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #b4c2c9
}

.light-blue-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #b4c2c9;
    border-bottom: 2px solid #b4c2c9
}

.light-blue-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.light-blue-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #b4c2c9;
    border-color: #b4c2c9
}

.light-blue-skin .md-form .prefix.active {
    color: #b4c2c9
}

.light-blue-skin .dropdown-content li:not(.disabled) span {
    color: #b4c2c9
}

.light-blue-skin .top-nav-collapse {
    background-color: #3f5c80
}

.light-blue-skin .carousel-multi-item .carousel-indicators li,
.light-blue-skin .carousel-multi-item .carousel-indicators li.active,
.light-blue-skin .carousel-multi-item .controls-top>a {
    background-color: #b4c2c9
}

.light-blue-skin .card-header,
.light-blue-skin .form-header {
    background-color: #bac7cd
}

.light-blue-skin .spinner-primary-color,
.light-blue-skin .spinner-primary-color-only {
    border-color: #3d799c
}

.light-blue-skin .pagination-primary-color .page-item.active .page-link,
.light-blue-skin .pagination-primary-color .page-item.active .page-link:focus,
.light-blue-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #3d799c;
    border-color: #3d799c
}

.light-blue-skin .pagination-primary-color .page-link {
    color: #3d799c
}

.grey-skin .gradient {
    background: #383838;
    background: -webkit-linear-gradient(-45deg, #383838 0, #a5a4a4 100%);
    background: -webkit-linear-gradient(315deg, #383838 0, #a5a4a4 100%);
    background: linear-gradient(135deg, #383838 0, #a5a4a4 100%)
}

.grey-skin .primary-color,
.grey-skin .stepper li.active a .circle,
.grey-skin .stepper li.completed a .circle,
.stepper li.active a .grey-skin .circle,
.stepper li.completed a .grey-skin .circle {
    background-color: #2b2b2b !important
}

.grey-skin .navbar {
    background-color: #44474b;
    color: #fff
}

.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #5eb7ee
}

.grey-skin .page-footer {
    background-color: #44474b
}

.grey-skin .side-nav {
    background-color: #a5a4a4
}

.grey-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.grey-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #30a2e9
}

.grey-skin .side-nav .social a:hover .fa {
    color: #75c1f0 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.grey-skin .side-nav .collapsible li {
    background-color: transparent
}

.grey-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.grey-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(0, 0, 0, .4)
}

.grey-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(0, 0, 0, .4)
}

.grey-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.grey-skin .side-nav .collapsible li .collapsible-body a:hover {
    color: #afdffc
}

.grey-skin .side-nav .fa {
    color: #fff
}

.grey-skin .side-nav .sidenav-bg.mask-strong:after,
.grey-skin .side-nav .sidenav-bg:after {
    background: rgba(121, 121, 121, .8)
}

.grey-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(121, 121, 121, .65)
}

.grey-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(121, 121, 121, .5)
}

.grey-skin .btn-primary {
    background-color: #46aded !important;
    color: #fff !important
}

.grey-skin .btn-primary:hover {
    background-color: #5db7ef
}

.grey-skin .btn-primary.active,
.grey-skin .btn-primary:active,
.grey-skin .btn-primary:focus {
    background-color: #1898e8
}

.grey-skin .btn-primary.dropdown-toggle {
    background-color: #46aded !important
}

.grey-skin .btn-primary.dropdown-toggle:focus,
.grey-skin .btn-primary.dropdown-toggle:hover {
    background-color: #5db7ef !important
}

.grey-skin .btn-primary:not([disabled]):not(.disabled).active,
.grey-skin .btn-primary:not([disabled]):not(.disabled):active,
.show>.grey-skin .btn-primary.dropdown-toggle {
    background-color: #46aded !important
}

.grey-skin .btn-secondary {
    background-color: #7d8488 !important;
    color: #fff !important
}

.grey-skin .btn-secondary:hover {
    background-color: #8a9194
}

.grey-skin .btn-secondary.active,
.grey-skin .btn-secondary:active,
.grey-skin .btn-secondary:focus {
    background-color: #646a6e
}

.grey-skin .btn-secondary.dropdown-toggle {
    background-color: #7d8488 !important
}

.grey-skin .btn-secondary.dropdown-toggle:focus,
.grey-skin .btn-secondary.dropdown-toggle:hover {
    background-color: #8a9194 !important
}

.grey-skin .btn-secondary:not([disabled]):not(.disabled).active,
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active,
.show>.grey-skin .btn-secondary.dropdown-toggle {
    background-color: #7d8488 !important
}

.grey-skin .btn-default {
    background-color: #353637 !important;
    color: #fff !important
}

.grey-skin .btn-default:hover {
    background-color: #424344
}

.grey-skin .btn-default.active,
.grey-skin .btn-default:active,
.grey-skin .btn-default:focus {
    background-color: #1c1d1d
}

.grey-skin .btn-default.dropdown-toggle {
    background-color: #353637 !important
}

.grey-skin .btn-default.dropdown-toggle:focus,
.grey-skin .btn-default.dropdown-toggle:hover {
    background-color: #424344 !important
}

.grey-skin .btn-default:not([disabled]):not(.disabled).active,
.grey-skin .btn-default:not([disabled]):not(.disabled):active,
.show>.grey-skin .btn-default.dropdown-toggle {
    background-color: #353637 !important
}

.grey-skin .card .btn-action {
    background: #353637
}

.grey-skin .card .btn-action:focus,
.grey-skin .card .btn-action:hover {
    background-color: #424344 !important
}

.grey-skin .card .btn-action.active {
    background-color: #030303 !important
}

.grey-skin input[type=email]:focus:not([readonly]),
.grey-skin input[type=password]:focus:not([readonly]),
.grey-skin input[type=text]:focus:not([readonly]),
.grey-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #75c1f0;
    box-shadow: 0 1px 0 0 #75c1f0
}

.grey-skin input[type=email]:focus:not([readonly])+label,
.grey-skin input[type=password]:focus:not([readonly])+label,
.grey-skin input[type=text]:focus:not([readonly])+label,
.grey-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #75c1f0
}

.grey-skin input[type=checkbox]:checked+label:before {
    border-right: 2px solid #75c1f0;
    border-bottom: 2px solid #75c1f0
}

.grey-skin input[type=checkbox].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.grey-skin input[type=checkbox].filled-in:checked+label:after {
    background-color: #75c1f0;
    border-color: #75c1f0
}

.grey-skin .md-form .prefix.active {
    color: #75c1f0
}

.grey-skin .dropdown-content li:not(.disabled) span {
    color: #75c1f0
}

.grey-skin .top-nav-collapse {
    background-color: #44474b
}

.grey-skin .carousel-multi-item .carousel-indicators li,
.grey-skin .carousel-multi-item .carousel-indicators li.active,
.grey-skin .carousel-multi-item .controls-top>a {
    background-color: #75c1f0
}

.grey-skin .card-header,
.grey-skin .form-header {
    background-color: #7ec5f1
}

.grey-skin .spinner-primary-color,
.grey-skin .spinner-primary-color-only {
    border-color: #2b2b2b
}

.grey-skin .pagination-primary-color .page-item.active .page-link,
.grey-skin .pagination-primary-color .page-item.active .page-link:focus,
.grey-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #2b2b2b
}

.grey-skin .pagination-primary-color .page-link {
    color: #2b2b2b
}

.picker__input {
    cursor: default
}

.picker__input.picker__input--active {
    border-color: #0089ec
}

.picker {
    font-size: 16px;
    text-align: center;
    line-height: 1.2;
    color: #000;
    position: absolute;
    z-index: 10000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.picker .picker__holder {
    width: 100%;
    overflow-y: auto;
    overflow-scrolling: touch;
    position: fixed;
    -webkit-transition: background .15s ease-out, top 0s .15s;
    transition: background .15s ease-out, top 0s .15s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.picker .picker__frame,
.picker .picker__holder {
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%
}

.picker .picker__frame {
    position: absolute;
    margin: 0 auto;
    min-width: 256px;
    max-width: 325px;
    width: 300px;
    max-height: 350px;
    opacity: 0;
    -webkit-transition: all .15s ease-out;
    transition: all .15s ease-out
}

@media (min-height:28.875em) {
    .picker .picker__frame {
        overflow: visible;
        top: auto;
        bottom: -100%;
        max-height: 80%
    }
}

@media (min-height:40.125em) {
    .picker .picker__frame {
        margin-bottom: 7.5%
    }
}

.picker .picker__frame .picker__wrap {
    display: table;
    width: 100%;
    height: 100%
}

@media (min-height:28.875em) {
    .picker .picker__frame .picker__wrap {
        display: block
    }
}

.picker .picker__box {
    background: #fff;
    display: table-cell;
    vertical-align: middle
}

@media (min-height:28.875em) {
    .picker .picker__box {
        display: block;
        border: 1px solid #777;
        border-top-color: #898989;
        border-bottom-width: 0;
        border-radius: 5px 5px 0 0;
        box-shadow: 0 12px 36px 16px rgba(0, 0, 0, .24)
    }
}

.picker--opened .picker__holder {
    top: 0;
    background: 0 0;
    zoom: 1;
    background: rgba(0, 0, 0, .32);
    -webkit-transition: background .15s ease-out;
    transition: background .15s ease-out
}

.picker--opened .picker__frame {
    top: 0;
    opacity: 1
}

@media (min-height:35.875em) {
    .picker--opened .picker__frame {
        top: 10%;
        bottom: auto
    }
}

.picker__input.picker__input--active {
    border-color: #e3f2fd
}

.picker__list {
    list-style: none;
    padding: .75em 0 4.2em;
    margin: 0
}

.picker__list-item {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-bottom: -1px;
    position: relative;
    padding: .75em 1.25em
}

@media (min-height:46.75em) {
    .picker__list-item {
        padding: .5em 1em
    }
}

.picker__list-item:hover {
    cursor: pointer;
    background: #b1dcfb;
    border-color: #0089ec;
    z-index: 10
}

.picker__list-item--highlighted {
    border-color: #0089ec;
    z-index: 10
}

.picker--focused .picker__list-item--highlighted,
.picker__list-item--highlighted:hover {
    cursor: pointer;
    color: #000;
    background: #b1dcfb
}

.picker--focused .picker__list-item--selected,
.picker__list-item--selected,
.picker__list-item--selected:hover {
    background: #0089ec;
    z-index: 10
}

.picker--focused .picker__list-item--disabled,
.picker__list-item--disabled,
.picker__list-item--disabled:hover {
    background: #f5f5f5;
    border-color: #f5f5f5;
    color: #ddd;
    cursor: default;
    border-color: #ddd;
    z-index: auto
}

.picker--time .picker__button--clear {
    display: block;
    width: 80%;
    margin: 1em auto 0;
    padding: 1em 1.25em;
    background: 0 0;
    border: 0;
    font-weight: 500;
    font-size: .67em;
    text-align: center;
    text-transform: uppercase;
    color: #666
}

.picker--time .picker__button--clear:focus,
.picker--time .picker__button--clear:hover {
    color: #000;
    background: #b1dcfb;
    background: #e20;
    border-color: #e20;
    cursor: pointer;
    outline: 0
}

.picker--time .picker__button--clear:before {
    top: -.25em;
    color: #666;
    font-size: 1.25em;
    font-weight: 700
}

.picker--time .picker__frame {
    min-width: 256px;
    max-width: 320px
}

.picker--time .picker__box {
    font-size: 1em;
    background: #f2f2f2;
    padding: 0
}

@media (min-height:40.125em) {
    .picker--time .picker__box {
        margin-bottom: 5em
    }
}

.picker__date-display {
    text-align: center;
    background-color: #4285f4;
    padding-bottom: 15px;
    font-weight: 300;
    margin-bottom: 1rem
}

.picker__date-display .clockpicker-display {
    vertical-align: middle;
    display: inline-block;
    margin: auto;
    height: 85px;
    font-size: 70px;
    padding: 10px;
    padding-bottom: 0;
    color: #b2dfdb
}

.picker__date-display .clockpicker-display .clockpicker-display-column {
    float: left
}

.picker__date-display .clockpicker-display .clockpicker-display-column #click-am.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

.picker__date-display .clockpicker-display .clockpicker-display-column #click-am,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-pm {
    cursor: pointer
}

.picker__date-display .clockpicker-display .clockpicker-display-am-pm {
    padding-left: 5px;
    vertical-align: bottom;
    height: 85px
}

.picker__date-display .clockpicker-display .clockpicker-display-am-pm .clockpicker-span-am-pm {
    display: inline-block;
    font-size: 23px;
    line-height: 25px;
    color: #b2dfdb
}

.picker__date-display .clockpicker-display .clockpicker-span-hours,
.picker__date-display .clockpicker-display .clockpicker-span-minutes {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transition: color .5s;
    transition: color .5s;
    cursor: pointer
}

.clockpicker-display {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    margin: auto;
    height: 85px;
    font-size: 70px;
    padding: 10px;
    padding-bottom: 0;
    color: #b2dfdb
}

.clockpicker-display .clockpicker-display-column {
    float: left
}

.clockpicker-display .clockpicker-display-column #click-am.text-primary,
.clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

.clockpicker-display .clockpicker-display-column #click-am,
.clockpicker-display .clockpicker-display-column #click-pm {
    cursor: pointer
}

.clockpicker-display .clockpicker-display-am-pm {
    padding-left: 5px;
    vertical-align: bottom;
    height: 85px
}

.clockpicker-display .clockpicker-display-am-pm .clockpicker-span-am-pm {
    display: inline-block;
    font-size: 23px;
    line-height: 25px;
    color: #b2dfdb
}

.clockpicker-display .clockpicker-span-hours,
.clockpicker-display .clockpicker-span-minutes {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transition: color .5s;
    transition: color .5s;
    cursor: pointer
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.clockpicker-moving {
    cursor: move
}

.clockpicker-plate {
    background-color: #eee;
    border-radius: 50%;
    width: 270px;
    height: 270px;
    overflow: visible;
    position: relative;
    margin: auto;
    margin-top: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.clockpicker-plate .clockpicker-canvas,
.clockpicker-plate .clockpicker-dial {
    width: 270px;
    height: 270px;
    position: absolute;
    left: -1px;
    top: -1px
}

.clockpicker-plate .clockpicker-dial {
    -webkit-transition: opacity 350ms, -webkit-transform 350ms;
    transition: opacity 350ms, -webkit-transform 350ms;
    transition: transform 350ms, opacity 350ms;
    transition: transform 350ms, opacity 350ms, -webkit-transform 350ms
}

.clockpicker-plate .clockpicker-dial .clockpicker-tick {
    border-radius: 50%;
    color: #666;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    position: absolute;
    cursor: pointer;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    background-color: rgba(0, 150, 136, 0)
}

.clockpicker-plate .clockpicker-dial .clockpicker-tick.active,
.clockpicker-plate .clockpicker-dial .clockpicker-tick:hover {
    background-color: rgba(0, 150, 136, .25)
}

.clockpicker-plate .clockpicker-minutes {
    visibility: hidden
}

.clockpicker-plate .clockpicker-dial-out {
    opacity: 0
}

.clockpicker-plate .clockpicker-hours.clockpicker-dial-out {
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2)
}

.clockpicker-plate .clockpicker-minutes.clockpicker-dial-out {
    -webkit-transform: scale(.8, .8);
    -ms-transform: scale(.8, .8);
    transform: scale(.8, .8)
}

.clockpicker-canvas {
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.clockpicker-canvas line {
    stroke: rgba(0, 150, 136, .25);
    stroke-width: 1
}

.clockpicker-canvas-out {
    opacity: .25
}

.clockpicker-canvas-bearing {
    stroke: none;
    fill: rgba(0, 77, 64, .75)
}

.clockpicker-canvas-fg {
    stroke: none;
    fill: rgba(0, 77, 64, 0)
}

.clockpicker-canvas-fg.active {
    fill: rgba(0, 77, 64, .5)
}

.clockpicker-canvas-bg {
    stroke: none;
    fill: rgba(0, 150, 136, .25)
}

.clockpicker-canvas-bg-trans {
    fill: rgba(0, 150, 136, .25)
}

.clockpicker-am-pm-block {
    margin-top: -10px;
    width: 100%;
    height: 50px
}

.clockpicker-am-pm-block .clockpicker-button.am-button {
    height: 45px;
    width: 45px;
    float: left
}

.clockpicker-am-pm-block .clockpicker-button.pm-button {
    height: 45px;
    width: 45px;
    float: right
}

.btn-floating.btn-flat {
    padding: 0;
    background: #4285f4
}

.btn-floating.btn-flat:hover {
    box-shadow: none
}

.btn-floating.btn-flat:focus,
.btn-floating.btn-flat:hover {
    background-color: #5a95f5 !important
}

.btn-floating.btn-flat.active {
    background-color: #0b51c5 !important
}

.picker__footer .clockpicker-button {
    margin: auto;
    margin-top: 15px;
    background-color: transparent;
    text-transform: uppercase
}

.picker__footer .clockpicker-button:focus {
    background-color: transparent
}

.picker__footer .clockpicker-button:active {
    background-color: rgba(0, 150, 136, .25)
}

.darktheme .picker__box {
    background-color: #212121
}

.darktheme .picker__box .picker__date-display {
    background-color: transparent
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate {
    background-color: transparent
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-tick {
    background-color: rgba(255, 64, 129, 0)
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-tick.active,
.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-tick:hover {
    background-color: rgba(255, 64, 129, .25)
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-canvas line {
    stroke: rgba(255, 64, 129, .25)
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-canvas-bearing {
    fill: #fff
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-canvas-fg {
    fill: rgba(255, 64, 129, 0)
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-canvas-fg.active {
    fill: rgba(255, 64, 129, .5)
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-canvas-bg {
    fill: rgba(255, 64, 129, .25)
}

.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-canvas-bg-trans {
    fill: rgba(255, 64, 129, .25)
}

.darktheme .picker__box .picker__footer .clockpicker-button:active {
    background-color: rgba(255, 64, 129, .25)
}

.picker__box {
    padding: 0;
    border-radius: 2px;
    overflow: hidden
}

.picker__box .picker__header {
    text-align: center;
    position: relative;
    margin-bottom: 20px
}

.picker__box .picker__header select {
    display: inline-block !important
}

.picker__box .picker__header .picker__date-display {
    text-align: center;
    background-color: #4285f4;
    padding-bottom: 15px;
    font-weight: 300;
    margin-bottom: 1rem
}

.picker__box .picker__header .picker__date-display .picker__weekday-display {
    background-color: #206ff2;
    padding: 10px;
    font-weight: 200;
    letter-spacing: .5;
    font-size: 1rem;
    margin-bottom: 15px
}

.picker__box .picker__header .picker__date-display .picker__month-display {
    text-transform: uppercase;
    font-size: 2rem
}

.picker__box .picker__header .picker__date-display .picker__day-display {
    font-size: 4.5rem;
    font-weight: 400
}

.picker__box .picker__header .picker__date-display .picker__year-display {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, .4)
}

.picker__box .picker__header .picker__month,
.picker__box .picker__header .picker__year {
    display: inline-block;
    margin-left: .25em;
    margin-right: .25em
}

.picker__box .picker__header .picker__select--month,
.picker__box .picker__header .picker__select--year {
    height: 2em;
    padding: 0;
    margin-left: .25em;
    margin-right: .25em;
    display: inline-block
}

.picker__box .picker__header .picker__select--month:focus,
.picker__box .picker__header .picker__select--year:focus {
    border-color: rgba(0, 0, 0, .05)
}

.picker__box .picker__header .picker__select--year {
    width: 30%
}

.picker__box .picker__header .picker__select--month.browser-default {
    display: inline;
    width: 40%
}

.picker__box .picker__header .picker__select--year.browser-default {
    display: inline;
    width: 25%
}

.picker__box .picker__header .picker__nav--next,
.picker__box .picker__header .picker__nav--prev {
    position: absolute;
    padding: .5em 1.55em;
    width: 1em;
    height: 1em;
    box-sizing: content-box;
    bottom: 0
}

.picker__box .picker__header .picker__nav--next:hover,
.picker__box .picker__header .picker__nav--prev:hover {
    cursor: pointer;
    color: #000;
    background: #ecf2fc
}

.picker__box .picker__header .picker__nav--next:before,
.picker__box .picker__header .picker__nav--prev:before {
    content: " ";
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: .75em solid #676767;
    width: 0;
    height: 0;
    display: block;
    margin: 0 auto
}

.picker__box .picker__header .picker__nav--prev {
    left: -1em;
    padding-right: 1.25em
}

.picker__box .picker__header .picker__nav--next {
    right: -1em;
    padding-left: 1.25em
}

.picker__box .picker__header .picker__nav--next:before {
    border-right: 0;
    border-left: .75em solid #676767
}

.picker__box .picker__header .picker__nav--disabled,
.picker__box .picker__header .picker__nav--disabled:before,
.picker__box .picker__header .picker__nav--disabled:before:hover,
.picker__box .picker__header .picker__nav--disabled:hover {
    cursor: default;
    background: 0 0;
    border-right-color: #f5f5f5;
    border-left-color: #f5f5f5
}

.picker__box .picker__table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 1rem;
    width: 100%;
    margin-top: .75em;
    margin-bottom: .5em
}

.picker__box .picker__table td,
.picker__box .picker__table th {
    text-align: center
}

.picker__box .picker__table td {
    margin: 0;
    padding: 0
}

.picker__box .picker__table .picker__weekday {
    width: 14%;
    font-size: .9em;
    padding-bottom: .25em;
    color: #999;
    font-weight: 500
}

@media (min-height:33.875em) {
    .picker__box .picker__table .picker__weekday {
        padding-bottom: .5em
    }
}

.picker__box .picker__table .picker__day--today {
    position: relative;
    letter-spacing: -.3;
    padding: .75rem 0;
    font-weight: 400;
    border: 1px solid transparent
}

.picker__box .picker__table .picker__day.picker__day--today {
    color: #4285f4
}

.picker__box .picker__table .picker__day--disabled:before {
    border-top-color: #aaa
}

.picker__box .picker__table .picker__day--infocus {
    color: #595959;
    letter-spacing: -.3;
    padding: .75rem 0;
    font-weight: 400;
    border: 1px solid transparent
}

.picker__box .picker__table .picker__day--infocus:hover {
    cursor: pointer;
    color: #000;
    font-weight: 500
}

.picker__box .picker__table .picker__day--outfocus {
    display: none;
    padding: .75rem 0
}

.picker__box .picker__table .picker__day--outfocus:hover {
    cursor: pointer;
    color: #ddd;
    font-weight: 500
}

.picker__box .picker__table .picker--focused .picker__day--highlighted,
.picker__box .picker__table .picker__day--highlighted:hover {
    cursor: pointer
}

.picker__box .picker__table .picker--focused,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover {
    border-radius: 50%;
    -webkit-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9);
    background-color: #4285f4
}

.picker__box .picker__table .picker--focused.picker__day--outfocus,
.picker__box .picker__table .picker__day--selected.picker__day--outfocus,
.picker__box .picker__table .picker__day--selected:hover.picker__day--outfocus {
    background-color: #ecf2fc
}

.picker__box .picker__table .picker--focused,
.picker__box .picker__table .picker__day--disabled,
.picker__box .picker__table .picker__day--disabled:hover {
    background: #f5f5f5;
    border-color: #f5f5f5;
    color: #ddd;
    cursor: default
}

.picker__box .picker__table .picker__day--highlighted.picker__day--disabled,
.picker__box .picker__table .picker__day--highlighted.picker__day--disabled:hover {
    background: #bbb
}

.picker__box .picker__footer {
    text-align: right;
    padding: 5px 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.picker__box .picker__footer .picker__button--clear,
.picker__box .picker__footer .picker__button--close,
.picker__box .picker__footer .picker__button--today {
    border: 1px solid #fff;
    background: #fff;
    font-size: .8em;
    padding: 1rem 0 .7rem 0;
    font-weight: 700;
    width: 33%;
    display: inline-block;
    vertical-align: bottom;
    text-transform: uppercase
}

.picker__box .picker__footer .picker__button--clear:hover,
.picker__box .picker__footer .picker__button--close:hover,
.picker__box .picker__footer .picker__button--today:hover {
    cursor: pointer;
    color: #000;
    background: #b1dcfb;
    border-bottom-color: #b1dcfb
}

.picker__box .picker__footer .picker__button--clear:focus,
.picker__box .picker__footer .picker__button--close:focus,
.picker__box .picker__footer .picker__button--today:focus {
    background: #b1dcfb;
    border-color: rgba(0, 0, 0, .05);
    outline: 0
}

.picker__box .picker__footer .picker__button--clear:before,
.picker__box .picker__footer .picker__button--close:before,
.picker__box .picker__footer .picker__button--today:before {
    position: relative;
    display: inline-block;
    height: 0
}

.picker__box .picker__footer .picker__button--clear:before,
.picker__box .picker__footer .picker__button--today:before {
    content: " ";
    margin-right: .45em
}

.picker__box .picker__footer .picker__button--today:before {
    top: -.05em;
    width: 0;
    border-top: .66em solid #0059bc;
    border-left: .66em solid transparent
}

.picker__box .picker__footer .picker__button--clear:before {
    top: -.25em;
    width: .66em;
    border-top: 3px solid #e20
}

.picker__box .picker__footer .picker__button--close:before {
    content: "\D7";
    top: -.1em;
    vertical-align: top;
    font-size: 1.1em;
    margin-right: .35em;
    color: #777
}

.picker__box .picker__footer .picker__button--today[disabled],
.picker__box .picker__footer .picker__button--today[disabled]:hover {
    background: #f5f5f5;
    border-color: #f5f5f5;
    color: #ddd;
    cursor: default
}

.picker__box .picker__footer .picker__button--today[disabled]:before {
    border-top-color: #aaa
}

.picker__calendar-container {
    padding: 0 1rem
}

.picker__calendar-container thead {
    border: none
}

.section img {
    max-width: 100%;
    height: auto
}

.fixed-sn .double-nav,
.fixed-sn footer,
.fixed-sn main {
    padding-left: 240px
}

.fixed-sn main {
    padding-top: 5.5rem
}

@media (max-width:1440px) {

    .fixed-sn .double-nav,
    .fixed-sn footer,
    .fixed-sn main {
        padding-left: 0
    }
}

@media (min-width:600px) {

    .fixed-sn .page-footer .container-fluid,
    .fixed-sn main {
        margin-left: 2%;
        margin-right: 2%
    }
}

@media (min-width:992px) {

    .fixed-sn .page-footer .container-fluid,
    .fixed-sn main {
        margin-left: 5%;
        margin-right: 5%
    }
}

@media (min-width:1200px) {

    .fixed-sn .page-footer .container-fluid,
    .fixed-sn main {
        margin-left: 6%;
        margin-right: 6%
    }
}

.hidden-sn main {
    padding-top: 5.5rem
}

.hidden-sn .button-collapse {
    display: block;
    position: relative;
    font-size: 1.4rem;
    margin-right: 10px;
    margin-left: -6px;
    padding-left: 0
}

.feature-box {
    text-align: center
}

.feature-box .feature-title {
    font-weight: 500;
    margin-bottom: 1rem
}

.feature-box .features-big .feature-title {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.feature-box .features-big .fa {
    font-size: 4rem
}

.feature-box .features-small {
    text-align: left
}

.feature-box .features-small .fa {
    font-size: 2rem
}

.mdb-feed {
    margin: 1rem 0 1rem 0
}

.mdb-feed .news {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.mdb-feed .news .label {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    width: 2.5rem
}

.mdb-feed .news .label img {
    width: 100%;
    height: auto
}

.mdb-feed .news .excerpt {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    word-wrap: break-word;
    margin: 0 0 1.2rem 1.2rem
}

.mdb-feed .news .excerpt .brief {
    padding-bottom: .5rem;
    font-weight: 500
}

.mdb-feed .news .excerpt .brief a {
    color: #4285f4
}

.mdb-feed .news .excerpt .brief .name {
    display: inline-block;
    vertical-align: baseline
}

.mdb-feed .news .excerpt .brief .date {
    display: inline-block;
    float: none;
    padding-left: .7rem;
    font-weight: 300;
    font-size: .86rem;
    color: #9e9e9e
}

.mdb-feed .news .excerpt .added-images {
    margin-bottom: .6rem
}

.mdb-feed .news .excerpt .added-images img {
    display: inline-block;
    margin-right: .3rem;
    width: 7rem
}

.mdb-feed .news .excerpt .added-text {
    margin-bottom: .6rem;
    max-width: 450px
}

.mdb-feed .news .excerpt .feed-footer .like {
    font-weight: 300;
    font-size: .86rem;
    color: #9e9e9e
}

.mdb-feed .news .excerpt .feed-footer .like:hover .fa {
    color: #f44336;
    -webkit-transition: .4s;
    transition: .4s
}

.mdb-feed .news .excerpt .feed-footer .like:hover span {
    color: #000;
    -webkit-transition: .4s;
    transition: .4s
}

.mdb-feed .news .excerpt .feed-footer .like .fa {
    padding-right: .5rem
}

.mdb-feed .news .excerpt .feed-footer span a {
    padding-right: .3rem;
    color: #4285f4;
    cursor: default
}

.mdb-feed .news .excerpt .feed-footer .thumbs .fa {
    color: #9e9e9e;
    -webkit-transition: .4s;
    transition: .4s
}

.mdb-feed .news .excerpt .feed-footer .thumbs .fa:hover {
    color: #757575
}

.mdb-feed .news .excerpt .feed-footer .thumbs .fa-thumbs-up {
    padding-right: .3rem
}

.mdb-feed .news .excerpt .feed-footer .comment {
    color: #9e9e9e
}

.card-personal .card-body .card-title {
    font-weight: 400
}

.card-personal .card-body .card-title.title-one {
    -webkit-transition: .4s;
    transition: .4s
}

.card-personal .card-body .card-title.title-one:hover {
    color: #4285f4
}

.card-personal .card-body .card-title {
    margin-bottom: .3rem
}

.card-personal .card-body .card-meta {
    font-weight: 300;
    font-size: .86rem;
    color: #757575
}

.card-personal .card-body .card-meta .fa {
    padding-right: .5rem
}

.card-personal .card-body span {
    -webkit-transition: .5s;
    transition: .5s
}

.card-personal .card-body span:hover {
    color: #4285f4
}

.news-card .content .right-side-meta {
    float: right;
    font-weight: 300;
    color: #9e9e9e;
    margin-top: .3rem
}

.news-card .content .avatar-img {
    width: 2rem;
    margin-right: 1rem
}

.news-card .content img {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%
}

.news-card .social-meta .fa {
    padding-right: .6rem
}

.news-card .social-meta .fa-heart-o {
    cursor: pointer;
    color: rgba(0, 0, 0, .4);
    -webkit-transition: .53s;
    transition: .53s
}

.news-card .social-meta .fa-heart-o:hover {
    color: #f44336
}

.news-card .social-meta span {
    float: right
}

.news-card .md-form {
    margin-top: 1.3rem;
    margin-bottom: .3rem
}

.news-card .md-form .prefix {
    font-size: 1.5rem;
    margin-left: .2rem;
    color: rgba(0, 0, 0, .4)
}

.news-card .md-form .prefix .md-form .prefix~textarea,
.news-card .md-form .prefix~input {
    width: -webkit-calc(100% - 4rem);
    width: calc(100% - 4rem)
}

.news-card .md-form .prefix.active {
    color: rgba(0, 0, 0, .4)
}

.news-card .md-form .form-control {
    padding-top: 0;
    padding-bottom: .5rem;
    margin-left: 3rem;
    margin-bottom: 0;
    height: 1.3rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.no-radius {
    border-radius: 0 !important
}

.section .section-heading {
    margin-top: 2rem;
    margin-bottom: 4rem
}

.section-heading {
    text-align: center
}

.section-heading h1 {
    margin-top: 2rem;
    margin-bottom: 3rem
}

.section-heading p {
    margin-bottom: 3rem;
    margin-right: 15%;
    margin-left: 15%
}

.section-description {
    color: #757575;
    margin-bottom: 4rem;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center
}

@media (max-width:992px) {
    .section-description {
        margin-left: 5%;
        margin-right: 5%
    }
}

.section-blog-fw .view img {
    border-radius: 2px
}

.section-blog-fw .author-box,
.section-blog-fw .jumbotron {
    text-align: center
}

.section-blog-fw h2 {
    margin-bottom: 1rem;
    font-weight: 300
}

.section-blog-fw h2 a {
    color: #424242;
    -webkit-transition: .2s;
    transition: .2s
}

.section-blog-fw h2 a:hover {
    color: #616161;
    -webkit-transition: .2s;
    transition: .2s
}

.section-blog-fw .excerpt,
.section-blog-fw .post-text {
    margin-left: 10%;
    margin-right: 10%;
    text-align: justify
}

@media (max-width:992px) {

    .section-blog-fw .excerpt,
    .section-blog-fw .post-text {
        margin-left: 5%;
        margin-right: 5%
    }
}

.section-blog-fw hr {
    display: block
}

@media (min-width:1200px) {
    .hr-width {
        width: 83%
    }
}

.section.team-section .avatar {
    margin-bottom: 2rem;
    height: auto
}

.section.team-section .avatar .rounded-circle {
    max-width: 170px
}

.section.team-section .avatar img {
    max-width: 200px;
    height: auto
}

.section.team-section .title-color {
    font-weight: 400;
    color: rgba(0, 0, 0, .6)
}

.section.team-section h4,
.section.team-section h5 {
    margin-bottom: 1rem
}

.section.team-section .grey-text {
    color: #6e6e6e !important
}

.section h4 {
    font-weight: 400
}

.section .card-wrapper .fa-repeat,
.section .card-wrapper .fa-undo {
    font-size: 15px
}

.testimonial-carousel .carousel-control {
    background-image: none;
    top: 50%;
    -webkit-transform: translateY(-35%);
    -ms-transform: translateY(-35%);
    transform: translateY(-35%)
}

.testimonial-carousel .carousel-control:before {
    font-size: 5rem;
    color: rgba(0, 0, 0, .6)
}

.testimonial-carousel .carousel-control:hover:before {
    color: #000;
    -webkit-transition: .4s;
    transition: .4s
}

.testimonial-carousel .carousel-control.left {
    left: 6%
}

.testimonial-carousel .carousel-control.left:before {
    content: "\2039"
}

.testimonial-carousel .carousel-control.right {
    right: 6%
}

.testimonial-carousel .carousel-control.right:before {
    content: "\203a"
}

.testimonial-carousel .testimonial {
    margin-right: 15%;
    margin-left: 15%
}

.testimonial-carousel .testimonial .avatar {
    margin-bottom: 2rem
}

.testimonial-carousel .testimonial .avatar img {
    max-width: 170px
}

.testimonial-carousel .testimonial h4 {
    margin-bottom: 1.3rem;
    font-weight: 500
}

.testimonial-carousel .testimonial h5 {
    margin-bottom: .9rem;
    color: #424242;
    font-weight: 400
}

.testimonial-carousel .testimonial p {
    color: #666
}

.card-body h3,
.card-body h5 {
    font-weight: 400;
    margin-bottom: 1rem
}

.card-body h4 {
    font-weight: 500;
    margin-bottom: 1rem
}

.card-body a h5 {
    font-size: 1rem
}

.magazine-section .view {
    margin-bottom: 2rem
}

.magazine-section .single-news {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1.5rem
}

.magazine-section .single-news:last-of-type {
    border-bottom: none
}

@media (max-width:772px) {
    .magazine-section .single-news {
        padding-bottom: 1rem
    }
}

.magazine-section .single-news .news-data {
    margin-bottom: 5rem
}

.magazine-section .single-news .news-data h6 {
    float: left
}

.magazine-section .single-news .news-data p {
    float: right
}

.magazine-section .single-news h3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.magazine-section .single-news a {
    color: #424242;
    font-weight: 300;
    margin-bottom: 1rem
}

.magazine-section .single-news a:hover {
    color: #616161;
    -webkit-transition: .2s;
    transition: .2s
}

.magazine-section .single-news a .fa-angle-right {
    float: right
}

.magazine-section .single-news p {
    color: #757575
}

.magazine-section .single-news strong {
    font-weight: 500
}

.magazine-section.multi-columns h6 {
    margin-bottom: 1.5rem;
    text-align: center
}

.magazine-section.multi-columns .view {
    margin-bottom: 2rem
}

.magazine-section.multi-columns .single-news {
    padding-bottom: 1rem
}

.magazine-section.multi-columns .single-news strong {
    font-size: 1.2rem
}

.pricing-card {
    text-align: center
}

.pricing-card p {
    font-size: 1rem
}

.pricing-card ul {
    list-style-type: none;
    padding: 0
}

.pricing-card .header {
    border-radius: 6px 6px 0 0;
    color: #fff
}

.pricing-card .header h4 {
    padding: 2.5rem;
    font-weight: 500
}

.pricing-card .price {
    position: relative;
    padding-top: 3rem
}

.pricing-card .price h1,
.pricing-card .price h2 {
    font-size: 5rem;
    padding: 1.5rem;
    font-weight: 300
}

.pricing-card .price h1:before,
.pricing-card .price h2:before {
    content: "$";
    font-size: 30px;
    position: absolute;
    margin-left: -1.2rem;
    margin-top: .7rem
}

.pricing-card .price h1:after,
.pricing-card .price h2:after {
    content: "/mo";
    font-size: 30px;
    position: absolute;
    margin-top: 3rem
}

.pricing-card .price .version {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    padding: 1rem
}

.pricing-card .price .version h5 {
    padding: 0;
    margin: 0
}

.pricing-card .heading h1 {
    font-size: 4rem;
    font-weight: 400
}

.pricing-card .striped {
    padding: .6rem
}

.pricing-card .striped .fa {
    margin-right: 5px
}

.pricing-card .striped .fa-check {
    color: green
}

.pricing-card .striped .fa-times {
    color: red
}

.pricing-card .striped li {
    border-bottom: 1px solid rgba(153, 153, 153, .298039);
    margin-bottom: 1rem
}

.pricing-card.card-overlay {
    display: block;
    color: #fff
}

.pricing-card.card-overlay p {
    color: #fff
}

.pricing-card.card-overlay .price {
    padding-top: 0
}

.pricing-card .green-striped li {
    border-color: #33c28a
}

.pricing-card .orange-striped li {
    border-color: #e97d63
}

.pricing-card .purple-striped li {
    border-color: #963c94
}

.pricing-card .card-background {
    background-color: #28283f;
    border-radius: 0 0 6px 6px
}

.card .card-circle {
    border: 2px solid #e0e0e0;
    height: 120px;
    width: 120px;
    margin-bottom: 2rem;
    border-radius: 50%
}

.card .card-circle .fa {
    font-size: 4rem
}

.naked-card h5 {
    margin-top: 1.2rem
}

.naked-card .price {
    padding-top: 0
}

.form-header {
    color: #fff;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 2px
}

.card .card-body h3 {
    margin-bottom: 0;
    padding: .7rem
}

.md-form .fa.prefix {
    font-size: 1.5rem;
    margin-top: 1rem
}

.section .contact-icons {
    text-align: center;
    list-style-type: none;
    padding: 0
}

.section .contact-icons li {
    margin-bottom: 2.2rem
}

section .form form .btn-floating {
    float: right;
    position: relative;
    bottom: 3rem;
    margin-right: 0
}

section .contact {
    background-color: #103f6d;
    height: 100%
}

section .contact .contact-icons li i {
    float: left;
    clear: both;
    margin-right: 1rem
}

section .contact .contact-icons li .fa {
    font-size: 1.5rem
}

section .contact .contact-icons li p {
    padding-top: .5rem;
    text-align: left
}

section .contact i {
    color: #6b89a5
}

code[class*=language-],
pre[class*=language-] {
    color: #292b2c;
    background: #eaeaea;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    font-size: .85rem;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background: #eaeaea
}

pre[class*=language-] {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2), 0 1px 6px 0 rgba(0, 0, 0, .19);
    margin: 2.5em 0;
    overflow: auto;
    padding: 1em
}

pre[class*=language-]::-moz-selection {
    background: rgba(3, 169, 244, .7)
}

code[class*=language-]::-moz-selection,
pre[class*=language-]::-moz-selection {
    text-shadow: none;
    background: rgba(3, 169, 244, .7)
}

code[class*=language-]::selection,
pre[class*=language-]::selection {
    text-shadow: none;
    background: rgba(3, 169, 244, .7)
}

pre[class*=language-] span::-moz-selection {
    background: rgba(3, 169, 244, .7)
}

code[class*=language-] span::-moz-selection,
pre[class*=language-] span::-moz-selection {
    text-shadow: none;
    background: rgba(3, 169, 244, .7)
}

code[class*=language-] span::selection,
pre[class*=language-] span::selection {
    text-shadow: none;
    background: rgba(3, 169, 244, .7)
}

:not(pre)>code[class*=language-] {
    border: .13em solid #545454;
    box-shadow: 1px 1px .3em -.1em #000 inset;
    padding: .15em .2em .05em;
    white-space: normal
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #777
}

.namespace,
.token.punctuation {
    opacity: .7
}

.token.boolean,
.token.deleted,
.token.number,
.token.tag {
    color: #ce6849
}

.token.tag {
    box-shadow: none;
    text-shadow: none;
    font-weight: 400;
    display: inline;
    padding: 0
}

.token.builtin,
.token.constant,
.token.keyword,
.token.property,
.token.selector,
.token.symbol {
    color: #328946
}

.language-css .token.string,
.style .token.string,
.token.attr-name,
.token.attr-value,
.token.char,
.token.entity,
.token.inserted,
.token.operator,
.token.string,
.token.url,
.token.variable {
    color: #b4284f
}

.token.atrule {
    color: #7385a5
}

.token.important,
.token.regex {
    color: #e8c062
}

.token.bold,
.token.important {
    font-weight: 700
}

.token.italic {
    font-style: italic
}

.token.entity {
    cursor: help
}

pre[data-line] {
    padding: 1em 0 1em 3em;
    position: relative
}

.language-markup .token.attr-name,
.language-markup .token.punctuation,
.language-markup .token.tag {
    color: #2456a0
}

.token {
    position: relative;
    z-index: 1
}

.line-highlight {
    background: -moz-linear-gradient(to right, hsla(0, 0%, 33%, .1) 70%, hsla(0, 0%, 33%, 0));
    background: -o-linear-gradient(to right, hsla(0, 0%, 33%, .1) 70%, hsla(0, 0%, 33%, 0));
    background: -webkit-linear-gradient(to right, hsla(0, 0%, 33%, .1) 70%, hsla(0, 0%, 33%, 0));
    background: hsla(0, 0%, 33%, .25);
    background: linear-gradient(to right, hsla(0, 0%, 33%, .1) 70%, hsla(0, 0%, 33%, 0));
    border-bottom: 1px dashed #545454;
    border-top: 1px dashed #545454;
    left: 0;
    line-height: inherit;
    margin-top: .75em;
    padding: inherit 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    white-space: pre;
    z-index: 0
}

.line-highlight:before,
.line-highlight[data-end]:after {
    background-color: #8693a6;
    border-radius: 999px;
    box-shadow: 0 1px #fff;
    color: #f4f1ef;
    content: attr(data-start);
    font: 700 65%/1.5 sans-serif;
    left: .6em;
    min-width: 1em;
    padding: 0 .5em;
    position: absolute;
    text-align: center;
    text-shadow: none;
    top: .4em;
    vertical-align: .3em
}

.line-highlight[data-end]:after {
    bottom: .4em;
    content: attr(data-end);
    top: auto
}

pre.code-toolbar {
    position: relative
}

pre.code-toolbar>.toolbar {
    position: absolute;
    top: .3em;
    right: .3em;
    background: #f5f2f0;
    background: rgba(224, 224, 224, .2)
}

pre.code-toolbar>.toolbar a {
    color: #6e6e6e;
    font-size: .9rem;
    cursor: pointer;
    padding: 0 .5em;
    font-weight: 600
}

pre.code-toolbar>.toolbar a.zeroclipboard-is-hover,
pre.code-toolbar>.toolbar a:focus,
pre.code-toolbar>.toolbar a:hover {
    color: inherit
}

.wpcf7-submit,
div.bbp-submit-wrapper button {
    line-height: 1.33333;
    position: relative;
    padding: 5px 22px;
    border: 0;
    margin: 10px;
    cursor: pointer;
    border-radius: 2px;
    text-transform: uppercase;
    text-decoration: none;
    outline: 0 !important;
    color: #fff !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    background-color: #154771 !important
}

.wpcf7-submit:hover,
div.bbp-submit-wrapper button:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}

.wpcf7-submit:hover,
div.bbp-submit-wrapper button:hover {
    background-color: #1d629c !important
}

.wpcf7-submit:focus,
div.bbp-submit-wrapper button:focus {
    background-color: #1d629c !important
}

@media only screen and (min-width:992px) {
    footer.page-footer .col-md-4 {
        margin-left: 2rem
    }
}

.price-select {
    margin-left: 30%;
    margin-right: 30%
}

@media only screen and (max-width:992px) {
    .price-select {
        margin-left: 25%;
        margin-right: 25%
    }
}

@media only screen and (max-width:768px) {
    .price-select {
        margin-left: 15%;
        margin-right: 15%
    }
}

@media only screen and (max-width:500px) {
    .price-select {
        margin-left: 5%;
        margin-right: 5%
    }
}

.side-nav .collapsible a {
    height: 40px;
    line-height: 40px;
    margin-bottom: 0
}

.side-nav .collapsible .collapsible-body a {
    height: 32px;
    line-height: 32px
}

.side-nav .fa-angle-down.rotate-icon {
    top: 12px
}

.side-nav #mdw_main_search {
    height: 1.1rem
}

.side-nav .form-group {
    margin-bottom: .7rem
}

.side-nav .current-menu-item a {
    background: #33b5e5
}

#menu-item-403,
#menu-item-43627,
#menu-item-43629,
#menu-item-44795 {
    border-top: 1px solid rgba(153, 153, 153, .298039)
}

#bbpress-forums div.bbp-the-content-wrapper textarea.bbp-the-content {
    height: 200px
}

#bbpress-forums #bbp-search-form {
    margin-bottom: 2rem
}

#bbp_search_submit {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    color: #fff;
    margin: 6px;
    background-color: #154771 !important;
    padding: .6rem 1.05rem;
    font-size: 1.05rem;
    line-height: 1.11
}

#bbp_search_submit:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    color: #fff
}

#bbp_search_submit.active,
#bbp_search_submit:active,
#bbp_search_submit:focus {
    outline: 0;
    color: #fff
}

#bbp_search_submit:hover {
    background-color: #1d629c !important
}

#bbp_search_submit:focus {
    background-color: #1d629c !important
}

.newsfeed .img-fluid {
    width: 100%
}

.documentation .doc-title {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 2rem 0;
    margin: 5rem 0 2rem;
    text-transform: uppercase;
    font-size: 2rem
}

.documentation .doc-title.doc-first {
    border-top: none;
    margin-top: 0;
    padding-top: 1.2rem
}

.documentation .doc-title.doc-subtitle {
    text-transform: none;
    border-top: none;
    font-size: 1.7rem;
    margin-top: 2rem
}

.documentation .doc-title .btn {
    margin-top: -2px
}

@media (max-width:776px) {
    .documentation .doc-title .btn {
        margin-top: 1rem;
        display: block
    }
}

.documentation .col-example {
    padding: 1rem;
    background-color: #33b5e5;
    border: 2px solid #fff;
    color: #fff;
    text-align: center
}

.documentation .example-td {
    padding: 1rem;
    background-color: #33b5e5 !important;
    border: 2px solid #fff !important;
    color: #fff
}

.documentation .example-parent {
    background-color: #eee !important
}

.documentation li.circle-li {
    list-style-type: disc
}

.documentation ul.ul-flex {
    padding-left: 3rem
}

.documentation .is-visible {
    background-color: #bbdefb
}

.documentation .is-hidden {
    color: #9e9e9e
}

.documentation .scroll-box {
    position: relative
}

.documentation .scrollspy-example {
    overflow-y: scroll;
    position: relative;
    height: 200px;
    padding: 1rem
}

.documentation .dzik:after {
    content: "Wrrr. jestem wielkim strasznym dzikiem"
}

.documentation footer {
    padding-left: 0
}

.color-block {
    text-align: center;
    height: 130px;
    color: #fff;
    padding: 40px
}

.color-block-dark {
    height: 130px;
    text-align: center;
    color: #fff;
    padding: 40px
}

.color-block-dark h5 {
    font-size: 1rem
}

.dynamic-color .col-md-4 {
    margin-bottom: 2rem
}

.dynamic-color .col-md-4 div {
    padding: 7px 20px 7px 20px
}

#comments .comment-text {
    margin-top: 1rem
}

#comments button,
#comments html input[type=button],
#comments input[type=reset],
#comments input[type=submit] {
    display: inline-block;
    padding: .375rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: .25rem;
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    color: #fff;
    margin: 6px
}

#comments button:hover,
#comments html input[type=button]:hover,
#comments input[type=reset]:hover,
#comments input[type=submit]:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    color: #fff
}

p.form-submit i.btn input[type=submit] {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

p.form-submit i.btn input[type=submit]:hover {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.author-box {
    margin-bottom: 2rem
}

.comments-section {
    padding-top: 1rem;
    margin-bottom: 2rem
}

.comments-section .avatar {
    max-width: 100px !important
}

.tutorial-content pre.code-toolbar {
    max-height: 450px !important
}

.tutorial-content p {
    text-align: justify
}

.bbp-author-ip {
    display: none
}

input[name=quantity] {
    position: absolute;
    left: -99999px
}

.product_meta {
    position: absolute;
    top: -1000px
}

.variable_name label {
    font-size: 1.3rem
}

.single_variation span {
    font-size: 2.5rem
}

p.price {
    font-size: 2.5rem
}

code[class*=language-],
pre[class*=language-] {
    max-height: 450px
}

.mdb-skin-custom .navbar {
    background-color: #243a51;
    color: #fff
}

.mdb-skin-custom .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.mdb-skin-custom .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.mdb-skin-custom .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #1dade2
}

.mdb-skin-custom .page-footer {
    background-color: #243a51
}

.mdb-skin-custom .side-nav {
    background-color: #224562
}

.mdb-skin-custom .side-nav .social a:hover .fa {
    color: #33b5e5 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.mdb-skin-custom .side-nav .collapsible li {
    background-color: transparent
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(72, 198, 255, .8)
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(72, 198, 255, .8)
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-body a:hover {
    background-color: transparent;
    color: #67d5ff
}

.mdb-skin-custom .side-nav .fa {
    color: #fff
}

.mdb-skin-custom .side-nav .sidenav-bg.mask-strong:after,
.mdb-skin-custom .side-nav .sidenav-bg:after {
    background: rgba(13, 36, 60, .85)
}

.mdb-skin-custom .side-nav .sidenav-bg.mask-light:after {
    background: rgba(13, 36, 60, .65)
}

.mdb-skin-custom .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(13, 36, 60, .5)
}

.mdb-skin-custom .side-nav .sidenav-bg:after {
    z-index: -1
}

.mdb-skin-custom .btn-ptc {
    background: #08aae6
}

.mdb-skin-custom .btn-ptc:focus,
.mdb-skin-custom .btn-ptc:hover {
    background-color: #4abde8 !important
}

.mdb-skin-custom .btn-ptc.active {
    background-color: #14799e !important
}

.mdb-skin-custom .btn-dtc {
    background: #086fb9
}

.mdb-skin-custom .btn-dtc:focus,
.mdb-skin-custom .btn-dtc:hover {
    background-color: #1e71ae !important
}

.mdb-skin-custom .btn-dtc.active {
    background-color: #0b2a41 !important
}

.mdb-skin-custom .btn-stc {
    background: #083761
}

.mdb-skin-custom .btn-stc:focus,
.mdb-skin-custom .btn-stc:hover {
    background-color: #114a7d !important
}

.mdb-skin-custom .btn-stc.active {
    background-color: #02080d !important
}

.mdb-skin-custom .form-header-custom {
    background: #1b95c1 !important
}

.angular-skin .navbar {
    background-color: #4c576c;
    color: #fff
}

.angular-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active,
.angular-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.angular-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: #1dade2
}

.angular-skin .page-footer {
    background-color: #4c576c
}

.angular-skin .side-nav {
    background-color: #224562
}

.angular-skin .side-nav .social a:hover .fa {
    color: #33b5e5 !important;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.angular-skin .side-nav .collapsible li {
    background-color: transparent
}

.angular-skin .side-nav .collapsible li .collapsible-header {
    color: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear
}

.angular-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: rgba(232, 44, 64, .8)
}

.angular-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: rgba(232, 44, 64, .8)
}

.angular-skin .side-nav .collapsible li .collapsible-body a {
    color: #fff
}

.angular-skin .side-nav .collapsible li .collapsible-body a:hover {
    background-color: transparent;
    color: #fc96ad
}

.angular-skin .side-nav .fa {
    color: #fff
}

.angular-skin .side-nav .sidenav-bg.mask-strong:after,
.angular-skin .side-nav .sidenav-bg:after {
    background: rgba(52, 64, 86, .85)
}

.angular-skin .side-nav .sidenav-bg.mask-light:after {
    background: rgba(52, 64, 86, .65)
}

.angular-skin .side-nav .sidenav-bg.mask-slight:after {
    background: rgba(52, 64, 86, .5)
}

.angular-skin .btn-ptc {
    background: #08aae6
}

.angular-skin .btn-ptc:focus,
.angular-skin .btn-ptc:hover {
    background-color: #4abde8 !important
}

.angular-skin .btn-ptc.active {
    background-color: #14799e !important
}

.angular-skin .btn-dtc {
    background: #086fb9
}

.angular-skin .btn-dtc:focus,
.angular-skin .btn-dtc:hover {
    background-color: #1e71ae !important
}

.angular-skin .btn-dtc.active {
    background-color: #0b2a41 !important
}

.angular-skin .btn-stc {
    background: #083761
}

.angular-skin .btn-stc:focus,
.angular-skin .btn-stc:hover {
    background-color: #114a7d !important
}

.angular-skin .btn-stc.active {
    background-color: #02080d !important
}

.angular-skin .form-header-custom {
    background: #1b95c1 !important
}

#billing_vat_field {
    margin-bottom: 1.5rem
}

#billing_vat_field label {
    font-size: 1.2rem;
    font-weight: 500
}

div.dropdown-wrapper {
    position: relative;
    width: 100%;
    height: 0
}

ul.search-dropdown {
    position: absolute;
    top: -20px;
    width: 100%;
    background-color: #293a48;
    padding: 20px 0;
    z-index: 100
}

ul.search-dropdown li {
    padding: 0 5px
}

ul.search-dropdown li a {
    color: #fff;
    line-height: 30px;
    height: 30px
}

ul.search-dropdown li a:hover {
    background: rgba(0, 0, 0, .12)
}

.variations_form label {
    width: 100%;
    font-size: .7rem;
    padding: .85rem 1.5rem
}

.variations_form .btn-group .btn+.btn {
    margin-left: 0 !important
}

@media (min-width:992px) {
    .variations_form label {
        width: 50%
    }
}

@media (min-width:1200px) {
    .variations_form label {
        width: auto
    }
}

.variations_form label.active .fa {
    margin-left: 7px;
    font-size: .9rem;
    color: #fff
}

.variations_form label:not(.active) .fa.fa-check {
    display: none
}

.woocommerce-MyAccount-navigation ul {
    padding: .7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link {
    display: inline-block
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link a {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    color: #666;
    text-align: center;
    padding: 10px
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link:not(.is-active) a:hover {
    background: rgba(158, 158, 158, .3)
}

.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link.is-active a {
    background: #3f729b;
    color: #fff;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}

.error404 {
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.error404 main {
    -webkit-box-flex: 2;
    -webkit-flex: 2 0 auto;
    -ms-flex: 2 0 auto;
    flex: 2 0 auto
}

#wpadminbar {
    top: auto;
    bottom: 0
}

@media screen and (max-width:600px) {
    #wpadminbar {
        position: fixed
    }
}

#wpadminbar .menupop .ab-sub-wrapper,
#wpadminbar .shortlink-input {
    bottom: 32px
}

@media screen and (max-width:782px) {

    #wpadminbar .menupop .ab-sub-wrapper,
    #wpadminbar .shortlink-input {
        bottom: 46px
    }
}

@media screen and (min-width:783px) {
    .admin-bar.masthead-fixed .site-header {
        top: 0
    }
}

img[alt=amex] {
    display: none !important
}

.header-breadcrumb .breadcrumb-item.active {
    color: rgba(255, 255, 255, .65)
}

.reviews-rating {
    display: inline-block;
    width: 184px;
    height: 28px;
    /* background-image:url(https://mdbootstrap.com/img/Others/stars.png); */
    background-repeat: no-repeat
}

.stars-5 {
    background-position: -15px -22px
}

.stars-4 {
    background-position: -15px -76px
}

.stars-3 {
    background-position: -15px -134px
}

.stars-2 {
    background-position: -15px -190px
}

.stars-1 {
    background-position: -15px -248px
}

@media (max-width:760px) {

    .modal-backdrop,
    .side-modal {
        display: none !important
    }
}

#scrollspy {
    width: 100%
}

#scrollspy a {
    font-size: .9rem
}

.scrollspy-col {
    margin-left: 6%
}

.main-title {
    font-size: 3.5rem;
    margin-bottom: 1.5rem
}

.main-title:before {
    display: block;
    height: 6rem;
    margin-top: -6rem;
    visibility: hidden;
    content: ""
}

.title {
    margin-bottom: 1rem
}

.title:before {
    display: block;
    height: 6rem;
    margin-top: -6rem;
    visibility: hidden;
    content: ""
}

.description {
    margin-bottom: 2rem
}

.related-content-heading {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    overflow: visible;
    padding-top: .5rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    line-height: inherit;
    white-space: nowrap
}

.mdb-sidenav {
    overflow: hidden
}

.side-nav .logo-sn {
    padding-bottom: 1rem;
    padding-top: 1rem
}

.side-nav .search-form {
    margin-top: 0;
    border-top: 1px solid rgba(255, 255, 255, .65)
}

.side-nav .search-form input[type=text] {
    border-bottom: 1px solid rgba(255, 255, 255, .65)
}

.navbar .notifications-nav .dropdown-menu {
    width: 23rem
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

.dwqa-search {
    margin-right: 1.8rem;
    margin-bottom: 3rem
}

.dwqa-sort-by {
    margin-top: -20px
}

.btn-submit-dwqa,
.dwqa-btn-primary {
    font-size: 1rem;
    padding: .2rem 1rem;
    margin-bottom: 3rem
}

.dwqa-leaderboard {
    list-style: none
}

.dwqa-user-block {
    margin-bottom: 3rem
}

.dwqa-leaderboard img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    float: left;
    margin-right: 20px
}

.dwqa-user-header {
    font-size: 1.1rem
}

.dwqa-container .rounded {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center
}

.dwqa-container label {
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center
}

[shadow],
[shadow="xxs"] {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12) !important
}

[shadow="xs"] {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12) !important
}

[shadow="sm"] {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2) !important
}

[shadow="md"],
.shadowed {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2) !important
}

[shadow="lg"] {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2) !important
}

[shadow="xl"] {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2) !important
}

[shadow="xxl"] {
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2) !important
}

[shadow="none"],
[shadow="none"]:hover {
    box-shadow: none !important;
}

.form-header {
    margin-bottom: 2rem;
}

.md-form .prefix~input~.invalid-feedback {
    margin-left: 3rem;
}

.card.card-image {
    border-radius: 0;
}

.card.card-image .card-inner {
    margin: 0 auto;
}

.card .card-inner-wrap>.fa,
.card .card-inner-wrap>.card-bkg>.fa {
    position: absolute;
    top: 1rem;
    left: 50%;
    font-size: 4rem;
    transform: translate(-50%, 0);
    z-index: 0;
}

.card-inner {
    z-index: 1;
}

.navbar:not(.navbar-dark) .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    cursor: pointer;
}

.card[border-color] .card-up {
    border-radius: 0;
}

.card .card-up>.fa {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    font-size: 70px;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.dropdown-menu.dropdown-menu-right {
    left: 100%;
    transform: translate(-100%);
}

.dropdown .dropdown-menu a.dropdown-item {
    line-height: .9rem;
    padding: .5rem 1.5rem;
}

.dropdown .dropdown-menu h6.dropdown-header {
    padding: .1rem;
}

.hidden {
    display: none !important;
}

.card .card-body table.table tbody p {
    margin: 0;
}

.pt-7 {
    padding-top: 5rem !important;
}

table.table .form-group,
table.table .select-dropdown {
    margin-bottom: 0;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

input[type="checkbox"][look="slider"] {
    display: none;
}

input[type="checkbox"][look="slider"]+label {
    overflow: hidden;
    cursor: pointer;
    font-size: 1em;
    background-color: #FFF;
    padding: 0.5625rem;
    border-radius: 3.125rem;
    display: inline-block;
    position: relative;
    margin-right: 1.875rem;
    width: 2.5rem;
    height: 0.9375rem;
    box-sizing: initial;
    margin-bottom: initial;
    line-height: initial;
}

input[type="checkbox"][look="slider"]+label::before {
    content: attr(icon-off);
    font-family: 'iconset' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    background: #fff;
    top: 0.125rem;
    left: 0.125rem;
    z-index: 99999;
    width: 1.8125rem;
    height: 1.8125rem;
    border-radius: 6.25rem;
    box-shadow: 0 0 0.125rem rgba(0, 0, 0, .5), inset 0 -1.125rem 0.9375rem -0.625rem rgba(0, 0, 0, .05);
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
    color: #222;
    font-size: 1.25rem;
    text-align: center;
    padding-top: 4px;
    margin: 0;
}

input[type="checkbox"][look="slider"]+label::after {
    content: ' ';
    position: absolute;
    background: #E6332C;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6.25rem;
    box-shadow: inset 0 0 1.25rem rgba(0, 0, 0, .2);
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

input[type="checkbox"][look="slider"][color]+label::after {
    background: #aaa;
}

input[type="checkbox"][look="slider"]:checked+label::before {
    content: attr(icon-on);
    font-family: 'iconset' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 1.6875rem;
    border-radius: 6.25rem;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border: none;
}

input[type="checkbox"][look="slider"]:checked+label::after {
    content: ' ';
    font-size: 1.5em;
    position: absolute;
    background: #63C99C;
}

input[type="checkbox"][look="slider"][color="blue"]:checked+label::after {
    background: #63c3c9;
}

input[type="checkbox"][look="slider"][color="green"]:checked+label::after {
    background: #63C99C;
}

input[type="checkbox"][look="slider"][color="red"]:checked+label::after {
    background: #b94740;
}

input[type="checkbox"][look="slider"][color="black"]:checked+label::after {
    background: #000;
}

input[type="checkbox"][look="slider"][color="yellow"]:checked+label::after {
    background: #f3d554;
}

input[type="checkbox"][look="slider"][color="orange"]:checked+label::after {
    background: #ea7910;
}

[affixed] {
    position: fixed !important;
    z-index: 9999999999999;
}

[affixed="top-right"] {
    top: 8rem;
    right: 1rem;
}

[affixed="bottom-right"] {
    bottom: 1rem;
    right: 1rem;
}

@media only screen and (max-width: 960px) {
    .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
        box-shadow: none;
    }

    .table.table-bordered .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
        border-right: none;
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
        padding-left: 0.75rem;
        text-align: left;
    }

    .table.table-bordered .btn-group>.btn:not(:last-child):not(.dropdown-toggle)+.btn-sm {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
        padding-left: 0.75rem;
        padding-right: .75rem;
    }

    .btn[class*=btn-outline-].btn-sm,
    .btn[class*=btn-outline-].wishlist,
    .btn-group .btn {
        border-width: 1px;
    }

    .table.table-bordered .borderLeft-blk {
        padding: 0.2rem 0.4rem !important;
    }

    .table.table-bordered tr[border-color][border-color="green"]>td:last-child,
    .table.table-bordered tr:last-of-type td.borderLeft-blk {
        text-align: right !important;
    }

    .cfofxn-footer {
        padding: 0 1.5rem !important;
        font-size: 12px;
        color: #54595c;
    }

    table.table td,
    table.table th {
        padding: 0.2rem 0.5rem;
    }

    .modal:not([no-background]).show .MuiTabs-root {
        zoom: 67%;
    }

    .modal:not([no-background]).show {
        zoom: 80%;
    }

    .modal-footer {
        padding: 1rem;
    }

    .cfo-assistant {
        zoom: 60%;
    }

    .btn {
        box-shadow: none !important;
    }

    .material-icons.MuiIcon-root[class^="Component-lockIcon"] {
        padding: 20px;
        color: #ffffff;
        background: #4caf50;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 10px;
    }

    #navbarSupportedContent > .MuiIconButton-root {
        padding: 12px 0;
    }

    .z-1 {
        z-index: 1;
    }

    .calculation-editor {
        zoom: 70%;
    }
}