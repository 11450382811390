@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.notification-dot {
    background: #66bb6a;
    color: #fff;
}

.notification-dot.has-important {
    background: rgba(228, 30, 58, 1);
}

.notification-wrapper > div {
    padding: 0;
    min-width: unset !important;
    max-width: unset !important;
}

.notification-wrapper > div > div {
    padding: .5rem;
}

.notification-wrapper .notification {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notification-wrapper .notification:not(.empty) {
    padding: .5rem .75rem;
}

.notification-wrapper .notification:not(.empty) .notification-icon {
    margin-right: .5rem;
    font-size: 2rem;
}

.notification-wrapper .notification.empty .notification-icon {
    font-size: 3rem;
}

.notification-wrapper .notification .notification-body {
    flex: 1 1 auto;
    max-width: 20rem;
    font-size: 1rem;
}

.notification-list-wrapper {
    width: 35rem;
    max-height: 35rem;
    overflow-y: auto;
}

.notification-list-wrapper .header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: #eee;

    height: 3rem;
    line-height: 3rem;
    align-content: center;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    z-index: 10;
}

.notification-list-wrapper .header .notifications-title {
    font-size: 1.5rem;
    margin: 0;
    margin-left: 1rem;
}

.notification-list-wrapper .header .button-section a {
    margin-left: .5rem;
    margin-right: .5rem;
    font-size: 1.5rem;
    color: #212529;
}

.notification-list-wrapper .header .button-section .material-icons {
    font-size: 2rem;
    line-height: 3rem;
    overflow: visible;
}

.notification-list-wrapper .no-notifications {
    line-height: 3rem;
    margin: 0;
    margin-left: .5rem;
}

.notification-list-wrapper .notification {
    border-right: .3rem solid transparent;
    background: #efefef;
}

.notification-list-wrapper .notification:not(.read) {
    background: #fff;
    border-color: #66bb6a;
}

.notification-list-wrapper .notification.important {
    border-color: #d00;
}

.notification-list-wrapper .notification:not(.read).important {
    border-color: #f00;
}

.notification-list-wrapper .notification .material-icons {
    overflow: visible;
    font-size: 2.5rem;
}

.notification-list-wrapper .notification .material-icons.spinning {
    animation: spin 3s infinite linear;
    animation-direction: reverse;
}

.notification-list-wrapper .notification .material-icons.spinning.reverse-spin {
    animation-direction: normal;
}

.notification-list-wrapper .notification:not(.read) .material-icons {
    color: #66bb6a;
}

.notification-list-wrapper .notification.important:not(.read) .material-icons {
    color: #f00;
}

.notification-list-wrapper .notification.pinned {
    background: #efe;
}

.notification-list-wrapper .notification.pinned.important {
    background: #fee;
}

.notification-list-wrapper hr {
    height: 2px;
    background-color: #ccc;
}

.notification-list-wrapper ul {
    padding: 0 !important;
}

.notification-list-wrapper .notification .notif-title span {
    color: black !important;
}

/* Color Theming */
.notification-list-wrapper .notification[data-color="blue"] {
    border-color: var(--blue);
}

.notification-list-wrapper .notification[data-color="blue"].pinned {
    background: #eef;
}

.notification-list-wrapper .notification[data-color="blue"]:not(.read) .material-icons {
    color: var(--blue);
}

.notification-list-wrapper .notification[data-color="yellow"] {
    border-color: var(--amber);
}

.notification-list-wrapper .notification[data-color="yellow"].pinned {
    background: #ffffee;
}

.notification-list-wrapper .notification[data-color="yellow"]:not(.read) .material-icons {
    color: var(--amber);
}

.notification-list-wrapper .notification[data-color="green"] {
    border-color: var(--green);
}

.notification-list-wrapper .notification[data-color="green"].pinned {
    background: #efe;
}

.notification-list-wrapper .notification[data-color="green"]:not(.read) .material-icons {
    color: var(--green);
}

.notification-list-wrapper .notif-actions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 4rem;
    display: flex;
    justify-items: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%);
    pointer-events: visible;
}
.notification-list-wrapper .notification:hover .notif-actions {
    visibility: visible;
    opacity: 1;
}

.notification-list-wrapper .notif-actions > button .material-icons {
    font-size: 1.5rem;
    color: #000 !important;
}