/* https://codepen.io/bchalker/pen/pyvBmq - Cleaned up and optimized */
.slim_modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.85);
    z-index: 2;
    display: none;
    font-size: 1em;
    -webkit-transition: background 0.5s ease 0.06s;
    -moz-transition: background 0.5s ease 0.06s;
    -ms-transition: background 0.5s ease 0.06s;
    -o-transition: background 0.5s ease 0.06s;
    transition: background 0.5s ease 0.06s;
}

.slim_modal.show {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

.slim_modal.none {
    background: rgba(10, 10, 10, 0);
}

.slim_modal.red {
    background: rgba(228, 30, 58, 0.8);
}

.slim_modal.tan {
    background: rgba(202, 180, 75, 0.8);
}

.slim_modal.green {
    background: rgba(111, 171, 85, 0.8);
}

.slim_modal .sm_content {
    background: whitesmoke;
    text-align: center;
    max-width: 800px;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content {
        padding: 0px;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content {
        padding: 0px;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content {
        padding: 15px;
        width: 80%;
        max-height: 90%;
        height: auto;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content {
        padding: 15px;
        width: 80%;
        max-height: 90%;
        height: auto;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content {
        padding: 15px;
        width: 80%;
        max-height: 90%;
        height: auto;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

.slim_modal .sm_content .sm_content_inner_wrap {
    position: relative;
    margin: 0 auto;
    height: 100%;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 1px 0 rgba(30, 30, 30, 0.5);
    -moz-box-shadow: 0px 0px 1px 0 rgba(30, 30, 30, 0.5);
    box-shadow: 0px 0px 1px 0 rgba(30, 30, 30, 0.5);
    z-index: 2;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content .sm_content_inner_wrap {
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content .sm_content_inner_wrap {
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content .sm_content_inner_wrap {
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content .sm_content_inner_wrap {
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content .sm_content_inner_wrap {
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

.slim_modal .sm_content .sm_content_inner_wrap .sm_close_button {
    padding: 10px;
    width: auto;
    display: block;
    background: #efefef;
}

.slim_modal .sm_content .sm_content_inner_wrap .sm_area_top,
.slim_modal .sm_content .sm_content_inner_wrap .sm_area_bottom,
.slim_modal .sm_content .sm_content_inner_wrap .sm_area_all {
    padding: 4em;
}

.slim_modal .sm_content .sm_content_inner_wrap .sm_area_top h3 span,
.slim_modal .sm_content .sm_content_inner_wrap .sm_area_bottom h3 span,
.slim_modal .sm_content .sm_content_inner_wrap .sm_area_all h3 span {
    display: block;
    font-size: .7em;
}

.slim_modal .sm_content .sm_content_inner_wrap .sm_area_top {
    background: rgba(30, 30, 30, 0.02);
}

.slim_modal .sm_content .sm_content_inner_wrap .sm_area_bottom {
    background: white;
}

.slim_modal .sm_content .sm_icon_menu {
    opacity: 1;
    box-sizing: border-box;
    position: absolute;
    top: 40px;
    background: whitesmoke;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.slim_modal .sm_content .sm_icon_menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
}

.slim_modal .sm_content .sm_icon_menu ul li {
    font-size: 1.1em;
    height: 3em;
    line-height: 3em;
    background: #e6e6e6;
    padding: 0px 8px 0px 8px;
    margin-bottom: 1px;
    color: rgba(30, 30, 30, 0.4);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.slim_modal .sm_content .sm_icon_menu ul li.sm_close {
    background: #ffffff;
}

.slim_modal .sm_content .sm_icon_menu ul li:nth-child(2) {
    margin-top: 10px;
}

.slim_modal .sm_content .sm_icon_menu ul li:hover {
    background: #ffffff;
    color: rgba(30, 30, 30, 0.7);
}

.slim_modal .sm_content .sm_icon_menu ul li::last-child {
    border: none;
}

.slim_modal .sm_content .sm_icon_menu.is_right {
    right: 0px;
    border-radius: 0px 4px 4px 0px;
    -webkit-border-radius: 0px 4px 4px 0px;
    -moz-border-radius: 0px 4px 4px 0px;
    -ms-border-radius: 0px 4px 4px 0px;
    -o-border-radius: 0px 4px 4px 0px;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content .sm_icon_menu.is_right {
        top: 70px;
        right: 0px;
        z-index: 9;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        -moz-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        padding: 1px;
        border: none;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content .sm_icon_menu.is_right {
        top: 70px;
        right: 0px;
        z-index: 9;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        -moz-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        padding: 1px;
        border: none;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content .sm_icon_menu.is_right {
        top: 15px;
        right: 15px;
        z-index: 1;
        -webkit-box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        -moz-box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-left: none;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content .sm_icon_menu.is_right {
        top: 15px;
        right: 15px;
        z-index: 1;
        -webkit-box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        -moz-box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-left: none;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content .sm_icon_menu.is_right {
        top: 15px;
        right: 15px;
        z-index: 1;
        -webkit-box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        -moz-box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        box-shadow: 15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-left: none;
    }
}

.slim_modal .sm_content .sm_icon_menu.is_left {
    border-radius: 4px 0px 0px 4px;
    -webkit-border-radius: 4px 0px 0px 4px;
    -moz-border-radius: 4px 0px 0px 4px;
    -ms-border-radius: 4px 0px 0px 4px;
    -o-border-radius: 4px 0px 0px 4px;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content .sm_icon_menu.is_left {
        top: 70px;
        left: 0px;
        z-index: 9;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        -moz-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        padding: 1px;
        border: none;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content .sm_icon_menu.is_left {
        top: 70px;
        left: 0px;
        z-index: 9;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        -moz-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0);
        padding: 1px;
        border: none;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content .sm_icon_menu.is_left {
        top: 15px;
        left: 15px;
        z-index: 1;
        -webkit-box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        -moz-box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-right: none;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content .sm_icon_menu.is_left {
        top: 15px;
        left: 15px;
        z-index: 1;
        -webkit-box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        -moz-box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-right: none;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content .sm_icon_menu.is_left {
        top: 15px;
        left: 15px;
        z-index: 1;
        -webkit-box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        -moz-box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        box-shadow: -15px 0px 30px 0px rgba(30, 30, 30, 0.2);
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-right: none;
    }
}

.slim_modal .sm_content .sm_header {
    box-sizing: border-box;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    color: rgba(30, 30, 30, 0.7);
    text-transform: uppercase;
    margin: 0 auto;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content .sm_header {
        position: relative;
        width: 100%;
        background: #e6e6e6;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        padding-top: 0;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content .sm_header {
        position: relative;
        width: 100%;
        background: #e6e6e6;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        padding-top: 0;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content .sm_header {
        position: absolute;
        width: 90%;
        background: whitesmoke;
        border-radius: 4px 4px 0px 0px;
        -webkit-border-radius: 4px 4px 0px 0px;
        -moz-border-radius: 4px 4px 0px 0px;
        -ms-border-radius: 4px 4px 0px 0px;
        -o-border-radius: 4px 4px 0px 0px;
        padding-top: 7.5px;
        text-align: center;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content .sm_header {
        position: absolute;
        width: 90%;
        background: whitesmoke;
        border-radius: 4px 4px 0px 0px;
        -webkit-border-radius: 4px 4px 0px 0px;
        -moz-border-radius: 4px 4px 0px 0px;
        -ms-border-radius: 4px 4px 0px 0px;
        -o-border-radius: 4px 4px 0px 0px;
        padding-top: 7.5px;
        text-align: center;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content .sm_header {
        position: absolute;
        width: 90%;
        background: whitesmoke;
        border-radius: 4px 4px 0px 0px;
        -webkit-border-radius: 4px 4px 0px 0px;
        -moz-border-radius: 4px 4px 0px 0px;
        -ms-border-radius: 4px 4px 0px 0px;
        -o-border-radius: 4px 4px 0px 0px;
        padding-top: 7.5px;
        text-align: center;
    }
}

.slim_modal .sm_content.pushup {
    -moz-transform: scale(0.98) translateY(20px);
    -o-transform: scale(0.98) translateY(20px);
    -ms-transform: scale(0.98) translateY(20px);
    -webkit-transform: scale(0.98) translateY(20px);
    transform: scale(0.98) translateY(20px);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0.4);
    -moz-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0.4);
    box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0.4);
    opacity: 0;
}

.slim_modal .sm_content.pushup.show {
    -webkit-box-shadow: 0px 20px 80px 0px rgba(30, 30, 30, 0.4);
    -moz-box-shadow: 0px 20px 80px 0px rgba(30, 30, 30, 0.4);
    box-shadow: 0px 20px 80px 0px rgba(30, 30, 30, 0.4);
    opacity: 1;
}

.slim_modal .sm_content.pushdown {
    -moz-transform: scale(0.98) translateY(-20px);
    -o-transform: scale(0.98) translateY(-20px);
    -ms-transform: scale(0.98) translateY(-20px);
    -webkit-transform: scale(0.98) translateY(-20px);
    transform: scale(0.98) translateY(-20px);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0.4);
    -moz-box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0.4);
    box-shadow: 0px 0px 0px 0px rgba(30, 30, 30, 0.4);
    opacity: 0;
}

.slim_modal .sm_content.pushdown.show {
    -webkit-box-shadow: 0px -20px 80px 0px rgba(30, 30, 30, 0.4);
    -moz-box-shadow: 0px -20px 80px 0px rgba(30, 30, 30, 0.4);
    box-shadow: 0px -20px 80px 0px rgba(30, 30, 30, 0.4);
    opacity: 1;
}

.slim_modal .sm_content.show {
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.slim_modal .sm_content.show .sm_icon_menu {
    -webkit-transition: all 0.5s ease 0.06s;
    -moz-transition: all 0.5s ease 0.06s;
    -ms-transition: all 0.5s ease 0.06s;
    -o-transition: all 0.5s ease 0.06s;
    transition: all 0.5s ease 0.06s;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_right {
        top: 70px;
        right: 0px;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_right {
        top: 70px;
        right: 0px;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_right {
        top: 15px;
        right: -25px;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_right {
        top: 15px;
        right: -25px;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_right {
        top: 15px;
        right: -25px;
    }
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_left {
        top: 70px;
        left: 0px;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_left {
        top: 70px;
        left: 0px;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_left {
        top: 15px;
        left: -25px;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_left {
        top: 15px;
        left: -25px;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content.show .sm_icon_menu.is_left {
        top: 15px;
        left: -25px;
    }
}

.slim_modal .sm_content.show .sm_header {
    -webkit-transition: top 0.5s ease 0.1s;
    -moz-transition: top 0.5s ease 0.1s;
    -ms-transition: top 0.5s ease 0.1s;
    -o-transition: top 0.5s ease 0.1s;
    transition: top 0.5s ease 0.1s;
    opacity: 1;
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content.show .sm_header {
        top: 0px;
        z-index: 9;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content.show .sm_header {
        top: 0px;
        z-index: 9;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content.show .sm_header {
        top: -40px;
        z-index: 1;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content.show .sm_header {
        top: -40px;
        z-index: 1;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content.show .sm_header {
        top: -40px;
        z-index: 1;
    }
}

.slim_modal .sm_content.show .sm_header.detached {
    -webkit-transition: opacity 0.5s ease 0.1s;
    -moz-transition: opacity 0.5s ease 0.1s;
    -ms-transition: opacity 0.5s ease 0.1s;
    -o-transition: opacity 0.5s ease 0.1s;
    transition: opacity 0.5s ease 0.1s;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background: rgba(0, 0, 0, 0.9);
    color: rgba(255, 255, 255, 0.8);
}

@media only screen and (min-width: 320px) {
    .slim_modal .sm_content.show .sm_header.detached {
        top: 0px;
        z-index: 9;
    }
}

@media only screen and (min-width: 414px) {
    .slim_modal .sm_content.show .sm_header.detached {
        top: 0px;
        z-index: 9;
    }
}

@media only screen and (min-width: 769px) {
    .slim_modal .sm_content.show .sm_header.detached {
        top: -60px;
        padding-top: 0;
        z-index: 1;
    }
}

@media only screen and (min-width: 1025px) {
    .slim_modal .sm_content.show .sm_header.detached {
        top: -60px;
        padding-top: 0;
        z-index: 1;
    }
}

@media only screen and (min-width: 1401px) {
    .slim_modal .sm_content.show .sm_header.detached {
        top: -60px;
        padding-top: 0;
        z-index: 1;
    }
}

.sm_content.red {
    background: #EF798A;
}

.sm_content.red .sm_header {
    background: #EF798A;
    color: #ffffff;
}

.sm_content.tan {
    background: #E1D59A;
}

.sm_content.tan .sm_header {
    background: #E1D59A;
    color: #ffffff;
}

.sm_content.green {
    background: #a9cd99;
}

.sm_content.green .sm_header {
    background: #a9cd99;
    color: #ffffff;
}