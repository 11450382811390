.tree {
    width: 100%;
    padding-right: 1rem;
}

.tree span {
    text-align: right;
}

.tree .tree-node {
    width: 100%;
}

.tree .tree-node.node-background-stripe {
    background: #f0f0f0;
}

.tree li {
    list-style-type: none;
    position: relative;
}

.tree li::before {
    content: "";
    position: absolute;
    top:-6px;
    left:-20px;
    border-left: 1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-radius:0 0 0 0px;
    width:20px;
    height:15px;
}

.tree li::after {
    position:absolute;
    content:"";
    top:8px;
    left:-20px;
    border-left: 1px solid #ccc;
    border-top:1px solid #ccc;
    border-radius:0px 0 0 0;
    width:20px;
    height:100%;
}

.tree li:last-child::after  {
    display:none;
}

.tree li:last-child:before{
    border-radius: 0 0 0 5px;
}

ul.tree>li:first-child::before {
    display:none;
}

ul.tree > li:first-child::after {
    border-radius:5px 0 0 0;
}

.tree li a {
    padding:2px 5px;
    width: 100%;
}

.tree li a > span {
    padding: 0 .5rem;
}

.tree li a,
.tree li a > span {
    transition: all .15s ease-in-out;
}

.tree li.bold > a {
    font-weight: bold;
}

.tree li:hover, .tree li:hover a+ul li,
.tree li:focus, .tree li:focus a+ul li {
    background: #dedede; color: #000;
}

.tree li:hover a > span,
.tree li:focus a > span {
    background: rgba(0, 0, 0, 0.1); color: #000;
}

.tree li[node-highlight="red"] {
    background: #fdd;
}