[type="checkbox"]:checked+label::before {
    border-color: transparent #40d125 #40d125 transparent;
}

[type="radio"]:checked + label::after {
    border-color: #40d125;
    background-color: #40d125;
}

.form-control:focus {
    border-color: #80ff86;
}

input[type="date"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="search-md"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="text"]:focus:not([readonly]):not(.nogreen),
input[type="time"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #40d125;
    box-shadow: 0 1px 0 0 #40d125;
}

.cfo-green-line:after {
    border-color:#40d125 !important;
}

input[type="date"]:focus:not([readonly])+label,
input[type="datetime-local"]:focus:not([readonly])+label,
input[type="email"]:focus:not([readonly])+label,
input[type="number"]:focus:not([readonly])+label,
input[type="password"]:focus:not([readonly])+label,
input[type="search-md"]:focus:not([readonly])+label,
input[type="search"]:focus:not([readonly])+label,
input[type="tel"]:focus:not([readonly])+label,
input[type="text"]:focus:not([readonly])+label,
input[type="time"]:focus:not([readonly])+label,
input[type="url"]:focus:not([readonly])+label,
textarea.md-textarea:focus:not([readonly])+label {
    color: #40d125;
}

.custom-select.invalid, .form-control.invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
}

input[type="date"].invalid, input[type="date"]:focus.invalid, input[type="datetime-local"].invalid, input[type="datetime-local"]:focus.invalid, input[type="email"].invalid, input[type="email"]:focus.invalid, input[type="number"].invalid, input[type="number"]:focus.invalid, input[type="password"].invalid, input[type="password"]:focus.invalid, input[type="search-md"].invalid, input[type="search-md"]:focus.invalid, input[type="search"].invalid, input[type="search"]:focus.invalid, input[type="tel"].invalid, input[type="tel"]:focus.invalid, input[type="text"].invalid, input[type="text"]:focus.invalid, input[type="time"].invalid, input[type="time"]:focus.invalid, input[type="url"].invalid, input[type="url"]:focus.invalid, textarea.md-textarea.invalid, textarea.md-textarea:focus.invalid {
    border-bottom: 1px solid #f44336;
    -webkit-box-shadow: 0 1px 0 0 #f44336;
    box-shadow: 0 1px 0 0 #f44336;
}

a {
    color: #02d80d;
}

a:hover {
    color: #03b300;
}

.Select.is-focused:not(.is-open)>.Select-control {
    border-color: #40d125;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 255, 0, 0.1);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 255, 0, 0.1);
}

.Select.has-value.Select--single>.Select-control .Select-value a.Select-value-label:hover,
.Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value a.Select-value-label:hover,
.Select.has-value.Select--single>.Select-control .Select-value a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value a.Select-value-label:focus {
    color: #40d125;
}

.Select-option.is-selected {
    background-color: #f7fff5;
    background-color: rgba(0, 255, 0, 0.04);
}
  .Select-option.is-focused {
    background-color: #ebffed;
    background-color: rgba(0, 255, 0, 0.08);
  }

  .Select--multi .Select-value {
    background-color: #ebffed;
    background-color: rgba(0, 255, 0, 0.08);
    border: 1px solid #c3ffc2;
    border: 1px solid rgba(0, 255, 0, 0.24);
    color: #40d125;
  }

  .Select--multi a.Select-value-label {
    color: #40d125;
  }

  .Select--multi .Select-value-icon {
    border-right: 1px solid #c3ffc2;
    border-right: 1px solid rgba(0, 255, 0, 0.24);
  }
  
  .Select--multi .Select-value-icon:hover,
  .Select--multi .Select-value-icon:focus {
    background-color: #d8fdda;
    background-color: rgba(0, 230, 0, 0.08);
    color: #40d125;
  }

  .Select--multi .Select-value-icon:active {
    background-color: #c3ffc2;
    background-color: rgba(0, 255, 0, 0.24);
  }

  .Select--multi.Select--rtl .Select-value-icon {
    border-left: 1px solid #c3ffc2;
    border-left: 1px solid rgba(0, 255, 0, 0.24);
  }

  .md-form .prefix.active {
      color: #40d125;
  }